import { HELP_GROUPS, helpPaths } from "./helpPaths"

export const ACCEPTED_LANGUAGES = {
    'EN': 'en'
}

export const DELETE_ACCOUNT_TAG = 'Delete my account'

export const USER_DELETE_FUNCTION_TAG = 'USER_DELETE_FUNCTION_TAG'

export const DEFAULT_TEXT = {

    'header.tab.deals': 'Deals',

    'form.email.label': 'Email',
    'form.email.placeholder': 'Email',
    'form.email.error.required': 'Please enter your email',
    'form.email.error.valid': 'Please enter a valid email',
    'form.password.placeholder': 'Password',
    'form.password.label': 'Password',
    'form.password.signup.error.required': 'Please create a password',
    'form.password.signin.error.required': 'Please enter your password',
    'form.password.resetPassword.error.required': 'Please create a new password',
    'form.password.error.minimum': 'Password must be a minimum of 6 characters in length',
    'form.displayName.label': 'Display Name',
    'form.displayName.placeholder': 'Display name',
    'form.displayName.error.required': 'Please enter a display name',
    'form.displayName.error.minimum': 'Display name must be a minimum 3 characters in length',
    'form.displayName.error.base': `Whoops! Something went wrong, please contact <a href="mailto:${process.env.REACT_APP_URL_SUPPORT_EMAIL}"><u>support</u></a> to update your display name.`,
    'form.phoneNumber.placeholder': 'Phone number (optional)',
    'form.phoneNumber.label': 'Phone number (optional)',
    'form.baseError.inUse': 'Email is already in use. Please sign in.',
    'form.baseError.forgotPassword': "We couldn't find an account with that email address",
    'form.baseError.resetPassword': `Your reset password link has expired. <a href="{replaceUrl}"><u>Please try again.</a>`,
    'form.baseError.userNotFound': `Whoops. We could not find your account. Please contact <a href="mailto:${process.env.REACT_APP_URL_SUPPORT_EMAIL}"><u>support</u></a>.`,
    'form.baseError.whoops.signup': 'Whoops. Something went wrong, please try again.',
    'form.baseError.whoops.signin': 'Incorrect email or password.',
    'form.baseError.whoops.signin.toomany': `Your account is templorarily locked due to many failed login attempts. Please use the <a href="{replaceUrl}"><u>forgot password</u></a> link to reset your password.`,
    'form.success.forgotPassword': "If the account exists, a password reset link has been sent to your email.",
    'form.success.resetPassword': 'Password saved. Signing in....',
    'form.param.usernotfound': 'usernotfound',

    'page.auction.checks': [
        `The item is brand new, unused, unopened, undamaged, and in its original packaging (where packaging is applicable).`,
        `Delivery is free, with no minimum order threshold. Receive your order in 2 business days or less.`,
        `Bid, win and save if you're located anywhere in the US.`,
        `Each bid placed increases the price of the auction by $0.01.`,
        `Bids are non refundable once placed.`,
        `Be the last bidder before the countdown strikes 00:00:00 and be the winner.`,
        `Pay only the final price. (Plus the cost of each bid you placed)`],
    'page.sold.title': `Recent selling auctions`,
    'page.sold.blurb': `Save Up to 95% off retail price!`,


    'shoppingFrom.preText': `You're Shopping from`,
    'shoppingFrom.underline': 'Set Your Location',
    'shoppingFrom.success': `Your location is set!`,
    'shoppingFrom.title': `Update Your Location`,
    'shoppingFrom.placeholder': 'Zip code',
    'shoppingFrom.error': 'Please enter a valid zip code.',
    'shoppingFrom.button': 'Set Location',

    'member.pop.title.SELECT_CREDIT_PACK': `Add Bids`,
    'member.pop.title.ADD_CREDIT_CARD_LAST_STEP': `Credit Card`,
    'member.pop.title.AVATARS': `Select Your Avatar`,
    'member.pop.title.BIDS_ADDED': `Bids Added!`,
    'member.pop.title.EDIT_DISPLAY_NAME': `Edit display name`,
    'member.pop.title.NEW_DISPLAY_NAME': `Enter a display name`,
    'member.pop.title.SELECT_PAYMENT_METHOD': `Complete purchase`,
    'member.pop.title.SELECT_PAYMENT_METHOD.title': `Select Payment Method:`,
    'member.pop.title.EXISTING_PAYMENT_METHODS': `Select Payment Method`,
    'member.pop.title.cartModeShipping': `Shipping Address`,
    'member.pop.title.IDLE_BOX': `Are you still there?`,
    'member.pop.title.USER_DELETED': `Deactivated Account`,
    'member.pop.title.CART': `Checkout`,
    'member.pop.title.DELETE_ACCOUNT': `Delete Your Account`,
    'member.pop.title.howItWorks': `Live auction demo`,
    'member.pop.button.SELECT_CREDIT_PACK': 'Next',
    'member.pop.button.ADD_CREDIT_CARD_LAST_STEP': 'Start Bidding!',
    'member.pop.button.ADD_CREDIT_CARD_LAST_STEP.CART': 'Purchase',
    'member.pop.button.AVATARS': 'Continue',
    'member.pop.button.BIDS_ADDED': 'Continue',
    'member.pop.button.EDIT_DISPLAY_NAME': 'Save',
    'member.pop.button.NEW_DISPLAY_NAME': 'Continue',
    'member.pop.button.ADD_NEW_CARD': 'Add New Card',
    'member.pop.button.submit': 'Submit',
    'member.pop.button.cartModeShipping': 'Continue',
    'member.pop.button.CART': 'Continue',
    'member.pop.button.cartModeShipping': 'Continue',
    'member.pop.button.IDLE_BOX': 'Yes! Go Back.',
    'member.pop.button.USER_DELETED': 'OK',
    'member.pop.button.DELETE_ACCOUNT': 'Confirm',
    'member.pop.button.EXISTING_PAYMENT_METHODS': `Continue`,
    'member.pop.button.howItWorks': `Start now!`,
    'member.pop.bidSelect.bids': 'Bids',
    'member.pop.afterPurchase.bids': 'Bids Added',
    'member.pop.selectCreditCard.addNew': 'Add a new credit card',
    'member.pop.paymentMethod.error': `There was an error with your payment method. Please try again or choose a new payment method below.`,
    'member.pop.DELETE_ACCOUNT.error': `You must type the phrase above correctly.`,
    'member.pop.DELETE_ACCOUNT.error.fatal': `We were unable to delete your account at this time. Please contact support or try again later.`,
    'member.pop.paypal.extraDescriptor': `Bid Pack of`,
    'member.pop.selectCreditCard.addNewError': `There was an error with your card. Please use a different payment method or try again later.`,
    'member.pop.paypal.createIntent.fatal': `Whoops! Your order could not be created. Please try again or contact support.`,
    'member.pop.EDIT_DISPLAY_NAME.placeholder': 'New Display Name',
    'member.pop.NEW_DISPLAY_NAME.placeholder': 'Display Name',
    'member.pop.DELETE_ACCOUNT.placeholder': `Type: "${DELETE_ACCOUNT_TAG}"`,
    'member.pop.CART.bidAmountSubText': 'Bids instantly added to your account',
    'member.pop.CART.cartLoadError.ALREADY_PURCHASED': 'You have already purchased this auction.',
    'member.pop.CART.cartLoadError.NO_WINNING_ID': 'Winning auction not found, please contact support.',
    'member.pop.button.CART.cartLoadError': 'Go Back',
    'member.pop.paragraph.USER_DELETED': `This account is deactivated. If you feel this is an error please contact ${USER_DELETE_FUNCTION_TAG} with your account information.`,
    'member.pop.paragraph.logout.tag.USER_DELETED': `support`,
    'member.pop.paragraph.DELETE_ACCOUNT': `Type in the box below <strong>"${DELETE_ACCOUNT_TAG}"</strong> to confirm deletion.`,

    'page.myaccount.shippingInfo.errors.firstName': `Please enter your first name`,
    'page.myaccount.shippingInfo.errors.lastName': `Please enter your last name`,
    'page.myaccount.shippingInfo.errors.phoneNumber': `Please enter your phone number`,
    'page.myaccount.shippingInfo.errors.address': `Please enter your address`,
    'page.myaccount.shippingInfo.errors.city': `Please enter your city`,
    'page.myaccount.shippingInfo.errors.state': `Please enter your state`,
    'page.myaccount.shippingInfo.errors.zipCode': `Please enter your zip code`,
    'page.myaccount.shippingInfo.errors.base': `Whoops! Something went wrong, please contact <a href="mailto:${process.env.REACT_APP_URL_SUPPORT_EMAIL}"><u>support</u></a> to update your address.`,

    'page.myaccount.shippingInfo.firstName.placeholder': 'First Name',
    'page.myaccount.shippingInfo.lastName.placeholder': 'Last Name',
    'page.myaccount.shippingInfo.phoneNumber.placeholder': 'Phone Number',
    'page.myaccount.shippingInfo.address.placeholder': 'Address',
    'page.myaccount.shippingInfo.address2.placeholder': `Apt, suite, etc (optional)`,
    'page.myaccount.shippingInfo.city.placeholder': 'City',
    'page.myaccount.shippingInfo.state.placeholder': 'State',
    'page.myaccount.shippingInfo.zipCode.placeholder': 'Zip Code',

    'page.myaccount.menu.addBids': `Add Bids`,
    'page.myaccount.menu.myAccount': `My account`,
    'page.myaccount.menu.shippingInfo': `Shipping Info`,
    'page.myaccount.menu.winningAuctions': `Winning Auctions`,
    'page.myaccount.menu.orderHistory': `Order History`,
    'page.myaccount.menu.bidHistory': `Bid History`,
    'page.myaccount.menu.signOut': `Sign out`,

    'page.auth0.button.signin': 'Continue',

    'page.signin.title': 'Welcome back!',
    'page.signup.title': `Let's get started!`,
    'page.signup.title2': `Exclusive deals <br>on brand name items.`,
    'page.forgotPassword.title': 'Forgot Your Password?',
    'page.resetPassword.title': `Reset Your Password`,

    'page.signin.subtitle': `Sign into your account now.`,
    'page.signup.subtitle': `Sign up for free. Start bidding!`,
    'page.forgotPassword.subtitle': `A password reset link will be sent to your email.`,
    'page.resetPassword.subtitle': `Securely reset your password now.`,

    'page.signin.under.button.text': `Need to create an account?`,
    'page.signup.under.button.text': `Already a member?`,
    'page.forgotPassword.under.button.text': `Need to create an account?`,
    'page.resetPassword.under.button.text': `Need to create an account?`,

    'page.signup.icon.0': 'Top brand name items',
    'page.signup.icon.1': 'Free 2-Day shipping',
    'page.signup.icon.2': 'No membership costs',
    'page.signup.icon.3': 'No hidden fees',
    'page.signup.icon.4': 'All items brand new and unopened',
    'page.signup.icon.5': 'Up to 95% off retail price!',

    'page.myaccount.tab.profile': `Profile`,
    'page.myaccount.tab.shippingInfo': `Shipping info`,
    'page.myaccount.tab.bidHistory': `Bid history`,
    'page.myaccount.tab.paymentMethods': `Payment Methods`,
    'page.myaccount.tab.account': `Account`,

    'page.myaccount.tab.account.resetPassword': 'Reset your password',
    'page.myaccount.tab.account.pleaseCheckYourEmail': 'Please check your email for a reset password link.',

    'page.myaccount.tab.account.deleteAccount': 'Delete your account',

    'page.myaccount.tab.paymentMethods.noSaved': 'You do not have any saved payment methods.',
    'page.myaccount.tab.paymentMethods.label.zipCode': 'Zip Code:',
    'page.myaccount.tab.paymentMethods.label.expiry': 'Expiry:',

    'page.myaccount.tab.paymentMethods.error.base': `Whoops! Something went wrong. Please contact <a href="mailto:${process.env.REACT_APP_URL_SUPPORT_EMAIL}"><u>support</u></a>.`,
    'page.myaccount.tab.paymentMethods.error.zipCode': 'Zip code must be 5 numbers.',

    'button.myaccount.tab.profile.avatar': 'Avatar',
    'button.myaccount.tab.profile.displayName': 'Display Name',
    'button.myaccount.tab.profile.email': 'Email',
    'button.myaccount.tab.profile.bidsAvailable': 'Bids Available',

    'page.bidHistory.title': `Bid history`,
    'page.bidHistory.bidPrice': `(Bid Price)`,
    'page.bidHistory.noHistory': `You currently have not bid on any auctions.`,
    'page.bidHistory.view': `View Auction`,
    'page.bidHistory.page': `Page`,
    'page.bidHistory.of': `of`,

    'page.help.mobileMenu': 'Help Topics',
    'page.help.index.readNow': 'Read now',
    'page.help.intro': `Welcome to our guides! Get answers to common topics including: bidding, winning, shipping and more. Can't find the answer you're looking for? No problem. You can contact support with your question at any time and we would gladly assist you.`,
    'page.help.backToTop': 'Back To Top',

    'page.help.contact.email.placeholder': 'Your email',
    'page.help.contact.message.placeholder': 'Your email',

    'page.help.contact.error.email': `Please enter a valid email address`,
    'page.help.contact.error.message': `Please enter your message`,

    'page.help.contact.success': 'Thank you for your inquiry. Your message has been successfully received. A support agent will view your request and respond to you shortly.',

    'page.help.intro': `These guides cover all you need to know to start bidding and winning.`,
    'page.help.breadcrumbFirst': 'Help Guides',
    'page.help.relatedArticles': 'Related Articles',
    'page.help.joinButton': 'Create your account now',
    'page.help.avatarsBoxTitle': 'Avatars to choose from',
    'page.help.perBid': 'per bid',
    'page.help.bidsPack': 'Bids Pack',
    'page.help.nextTopic': 'Next Topic',


    'page.help.questions': 'Have any questions?',

    'auction.sold': 'SOLD',
    'auction.won': 'YOU WON',
    'auction.noBidders': `No Bidders Yet`,
    'auction.highBidder': `You're already the high bidder`,
    'auction.biddingHistory': `Bidding History`,
    'auction.endingSoon': `Auctions Ending Soon:`,
    'auction.countdown.ended': 'ENDED',
    'auction.creditPackageCorner': 'Credits',
    'auction.biddingHistory.winner': 'Winner',
    'auction.bottom.button': 'View All Deals',

    'auction.extraDetail.1': `Condition:`,
    'auction.extraDetail.1.text': `Brand New`,
    'auction.extraDetail.2': `Delivery to:`,
    'auction.extraDetail.2.text': `United States`,
    'auction.extraDetail.3': `Shipping:`,
    'auction.extraDetail.3.text': `2 Business Days (Free)`,
    'auction.extraDetail.4': `Payments:`,

    'auction.details.title': `Description of`,
    'auction.details.title.2': `Auction Details:`,
    'auction.details.title.3.bottom': `Don't miss out! Start bidding on auctions right now!`,

    'auction.notFound.title': 'Whoops!',
    'auction.notFound.sub': 'Auction has expired or does not exist. <br>Please select a new auction.',
    'auction.notFound.button': 'View Auctions',

    'member.pop.intro.NEW_DISPLAY_NAME': 'Your display name will appear when you place a bid',

    'url.signup': '/signup',
    'url.signin': '/signin',
    'url.forgotPassword': '/forgot-password',
    'url.sold': '/sold',
    'url.howItWorks': '/how-it-works',
    'url.help': '/help',
    'url.myAccount': '/myaccount',
    'url.winningAuctions': '/winning-auctions',
    'url.orderHistory': '/order-history',
    'url.bidHistory': '/bid-history',

    'url.signin.text': 'Sign In',
    'url.signup.text': 'Join',
    'url.forgotPassword.text': 'Forgot Your Password?',
    'url.sold.text': 'Sold',
    'url.howItWorks.text': 'How it Works',
    'url.help.text': 'Help',

    'url.terms': '/terms',
    'url.privacy': '/privacy',

    'button.signin': 'Sign In',
    'button.signup': 'Sign Up',
    'button.signup.bottom': 'Create your account now!',
    'button.join': 'Join',
    'button.resetPassword': 'Reset Password',
    'button.forgotPassword': 'Reset Password',

    'button.dealTabHeader': 'View Deals',

    'button.contact.submit': 'Submit',

    'button.myaccount.tab.account.sendReset': 'Send password reset email',

    'button.myaccount.tab.account.deleteAccount': 'Delete',

    'button.myaccount.tab.paymentMethods.addPaymentMethod': 'Add Payment Method',
    'button.myaccount.tab.paymentMethods.updateCard': 'Update Card',
    'button.myaccount.tab.paymentMethods.remove': 'Remove',
    'button.myaccount.tab.paymentMethods.close': 'Close',
    'button.myaccount.tab.paymentMethods.addNew': 'Add new payment method',

    'button.myaccount.tab.shippingInfo.save': 'Save',
    'button.myaccount.tab.shippingInfo.success': 'Address Saved',

    'button.myaccount.tab.profile.edit': 'Edit',
    'button.myaccount.tab.profile.addBids': 'Add Bids',

    'button.myaccount.tab.winningAuctions.noHistory': 'You currently have no winning auctions.',

    'button.bid': 'BID',
    'button.bid.carMode': 'BUY',
    'button.won.PAY_NOW': 'PAY NOW',
    'button.won.COMPLETE': 'PURCHASED',

    'button.help.contactUs': 'Contact Us',

    'footer.title.deals': 'Deals',
    'footer.title.help': 'Help',
    'footer.language.english': 'English',
    'footer.flag.usa': 'usa',
    'footer.phoneText': 'Phone:',

    'terms.text': 'Terms of Use',
    'privacy.text': 'Privacy Policy',

    'breadcrumb.allDeals': 'All Deals',
    'breadcrumb.and': 'and',
    'breadcrumb.sold': 'Sold',
    'breadcrumb.results': 'Results',
    'breadcrumb.page': 'Page:',

    'hero.tag': 'Save Up to 95% off',
    'hero.h1': 'Huge <br />Brand name<br /> Savings!',
    'hero.sub': 'Bid and Win to Earn Huge Savings on <br />Brand Name Items You Know and Love.',
    'hero.cta': 'Create my account',
    'hero.replay': 'Replay',

    'winningAuctions.title.ALL': 'Your Winning Auctions:',
    'winningAuctions.title.PAY_NOW': 'Your Auctions Requiring Payment:',
    'winningAuctions.title.SHIPPED': 'Your Shipped Auctions:',
    'winningAuctions.title.COMPLETE': 'Your Purchased Auctions:',
    'winningAuctions.select.filter': 'Filter',
    'winningAuctions.select.ALL': 'All',
    'winningAuctions.select.PAY_NOW': 'Pending Payment',
    'winningAuctions.select.SHIPPED': 'Shipped',
    'winningAuctions.select.COMPLETE': 'Purchased',
    'winningAuctions.none': 'You currently have no winning auctions.',
    'winningAuctions.button.none': 'All Auctions',
    'winningAuctions.filteredZero': 'You have no winning auctions that match your criteria. Try again.',

    'orderHistory.title': 'Your Order History:',
    'orderHistory.noOrders': 'You currently have no orders.',
    'orderHistory.auction.creditPurchase': 'CREDITS',

    'contactUs.form.name.label': 'Your name',
    'contactUs.form.name.placeholder': 'Your name',
    'contactUs.form.error.name.required': 'Please enter your name',
    'contactUs.form.subject.label': 'Subject',
    'contactUs.form.subject.placeholder': 'Subject',
    'contactUs.form.error.subject.required': 'Please enter a subject',
    'contactUs.form.message.label': 'How can we help?',
    'contactUs.form.message.placeholder': 'How can we help?',
    'contactUs.form.error.message.required': 'Please enter a message',
    'contactUs.form.button.submit': 'Send Request',
    'contactUs.form.error.base': 'Your message could not be sent. Please try again shortly.',
    'contactUs.form.success': 'Your inquiry has been received. A support agent will be in contact with you shortly.',
    'contactUs.form.subline': `By submitting my phone number I agree to authorize ${process.env.REACT_APP_URL_SITE_NAME} to send text messages with information regarding my account and other information. Standard message/data rates apply. Reply STOP to opt out.`,

    'howItWorks.h1': `How it works`,
    'howItWorks.subText': `Learn how to bid and win brand name items!`

}

//REPLACE URLS
DEFAULT_TEXT['form.baseError.resetPassword'] = DEFAULT_TEXT['form.baseError.resetPassword'].replace('{replaceUrl}', DEFAULT_TEXT['url.forgotPassword'])

//REPLACE URLS
DEFAULT_TEXT['form.baseError.whoops.signin.toomany'] = DEFAULT_TEXT['form.baseError.whoops.signin.toomany'].replace('{replaceUrl}', DEFAULT_TEXT['url.forgotPassword'])

export const DISPLAY_NAME_MAX_CHARACTERS = 13;
export const PASSWORD_MAX_CHARACTERS = 50;

