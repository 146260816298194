export const BRAND_LIST = Object.freeze({
    'APPLE': 'Apple',
    'BIDPACK': 'Bid Packs',
    'GIFT_CARD': 'Gift Cards',
    'NINTENDO': 'Nintendo',
    'PLAYSTATION': 'PlayStation',
    'SAMSUNG': 'Samsung',
    'XBOX': 'Xbox'
})

const BRAND_LIST_EXTRA = Object.freeze({
    'AMAZON': 'Amazon',
    'TARGET': 'Target',
    'WALMART': 'Walmart',
})

export const HELP_BRANDS = [
    BRAND_LIST_EXTRA.AMAZON,
    BRAND_LIST.APPLE,
    BRAND_LIST.NINTENDO,
    BRAND_LIST.SAMSUNG,
    BRAND_LIST.PLAYSTATION,
    BRAND_LIST_EXTRA.TARGET,
    BRAND_LIST_EXTRA.WALMART,
    BRAND_LIST.XBOX
]


export const BRAND_ALL_TEXT = {
    US: 'All'
}