import React from 'react';
import { AuctionPageBottomButtonBox } from '../../style/main';
import { Link } from 'react-router-dom';
import { DEFAULT_TEXT } from '../../content';
import { scrollTop } from '../helpers';
import GoToTop from './GoToTop';

const AuctionPageBottomButton = React.memo(() => {
    return (

        <AuctionPageBottomButtonBox className='row'>
            <div className='wrap column'>
                <Link to={"/"} className="button row" onClick={scrollTop}>
                    {DEFAULT_TEXT['auction.bottom.button']}
                </Link>

                <GoToTop />
            </div>
        </AuctionPageBottomButtonBox>


    );
})

export default AuctionPageBottomButton;