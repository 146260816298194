import styled, { css } from 'styled-components/macro';
import { assetPrefix } from '../components/helpers';

const menuSpacing = {
    1: '40px',
    2: '30px'
}


const bidButtonStickMobile = '70px';
const helpMenuHeight = '70px';

export const breakpoint = {
    1600: '1600px',
    1500: '1500px',
    1365: '1365px',
    1152: '1152px',
    1151: '1151px',
    1024: '1024px',
    1023: '1023px',
    768: '768px',
    767: '767px',
    550: '550px',
    450: '450px',
    374: '374px'
}

const breakpointHeight = {
    627: '627px'
}

const borderColor = '#eeeeee'

const topMargin = '40px'

export const selectPaymentHeight = 55;

const borderRadius = '5px';
const boxPadding = '25px';
const boxPadding2 = '20px';
const boxPaddingDescription = '55px';

const boxShadow = 'box-shadow: 0 2px 1px rgba(0,0,0,0.1);'
const boxShadowAddBids = 'box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3), 0px 3px 6px rgba(0, 0, 0, 0.2);'

const boxLayout = `
    border: 1px solid ${borderColor};
    ${boxShadow}
    border-radius: ${borderRadius};
`

const viewDealsButtonMobileBox = '80px'

const logoBox = ({ theme }) => css`

    width: 100%;
    color: ${({ theme }) => theme.white};
    background: ${({ theme }) => theme.header};
    padding: 30px 20px;
    border-radius: ${borderRadius} ${borderRadius} 0 0;

    @media (max-width: ${breakpoint[767]}) {
        padding: 25px 20px;
    }

    @media (max-width: ${breakpoint[374]}){
        padding: 20px;
    }
            
`

const radio = ({ theme }) => css`

    width: 25px;
    min-width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 2px solid #bbbbbb;
    position: relative;
    margin: 0 0 0 10px;

    .circle {

        width: 15px;
        height: 15px;
        border-radius: 50%;
        background: ${theme.button};
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        display: none;
        border: 2px solid;
        border-color: ${theme.header};

    }
`

const field = ({ theme }) => css`

    .field {
        width: 100%;
        margin: 25px 0 0;

        .inputBox {
            width: 100%;
            position: relative;

            &.active {
                label {
                    opacity: 1;
                }
                input, 
                select,
                textarea {
                    &::placeholder {
                        opacity: 0;
                    }
                }
            }

            label {
                position: absolute;
                left: 2px;
                top: -8px;
                font-size: 13px;
                background: #ffffff;
                padding: 2px 10px;
                opacity: 0;
                color: ${({ theme }) => theme.base};
                border-radius: ${borderRadius} ${borderRadius} 0 0;
                font-weight: 500;
            }

            input, 
            select,
            textarea {
                width: 100%;
                height: 60px;
                padding: 0 15px;
                font-size: 17px;
                border: 2px solid ${borderColor};
                border-radius: ${borderRadius};

                @media (max-width: ${breakpoint[767]}) {
                    height: 50px;
                }

                @media (max-width: ${breakpoint[374]}){
                    height: 45px;
                    font-size: 15px;
                }

                &[name=password] {
                    padding-right: 50px;
                }

                &:focus {
                    border-color: ${theme.button};
                }
            }

            textarea {
                height: 200px;
                padding-top: 15px;
                padding-bottom: 15px;
            }

            .passwordEye {
                position: absolute;
                right: 0;
                top: 0;
                height: 100%;
                padding: 0 20px 0 10px;
                cursor: pointer;

                svg {
                    width: 23px;
                }
            }
        }
    }

`

const error = ({ theme }) => css`

    .error {
        width: 100%;
        text-align: left;
        padding: 10px;
        font-size: 13px;
        color: ${theme.error};
        background: #ffffff;
        border: 1px solid ${theme.error};
        margin: 5px 0 0;

        a {
            color: ${theme.error};
        }

        &.base {
            margin: 18px 0 0;
        }
    }

`

const card = ({ theme }) => css`

    .card {
        img {
            width: 35px;
        }

        .cardNumber {

            margin: 0 0 0 10px;

            .dot {
                width: 5px;
                height: 5px;
                background: ${theme.base};
                border-radius: 50%;
                margin: 0 5px 0 0;

                &:nth-child(4),
                &:nth-child(8),
                &:nth-child(12) {
                    margin: 0 10px 0 0;
                }

                @media (max-width: ${breakpoint[450]}){
                    width: 3px;
                    height: 3px;
                }

                @media (max-width: ${breakpoint[374]}){
                    margin: 0 3px 0 0;

                    &:nth-child(4),
                    &:nth-child(8),
                    &:nth-child(12) {
                        margin: 0 6px 0 0;
                    }

                }


            }

            .last4 {
            }

        }

    }

`

const smallBtn = ({ theme }) => css`

    .smallBtn {
        padding: 7px 20px;
        font-size: 12px;
        text-transform: uppercase;
        border-radius: 55px;
        background: ${theme.button};
        color: ${theme.white};
        font-weight: 700;
        letter-spacing: 1px;
        cursor: pointer;
        white-space: nowrap;
    }

`

export const ldsRing = ({ theme }) => css`
    .lds-ring {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
    }
    .lds-ring div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 64px;
        height: 64px;
        margin: 8px;
        border: 8px solid;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #fff transparent transparent transparent;
    }
    .lds-ring div:nth-child(1) {
        animation-delay: -0.45s;
    }
    .lds-ring div:nth-child(2) {
        animation-delay: -0.3s;
    }
    .lds-ring div:nth-child(3) {
        animation-delay: -0.15s;
    }
    @keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

`

const radioSelected = ({ theme }) => css`

    border-color: ${theme.header};

    .circle {
        display: block;
    }
`

const loadingDiv = ({ theme }) => css`

    position: relative;

    &:before {
        width: 200px;
        height: 100%;
        content: '';
        background: ${({ theme }) => theme.loading};
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        border-radius: 55px;
    }
    div {
        opacity: 0;
        cursor: default;
    }
`


const soldShared = ({ theme }) => css`
    width: 100%;
    background: ${theme.price};
    color: ${theme.white};
    padding: 20px 0;
    position: absolute;
    left: -32%;
    top: 10%;
    transform: rotate(-45deg);
    margin: auto;
    text-transform: uppercase;
    z-index: 10000;
    font-weight: 700;
    font-size: 21px;
    display: none;

    &.won {
        background: ${({ theme }) => theme.wonGradientMiddle};
        background: linear-gradient(180deg, ${({ theme }) => theme.wonGradientMiddle} 0%, ${({ theme }) => theme.wonGradientMiddle} 30%, ${({ theme }) => theme.wonGradientSide} 100%);
    }

    &.CREDIT_PACKAGE {
        background: ${({ theme }) => theme.creditPackageCornerGradientMiddle};
        background: linear-gradient(180deg, ${({ theme }) => theme.creditPackageCornerGradientMiddle} 0%, ${({ theme }) => theme.creditPackageCornerGradientMiddle} 30%, ${({ theme }) => theme.creditPackageCornerGradientSide} 100%);
    }

    @media (max-width: ${breakpoint[1365]}){
        font-size: 19px;
    }

`

const headerPadding = {
    1: '40px',
    2: '20px',
    3: '17px'
}

export const headerHeight = {
    1: '75px',
    2: '60px'
}

export const HeaderBox = styled.div`
    width: 100%;
    height: ${headerHeight[1]};
    position: fixed;
    left: 0;
    top: 0;
    z-index: 99999999;

    color: ${({ theme }) => theme.white};
    background: ${({ theme }) => theme.header};
    font-weight: 700;
    font-size: 18px;
    padding: 0 ${headerPadding[1]};

    &.row {
        justify-content: space-between;
        align-items: stretch;       
        
        &.center {
            justify-content: center;
        }
    }

    a {
        color: ${({ theme }) => theme.white};
    }

    @media (max-width: ${breakpoint[1500]}){

        height: ${headerHeight[2]};
        padding: 0 ${headerPadding[2]};
        font-size: 16px;

    }

    @media (max-width: ${breakpoint[1151]}){

        padding-left: 0;

    }

    @media (max-width: ${breakpoint[374]}){

        padding-right: ${headerPadding[3]};

    }
    
    .leftSideHeader {
        height: 100%;

        @media (max-width: ${breakpoint[767]}){
            justify-content: flex-start;
        }

        .logo {
            width: 240px;
            height: 100%;
            user-select: none;

            @media (max-width: ${breakpoint[1500]}) {
                width: 100%;
                max-width: 200px;
            }

            @media (max-width: ${breakpoint[1151]}){

                margin: 0 15px 0 0;

            }

            img {
                width: 100%;
            }
        } 

        .menuLeft {

            margin: 0 0 0 20px;

            @media (max-width: ${breakpoint[1151]}){
                width: 100%;
                max-width: 500px;
                background: ${({ theme }) => theme.shoppingFrom.bg};
                height: calc(100% - ${headerHeight[2]});
                position: fixed;
                left: 0;
                bottom: 0;
                z-index: 99999999;
                flex-direction: column;
                overflow: auto;
                justify-content: flex-start;
                transform: translateX(-100%);
                margin: 0;

                &.active {
                    transition: transform 0.2s;
                    transform: translateX(0%);
                }

            }

            svg {

                &.arrow {
                    width: 23px;
                    margin: 0 0 0 5px;
                    fill: ${({ theme }) => theme.white};

                    @media (max-width: ${breakpoint[1151]}){
                        fill: ${({ theme }) => theme.base};
                    }
                }

            }

            .menuClickCover {
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                z-index: 0;
                display: none;

                @media (max-width: ${breakpoint[1151]}){
                    display: block;
                }
            }

            li {

                position: relative;
                z-index: 5;

                &.dealsTab {
                    z-index: 999999999;
                }

                @media (max-width: ${breakpoint[1151]}){
                    height: auto;
                    width: 100%;
                    border-top: 1px solid ${({ theme }) => theme.shoppingFrom.border};
                    &:first-child {
                        border: 0;
                    }
                }

                .liInner {
                    height: 100%;
                    padding: 0 15px;
                    margin: 0 10px;
                    cursor: pointer;
                    position: relative;
                    user-select: none;

                    @media (max-width: ${breakpoint[1500]}){
                        padding: 0 0 0 ${menuSpacing[2]};
                    }

                    @media (max-width: ${breakpoint[1151]}){
                        width: 100%;
                        height: auto;
                        padding: 20px;
                        margin: 0;
                        justify-content: flex-start;
                        font-size: 24px;
                        color: ${({ theme }) => theme.blackText};
                    }

                    svg {
                        &.upArrow {
                            width: 17px;
                            fill: ${({ theme }) => theme.white};
                            position: absolute;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            margin: auto;
                            z-index: 99999999;

                            @media (max-width: ${breakpoint[1151]}){
                                display: none;
                            }
                        }
                    }

                    .liDropdownBox {

                        width: 300px;
                        position: absolute;
                        left: 0;
                        top: 100%;
                        z-index: 9999999;
                        color: ${({ theme }) => theme.blackText};

                        display: none;

                        @media (max-width: ${breakpoint[1151]}){
                            
                            width: 100%;
                            height: 100%;
                            position: fixed;
                            justify-content: flex-start;
                            top: 0;

                        }

                        &.active {
                            display: flex;
                        }

                        .liDropdown {
                            width: 100%;
                            background: ${({ theme }) => theme.shoppingFrom.bg};
                            
                            border-radius: ${borderRadius};
                            font-size: 17px;
                            font-weight: normal;
                            box-shadow: 0 10px 50px rgba(0, 0, 0, 1);
                            user-select: none;

                            @media (max-width: ${breakpoint[1151]}){
                            
                                justify-content: flex-start;
                                flex-grow: 1;
                                border-radius: 0;
                                box-shadow: none;

                            }

                            .liDropdownScroll {

                                width: 100%;
                                background: ${({ theme }) => theme.white};
                                justify-content: flex-start;
                                cursor: default;

                                @media (max-width: ${breakpoint[1151]}){
                                    height: calc(100% - ${viewDealsButtonMobileBox});
                                    overflow: auto;
                                    position: absolute;
                                    left: 0;
                                    top: 0;
                                    overflow: auto;
                                }

                                .tabType {
                                    width: 100%;
                                    height: 55px;
                                    min-height: 55px;
                                    text-align: left;
                                    padding: 0 20px;
                                    justify-content: space-between;
                                    border-bottom: 1px solid ${({ theme }) => theme.shoppingFrom.border};
                                    cursor: pointer;

                                    &:first-child {

                                        @media (max-width: ${breakpoint[1151]}){
                                            padding-left: 0;
                                        }

                                    }

                                    &:last-child {

                                        border: 0;
                                    
                                    }

                                    &.close {

                                        display: none;

                                        @media (max-width: ${breakpoint[1151]}){
                                            display: flex;
                                        }

                                        .closeBox {
                                            width: 100%;
                                            height: 100%;
                                            padding: 0 20px;
                                            justify-content: flex-start;

                                            svg {
                                                width: 23px;
                                                transform: rotate(90deg);
                                            }

                                        }

                                    }

                                    .leftSide {

                                        flex-grow: 1;
                                        height: 100%;
                                        font-size: 15px;
                                        font-weight: 500;
                                        justify-content: flex-start;

                                        span {
                                            font-size: 12px;
                                            font-weight: 700;
                                            margin: 0 0 0 5px;
                                            color: ${({ theme }) => theme.price};
                                        }
                                    
                                    }

                                    .radio {
                                        ${radio}
                                        margin: 0;

                                        &.selected {
                                            ${radioSelected}
                                        }

                                    }

                                }

                            }

                            .buttonBox {
                                width: 100%;
                                padding: 15px 20px;
                                background: ${({ theme }) => theme.shoppingFrom.bg};
                                border-top: 1px solid ${({ theme }) => theme.shoppingFrom.border};
                                cursor: default;
                                border-radius: 0 0 ${borderRadius} ${borderRadius};

                                @media (max-width: ${breakpoint[1151]}){
                                    height: ${viewDealsButtonMobileBox};
                                    position: fixed;
                                    padding: 0 20px;
                                    left: 0;
                                    bottom: 0;
                                }

                                button {
                                    width: 100%;
                                    max-width: none;
                                    cursor: pointer;
                                    font-size: 21px;
                                    font-size: 18px;
                                    height: 50px;
                                }

                            }
                        }

                    }
                }
            }
            
            .mobile {
                display: none;

                @media (max-width: ${breakpoint[550]}){
                    display: flex;
                }
            }
        }

        .menuIcon {

            height: 100%;
            cursor: pointer;
            padding: 0 ${headerPadding[2]};
            display: none;
            
            @media (max-width: ${breakpoint[374]}){
                padding: 0 ${headerPadding[3]};
            }

            &.active {

                .open {

                    display: none;

                }

                .close {

                    display: block;
                    
                }

            }

            svg {
                width: 25px;
                fill: ${({ theme }) => theme.white};
                
                @media (max-width: ${breakpoint[374]}){
                    width: 20px;
                }
                
                &.open {

                }
                
                &.close {

                    display: none;
                    
                }

            }

            @media (max-width: ${breakpoint[1151]}){
                display: flex;
            }

        }

    }

    .rightSideHeader {

        .avatarBox {

            picture {
                font-size: 11px;
                width: 50px;

                @media (max-width: ${breakpoint[767]}){
                    width: 40px;
                }

                @media (max-width: ${breakpoint[374]}){
                    width: 35px;
                }

                img {
                    width: 100%;
                    display: block;
                }
            }


            height: 100%;
            cursor: pointer;
            margin: 0 0 0 40px;

            @media (max-width: ${breakpoint[767]}){
                margin: 0 0 0 8px;
            }

            svg {
                width: 23px;
                fill: ${({ theme }) => theme.white};
            }
        }

        .credits {

            height: 100%;
            cursor: pointer;
            position: relative;

            img {
                width: 30px;

                @media (max-width: ${breakpoint[374]}){
                    width: 25px;
                }

            }

            span {
                margin: 0 0 0 10px;
                font-size: 21px;

                @media (max-width: ${breakpoint[374]}){
                    margin: 0 0 0 7px;
                }
            }

            .bubble {

                width: 100%;
                background: #ffffff;
                padding: 10px 15px;
                position: absolute;
                left: 0;
                top: 100%;
                font-size: 14px;
                color: ${({ theme }) => theme.base};
                font-weight: 700;
                text-align: center;
            }

        }

        svg {
            margin: 0 5px 0 0;
        }

        .btn {
            height: 40px;
            padding: 0 45px;
            border-radius: 55px;
            border: 2px solid;
            border-color: ${({ theme }) => theme.white};
            cursor: pointer;
            user-select: none;

            @media (max-width: ${breakpoint[767]}){
                height: 30px;
                padding: 0 30px;
                font-size: 15px;
            }
        }

        .signinLink {
            height: 100%;
            padding: 0 15px;
            margin: 0 20px 0 0;
            cursor: pointer;

            @media (max-width: ${breakpoint[1500]}){
                padding: 0 ${menuSpacing[2]};
            }

            @media (max-width: ${breakpoint[550]}){

                display: none;

            }

        }

    }

    .menuLeft, .menuRight {
        height: 100%;
        text-transform: capitalize;

        li, li a {
            height: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
        }

    }
`

export const ShoppingFromBox = styled.div`
    width: 100%;
    height: 45px;
    background: ${({ theme }) => theme.shoppingFrom.bg};
    border-bottom: 1px solid ${({ theme }) => theme.shoppingFrom.border};
    padding: 0 ${headerPadding[1]};
    user-select: none;
    position: relative;

    @media (max-width: ${breakpoint[1500]}){
        padding: 0 ${headerPadding[2]};
    }

    &.row {
        justify-content: flex-start;
    }

    .left {
        height: 100%;
        cursor: pointer;
        svg {
            width: 14px;
            min-width: 14px;
            fill: ${({ theme }) => theme.base};
            margin: 0 2px 0 0;
        }

        .locationText {
            font-size: 14px;
            font-weight: 500;
            margin: 0 0 0 7px;
            color: ${({ theme }) => theme.base};
            text-align: left;
            position: relative;

            .blurb {
                display: inline-block;
                margin: 0 5px 0 0;
            }

            .location {
                display: inline-block;
                position: relative;

                u {
                    white-space: nowrap;
                }
            }

            .saveBox {
                    width: 250px;
                    position: absolute;
                    left: 0;
                    top: calc(100% + 7px);
                    cursor: default;
                    opacity: 0;
                    z-index: -1;
                    transition: 0.2s;

                    &.active {
                        opacity: 1;
                        z-index: 9999999;
                    }

                    svg {
                        &.upArrow {
                            width: 17px;
                            fill: rgba(0, 0, 0, 0.95);
                            position: relative;
                            z-index: 100;
                        }
                    }
                    
                    .saveBoxText {
                        
                        width: 100%;
                        padding: ${boxPadding};
                        background: rgba(0, 0, 0, 0.95);
                        color: ${({ theme }) => theme.white};
                        border-radius:  ${borderRadius};
                        box-shadow: 0 5px 20px 5px rgba(255,255,255,0.9);
                        position: relative;
                        z-index: 0;
                        font-size: 15px;

                    }
                }

            @media (max-width: ${breakpoint[767]}){
                font-size: 12px;
            }
        }
    }

    .shoppingfromDropdownBox {
        width: 100%;
        max-width: 500px;
        height: calc(100% - ${headerHeight[1]});
        position: fixed;
        left: 0;
        bottom: 0;
        z-index: 90000099;
        transform: translateX(-100%);
        background: ${({ theme }) => theme.shoppingFrom.bg};
        justify-content: flex-start;
        padding: ${boxPadding};
        overflow: auto;

        @media (max-width: ${breakpoint[1500]}){
            height: calc(100% - ${headerHeight[2]});
        }

        @media (max-width: ${breakpoint[767]}){
            font-size: 12px;
        }

        &.active {
            transition: transform 0.2s;
            transform: translateX(0%);
        }

        .title {
            width: 100%;
            text-align: center;
            align-items: stretch;
            font-weight: 700;
            font-size: 24px;
            color: ${({ theme }) => theme.base};

            @media (max-width: ${breakpoint[374]}){
                font-size: 18px;
            }

            .arrowBox {
                
                cursor: pointer;

                svg {
                    width: 25px;
                    transform: rotate(90deg);
                }
            }

            span {
                margin: 0 0 0 15px;
            }

        }

        ${field}

        .field {

            position: relative;

            @media (max-width: ${breakpoint[374]}){
                margin: 15px 0 0;
            }
        }

        .inputBox {
            width: 100%;
            position: relative;

            .clear {
                height: 100%;
                position: absolute;
                right: 0;
                top: 0;
                bottom: 0;
                margin: auto;
                padding: 0 20px;
                z-index: 1;
                cursor: pointer;

                svg {
                    width: 15px;
                    fill: ${({ theme }) => theme.base};
                }
            }

        }

        .dropdown, .error {
            width: 100%;
            margin: 3px 0 0;
            background: ${({ theme }) => theme.white};
            ${boxShadow}
            border-radius:  ${borderRadius};
            border: 1px solid ${({ theme }) => theme.shoppingFrom.border};

            li {
                width: 100%;
                padding: 15px;
                font-size: 14px;
                cursor: pointer;
                border-bottom: 1px solid ${({ theme }) => theme.shoppingFrom.border};
                justify-content: space-between;

                ${smallBtn}

                &.none {

                    cursor: default;
                    color: ${({ theme }) => theme.error};
                    
                    &:hover {
                        background: transparent;
                    }

                }

                span {
                    flex-grow: 1;
                    padding: 0 15px 0 0;

                    @media (max-width: ${breakpoint[550]}){
                        font-size: 12px;
                    }

                }
                
                &:hover {
                    background: #ebebeb;
                }

                &:last-child {
                    border: 0;
                }

            }

        }

        button {
            margin: 30px 0 0;
        }

    }

`


export const Auctions = styled.div`

    width: 100%;
    flex-wrap: wrap;
    padding: 0 0 ${topMargin};

    &.row {
        justify-content: flex-start;
        align-items: stretch;

        @media (max-width: ${breakpoint[1023]}){
            justify-content: space-between;
        }
    }

    &.howItWorks {
        justify-content: center;

        .auction {
            border: 0;
            box-shadow: none;
            cursor: pointer;
            transition: 0.2s;
        }
    }

    &.auctionPageBottom {

        .auction {

            &:last-child {

                display: none;

                @media (max-width: ${breakpoint[1023]}){
                    display: flex;
                }

            }

        }

    }

    .auction {
        width: calc((100% / 3) - 14px);
        border: 1px solid ${borderColor};
        box-shadow: 0 2px 1px rgba(0,0,0,0.1);
        border-radius: ${borderRadius};
        text-align: center;
        margin: 0 20px 20px 0;
        justify-content: flex-start;

        @media (max-width: ${breakpoint[1023]}){
            width: calc(50% - 10px);
            margin: 0 0 20px;
        }

        @media (max-width: ${breakpoint[550]}){
            width: 100%;
            margin: 0 0 20px;
        }

        &:nth-child(3n) {
            margin-right: 0;
        }

        .link {
            width: 100%;
            flex-grow: 1;
        }

        .top {
            width: 100%;
            background: ${({ theme }) => theme.auctionLight};
            padding: 30px 25px;
            border-radius: ${borderRadius} ${borderRadius} 0 0;
            position: relative;
            overflow: hidden;
            .product {
                position: relative;
                z-index: 100;
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                margin: auto;
            }
            .productbg {
                width: 100%;
                position: absolute;
                left: 0;
                top: 0;
                z-index: 0;
                border-radius: ${borderRadius} ${borderRadius} 0 0;
            }

            .sold {
                ${soldShared};
            }

            svg {
                position: relative;
                z-index: 0;
                opacity: 0;
            }

            svg, 
            .product {
                width: 65%;
                max-width: 250px;
                @media (max-width: ${breakpoint[550]}){
                    width: 55%;
                }
            }

        }
        .title {
            width: 100%;
            flex-grow: 1;
            background: ${({ theme }) => theme.header};
            color: ${({ theme }) => theme.white};
            padding: 15px 20px;

            @media (max-width: ${breakpoint[767]}){
                padding: 12px;
            }

            h2 {
                font-size: 21px;

                @media (max-width: ${breakpoint[1500]}){
                    font-size: 18px;
                }

                @media (max-width: ${breakpoint[767]}){
                    font-size: 14px;
                }

            }
        }
        .bottom {
            width: 100%;
            padding: ${boxPadding};
            background: ${({ theme }) => theme.white};
            transition: ${({ theme }) => theme.flashTime};

            @media (max-width: ${breakpoint[767]}){
                padding: 15px;
            }

            &.active {
                background: ${({ theme }) => theme.flash};
            }

            &.cartMode {
                background: none !important;
            }

            .price {
                width: 100%;
                text-align: center;
                color: ${({ theme }) => theme.price};
                font-weight: 500;
                font-size: 24px;
                cursor: default;
                @media (max-width: ${breakpoint[550]}){
                    font-size: 21px;
                }
            }

            .avatarBox {
                width: 100%;
                position: relative;
                .avatar { 
                    width: 75px;
                    margin: 10px 0 0;
                    position: relative;
                    z-index: 0;

                    @media (max-width: ${breakpoint[767]}){
                        width: 55px;
                    }

                    .avatarLoader {
                        width: 100%;
                        opacity: 0;
                    }

                    img, .avatarNoBids {
                        width: 100%;
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        margin: auto;
                    }

                    .avatarNoBids {
                        &.hide {
                            opacity: 0;
                        }
                    }
                }
            }

            .highBidder {
                width: 100%;
                text-align: center;
                margin: 15px 0;
                font-size: 16px;
                cursor: default;
                @media (max-width: ${breakpoint[550]}){
                    font-size: 14px;
                }
            }

            .countdown {
                width: 100%;
                font-weight: 700;
                font-size: 19px;
                text-align: center;
                cursor: default;
                transition: opacity 0.1s, transform 0.2s;
                opacity: 1;
                
                &.heated {
                    color: ${({ theme }) => theme.price};
                }

                &.flashing {
                    transform: scale(1.2);
                }

                &.loading {
                    opacity: 0;
                }
            }
        }

        &.justSold {
            .top {
                .sold {
                    display: flex;
                }
            }
        }

        &.wonAuction {
            .top {
                .sold {
                    display: flex;
                }
            }
            .bottom {
                button {
                    background: ${({ theme }) => theme.wonButton};
                }
            }
            &.COMPLETE {
                .bottom {
                    button {
                        background: ${({ theme }) => theme.wonButtonComplete};
                        cursor: default;
                    }
                }
            }
        }

        &.CREDIT_PACKAGE {
            &> * {
                cursor: default !important;
            }
            .top {
                .sold {
                    display: flex;
                }
            }
            .bottom {
                button {
                    background: ${({ theme }) => theme.wonButtonComplete};
                    cursor: default;
                }
            }
        }

        &.countdownZero, &.justSold {
            .bottom {
                button {
                    opacity: 0.3;
                    &.signedIn {
                        cursor: default;
                    }
                }
            }
        }

        &.loading {

            .top {
                background: ${({ theme }) => theme.loading};
                .productbg, .sold {
                    display: none;
                }
            }

            .title {
                background: ${({ theme }) => theme.loading};
                div {
                    opacity: 0;
                }
            }

            .bottom {

                .countdown, 
                .highBidder,
                .price  {

                    ${loadingDiv}

                }

                .avatarBox {
                    svg {
                        fill: ${({ theme }) => theme.loading};
                    }
                }

                button {
                    background: ${({ theme }) => theme.loading};
                    color: ${({ theme }) => theme.loading};
                    cursor: default;
                }

            }
        }
    }
`

export const SigninBox = styled.div`
    width: 100%;
    min-height: 100%;
    flex-grow: 1;
    padding: 20px;
    background: ${({ theme }) => theme.signupBackground};
    
    .background {
        width: 100%;
        height: 100%;
        position: fixed;
        left: 0;
        top: 0;
        background: url(${assetPrefix({ path: "warehouse.jpg" })}) no-repeat center center;
        background-size: cover;
        z-index: 0;
    }
    
    
    form {
        width: 100%;
        max-width: 700px;
        border-radius: ${borderRadius};
        ${boxShadow}
        position: relative;
        z-index: 200;

        &.signup {
            max-width: 1000px;
        }

        @media (max-width: ${breakpoint[767]}) {
            box-shadow: none;

            &.signup {
                max-width: 600px;
            }
        }

        .logoBox {

            ${logoBox}
            
            img {
                width: 250px;
                
                @media (max-width: ${breakpoint[767]}) {
                    width: 200px;
                }

            }
        }

        .innerForm {
            width: 100%;
            border-radius: 0 0 ${borderRadius} ${borderRadius};
            justify-content: space-between;
            align-items: stretch;
            position: relative;
            
            @media (max-width: ${breakpoint[767]}) {
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }

            h1, h2 {
                width: 100%;
                color: ${({ theme }) => theme.header};
                opacity: 0.9;
                line-height: 100%;

                @media (max-width: ${breakpoint[767]}){
                    opacity: 1;
                }
            }

            .formLeft, .formRight {
                width: 100%;
                padding: 30px 40px 40px;
                justify-content: flex-start;
                background: ${({ theme }) => theme.shoppingFrom.bg};
                border-top: 0;
                border-radius: 0 0 ${borderRadius} ${borderRadius};

                @media (min-width: ${breakpoint[1024]}){
                    &.signup {
                        width: 50%;
                    }
                }

                @media (max-width: ${breakpoint[1023]}){

                    padding: 30px 20px;
                    border: 0;

                }

                @media (max-width: ${breakpoint[767]}) {
                    width: 100%;
                    ${boxShadow}
                    border-radius: ${borderRadius};
                }

                @media (max-width: ${breakpoint[374]}){
                    padding: 20px;
                }

            }
            
            .formLeft {

                border-right: 1px solid ${borderColor};

                @media (max-width: ${breakpoint[767]}) {
                    order: 2;
                    padding: 0 0 30px;
                    border: 0;
                    margin: 20px 0 0;
                }

                h1 {
                    text-align: left;
                    font-size: 28px;

                    br {
                        display: none;
                    }

                    @media (max-width: ${breakpoint[1023]}){

                        font-size: 24px;

                    }

                    @media (max-width: ${breakpoint[767]}){
                        
                        ${logoBox}
                        text-align: center;

                    }

                    @media (max-width: ${breakpoint[550]}){

                        font-size: 21px;
                        padding: 17px 20px;

                        br {
                            display: block;
                        }

                    }

                }

                ul {
                    width: 100%;
                    margin: 40px 0 0;

                    @media (max-width: ${breakpoint[1023]}){

                        margin: 30px 0 0;

                    }

                    @media (max-width: ${breakpoint[767]}){

                        margin: 0;
                        padding: 20px 20px 30px;

                    }

                    li {
                        width: 100%;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        font-size: 18px;
                        margin:  30px 0 0;

                        @media (max-width: ${breakpoint[1023]}){

                            font-size: 16px;

                        }

                        @media (max-width: ${breakpoint[767]}){
                            
                            margin: 25px 0 0;
                        
                        }

                        svg {
                            width: 30px;
                            min-width: 30px;
                            margin: 0 15px 0 0;
                        }

                        &:first-child {
                            margin: 0;
                        }
                    }
                }

                .createBottom {
                    display: none;

                    @media (max-width: ${breakpoint[767]}){

                        display: flex;
                        width: calc(100% - 40px);
                    }

                    @media (max-width: 430px) {
                        font-size: 5.2vw;
                    }
                }
            }

            .formRight {
                
                border: 0;
                position: relative;
                padding-bottom: 0;

                @media (max-width: ${breakpoint[767]}) {

                    width: 100%;
                    border-top-left-radius: 0;
                    border-top-right-radius: 0;

                }

                h2 {
                    font-size: 37px;

                    @media (max-width: ${breakpoint[1023]}){

                        font-size: 28px;

                    }

                    @media (max-width: ${breakpoint[374]}){
                        font-size: 25px;
                    }

                }

                .blurb {
                    width: 100%;
                    text-align: center;
                
                    .blurbSmall {
                        width: 100%;
                        font-size: 21px;
                        opacity: 0.7;
                        margin: 8px 0 0;
                        
                        @media (max-width: ${breakpoint[1023]}){
                            font-size: 19px;
                        }

                        @media (max-width: ${breakpoint[374]}){
                            font-size: 16px;
                        }
                    }
                }

                .fields {
                    width: 100%;
                    margin: 40px 0 0;

                    @media (max-width: ${breakpoint[1023]}){

                        margin: 25px 0 0;

                    }

                    ${field}

                    .field {

                        @media (max-width: ${breakpoint[767]}) {
                            margin: 17px 0 0;
                        }

                        &:first-child {
                            margin: 0;
                        }      
                                          
                    }


                }

                button {
                    width: 100%;
                    margin: 30px 0 0;
                    position: relative;

                    ${ldsRing}

                    .lds-ring {
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        margin: auto;
                        transform: scale(0.6);
                    }

                }

                .alreadyMember {
                    width: 100%;
                    text-align: center;
                    border-top: 1px solid ${borderColor};
                    margin: 30px 0 0;
                    padding: 20px 0;
                    font-size: 15px;

                    @media (max-width: ${breakpoint[374]}){
                        font-size: 13px;
                    }

                    a {
                        font-weight: 700;
                        text-decoration: underline;
                        color: ${({ theme }) => theme.button};
                        margin: 0 0 0 6px;
                    }
                }

                ${error}

                .forgotPassword {
                    width: 100%;
                    justify-content: flex-start;
                    font-size: 15px;
                    margin: 15px 0 0;
                    font-weight: 700;
                    color: ${({ theme }) => theme.button};
                }

            }
        }
    }
`

export const SuccessCheckBlurb = styled.div`
        width: 100%;
        text-align: left;
        padding: 10px;
        font-size: 13px;
        color: #217632;
        font-weight: 70;
        background: #ddffe4;
        border: 1px solid #32c14f;
        margin: 18px 0 0;
        justify-content: flex-start !important;

        &.forgotPasswordSuccess {
            margin: 25px 0 0;
        }

        svg {
            width: 25px;
            min-width: 25px;
            fill: #217632;
        }

        .text {
            margin: 0 0 0 10px;
        }
`

export const ResetSuccess = styled.div`
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.6);
    position: fixed;
    left: 0;
    top: 0;
    z-index: 200;
    padding: 20px;
    font-weight: 500;
    color: ${({ theme }) => theme.base};
    backdrop-filter: blur(10px);
    z-index: 1000;
    cursor: default;

    ${ldsRing}

    .text {
        width: 100%;
        text-align: center;
        color: ${({ theme }) => theme.white};
        font-size: 21px;
        margin: 20px 0 0;
        text-shadow: 0 0 10px rgba(0,0,0,0.6);

        @media (max-width: ${breakpoint[374]}){
            font-size: 17px;
        }

    }

`

export const MemberPopBox = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 9999999999;
    background: rgba(0,0,0,0.6);
    padding: 20px;
    display: flex;
    overflow: auto;

    &.howItWorks {
        position: absolute;
        background: rgba(0,0,0,0.3);
    }

    &.blur {
        backdrop-filter: blur(10px);
    }

    .inner {
        width: 100%;
        max-width: 600px;
        border-radius: ${borderRadius};
        box-shadow: 0 20px 20px rgba(0,0,0,0.4);
        justify-content: flex-start;
        margin: auto;

        &.avatarsFormInner { 
            height: 100%;
            max-height: 580px;
        }
    }

    h4 {
        width: 100%;
        padding: 22px 0;
        background: ${({ theme }) => theme.header};
        color: ${({ theme }) => theme.white};
        font-size: 24px;
        width: 100%;
        text-align: center;    
        border-radius: ${borderRadius} ${borderRadius} 0 0;
        text-transform: capitalize;
        position: relative;

        @media (max-width: ${breakpoint[550]}){
            font-size: 19px;
            padding: ${boxPadding2} 0;
        }

        @media (max-width: ${breakpoint[550]}) and (max-height: ${breakpointHeight[627]}) {
            padding: 17px 0;
        }

        .svgBox {
            height: 100%;
            cursor: pointer;
            position: absolute;
            left: 0;
            top: 0;
            padding: 0 15px;
        }

        svg {
            width: 35px;
            height: 35px;
            fill: ${({ theme }) => theme.white};

            @media (max-width: ${breakpoint[550]}){
                width: 30px;
                height: 30px;
            }
        }

    }

    .formOuter {

        width: 100%;
        padding: ${boxPadding};
        position: relative;
        background: ${({ theme }) => theme.shoppingFrom.bg};

        &.hide {
            display: none;
        }

        @media (max-width: ${breakpoint[550]}){
            padding: ${boxPadding2};
        }

        &.avatarsForm {
            padding: 0;
            height: 100%;
            overflow: auto;
        }

        &.bidsAddedForm {
            background: ${({ theme }) => theme.white};
        }

        .cardListing {
            width: 100%;
        }

        .fieldText {
            width: 100%;
            font-size: 15px;
            color: #30313d;
            margin: 0 0 14px;
        }

        .creditsBox {
            width: 100%;

            &.hide {
                
                display: none;
            
            }

            .credit {
                width: 100%;
                border-radius: ${borderRadius};
                border: 1px solid ${borderColor};
                background: ${({ theme }) => theme.white};
                padding: ${boxPadding};
                justify-content: space-between;
                cursor: pointer;
                margin: 15px 0 0;
                ${boxShadowAddBids}

                &:first-child {
                    margin: 0;
                }

                @media (max-width: ${breakpoint[550]}){
                    padding: ${boxPadding2};
                }

                &.selected {

                    border: 1px solid  ${({ theme }) => theme.addBidsSelected.borderColor};
                    background:  ${({ theme }) => theme.addBidsSelected.background};

                    .left {

                        .radio {
                            background: ${({ theme }) => theme.white};
                            ${radioSelected}

                        }
                    }

                }

                .left {

                    .radio {
                        ${radio}
                        margin: 0;
                    }

                    .amount {
                        margin: 0 0 0 15px;

                        span {
                            font-size: 45px;
                            font-weight: 700;
                            
                            @media (max-width: ${breakpoint[550]}){
                                font-size: 25px;
                            }

                            @media (max-width: ${breakpoint[374]}){
                                font-size: 20px;
                            }

                        }

                        .sub {
                            font-size: 24px;
                            margin: 0 0 0 10px;

                            @media (max-width: ${breakpoint[550]}){
                                font-size: 20px;
                                margin: 0 0 0 7px;
                            }
                        }
                    }

                }
            }

        }

        .creditCardBox {
            width: 100%;

            z-index: -1;
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;

            &.active {
                z-index: 0;
                position: relative;
                opacity: 1;
            }

            button {
                display: none;
                margin: 0;
            }

            .StripeElement {

                width: 100%;
                min-height: 250px;
                position: relative;
                z-index: 100;
            }
        }

        .avatarsBox {
            width: 100%;
            height: 100%;
            flex-grow: 1;
            justify-content: flex-start; 
            align-items: flex-start;
            flex-wrap: wrap;

            .avatarBox {
                width: 25%;
                padding: 25px;
                cursor: pointer;
                border: 1px solid ${borderColor};
                order: 2;

                @media (max-width: ${breakpoint[767]}){
                    width: 33.3%;
                    padding: 20px;
                }

                @media (max-width: ${breakpoint[374]}){
                    width: 50%;
                    padding: 16px;
                }

                &.chosen {
                    order: 1;
                }

                &.selected {

                    background: ${({ theme }) => theme.white};

                    .radio {

                        ${radioSelected}
                    
                    }

                }

                .radio {
                    ${radio}
                }

                img {
                    width: 55px;

                    @media (max-width: ${breakpoint[767]}){
                        width: 52px;
                    }

                    @media (max-width: ${breakpoint[550]}){
                        width: 47px;
                    }

                    @media (max-width: ${breakpoint[374]}){
                        width: 52px;
                    }

                }
            }
        }

        .purchasedBox {
            width: 100%;

            img {
                width: 95%;
                max-width: 400px;
            }

            p {
                width: 100%;
                margin: 25px 0 15px;
                font-weight: 700;
                text-align: center;
                font-size: 27px;

                span {
                    color: ${({ theme }) => theme.bidsAddedPurchaseBox};
                }
            }
        }

        .editDisplayNameBox {
            width: 100%;
            ${field}

            .field {
                margin: 0;
            }

            ${error}

            button {
                display: none;
            }

            .newDisplayNameIntro {
                width: 100%;
                text-align: center;
                margin: 0 0 22px;
                font-size: 16px;
            }

        }

        .deleteAccountBox {
            width: 100%;
            ${field}

            .field {
                margin: 0;
            }

            ${error}

            button {
                display: none;
            }

            .deleteAccountIntro {
                width: 100%;
                text-align: center;
                margin: 0 0 22px;
                font-size: 16px;
            }

        }

        .cartLoading {
            width: 100%;
            height: 150px;
            ${ldsRing}
            .lds-ring div {
                border-color: ${({ theme }) => theme.base} transparent transparent transparent;
            }
        }

        .cartLoadError {
            width: 100%;
            ${error}
            .error {
                margin: 0;
                text-align: center;
            }
        }

        .userDeleted {
            width: 100%;
            line-height: 160%;
            .logout {
                color: ${({ theme }) => theme.button};
                text-decoration: underline;
                font-weight: 700;
                cursor: pointer;
            }
        }

    }

    .cardListing {

        width: 100%;

        .fieldText {
            width: 100%;
        }

        ${card}

        .card {
            width: 100%;
            border-radius: ${borderRadius};
            border: 1px solid ${borderColor};
            background: ${({ theme }) => theme.white};
            padding: ${boxPadding};
            justify-content: space-between;
            cursor: pointer;
            margin: 15px 0 0;
            ${boxShadowAddBids}

            @media (max-width: ${breakpoint[550]}){
                padding: ${boxPadding2};
                font-size: 15px;
            }

            @media (max-width: ${breakpoint[374]}){
                font-size: 14px;
            }

            &:first-child {
                margin: 0;
            }

            img {
                width: 45px;
                
                @media (max-width: ${breakpoint[450]}){
                    width: 33px;
                }

            }
        

            &.selected {

                background: ${({ theme }) => theme.white};

                .radio {

                    ${radioSelected}

                }

            }

            .radio {
                ${radio}
                margin: 0 15px 0 0;
            }

        }

    }

    .error {
        width: 100%;
        text-align: left;
        padding: 10px;
        font-size: 13px;
        color: ${({ theme }) => theme.error};
        font-weight: 70;
        background: #ffffff;
        border: 1px solid ${({ theme }) => theme.error};
        margin: 20px 0 0;
    }

    .buttonBox {
        width: 100%;
        padding: 30px ${boxPadding};
        background: ${({ theme }) => theme.white};
        box-shadow: 0 -2px 5px rgba(0,0,0,0.3);
        position: relative;
        z-index: 99999;

        &.idleBox {
            padding-top: 60px;
            padding-bottom: 60px;
        }

        @media (max-width: ${breakpoint[550]}){
            padding: ${boxPadding2};

            &.idleBox {
                padding-top: 40px;
                padding-bottom: 40px;
            }

        }

        button {
            position: relative;

            ${ldsRing}

            .lds-ring {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                margin: auto;
                transform: scale(0.6);
            }
            
        }
    }
    

`

export const BidButtonBox = styled.div`
    width: 100%;
    margin: 30px 0 0;
    position: relative;

    @media (max-width: ${breakpoint[1600]}){
        margin: 22px 0 0;
    }

    button {
        position: relative;
        z-index: 10;

        ${ldsRing}

        .lds-ring {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            transform: scale(0.6);
        }

    }

    .alreadyHightBidder {
        width: 100%;
        max-width: 250px;
        position: absolute;
        left: 0;
        right: 0;
        top: -38px;
        z-index: -1;
        margin: auto;
        cursor: default;

       
        transition: 0.1s;
        opacity: 0;

        &.active {
            opacity: 1;
            z-index: 200;
        }

        .text {
            width: 100%;
            text-align: center;
            background: ${({ theme }) => theme.title};
            color: ${({ theme }) => theme.white};
            font-size: 14px;
            font-weight: 500;
            padding: 10px;
            border-radius: 55px;

            @media (max-width: ${breakpoint[767]}){
                font-size: 12px;
            }

        }
        svg {
            width: 15px;
            fill: ${({ theme }) => theme.title};
        }
    }
`

const auctionRightWidth = '63%';

export const AuctionPageBox = styled.div`
    width: 100%;

    h1, h2, h3 {
        text-align: left;
        text-transform: capitalize;
        font-size: 21px;
        font-weight: 700;    
        
        @media (max-width: ${breakpoint[1365]}){
            font-size: 19px;
        }

        @media (max-width: ${breakpoint[550]}){
            font-size: 17px;
        }

    }

    h3 {
        width: 100%;
        color: ${({ theme }) => theme.base};
        margin: ${boxPaddingDescription} 0 0;
    }

    .mainBox {
        width: 100%;
        justify-content: space-between;
        align-items: stretch;

        @media (max-width: ${breakpoint[1023]}){
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }

    &.justSold {
        .left {
            .sold {
                display: flex;
            }
        }
    }

    &.countdownZero, &.justSold {
        .bottom {
            button {
                opacity: 0.3;

                &.signedIn {
                    cursor: default;
                }

                @media (max-width: ${breakpoint[767]}){
                    opacity: 1;
                    background: #6c6c6c;
                }

            }
        }
    }

    &.wonAuction {
        .bottom {
            button {
                background: ${({ theme }) => theme.wonButton};
            }
        }

        &.COMPLETE {
            .bottom {
                button {
                    background: ${({ theme }) => theme.wonButtonComplete};
                    cursor: default;
                }
            }
        }
    }

    h1 {
        width: ${auctionRightWidth};
        text-align: left;
        border: 1px solid ${borderColor};
        padding: ${boxPadding};
        align-self: flex-end;
        border-radius: ${borderRadius} ${borderRadius} 0 0;
        justify-content: flex-start;

        @media (max-width: ${breakpoint[1365]}){
            padding-top: 15px;
            padding-bottom: 15px;
        }

        @media (max-width: ${breakpoint[1023]}){
            width: 100%;
            border: 0;
            padding: 0;
            margin: 0 0 20px;
        }

        @media (max-width: ${breakpoint[767]}){
            margin: 0 0 10px;
        }
    }

    .left {
        width: 37%;
        padding: 0 70px 0 0;
        margin: -80px 0 0;
        position: relative;

        @media (max-width: ${breakpoint[1365]}){
            padding: 0 25px 0 0;
            margin: -55px 0 0;
        }

        @media (max-width: ${breakpoint[1023]}){
            width: 100%;
            margin: 0 0 40px;
            padding: 0;
        }

        @media (max-width: ${breakpoint[767]}){
            width: 100%;
            margin: 0 0 0;
        }

        .sold {
            ${soldShared};
        }

        .soldCorner {
            position: absolute;
            left: 0;
            top: 0;
            width: 350px;
            fill: ${({ theme }) => theme.price};
            overflow: hidden;
            cursor: default;

            &.won {
                fill: url(#won-gradient) ${({ theme }) => theme.wonGradientMiddle};
                
                #won-gradient {
                    --color-stop: ${({ theme }) => theme.wonGradientMiddle};
                    --color-bot: ${({ theme }) => theme.wonGradientSide};
                }
            }

            @media (max-width: 1400px){
                left: -25px;
            }

            @media (max-width: ${breakpoint[1365]}){
                width: 300px;
            }


            .soldText {
                position: absolute;
                left: -50%;
                top: -50%; 
                right: 0; 
                bottom: 0;
                margin: auto;
                font-weight: 700;
                font-size: 21px;
                text-transform: uppercase;
                transform: rotate(-45deg);
                color: ${({ theme }) => theme.white};
                z-index: 9999999;

                @media (max-width: ${breakpoint[1365]}){
                    font-size: 19px;
                }


            }

            svg {
                width: 100%;
                position: relative;
                z-index: 0;
            }
        }

        .imgBox {
            width: 100%;
            height: 100%;
            position: relative;

            @media (max-width: ${breakpoint[1023]}){
                max-width: 375px;
                height: auto;
            }

            @media (max-width: ${breakpoint[767]}){
                width: 65%;
            }

            svg.skeleton {
                border-radius: ${borderRadius};
                fill: ${({ theme }) => theme.loading};
            }

            img,
            svg.skeleton {
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                object-fit: contain;

                @media (max-width: ${breakpoint[1023]}){
                    position: relative;
                    max-height: 380px;
                }

                @media (max-width: ${breakpoint[767]}){
                    max-height: 180px;
                    object-fit: contain;
                }
            
            }
        }
    }

    .right {
        width: ${auctionRightWidth};
        ${boxLayout}
        text-align: center;
        justify-content: flex-start;


        @media (min-width: ${breakpoint[1024]}){
            border-top: 0;
            border-radius: 0 0 ${borderRadius} ${borderRadius};
        }
        
        @media (max-width: ${breakpoint[1023]}){
            width: 100%;
        }

        @media (max-width: ${breakpoint[767]}){
            border: 0;
            box-shadow: none;
        }


        .bottom {

            width: 100%;
            flex-grow: 1;
            justify-content: space-between;
            align-items: stretch;

            @media (max-width: ${breakpoint[767]}){
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }

            .bidArea {

                flex-grow: 1;

                @media (max-width: ${breakpoint[767]}){
                    width: 100%;
                    padding: 0;
                }

                .flashArea {
                    width: 100%;
                    transition: ${({ theme }) => theme.flashTime};
                    padding: ${boxPadding};

                    &.active {
                        background: ${({ theme }) => theme.flash};
                    }

                    &.cartMode {
                        background: none !important;
                        flex-grow: 1;
                    }

                    .price {
                        width: 100%;
                        text-align: center;
                        color: ${({ theme }) => theme.price};
                        font-weight: 500;
                        font-size: 31px;
                        cursor: default;

                        @media (max-width: ${breakpoint[767]}){
                            font-size: 21px;
                        }

                    }

                    .highBidder {
                        width: 100%;
                        text-align: center;
                        margin: 20px 0;
                        font-size: 16px;
                        cursor: default;

                        @media (max-width: ${breakpoint[767]}){
                            font-size: 14px;
                            margin: 10px 0;
                        }

                    }

                    .avatar {
                        width: 75px;
                        margin: 15px 0 0;
                        position: relative;

                        .avatarLoader {
                            width: 100%;
                            opacity: 0;
                        }

                        img, .avatarNoBids {
                            width: 100%;
                            position: absolute;
                            left: 0;
                            right: 0;
                            top: 0;
                            bottom: 0;
                            margin: auto;
                        }

                        .avatarNoBids {
                            &.hide {
                                opacity: 0;
                            }
                        }

                        @media (max-width: ${breakpoint[767]}){
                            width: 55px;
                            margin: 10px 0 0;
                        }
                    }

                    .countdown {
                        width: 100%;
                        font-size: 24px;
                        font-weight: 500;
                        transition: opacity 0.1s, transform 0.2s;
                        opacity: 1;
                        
                        &.heated {
                            color: ${({ theme }) => theme.price};
                        }

                        &.flashing {
                            transform: scale(1.2);
                        }

                        &.loading {
                            opacity: 0;
                        }

                        @media (max-width: ${breakpoint[767]}){
                            font-size: 19px;
                        }

                    }
                }

                .details {
                    width: 100%;
                    padding: ${boxPadding};
                    border-top: 1px solid #dddddd;
                    text-transform: capitalize;

                    @media (max-width: ${breakpoint[767]}){
                        padding-bottom: 0;
                    }

                    
                    @media (max-width: ${breakpoint[550]}){
                        padding: 25px 0 0;
                    }

                    .detail {
                        width: 100%;
                        font-size: 17px;
                        margin: 0 0 17px;

                        @media (max-width: ${breakpoint[1365]}){
                            font-size: 15px;
                        }

                        &:last-child {
                            margin: 0;
                            flex-grow: 1;
                            justify-content: flex-start;
                            img {
                                &.payments {
                                    width: 100%;
                                    max-width: 249px;
                                }
                            }
                        }

                        div {
                            &:first-child {
                                width: 135px;
                                min-width: 135px;
                                justify-content: flex-end;
                                font-weight: 500;
                                white-space: nowrap;
                                @media (max-width: ${breakpoint[1365]}){
                                    width: auto;
                                    min-width: initial;
                                }
                            }
                            &:last-child {
                                flex-grow: 1;
                                justify-content: flex-start;
                                padding: 0 0 0 10px;
                            }
                        }
                    }
                }

                ${BidButtonBox} {
                    @media (max-width: ${breakpoint[767]}){
                        position: fixed;
                        left: 0;
                        bottom: 0;
                        z-index: 9999999;

                        button {
                            width: 100%;
                            height: ${bidButtonStickMobile};
                            max-width: none;
                            border-radius: 0;
                        }
                    }
                }
            }

            .highBidders {
                
                width: 350px;
                min-width: 350px;
                background: ${({ theme }) => theme.auctionPageSideBox};
                justify-content: flex-start;
                ${boxLayout}
                margin: ${boxPadding};
                margin-left: 0;

                @media (max-width: ${breakpoint[1365]}){
                    width: 50%;
                    min-width: initial;
                    max-width: 350px;
                }

                @media (max-width: ${breakpoint[767]}){
                    width: 100%;
                    max-width: none;
                    margin: ${boxPadding} 0 0;
                }

                .title {
                    width: 100%;
                    background: ${({ theme }) => theme.title};
                    color: #ffffff;
                    padding: 15px;
                    font-weight: 700;
                    font-size: 17px;
                    border-radius: ${borderRadius} ${borderRadius} 0 0;
                }

                .noBidders {
                    width: 100%;
                    flex-grow: 1;
                    text-align: center;
                    padding: 20px;
                }

                .highBiddersList {
                    width: 100%;
                    justify-content: flex-start;
                    align-items: flex-start;
                    flex-grow: 1;
                

                    .bidder {
                        width: 100%;
                        font-size: 14px;
                        text-align: center;
                        border-bottom: 1px solid #dddddd;
                        align-items: flex-start;
                        position: relative;
                        transition: background-color 0.2s;

                        &.flash {
                            @media (max-width: ${breakpoint[767]}){
                                background: ${({ theme }) => theme.flash};
                            }
                        }

                        &.userBid {
                            background: ${({ theme }) => theme.userBid};
                        }

                        &.userTopBid {
                            color: ${({ theme }) => theme.white};
                            background: linear-gradient(180deg, ${({ theme }) => theme.wonGradientMiddle} 0%, ${({ theme }) => theme.wonGradientMiddle} 30%, ${({ theme }) => theme.wonGradientSide} 100%);
                        }

                        &:last-child {
                            border: 0;
                        }

                        @media (max-width: ${breakpoint[1151]}){
                            font-size: 12px;
                        }

                        &.highBidderWon {

                            .highBidderWonTitle {
                                width: 100%;
                                padding: 12px 0;
                                text-transform: uppercase;
                                color: ${({ theme }) => theme.white};
                                background: linear-gradient(180deg, ${({ theme }) => theme.wonGradientMiddle} 0%, ${({ theme }) => theme.wonGradientMiddle} 30%, ${({ theme }) => theme.wonGradientSide} 100%);
                                font-weight: 700;
                                cursor: default;
                            }

                            .innerBidder {
                                border: 4px solid ${({ theme }) => theme.wonGradientSide};
                                border-top: 0;
                            }
                        }
                        
                        .innerBidder {
                            width: 100%;
                            justify-content: space-between;
                            padding: 9px 15px;

                            @media (max-width: ${breakpoint[1151]}){
                                padding: 7px 12px;
                            }

                            .bidderLeft {
                                img {
                                    width: 25px;
                                    margin: 0 10px 0 0;
                                }
                            }
    
                            .priceRight {
                                text-align: right;
                                font-weight: 500;
                            }
                        }



                    }

                }

            }
        }
    }

    &.loading {
        h1 {
            position: relative;
            &:before {
                width: 200px;
                height: 50%;
                content: '';
                background: ${({ theme }) => theme.loading};
                position: absolute;
                left: 20px;
                top: 0;
                bottom: 0;
                margin: auto;
                border-radius: 55px;

                @media (max-width: ${breakpoint[1023]}){
                    height: 100%;
                    left: 0;
                }

            }
            div {
                opacity: 0;
            }
        }

        .right {

            box-shadow: none;

            .bottom {
                .bidArea {

                    .avatar {
                        svg {
                            fill: ${({ theme }) => theme.loading};
                        }
                    }

                    .price, 
                    .highBidder, 
                    .countdown, 
                    .details .detail {

                        ${loadingDiv}
                    
                    }

                    button {
                        background: ${({ theme }) => theme.loading};
                        color: ${({ theme }) => theme.loading};
                        cursor: default;
                    }

                    .details {

                        border-color: ${({ theme }) => theme.loading};

                        .detail {

                            div {
                                opacity: 0;
                            }

                        }
                    }

                }

                .highBidders {

                    background: ${({ theme }) => theme.loading};
                    box-shadow: none;

                    .title {

                        background: ${({ theme }) => theme.loading};

                        div {
                            opacity: 0;
                        }
                    }

                    .noBidders {
                        opacity: 0;
                    }

                }
            }
        }   
    }


    .notFoundBox {
        width: 100%;
        padding: 50px 0;
        text-align: center;

        .notFoundTitle {
            font-weight: 700;
            font-size: 35px;
        }

        .notFoundSub {
            margin: 20px 0 30px;
            font-size: 16px;
        }
    }

`

export const AuctionPageDescriptionBox = styled.div`
    width: 100%;
    margin: ${topMargin} 0;
    color: ${({ theme }) => theme.base};
    border-radius: ${borderRadius};
    background: ${({ theme }) => theme.auctionPageSideBox};
    ${boxShadow}

    &.row {
        justify-content: space-between;
        align-items: stretch;
    }

    @media (max-width: ${breakpoint[1023]}){
        margin: 30px 0;

        &.row {
            flex-direction: column;
        }
    }

    .descriptionSide, 
    .checks {
        width: 50%;
        font-size: 14px;
        padding: ${boxPaddingDescription};
        align-items: flex-start;
        justify-content: flex-start;

        &.cartMode {
            width: 100%;
        }

        @media (max-width: ${breakpoint[1023]}){
            width: 100%;
            padding: ${boxPadding};
        }

        @media (max-width: ${breakpoint[374]}){
            padding: 20px;
        }

        h2 {
            width: 100%;
            margin: 0 0 25px;

            div {
                opacity: 0;
            }
        }

    }

    .descriptionSide {

        .description {

            align-items: flex-start;

            width: 100%;
            line-height: 190%;

            p, ul {
                width: 100%;
                margin: 20px 0 0;
                line-height: 190%;

                &:first-child {
                    margin: 0;
                }

            }

            ul {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                line-height: 140%;

                li {
                    width: 100%;
                    margin: 15px 0 0;

                    &:first-child {
                        margin: 0;
                    }

                    ::before {
                        content: '';
                        width: 7px;
                        min-width: 7px;
                        height: 7px;
                        display: inline-block;
                        background: ${({ theme }) => theme.base};
                        border-radius: 50%;
                        margin: 6px 12px 1px 0;
                    }
                }
            }
        }

    }

    .checks {

        border-left: 1px solid #dddddd;

        @media (max-width: ${breakpoint[1023]}){
            border: 0;
        }

        @media (max-width: ${breakpoint[767]}){
            padding-top: 10px;
        }
        
        .checksBox {
            
            width: 100%;

            .checkRow {
                width: 100%;
                justify-content: flex-start;
                align-items: flex-start;
                margin: 20px 0 0;
                line-height: 170%;

                &:first-child {
                    margin: 0;
                }

                svg {
                    width: 23px;
                    min-width: 23px;
                    fill: ${({ theme }) => theme.base};
                    margin: 1px 12px 0 0;
                }
            }

            .checkBlurb {
                width: 100%;
                margin: 40px 0 0;
                font-weight: 700;
                cursor: pointer;
            }
        }

    }
`

export const AuctionBottomTitle = styled.h3`
        text-align: left;
        text-transform: capitalize;
        font-size: 21px;
        font-weight: 700;
        width: 100%;
        color: ${({ theme }) => theme.base};
        margin: 0 0 20px;
`
export const AuctionPageBottomButtonBox = styled.div`

    width: 100%;
    margin: 20px 0 40px;

    @media (max-width: ${breakpoint[767]}){
        margin: 0 0 20px;
    }

    .button {
        border: 2px solid ${({ theme }) => theme.header};
        color: ${({ theme }) => theme.header};
        background: none;
        box-shadow: none;

        @media (max-width: ${breakpoint[767]}){
            height: ${bidButtonStickMobile};
            padding: 0;
            width: 100%;
            max-width: none;
            border-radius: 0;
        }
    }
`

export const SoldPageTitleBox = styled.div`
        width: 100%;
        text-align: center;
        margin: 40px 0;

        @media (max-width: ${breakpoint[767]}){
            margin: 18px 0;
        }

        h1 {
            width: 100%;
            font-size: 45px;
            color: ${({ theme }) => theme.header};

            @media (max-width: ${breakpoint[1151]}){
                font-size: 37px;
            }

            @media (max-width: ${breakpoint[767]}){
                font-size: 28px;
            }

            @media (max-width: ${breakpoint[374]}){
                font-size: 7vw;
            }
        }

        .blurb {
            width: 100%;
            font-size: 24px;
            margin: 10px 0 0;
            color: ${({ theme }) => theme.base};

            @media (max-width: ${breakpoint[767]}){
                margin: 5px 0 0;
                font-size: 19px;
            }

            @media (max-width: ${breakpoint[374]}){
                font-size: 5.4vw;
            }
        }
`

export const MyAccountBox = styled.div`
    width: 100%;
    max-width: 900px;
    padding: 40px 0;

    @media (max-width: ${breakpoint[767]}){
        padding: 20px 0 40px;
    }

    .displayName {
        width: 100%;
        text-align: center;
        font-weight: 700;
        margin: 15px 0 30px;
        font-size: 25px;

        @media (max-width: ${breakpoint[767]}){
            font-size: 21px;
            margin: 15px 0 20px;
        }

        &.loading {
            position: relative;

            span {
                opacity: 0;
            }

        }

    }

    .tabsBox {
        width: 100%;

        .tabBox {
            width: 100%;
            background: ${({ theme }) => theme.shoppingFrom.bg};
            border: 1px solid ${({ theme }) => theme.shoppingFrom.border};
            border-radius: ${borderRadius};
            margin: 10px 0 0;
            user-select: none;
            position: relative;

            &.active {
                background: ${({ theme }) => theme.white};
                border-color: ${({ theme }) => theme.blackText};

                .titleBar {
                    svg {
                        &.arrow {
                            transform: rotate(180deg);
                        }
                    }
                }

                .infoBox {
                    display: flex;
                }
            }

            &:first-child {
                margin: 0;
            }

            @media (max-width: ${breakpoint[550]}){

                &.profile {

                    .infoRow {
                        flex-direction: column;
                        align-items: flex-start;

                        .infoRowRight {
                            width: 100%;
                            margin: 10px 0 0;
                            justify-content: space-between;

                            .smallBtn {
                                order: 2;
                                margin: 0;
                            }

                        }

                        
                    }

                }

            }

            .titleBar {
                width: 100%;
                height: 75px;
                justify-content: space-between;
                font-size: 21px;
                font-weight: 500;
                text-transform: capitalize;
                padding: 0 ${boxPadding};
                cursor: pointer;

                @media (max-width: ${breakpoint[767]}){
                    height: 60px;
                    font-size: 17px;
                    padding: 0 ${boxPadding2};
                }

                span {
                }

                svg {

                    &.arrow {
                        width: 35px;
                        transition: 0.2s;

                        @media (max-width: ${breakpoint[767]}){
                            width: 30px;
                        }
                    }

                }
            }

            ${smallBtn}

            .infoBox {
                width: 100%;
                align-items: flex-start;
                border-top: 1px solid #000000;
                display: none;

                .infoRow {
                    width: 100%;
                    justify-content: space-between;
                    font-size: 15px;
                    padding: ${boxPadding};
                    border-top: 1px solid ${({ theme }) => theme.shoppingFrom.border};

                    &:last-child {
                        border-radius: 0 0 ${borderRadius} ${borderRadius};
                    }

                    &:first-child {
                        border: 0;
                    }

                    @media (max-width: ${breakpoint[767]}){
                        padding: ${boxPadding2};
                    }

                    &.resetPassword {

                        justify-content: flex-start;
                        align-items: flex-start;

                        .resetPasswordRow {
                            width: 100%;
                            justify-content: space-between;
                        }

                    }

                    &.deleteAccount {
                        justify-content: space-between;
                        background: #fff2f2;

                        .smallBtn {
                            background: red;
                        }
                    }

                    .infoRowLeft {
                        text-transform: uppercase;
                        font-size: 14px;
                        margin: 0 15px 0 0;
                    }

                    .infoRowRight {
                        font-weight: 500;
                        font-size: 17px;

                        @media (max-width: ${breakpoint[767]}){
                            font-size: 14px;
                        }

                        .creditsBox {
                            cursor: pointer;

                            img {
                                width: 30px;

                                @media (max-width: ${breakpoint[374]}){
                                    width: 25px;
                                }

                            }

                            span {
                                margin: 0 0 0 10px;
                                font-size: 21px;
                            }

                        }
                    }

                    .smallBtn {
                        margin: 0 15px 0 0;
                    }

                }

                .noHistory {
                    width: 100%;
                    padding: ${boxPadding};
                    font-size: 18px;

                    @media (max-width: ${breakpoint[767]}){
                        padding: ${boxPadding2};
                        font-size: 14px;
                    }
                    
                }

                .bidHistory {
                    width: 100%;
                    font-size: 14px;

                    .bid {
                        width: 100%;
                        justify-content: space-between;
                        padding: ${boxPadding};
                        border-bottom: 1px solid #000000;
                        cursor: pointer;
                        color: ${({ theme }) => theme.base};

                        &:hover {
                            background: ${({ theme }) => theme.shoppingFrom.bg};
                        }

                        &:last-child {
                            border: 0;
                        }

                        @media (max-width: ${breakpoint[767]}){
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: flex-start;
                            padding: ${boxPadding2};
                        }

                        .leftInfo {

                            margin: 0 15px 0 0;

                            @media (max-width: ${breakpoint[767]}){
                                width: 100%;
                                margin: 0;
                                justify-content: flex-start;
                            }

                            .title {
                                margin: 0 0 0 10px;
                                font-weight: 500;
                            }

                            img {
                                width: 65px;
                            }
                        }

                        .price {
                            
                            font-weight: 500;
                            color: ${({ theme }) => theme.price};
                            white-space: nowrap;

                            @media (max-width: ${breakpoint[767]}){
                                margin: 10px 0 0;
                            }

                            .text {
                                margin: 0 4px 0 0;
                            }

                        }

                    }

                    .pages {

                        width: 100%;
                        padding: 30px 0;
                        font-size: 14px;

                        li {
                            padding: 17px;
                            margin: 0 10px;
                            cursor: pointer;

                            &:hover {
                                text-decoration: underline;
                            }

                            &.active {
                                background: #000000;
                                color: ${({ theme }) => theme.white};
                                font-weight: 700;
                            }

                        }

                    }

                }

                .orderHistory {
                    width: 100%;
                    font-size: 14px;

                    .order {
                        width: 100%;
                        justify-content: space-between;
                        padding: ${boxPadding};
                        border-bottom: 1px solid #000000;
                        color: ${({ theme }) => theme.base};
                        
                        &:last-child {
                            border: 0;
                        }

                        @media (max-width: ${breakpoint[767]}){

                            flex-direction: column;
                            padding: ${boxPadding2};

                        }

                        .leftInfo {

                            margin: 0 15px 0 0;

                            @media (max-width: ${breakpoint[767]}){
                                width: 100%;
                                margin: 0;
                                justify-content: flex-start;
                            }

                            .title {
                                margin: 0 0 0 10px;
                                font-weight: 500;
                            }

                            img {
                                width: 65px;
                            }
                        }

                        .rightInfo {

                            align-items: flex-end;
                            font-size: 12px;

                            @media (max-width: ${breakpoint[767]}){
                                width: 100%;
                                margin: ${boxPadding2} 0 0;
                                align-items: flex-start;
                            }

                            div {
                                
                                margin: 5px 0 0;
                                
                                &:first-child {
                                    margin: 0;
                                }

                            }

                            span {
                                font-weight: 500;
                                text-transform: uppercase;
                            }

                            .price {

                                font-weight: 500;

                                color: ${({ theme }) => theme.price};
                            }

                        }

                    }
                }

                .paymentMethods {
                    width: 100%;
                    font-size: 14px;
                    color: ${({ theme }) => theme.base};

                    .topBar {
                        width: 100%;
                        justify-content: flex-start;
                        align-items: flex-start;
                        padding: ${boxPadding};
                        border-bottom: 1px solid #000000;

                        @media (max-width: ${breakpoint[767]}){

                            padding: ${boxPadding2};

                        }

                        .smallBtn {

                            position: relative;

                            @media (max-width: ${breakpoint[550]}){
                                width: 100%;
                            }

                            &.closeBtn {

                                background: ${({ theme }) => theme.closeBtn};

                            }

                            svg {
                                &.closeIcon {
                                    width: 9px;
                                    margin: -1px 10px 0 0;
                                    fill: ${({ theme }) => theme.white};
                                }
                            }

                            &.loading {

                                span {
                                    opacity: 0;
                                }

                                .lds-ring {
                                    opacity: 1;
                                }
                            }

                            ${ldsRing}

                            .lds-ring {
                                position: absolute;
                                left: 0;
                                right: 0;
                                top: 0;
                                bottom: 0;
                                margin: auto;
                                transform: scale(0.3);
                                opacity: 0;
                            }

                        }

                    }

                    .cardRow {
                        width: 100%;
                        justify-content: space-between;
                        padding: ${boxPadding};
                        border-bottom: 1px solid #000000;

                        @media (max-width: ${breakpoint[767]}){

                            padding: ${boxPadding} ${boxPadding2};

                        }

                        .cardInfo {

                            font-weight: 500;
                            align-items: flex-start;

                            @media (max-width: ${breakpoint[550]}){
                                width: 100%;
                            }

                            ${error}

                            .error {
                                margin: 10px 0 0;
                            }

                            .smallBtns {
                                width: 100%;
                                justify-content: flex-start;
                                margin: 20px 0 0;

                                @media (max-width: ${breakpoint[550]}){
                                    justify-content: space-between;
                                }

                                .smallBtn {    
                                    position: relative;
                                    
                                    @media (max-width: ${breakpoint[550]}){
                                        width: 48.5%;
                                        text-align: center;
                                        padding-left: 0;
                                        padding-right: 0;
                                    }

                                    &:last-child {
                                        margin: 0 0 0 15px;
                                        background: ${({ theme }) => theme.closeBtn};

                                        @media (max-width: ${breakpoint[550]}){
                                            margin: 0;
                                        }
                                    }

                                    svg {
                                        width: 20px;
                                        fill: ${({ theme }) => theme.white};
                                        position: absolute;
                                        left: 0;
                                        right: 0;
                                        top: 0;
                                        bottom: 0;
                                        margin: auto;
                                        opacity: 0;

                                        &.show {
                                            opacity: 1;
                                        }
                                    }

                                    span {
                                        &.hide {
                                            opacity: 0;
                                        }
                                    }

                                }

                            }

                            ${card}

                            .expiry {
                                margin: 20px 0 0;
                            }

                            .zipCode {
                                margin: 15px 0 0;
                            }

                            .expiry,
                            .zipCode {

                                @media (max-width: ${breakpoint[550]}){
                                
                                    width: 100%;
                                    flex-direction: column;
                                    align-items: flex-start;
                            
                                }

                                .text {
                                    min-width: 90px;
                                    text-transform: uppercase;
                                    font-weight: normal;

                                    @media (max-width: ${breakpoint[550]}){
                                        margin: 0 0 5px;
                                    }
                                }

                                .separator {
                                    margin: 0 10px;
                                }

                                .inputBox {

                                    width: 250px;

                                    @media (max-width: ${breakpoint[550]}){
                                        width: 100%;
                                    }

                                    select, 
                                    input {
                                        flex-grow: 1;
                                        padding: 7px 5px;
                                        border: 1px solid ${({ theme }) => theme.shoppingFrom.border};
                                    }

                                    input {
                                        border-top: 0;
                                        border-left: 0;
                                        border-right: 0;
                                    }

                                }
                            }

                        } 

                        &:last-child {
                            border: 0;
                        }

                    }

                }

                .creditCardBox {

                    width: 100%;
                    margin: 30px 0 0;
                    opacity: 0;
                    position: absolute;
                    left: 0;
                    top: 0;
                    z-index: -1;

                    @media (max-width: ${breakpoint[767]}){
                        margin: 20px 0 0;
                    }

                    &.show {
                        display: flex;
                    }

                    &.opacity {
                        opacity: 1;
                        transition: 0.3s;
                        position: relative;
                        left: 0;
                        top: 0;
                        z-index: 100;
                    }

                    .StripeElement {
                        width: 100%;
                    }

                    .error {
                        width: 100%;
                        text-align: left;
                        padding: 10px;
                        font-size: 13px;
                        color: ${({ theme }) => theme.error};
                        font-weight: 70;
                        background: #ffffff;
                        border: 1px solid ${({ theme }) => theme.error};
                        margin: 20px 0 0;
                    }

                    .buttonBox {
                        width: 100%;
                        padding: 30px 0 0;
                        position: relative;
                        z-index: 99999;
                        align-items: flex-start;

                        button {
                            position: relative;

                            @media (max-width: ${breakpoint[374]}){
                                font-size: 15px;
                            }

                            ${ldsRing}

                            .lds-ring {
                                position: absolute;
                                left: 0;
                                right: 0;
                                top: 0;
                                bottom: 0;
                                margin: auto;
                                transform: scale(0.6);
                            }
                            
                        }
                    }
                }

                .accountBox {

                    width: 100%;

                    .infoRow {

                        @media (max-width: ${breakpoint[550]}){
                            flex-direction: column;
                            align-items: flex-start;
                        }

                        .infoRowRight {

                            align-items: flex-end;

                            @media (max-width: ${breakpoint[550]}){
                                width: 100%;
                                align-items: flex-start;
                                margin: 15px 0 0;
                            }

                            .smallBtn {
                                margin: 0;

                                @media (max-width: ${breakpoint[550]}){
                                    width: 100%;
                                    white-space: normal;
                                    text-align: center;
                                }
                            }

                        }
                    }

                }

            }


        }

    }
`

export const HelpIndexBox = styled.div`

    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .helpIntro {
        width: 100%;
        color: ${({ theme }) => theme.base};
        margin: 30px 0;
        justify-content: space-between;

        @media (max-width: ${breakpoint[550]}){
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            margin: 25px 0 30px;
        }

        .helpIntroRight {

            margin: 0 0 0 30px;

            @media (max-width: ${breakpoint[550]}){
                margin: 20px 0 0;
            }

            .questionsTop {
                width: 100%;
                text-align: center;
                font-weight: 500;
                font-size: 16px;
                margin: 0 0 15px;
                color: ${({ theme }) => theme.blackText};
            }

            .btn {
                background: ${({ theme }) => theme.color1};
                border-radius: 50%;
                padding: 12px 55px;
                border-radius: 55px;
                font-weight: 700;
                cursor: pointer;
                white-space: nowrap;
                color: ${({ theme }) => theme.white};
            }
        }

        h1 {
            width: 100%;
            font-weight: 700;
            font-size: 50px;
            line-height: 100%;
            color: ${({ theme }) => theme.blackText};

            @media (max-width: ${breakpoint[1365]}){
                font-size: 40px;
            }

            @media (max-width: ${breakpoint[550]}){
                font-size: 30px;
            }
        }

        p {
            width: 100%;
            font-size: 17px;
            font-weight: 500;
            line-height: 125%;
            margin: 15px 0 0;

            @media (max-width: ${breakpoint[1365]}){
                font-size: 15px;
            }

            @media (max-width: ${breakpoint[550]}){
                font-size: 14px;
                margin: 12px 0 0;
            }
        }

    }


    .helpIndexBlocks {
        width: 100%;
        justify-content: flex-start;
        flex-wrap: wrap;
        align-items: stretch;

        .section {
            width: calc(25% - 19px);
            border: 1px solid ${({ theme }) => theme.shoppingFrom.border};
            margin: 0 25px 25px 0;
            justify-content: flex-start;
            border-radius:  ${borderRadius};
            ${boxShadow}
            transition: 0.3s;

            @media (min-width: 1366px) {
                &:nth-child(4n) {
                    margin-right: 0;
                }
            }

            @media (max-width: ${breakpoint[1365]}) and (min-width: 1024px){
                width: calc(33% - 14px);
                &:nth-child(3n) {
                    margin-right: 0;
                }
            }

            @media (max-width: ${breakpoint[1023]}){
                width: calc(50% - 8px);
                margin: 0 15px 15px 0;
                &:nth-child(even) {
                    margin-right: 0;
                }
            }

            @media (max-width: ${breakpoint[767]}){
                width: 100%;
                margin: 0 0 15px !important;
            }



            &:hover {
                transform: translateY(-10px);
                box-shadow: 0 15px 15px rgba(0,0,0,0.2);
            }

            .title {
                width: 100%;
                text-align: left;
                border-bottom: 1px solid ${({ theme }) => theme.shoppingFrom.border};
                padding: ${boxPadding};
                border-radius:  ${borderRadius} ${borderRadius} 0 0;
                font-size: 17px;
                font-weight: 700;
                text-transform: capitalize;
                color: ${({ theme }) => theme.blackText};
                justify-content: space-between;
                
                svg {
                    width: 13px;
                }

                &:hover {
                    text-decoration: underline;
                }
            }

            .topics {
                width: 100%;
                flex-grow: 1;
                justify-content: flex-start;
                padding: ${boxPadding};

                .topic {
                    width: 100%;
                    font-size: 14px;
                    justify-content: flex-start;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    color: ${({ theme }) => theme.base};
                    position: relative;
                    line-height: 140%;
                    color: ${({ theme }) => theme.color1};

                    &:hover {
                        text-decoration: underline;
                    }

                    span {
                        position: relative;
                        z-index: 100;
                        line-height: 140%;
                    }

                    svg {
                        width: 16px;
                        min-width: 16px;
                        position: relative;
                        z-index: 100;
                        margin: -1px 12px 0 0;
                    }
                }
            }
        }
    }


`

export const ShippingBox = styled.form`
    width: 100%;
    padding: ${boxPadding};

    &.column {
        align-items: flex-start;
    }

    @media (max-width: ${breakpoint[767]}){
        padding: ${boxPadding2};
    }

    .halfGrouping {
        width: 100%;
        justify-content: space-between;
        align-items: flex-start;
        
        @media (min-width: 768px){

            &:first-child {
                .field {
                    margin: 0;
                }
            }

        }
        
        @media (max-width: ${breakpoint[767]}){
            flex-direction: column;
            justify-content: center;

            &:first-child {

                .field {

                    &:first-child {
                        margin: 0;
                    }

                }

            }

        }

        .field {
            width: 49%;

            @media (max-width: ${breakpoint[767]}){
                width: 100%;
            }

        }

    }

    ${field}

    .field {
        
        @media (max-width: ${breakpoint[767]}){

            
            margin: 12px 0 0;

            .inputBox {

                input, select {
                    height: 40px;
                    font-size: 13px;
                }

                label {
                    top: -11px;
                }

            }

        }

    }

    ${error}

    button {
        margin: 40px 0 0;
        position: relative;

        &.cartMode {
            display: none;
        }

        @media (max-width: ${breakpoint[767]}){
            margin: 20px 0 0;
        }

        ${ldsRing}

        .lds-ring {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            transform: scale(0.6);
        }

        .savedBox {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;

            svg {
                width: 35px;
                fill: ${({ theme }) => theme.white};
            }

            span {
                margin: 0 0 0 10px;

                @media (max-width: ${breakpoint[550]}){
                    font-size: 17px;
                }
            }
        }

    }
`

export const HelpPage = styled.div`
    width: 100%;
    padding: 0 0 60px;
    
    
    &.helpSubPage {
        @media (max-width: ${breakpoint[1023]}){
            padding-top: ${helpMenuHeight} !important;
        }
    }

    @media (max-width: ${breakpoint[767]}){
        padding: 0 0 30px;
    }

    .helpTabTopic {
        font-size: 14px;
        justify-content: flex-start;
        padding-top: 10px;
        padding-bottom: 10px;
        color: ${({ theme }) => theme.base};
        position: relative;

        span {
            position: relative;
            z-index: 100;
            line-height: 140%;
        }

        svg {
            width: 16px;
            min-width: 16px;
            position: relative;
            z-index: 100;
            margin: -1px 12px 0 0;
        }
    }

    .helpBox {
        width: 100%;
        

        .helpContent {
            width: 100%;
            justify-content: space-between;
            align-items: stretch;

            @media (max-width: ${breakpoint[1023]}){
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
            }
        }

        .helpLeft {
            width: 100%;
            justify-content: flex-start;
            border: 1px solid ${borderColor};
            border-radius:  ${borderRadius};
            ${boxShadow}
            margin: 0 20px 0 0;

            @media (max-width: ${breakpoint[1023]}){
                margin: 0;
            }

            .helpContent {
                width: 100%;
                align-items: flex-start;
                justify-content: flex-start;
                padding: 30px;
                padding-bottom: 40px;
                font-size: 16px;

                @media (max-width: ${breakpoint[767]}){
                    padding: 15px;
                    padding-bottom: 30px;
                }

                .html {
                    width: 100%;
                    max-width: 768px;
                    align-items: flex-start;
                    justify-content: flex-start;
                    line-height: 160%;

                    @media (max-width: ${breakpoint[767]}){
                        font-size: 15px;
                    }

                    img {
                        max-width: 100%;
                        border: 1px solid ${borderColor};
                        border-radius:  ${borderRadius};
                        margin: 35px 0 10px;

                        &:first-child {
                            margin-top: 0;
                        }

                        &.noBorder {
                            border: 0;
                        }

                        &.noRadius {
                            border-radius:  0;
                        }
                    }

                }

                .contactUs {
                    width: 100%;
                    align-items: flex-start;

                    input {
                        max-width: 500px;
                    }

                    ${field}
                    ${error}

                    .field {
                        &:first-child {
                            margin: 0;
                        }
                    }

                    .inputBox {
                        justify-content: flex-start;

                        textarea {
                            @media (max-width: ${breakpoint[767]}){
                                height: 150px !important;
                            }
                        }
                    }

                    button {
                        margin: 30px 0 0;
                        position: relative;
                    }

                    ${ldsRing}

                    .lds-ring {
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        margin: auto;
                        transform: scale(0.6);
                    }

                    .success {
                        width: 100%;
                        justify-content: flex-start;
                    }

                    .helpContactSubline {
                        width: 100%;
                        max-width: 700px;
                        font-size: 12px;
                        margin: 30px 0 0;
                        line-height: 130%;
                        opacity: 0.8;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        cursor: pointer;

                        .check {
                            width: 20px;
                            min-width: 20px;
                            height: 20px;
                            border: 1px solid #000000;
                            margin: 0 15px 0 0;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            
                            svg {
                                width: 70%;
                            }
                        }

                        .sublineRight {
                            width: 100%;
                        }
                    }
                }

                .avatarsBox {
                    @media (max-width: ${breakpoint[1151]}){
                        width: 500px;
                    }

                    @media (max-width: ${breakpoint[767]}){
                        width: 300px;
                        align-self: center;
                    }
                    
                    .avatarsInner {
                        .avatar {
                            @media (max-width: ${breakpoint[1151]}){
                                width: 100px !important;
                                height: 86px  !important;
                            }

                            @media (max-width: ${breakpoint[767]}){
                                &:nth-child(n+25) {
                                    display: none;
                                }
                            }

                            img {
                                @media (max-width: ${breakpoint[1151]}){
                                    width: 60px;
                                }
                            }
                        }
                    }

                }

                .bidsPack {
                    width: 100%;
                    justify-content: flex-start;
                    margin: 30px 0 0;

                    img {
                        width: 180px;

                        @media (max-width: ${breakpoint[767]}){
                            width: 120px;
                        }
                    }

                    .bidsAmount {
                        margin: 0 0 0 20px;
                        font-weight: 700;
                        font-size: 21px;

                        @media (max-width: ${breakpoint[767]}){
                            font-size: 19px;
                        }

                        span {
                            color: ${({ theme }) => theme.helpBidsPackText};
                        }
                    }
                }

                a {
                    color: ${({ theme }) => theme.color1};
                    text-decoration: underline;

                    &.helpTabTopic {
                        text-decoration: none;
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }

                .button {
                    color: ${({ theme }) => theme.white};
                    text-decoration: none;
                    padding: 15px 30px;
                    margin: 40px 0 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                }

                p, ol {
                    width: 100%;
                    margin: 30px 0 0;
                    line-height: 150%;

                    @media (max-width: ${breakpoint[767]}){
                        margin: 25px 0 0;
                    }

                    &:first-child {
                        margin-top: 0;
                    }
                }

                ol {
                    list-style-position: inside;

                    li {
                        width: 100%;
                        display: flex;
                        justify-content: flex-start;
                        align-items: flex-start;
                        margin: 20px 0 0;

                        &:first-child {
                            margin: 0;
                        }

                        .helpCircle {
                            width: 32px;
                            min-width: 32px;
                            height: 32px;
                            background: ${({ theme }) => theme.color1};
                            color: ${({ theme }) => theme.white};
                            border-radius: 50%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            font-weight: 700;
                            margin: -3px 10px 0 0;
                            cursor: default;
                        }

                        div {
                            width: 100%;

                            p {
                                width: 100%;
                                margin: 20px 0 0;
                                &:first-child {
                                    margin-top: 0;
                                }
                            }
                        }
                    }
                }
            }

            .brandsBox {
                width: 100%;
                flex-wrap: wrap;

                .brandBox {
                    width: 25%;
                    padding: 30px;

                    @media (max-width: ${breakpoint[1151]}){
                        width: 33%;
                        padding: 20px;
                    }

                    @media (max-width: ${breakpoint[767]}){
                        width: 50%;
                    }
                }
            }

            .avatarsBox {
                width: 650px;
                justify-content: flex-start;
                align-items: flex-start;
                margin: 30px 0 0;

                .avatarsTitle {
                    width: 100%;
                    padding: 22px 0;
                    background: ${({ theme }) => theme.header};
                    color: ${({ theme }) => theme.white};
                    font-size: 24px;
                    width: 100%;
                    text-align: center;    
                    border-radius: ${borderRadius} ${borderRadius} 0 0;
                    text-transform: capitalize;
                    position: relative;
                    font-weight: 700;

                    @media (max-width: ${breakpoint[767]}){
                        font-size: 17px;
                        padding: 18px 0;
                    }
                }

                .avatarsInner {
                    width: 100%;
                    flex-wrap: wrap;
                    background: ${({ theme }) => theme.shoppingFrom.bg};
                    justify-content: flex-start;
                    align-items: flex-start;

                    .avatar {
                        width: 130px;
                        height: 112px;
                        border: 1px solid ${({ theme }) => theme.shoppingFrom.border};

                        &.active {
                            img {
                                opacity: 1;
                            }
                        }

                        img {
                            width: 70px;
                            border: 0;
                            margin: 0;
                            opacity: 0;
                            transition: 0.2s;
                        }
                    }
                }
            }

            h1, h2 {
                width: 100%;
                text-align: left;
                border-bottom: 1px solid ${borderColor};
                padding: ${boxPadding};
                justify-content: flex-start;
                font-size: 17px;
                font-weight: 700;
                text-transform: capitalize;

                @media (max-width: ${breakpoint[767]}){
                    font-size: 19px;
                    padding: 22px;
                }
            }
            
            h2 {
                border-top: 1px solid ${borderColor};
            }

            .nextTopic {
                background: ${({ theme }) => theme.color1};
                border-radius: 50%;
                padding: 12px 30px;
                padding-right: 20px;
                margin: 40px 0 0;
                border-radius: 55px;
                font-weight: 700;
                cursor: pointer;

                span {
                    white-space: nowrap;
                    color: ${({ theme }) => theme.white};
                }

                svg {
                    width: 25px;
                    min-width: 25px;
                    fill: ${({ theme }) => theme.white};
                    margin: 0 0 0 2px;
                }
            }
        }
    }
`

export const HelpMenuBox = styled.div`
    width: 320px;
    min-width: 320px;
    background: ${({ theme }) => theme.shoppingFrom.bg};
    border: 1px solid ${({ theme }) => theme.shoppingFrom.border};
    border-radius:  ${borderRadius};
    ${boxShadow}
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;


    @media (max-width: ${breakpoint[1023]}){
        width: 100%;
        height: calc(100% - ${headerHeight[2]} - ${helpMenuHeight});
        min-width: initial;
        position: fixed;
        overflow: auto;
        left: 0;
        bottom: 0;
        border: 0;
        border-radius: 0;
        box-shadow: none;
        z-index: 999999;
        transform: translateX(-100%);
        opacity: 0;

        &.helpMenuOpen {
            display: flex;
            transform: translateX(0);
            transition: 0.2s;
            opacity: 1;
        }
    }

    .helpTab {
        width: 100%;
        border-top: 1px solid ${({ theme }) => theme.shoppingFrom.border};

        @media (max-width: ${breakpoint[1023]}){
            border-top: 0;
            border-bottom: 1px solid ${({ theme }) => theme.shoppingFrom.border} !important;
        }

        &:first-child {
            border: 0;
        }

        &.active, &.selectedGroup {

            .helpTabTopic {
                display: flex;
            }
            
            .helpTabTitle {
                text-decoration: underline;

                svg {
                    transform: rotate(-90deg);
                }
            }
        }

        .helpTabTitle {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-weight: 700;
            font-size: 17px;
            padding: 25px 0;
            color: ${({ theme }) => theme.blackText};
            text-transform: capitalize;

            svg {
                width: 7px;
                transform: rotate(90deg);
                transition: 0.2s;
            }
        }

        .helpTabTitle, .helpTabTopic {
            padding-left: 25px;
            padding-right: 25px;
        }

        .helpTabTopic {
            width: 100%;
            display: none;

            &:last-child {
                margin: 0 0 25px;
            }

            .activeBg {
                width: calc(100% + 2px);
                height: 100%;
                position: absolute;
                left: -1px;
                top: 0;
                z-index: 0;
                background: ${({ theme }) => theme.color1};
                opacity: 0;

                @media (max-width: ${breakpoint[1023]}){
                    width: 100%;
                    left: 0;
                }
            }

            &:hover {
                background: #eeeeee;
            }

            &.active {
                color: ${({ theme }) => theme.white};
                font-weight: 500;
                .activeBg {
                    opacity: 1;
                }
                svg {
                    fill: #ffffff;
                }
            }
        }   
    }
`

export const HelpMenuTitleMobileBox = styled.div`
    width: 100%;
    display: none;
    cursor: pointer;
    background: ${({ theme }) => theme.shoppingFrom.bg};
    border-bottom: 1px solid ${({ theme }) => theme.shoppingFrom.border};
    z-index: 999999;
    position: fixed;
    left: 0;
    top: ${headerHeight[2]};

    &.row {
        justify-content: flex-start;
        display: none;

        @media (max-width: ${breakpoint[1023]}){
            display: flex;
        }

    }


    &.active {
        svg {
            &.open {
                display: none;
            }
            &.close {
                display: block;
            }
        }
    }
    
    width: 100%;
    height: ${helpMenuHeight};
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: 700;
    font-size: 17px;
    padding: 0 25px;
    color: ${({ theme }) => theme.blackText};
    text-transform: capitalize;

    svg {
        width: 15px;
        margin: 0 12px 0 0;
        &.open {
    
        }
        &.close {
            display: none;
        }
    }

`

export const FooterBox = styled.footer`

    width: 100%;
    background: ${({ theme }) => theme.header};
    color: ${({ theme }) => theme.white};

    &.mobilePadding {

        @media (max-width: ${breakpoint[767]}){
            padding-bottom: ${bidButtonStickMobile};
        }
        
    }

    .menu {
        width: 100%;
        justify-content: space-between;
        align-items: flex-start;
        margin: 80px 0;

        @media (max-width: ${breakpoint[767]}){

            flex-direction: column;
            margin: 60px 0;

        }


        .menuBlock {
            width: 33%;
            align-items: flex-start;

            @media (max-width: ${breakpoint[767]}){

                width: 100%;
                justify-content: flex-start;
                margin: 60px 0 0;

                &:first-child {
                    margin: 0;
                }

            }
            
            h3 {
            
                font-size: 19px;
                font-weight: 700;
                opacity: 0.8;
                cursor: default;
            }

            ul {
                align-items: flex-start;
                margin: 10px 0 0;

                @media (max-width: ${breakpoint[767]}){
                    width: 100%;
                }

                li, li a {

                    @media (max-width: ${breakpoint[767]}){ 
                        width: 100%;
                    }

                }

                li {
                    text-transform: capitalize;
                    font-size: 17px;
                    font-weight: 500;
                    margin: 20px 0 0;
                    cursor: pointer;

                    &.lowercase {
                        text-transform: none;
                    }

                    &:hover {
                        text-decoration: underline;
                    }

                }
            }

        }

        a {
            color: ${({ theme }) => theme.white};

        }
    }

    .flagBox {

        cursor: default;

        img {

            &.flag {
                width: 32px;
            }

        }

        span {
            text-transform: uppercase;
            font-size: 14px;
            margin: 0 0 0 15px;
            letter-spacing: 1px;
            font-weight: 500;
        }

    }

    ${smallBtn}

    .smallBtns {

        width: 100%;
        margin: 60px 0;
        justify-content: flex-start;
        
        @media (max-width: ${breakpoint[1151]}) {
            flex-direction: column;
        }
        

        a {
            min-width: 120px;
            background: none;
            border: 1px solid ${({ theme }) => theme.white}; 
            color: ${({ theme }) => theme.white};
            text-align: center;


            @media (max-width: ${breakpoint[1151]}) {
                width: 100%;
                padding: 15px 0;
            }


            &:last-child {
                margin: 0 0 0 10px;

                @media (max-width: ${breakpoint[1151]}) {
                    margin: 15px 0 0;
                }
            }
        }

    }

    .logo {
        
        width: 200px;
        
        img {
            width: 100%;
        }

    }

    .address {
        margin: 50px 0;
    }

    .copyright {
        color: #424242;
        font-size: 13px;
        cursor: default;

        &.signedIn {

            margin: 40px 0 0;

        }

    }

    .footerBottom {
        width: 100%;
        justify-content: space-between;
        padding: 50px 0;
        border-top: 1px solid #424242;
        text-transform: capitalize;

        @media (max-width: ${breakpoint[767]}){

            flex-direction: column;
            justify-content: center;

        }

        .footerBottomRight {
            font-size: 12px;

            @media (max-width: ${breakpoint[767]}){
                margin: 60px 0 0;
            }

            a {
                color: ${({ theme }) => theme.white};

                &:hover {
                    text-decoration: underline;
                }

                &:last-child {

                    margin: 0 0 0 30px;
                    
                }

            }

        }

    }


`

export const PagesBox = styled.div`

    width: 100%;
    margin: 0 0 60px;
    align-items: stretch;

    &.bidHistoryPage {
        margin: 50px 0 30px;
    }

    .sideArrow {
        cursor: pointer;
        margin: 0 30px;
        
        &.default {
            opacity: 0.3;
            cursor: default;
        }

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }

        svg {
            width: 25px;
            min-width: 25px;
        }
    }

    a {

        color: ${({ theme }) => theme.header};

    }

    .pages {
        flex-wrap: wrap;
        .page {
            width: 50px;
            height: 50px;
            font-weight: 700;
            cursor: pointer;
            background: #eeeeee;

            &:hover {
                text-decoration: underline;
            }

            &.default {
                width: auto;
                cursor: default !important;
                text-decoration: none !important;
            }

            &.selected {

                background: ${({ theme }) => theme.header};
                color: ${({ theme }) => theme.white};
                cursor: default;
                text-decoration: none;
            }
        }

    }

`

export const BreadcrumbBox = styled.div`

    width: 100%;
    font-size: 14px;
    color: ${({ theme }) => theme.base};
    cursor: default;
    padding: 30px 0;
    transition: 0.2s;
    opacity: 1;
    line-height: 150%;

    @media (max-width: ${breakpoint[767]}){
        font-size: 12px;
        padding: 18px 0;
    }

    &.loading {
        opacity: 0;
    }

    &.row {
        justify-content: space-between;
    }

    .resultText {
        margin: 0 15px 0 0;
    }

    svg.arrowBreadcrumb {
        width: 5px;
        margin: 0 10px;
    }

    span {

        &.allDeals {
            font-weight: 500;
            white-space: nowrap;
            cursor: pointer;
            color: ${({ theme }) => theme.base};

            &:hover {
                text-decoration: underline;
            }
        }

        &.results {
            font-weight: 500;
        }

        &.totalAuctions {
            font-weight: 700;
            white-space: nowrap;
        }

    }

    .breadcrumbLeft {

        flex-wrap: wrap;
        justify-content: flex-start;

        li {

            font-weight: 500;

            &:last-child {
                font-weight: normal;
            }

        }

        a {

            color: ${({ theme }) => theme.base};
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }

        }

    }
    
    .breadcrumbRight {

        white-space: nowrap;

        span {

            font-weight: 500;

        }

    }


`

export const FeatureBannerBox = styled.div`
    width: 100%;
    margin: 30px 0 0;

    .wrap {
        position: relative;
        cursor: pointer;

        @media (max-width: ${breakpoint[768]}){
            width: 100%;
        }
    }

    @media (max-width: ${breakpoint[768]}){
        margin: 0;
    }
    
    &.loading {

        cursor: default;

        picture {
            display: none;
        }

        svg {
            opacity: 1;
        }

    }

    svg {

        width: 100%;
        fill: ${({ theme }) => theme.loading};
        border-radius: ${borderRadius};
        opacity: 0;

        @media (max-width: ${breakpoint[768]}){
            border-radius: 0;
        }

        &.feature {

            @media (max-width: ${breakpoint[768]}){
                display: none;
            }

        }

        &.feature768 {

            display: none;

            @media (max-width: ${breakpoint[768]}){
                display: block;
            }

            @media (max-width: ${breakpoint[450]}){
                display: none;
            }

        }

        &.feature450 {

            display: none;

            @media (max-width: ${breakpoint[450]}){
                display: block;
            }

        }

    }

    picture {
        width: 100%;
        border-radius: ${borderRadius};
        cursor: pointer;
        ${boxShadow}
        position: absolute;
        left: 0;
        top: 0;

        @media (max-width: ${breakpoint[768]}) {
            
            border-radius: 0;
        
        }

        img {
            width: 100%;
            border-radius: ${borderRadius};
            display: block;

            @media (max-width: ${breakpoint[768]}) {

                border-radius: 0;

            }

        }
    }

`

export const LegalBox = styled.div`
    width: 100%;
    font-size: 12px;
    padding: 35px 0 60px;

    .wrap {

        align-items: flex-start;
        justify-content: flex-start;

        h1, ol, li {
            width: 100%;
            margin: 0;
            padding: 0;
        }

        h1, h3 {
            font-size: 18px;
        }

        ol {
            margin: 20px 0 0;
        }

        p, h3 {
            margin: 15px 0;
        }

        a {
            color: ${({ theme }) => theme.blackText};
        }

    }
`

export const IdleBox = styled.div`

    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 999999999999;
    background: rgba(0,0,0,0.5);
    padding: 20px;
    display: flex;
    overflow: auto;

`

export const AvatarImage = styled.div`

    background: #ffffff;
    width: 50px;
    height: 50px;

    @media (max-width: ${breakpoint[767]}){
        width: 40px;
        height: 40px;
    }

    @media (max-width: ${breakpoint[374]}){
        width: 35px;
        height: 35px;
    }

    &.myAccount1 {

        width: 150px;
        height: 150px;
        border: 1px solid ${({ theme }) => theme.shoppingFrom.border};
        border-radius: 50%;
        cursor: pointer;

        @media (max-width: ${breakpoint[767]}){
            width: 90px;
            height: 90px;
        }
        
        img {
            width: 70%;
            height: 70%;
        }

    }

    &.myAccount2 {
        
        cursor: pointer;

        img {
            width: 50px;
            height: 50px;
        }

        ${smallBtn}

    }

`

export const MenuCoverBox = styled.div`
    width: 100%;
    height: calc(100% - ${headerHeight[1]});
    position: fixed;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 90000000;

    &.forceShow {
        display: flex !important;
    }

    @media (max-width: ${breakpoint[1500]}){
        height: calc(100% - ${headerHeight[2]});
    }

    @media (min-width: 1152px){
        display: none;
    }

`

export const SlideMenuRightBox = styled.div`
    min-width: 200px;
    background: ${({ theme }) => theme.shoppingFrom.bg};
    position: fixed;
    right: 0;
    top: ${headerHeight[1]};
    z-index: 99999999;
    overflow: auto;
    justify-content: flex-start;
    transform: translateX(100%);
    margin: 0;
    border-radius: 0 0 0 ${borderRadius};
    text-transform: capitalize;

    @media (max-width: ${breakpoint[1500]}){
        top: ${headerHeight[2]};
    }

    &.active {
        transition: transform 0.2s;
        transform: translateX(0%);
    }

    li {
        width: 100%;
        padding: 15px 20px;
        justify-content: flex-start;
        font-size: 17px;
        color: ${({ theme }) => theme.blackText};
        border-top: 1px solid ${({ theme }) => theme.shoppingFrom.border};
        cursor: pointer;

        @media (max-height: 424px){
            font-size: 14px;
            padding: 9px 15px;
        }

        &:first-child {
            border: 0;
        }

    }
`

export const CornerPieceBox = styled.div`
    width: 40px;
    height: ${headerHeight[1]};
    position: fixed;
    right: 0;
    top: 0;
    background: ${({ theme }) => theme.header};
    z-index: -1;
    display: none;

    @media (max-width: ${breakpoint[1500]}){

        height: ${headerHeight[2]};

    }

`

export const IntroVideoBox = styled.div`
    width: 100%;
    padding: 35px 0;
    background: linear-gradient(to top,#27828b 0%,#071314 40%);

    @media (max-width: ${breakpoint[768]}){
        background: none;
        padding: 0;
    }

    &.loading {
        
        .leftSide, .videoBox {
            opacity: 0;
        }

        background: ${({ theme }) => theme.loading};

    }

    .wrap {
        justify-content: space-between;

        @media (max-width: ${breakpoint[1600]}){
            justify-content: center;
        }

        @media (max-width: ${breakpoint[1152]}){
            justify-content: space-between;
        }

        @media (max-width: ${breakpoint[1023]}){
            width: 100%;
            flex-direction: column;
        }
    }

    .leftSide {
        flex-grow: 1;
        align-items: flex-start;
        padding:  0 70px 0 0;
        color: ${({ theme }) => theme.tag.font};

        @media (max-width: ${breakpoint[1600]}){
            flex-grow: unset;
        }

        
        @media (max-width: ${breakpoint[1023]}){
            width: 100%;
            text-align: center;
            align-items: center;
            padding: 0 0 25px;
        }
        
        @media (max-width: ${breakpoint[768]}){
            padding: 30px 0;
            background: linear-gradient(to top,#206970 0%,#000000 45%);
        }

        @media (max-width: ${breakpoint[767]}){
            padding: 15px 0 20px;
        }

        .tag {
            padding: 15px 20px;
            color: ${({ theme }) => theme.tag.font};
            background: ${({ theme }) => theme.tag.bg};
            font-weight: 700;
            font-size: 15px;
            text-transform: uppercase;
            cursor: default;

            @media (max-width: ${breakpoint[1600]}){
                font-size: 13px;
                padding: 12px 15px;
            }

            @media (max-width: ${breakpoint[1152]}){
                font-size: 15px;
            }

            @media (max-width: ${breakpoint[1024]}){
                font-size: 14px;
            }

            @media (max-width: ${breakpoint[767]}){
                font-size: 13px;
                padding: 10px 15px;
            }
        }

        h1 {
            width: 100%;
            font-weight: 700;
            font-size: 71px;
            line-height: 100%;
            text-transform: uppercase;
            letter-spacing: -2px;
            margin: 25px 0 30px;

            @media (max-width: ${breakpoint[1600]}){
                font-size: 54px;
            }
            
            @media (max-width: ${breakpoint[1500]}){
                font-size: 48px;
            }
            
            @media (max-width: ${breakpoint[1152]}){
                margin: 20px 0 25px;
                font-size: 55px;
            }
            
            @media (max-width: ${breakpoint[1024]}){
                font-size: 50px;
            }

            @media (max-width: ${breakpoint[1023]}){
                font-size: 47px;
                margin: 25px 0 20px;
            }

            @media (max-width: ${breakpoint[767]}){
                font-size: 5.8vw;
                letter-spacing: 0;
                margin: 22px 0 17px;
            }

            br {
                @media (max-width: ${breakpoint[1023]}){
                    display: none;
                }
            }

        }

        .h1Sub {
            width: 100%;
            font-size: 22px;
            font-weight: 500;
            line-height: 125%;

            @media (max-width: ${breakpoint[1500]}){
                font-size: 17px;
            }

            @media (max-width: ${breakpoint[1152]}){
                font-size: 21px;
            }

            @media (max-width: ${breakpoint[1024]}){
                font-size: 19px;
            }

            @media (max-width: ${breakpoint[1023]}){
                font-size: 21px;
            }

            @media (max-width: ${breakpoint[767]}){
                font-size: 18px;
                font-weight: 400;
            }
        }
    }

    .videoBox {
        width: 750px;
        min-width: 750px;
        box-shadow: 0 3px 3px rgba(0, 0, 0, .2);
        position: relative;

        @media (max-width: ${breakpoint[1600]}){
            width: 40%;
            min-width: 40%;
        }

        @media (max-width: ${breakpoint[1500]}){
            width: 42%;
            min-width: 42%;
        }

        @media (max-width: ${breakpoint[1152]}){
            width: 55%;
            min-width: 55%;
        }

        @media (max-width: ${breakpoint[1024]}){
            width: 550px;
            min-width: 550px;
        }

        @media (max-width: ${breakpoint[1023]}){
            width: 100%;
            max-width: 768px;
            min-width: initial;
            box-shadow: 0 0 15px rgba(0, 0, 0, .3);
        }

        svg {
            &.placeholder {
                width: 100%;
                position: relative;
                z-index: 0;
                fill: ${({ theme }) => theme.loading};
            }
        }

        .poster {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0; 
            z-index: 70;
        }

        .playCover {
            width: 100%;
            height: 100%;
            z-index: 100;
            position: absolute;
            left: 0;
            top: 0;
            cursor: pointer;

            .playCircle {
                width: 120px;
                height: 120px;
                background-color:rgba(255, 255, 255, 0.8);
                border-radius: 50%;
                border: 7px solid #ffffff;
                box-shadow: 0 0 10px rgba(0, 0, 0, .2), 0 0 10px rgba(0, 0, 0, .4) inset; 
                padding: 0 0 0 1%;

                @media (max-width: ${breakpoint[1500]}){
                    transform: scale(0.8);
                }

                @media (max-width: ${breakpoint[1023]}){
                    transform: scale(1);
                }

                @media (max-width: ${breakpoint[767]}){
                    transform: scale(0.8);
                }

                svg {
                    width: 50%;
                    fill: #051718;
                }
            }
        }

        .endedPreCover {
            width: 100%;
            height: 100%;
            z-index: 100;
            position: absolute;
            left: 0;
            top: 0;  
        }

        .endedCover {
            width: 100%;
            height: 100%;
            z-index: -1;
            position: absolute;
            left: 0;
            top: 0;
            background-color:rgba(0, 0, 0, 0.5);
            opacity: 0;

            &.active {
                opacity: 1;    
                z-index: 300;
                transition: 0.3s;

                .button {
                    opacity: 1;
                    transform: translate(0, 0);
                }
            }

            .reloadCorner {
                position: absolute;
                left: 20px;
                bottom: 20px;
                background-color:rgba(0, 0, 0, 0.5);
                color: ${({ theme }) => theme.white};
                border-radius: 55px;
                padding: 7px 15px;
                font-size: 14px;
                font-weight: 500;
                text-transform: uppercase;
                letter-spacing: 1px;
                cursor: pointer;
                user-select: none;
                transition: 0.2s;

                &:hover {
                    background-color:rgba(0, 0, 0, 0.8);
                }

                svg {
                    width: 12px;
                    margin: 0 7px 0 0;
                    fill: ${({ theme }) => theme.white};
                }
            }

            .button {
                width: 70%;
                box-shadow: none;
                opacity: 0;
                transform: translate(0, 60px);
                transition: 0.3s;
                transition-delay: 301ms;
            }
        }

        .videoInner {
            position: absolute;
            left: 0;
            top: 0;
            z-index: 50;
            cursor: pointer;
            video {
                width: 100%;
            }
        }
    }

`

export const StyledInCart = styled.div`
    width: 100%;
    margin: 0 0 ${boxPadding};
    border-radius: ${borderRadius};
    border: 1px solid  ${({ theme }) => theme.addBidsSelected.borderColor};
    background:  ${({ theme }) => theme.addBidsSelected.background};
    padding: ${boxPadding};
    font-size: 20px;
    cursor: default;
    ${boxShadowAddBids}
    
    &.row {
        justify-content: space-between;
    }

    @media (max-width: ${breakpoint[550]}){
        padding: 15px ${boxPadding2};
        font-size: 15px;
        margin: 0 0 20px;
    }

    @media (max-width: ${breakpoint[550]}) and (max-height: ${breakpointHeight[627]}) {
        padding: 12px 15px;
        margin: 0 0 15px;
    }

    .amount {
        margin: 0 0 0 15px;
        font-weight: 700;

        @media (max-width: ${breakpoint[550]}) and (max-height: ${breakpointHeight[627]}) {
            margin: 0 0 0 8px;
        }
    }

    .sub {
        font-weight: normal;
        margin: 0 0 0 5px;
    }

    .radio {
        ${radio}
        ${radioSelected}
        background: ${({ theme }) => theme.white};
        margin: 0;

        @media (max-width: ${breakpoint[550]}) and (max-height: ${breakpointHeight[627]}) {

            transform: scale(0.8);

        }
    }

    .productLeft {
        flex-grow: 1;
        font-size: 16px;
        align-items: flex-start;

        .title {
            
        }

        .cost {
            margin: 6px 0;
            font-weight: 700;
        }

        .subLine {
            font-size: 13px;
        }
    }

    img {
        width: 100px;
        margin: 0 15px;
    }

`

export const WinningAuctionBox = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    
    
    ${ldsRing}
    .lds-ring {
        height: 400px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .lds-ring div {
        border-color: ${({ theme }) => theme.base} transparent transparent transparent;
    }

    .winningAuctionTitleBox {
        width: 100%;
        justify-content: space-between;
        font-size: 14px;
        color: ${({ theme }) => theme.base};
        padding: 30px 0;
        line-height: 150%;

        @media (max-width: ${breakpoint[550]}){
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            padding: 25px 0;
        }

        .title {
            font-weight: 700;
            text-transform: capitalize;

            @media (max-width: ${breakpoint[550]}){
                width: 100%;
            }
        }

        select {
            padding: 10px;
            font-size: 17px;

            @media (max-width: ${breakpoint[550]}){
                width: 100%;
                margin: 15px 0 0;
            }
        }

    }

    .noCriteria {
        width: 100%;
        text-align: center;
        margin: 40px 0 110px;
        font-weight: 700;
    }

    .noCriteriaOrders {
        width: 100%;
        text-align: center;
        height: 400px;
        font-weight: 700;

        button {
            margin: 30px 0 0;
        }
    }

    .noAuctionsFiltered {
        width: 100%;
        height: 400px;
        font-weight: 700;
        text-align: center;

        button {
            margin: 30px 0 0;
        }
    }

`

export const BidHistoryBox = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 0 40px;

    ${ldsRing}
    .lds-ring {
        height: 400px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .lds-ring div {
        border-color: ${({ theme }) => theme.base} transparent transparent transparent;
    }

    .titleBox {
        width: 100%;
        font-size: 14px;
        color: ${({ theme }) => theme.base};
        font-weight: 700;
        padding: 30px 0;
        justify-content: space-between;
        cursor: default;

        .leftSide {
            text-transform: capitalize;
        }

        @media (max-width: ${breakpoint[550]}){
            padding: 25px 0;
        }
    }

    .bidHistory {
        width: 100%;
        border: 1px solid ${({ theme }) => theme.shoppingFrom.border};
        ${boxShadow}
        border-radius:  ${borderRadius};

        .bidHistoryRow {
            width: 100%;
            justify-content: space-between;
            font-size: 13px;
            padding: 20px;
            border-bottom: 1px solid ${({ theme }) => theme.shoppingFrom.border};

            @media (max-width: ${breakpoint[550]}){
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                font-size: 12px;
            }

            &:hover {
                .title {
                    text-decoration: underline;
                }
            }

            &:last-child {
                border: 0;
            }

            .leftSide {

                img {
                    width: 70px;
                }

                .info {
                    width: 100%;
                    padding: 0 0 0 20px;

                    .title {
                        width: 100%;
                        font-weight: 700;
                        color: ${({ theme }) => theme.base};
                    }

                    .price {
                        width: 100%;
                        font-weight: 700;
                        color: ${({ theme }) => theme.price};
                        margin: 10px 0;
                    }

                    .time {
                        width: 100%;
                        color: ${({ theme }) => theme.base};
                    }
                }

            }

            .btn {
                padding: 10px 20px;
                background: ${({ theme }) => theme.button};
                color: ${({ theme }) => theme.white};
                border-radius: 55px;
                font-weight: 700;
                white-space: nowrap;
                margin: 0 0 0 25px;
                text-transform: uppercase;

                @media (max-width: ${breakpoint[550]}){
                    margin: 15px 0 0;
                }
            }
        }
    }

    .noBidHistory {
        width: 100%;
        height: 400px;
        font-weight: 700;
        text-align: center;

        button {
            margin: 30px 0 0;
        }
    }

`

export const HowItWorksBox = styled.div`
    width: 100%;
    
    .introTop {
        width: 100%;
        padding: 40px 0 45px;

        @media (max-width: ${breakpoint[767]}){
            padding: 20px 0;
        }
            
        h1 {
            width: 100%;
            width: 100%;
            font-weight: 700;
            font-size: 80px;
            line-height: 100%;
            text-align: center;
            text-transform: capitalize;

            @media (max-width: ${breakpoint[767]}){
                font-size: 10vw;
            }
        }

        .subText {
            width: 100%;
            text-align: center;
            font-size: 27px;
            margin: 12px 0 0;
            font-weight: 500;
            color: #444444;

            @media (max-width: ${breakpoint[767]}){
                font-size: 4.2vw;
                margin: 7px 0 0;
            }
        }

        .arrowBox {
            margin: 20px 0 0;
            cursor: pointer;

            @media (max-width: ${breakpoint[550]}){
                margin: 12px 0 0;
            }

            span {
                width: 100%;
                font-size: 18px;
                margin: 10px 0 0;
                text-align: center;
                text-transform: uppercase;
                font-weight: 500;
                letter-spacing: 2px;
                color: ${({ theme }) => theme.button};
                white-space: nowrap;

                @media (max-width: ${breakpoint[550]}){
                    font-size: 15px;
                }
            }

            svg {
                width: 80px;
                height: 80px;            
    
                @media (max-width: ${breakpoint[550]}){
                    width: 60px;
                    height: 60px;
                }            
            }

        }

    }

    .slick-slider {
        width: 100%;
        border-top: 1px solid;
        border-bottom: 1px solid;
        border-color: ${({ theme }) => theme.shoppingFrom.border};
        padding: 15px 0;
        background: ${({ theme }) => theme.shoppingFrom.bg};
        
        @media (max-width: ${breakpoint[550]}){
            padding: 10px 0;
        }

        .slideInner {
            display: flex !important;
            justify-content: center;
            align-items: center;
        }

        img {
            width: 100px;
            margin: auto;

            @media (max-width: ${breakpoint[767]}){
                width: 80px;
            }

            @media (max-width: ${breakpoint[550]}){
                width: 90px;
            }
        }
    }


`

export const SelectPaymentMethodBox = styled.div`

    width: 100%;
    position: relative;
    display: none;

    &.column {
    }

    .cover {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background: #000000;
        z-index: 9999;
        opacity: 0;
    }

    .completingPaypalOrder {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background-color:rgba(255, 255, 255, 0.7);
        z-index: 9999;

        ${ldsRing}

        .lds-ring {
            div {
                border-color: ${({ theme }) => theme.base} transparent transparent transparent;
            }
        }

    }

    .selectPaymentMethodTitle {
        width: 100%;
        font-weight: 700;
        text-align: center;
        padding: 20px;
        border-bottom: 1px solid ${borderColor};
    }

    .selectPaymentMethodInner {
        width: 100%;
        border-radius: ${borderRadius};
        border: 1px solid ${borderColor};
        background: ${({ theme }) => theme.white};
        ${boxShadowAddBids}

        
        .cards {
            width: 100%;
            min-height: 150px;
            padding: 15px;
            padding-bottom: 0;

            .paypalButtonsBox {
                width: 100%;
            }
            
            .error {
                margin: 0 0 17px;
                &.errorInverse {
                    color: #ffffff;
                    background: ${({ theme }) => theme.error};
                }
            }
        }
    }

    .cardSelectBox {
        width: 100%;
        background: #ffffff;
        height: ${selectPaymentHeight}px;
        border-radius: 4px;
        border: 1px solid #555;
        color: #2C2E2F;
        margin: 0 0 15px;
        cursor: pointer;

        &:last-child {
            margin: 0;
        }

        &.paypal {
            border: 0;
            background: #fafafa;

            div {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        img {
            &.paymentMethods {
                max-width: 90%;
            }
        }

        &.existingCard {

            position: relative;

            ${ldsRing}

            .lds-ring {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                margin: auto;
                transform: scale(0.6);
                
                div {
                    border-color: ${({ theme }) => theme.base} transparent transparent transparent;
                }
            }
    
            img {
                width: 35px;
            }

            .cardNumber {

                margin: 0 0 0 10px;

                .dot {
                    width: 5px;
                    height: 5px;
                    background: ${({ theme }) => theme.base};
                    border-radius: 50%;
                    margin: 0 5px 0 0;

                    &:nth-child(4),
                    &:nth-child(8),
                    &:nth-child(12) {
                        margin: 0 10px 0 0;
                    }

                    @media (max-width: ${breakpoint[450]}){
                        width: 3px;
                        height: 3px;
                    }

                    @media (max-width: ${breakpoint[374]}){
                        margin: 0 3px 0 0;

                        &:nth-child(4),
                        &:nth-child(8),
                        &:nth-child(12) {
                            margin: 0 6px 0 0;
                        }

                    }


                }

                .last4 {
                    font-size: 15px;
                    font-weight: 700;
                }

            }

        }

        &:hover {
            filter: brightness(0.95);
        }
    }

`

export const PaypalButtonBox = styled.div`
    width: 100%;

    .orBox {
        width: 100%;
    }
`

export const GoToTopBox = styled.div`

    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 20px 0 0;
    padding: 20px 0;
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;

    .goToTopLeft {
        cursor: pointer;
    }

    svg {
        width: 25px;
        margin: 2px 5px 0 0;
    }

`