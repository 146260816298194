import React from 'react';
import { Link } from 'react-router-dom';

const TermsNormal = React.memo(() => {
    return (
        <>
            <p>Each {process.env.REACT_APP_URL_SITE_NAME} auction is sponsored by {process.env.REACT_APP_URL_SITE_NAME} and {process.env.REACT_APP_URL_SITE_NAME}'s brand partners, and is no way sponsored,
                enforced, or administered by, or associated with, Apple, Inc.</p>

            <p>Welcome to {process.env.REACT_APP_URL_SITE_NAME}! These Terms of Use (the "Terms") apply to your use of the {process.env.REACT_APP_URL_SITE_NAME}
                ("{process.env.REACT_APP_URL_SITE_NAME}," "we," or "us") online interfaces and properties, including the
                {process.env.REACT_APP_URL_SITE_NAME}.com website and {process.env.REACT_APP_URL_SITE_NAME} mobile applications (the "Site"), and the buying of or bidding on
                goods or other services offered by {process.env.REACT_APP_URL_SITE_NAME} through the Site. These Terms are a legal agreement and your use of
                {process.env.REACT_APP_URL_SITE_NAME}'s Services constitutes your acceptance of the Terms contained herein and {process.env.REACT_APP_URL_SITE_NAME}'s Privacy
                Policy incorporated herein by reference. You must register and accept these terms in order to use the Services. If
                you do not agree to these Terms or the Privacy Policy, you may not use the Services or any other services provided
                by {process.env.REACT_APP_URL_SITE_NAME}. BY REGISTERING FOR AND USING THE SERVICES, YOU AGREE TO BE BOUND BY THESE TERMS AND ALL POLICIES AND
                GUIDELINES INCORPORATED BY REFERENCE.</p>

            <p><strong>READ THESE TERMS CAREFULLY BEFORE ACCESSING THIS SERVICE OR THE SITE, OR CLICK-ACCEPTING
                THESE TERMS WHEN REGISTERING TO USE THE SERVICES. THESE TERMS HAVE LEGAL CONSEQUENCES AND WILL AFFECT YOUR LEGAL
                RIGHTS. BY AGREEING TO THESE TERMS, YOU ARE WAIVING YOUR RIGHT TO SUE IN COURT, YOUR RIGHT TO A TRIAL BY JURY, AND
                YOUR RIGHT TO BRING ANY CLASS, COLLECTIVE, OR REPRESENTATIVE PROCEEDING AGAINST {process.env.REACT_APP_URL_SITE_NAME}. AS USED IN THESE TERMS,
                THE SITE MEANS THE ENTIRETY OF THIS WEBSITE AND MOBILE APPLICATIONS INCLUDING, BUT NOT LIMITED TO,
                ALL CONTENT, SERVICES, INFORMATION, AND ANY PORTIONS THEREOF. THE {process.env.REACT_APP_URL_SITE_NAME} SERVICES ARE ANY SERVICES OFFERED OR
                PROVIDED BY {process.env.REACT_APP_URL_SITE_NAME} AND THE SERVICES AND THE SITE ARE COLLECTIVELY REFERRED TO AS THE SERVICES.</strong></p>

            <p><strong>BY BUYING OR BIDDING FOR PRODUCTS OR BY OTHER USE OF THE SERVICES, YOU AFFIRM THAT YOU ARE OF LEGAL AGE TO
                ENTER INTO THESE TERMS, AND YOU ACCEPT AND ARE BOUND BY THESE TERMS.</strong></p>

            <p><strong>THE {process.env.REACT_APP_URL_SITE_NAME} SERVICES ARE OFFERED AND MAY ONLY BE USED BY PERSONS OVER THE AGE OF 18. BY USING THE SERVICES,
                YOU REPRESENT AND WARRANT THAT YOU ARE OVER THE AGE OF 18, YOU ARE OF LEGAL AGE TO FORM A BINDING CONTRACT WITH
                {process.env.REACT_APP_URL_SITE_NAME}, AND THAT YOU MEET ALL OF THE ELIGIBILITY REQUIREMENTS DESCRIBED IN THESE TERMS. YOU MAY NOT ORDER, BID,
                PURCHASE, OR OBTAIN PRODUCTS OR SERVICES THROUGH THE SERVICES IF YOU (I) DO NOT AGREE TO THESE TERMS, (II) ARE NOT
                THE OLDER OF AT LEAST 18 YEARS OF AGE OR LEGAL AGE TO FORM A BINDING CONTRACT WITH {process.env.REACT_APP_URL_SITE_NAME}, OR (III) ARE PROHIBITED
                FROM ACCESSING OR USING THE SERVICES' CONTENTS OR GOODS BY APPLICABLE LAW.</strong></p>

            <p>{process.env.REACT_APP_URL_SITE_NAME} will not be liable if for any reason all or any part of the Services are unavailable at any time or for any
                period. From time to time, {process.env.REACT_APP_URL_SITE_NAME} may restrict access to some or all parts of the Services to users, including
                registered users.</p>

            <p>When using the {process.env.REACT_APP_URL_SITE_NAME} Services, a user may spend money on virtual credits such as Bids without receipt of physical
                items. You are solely responsible for your spending, bidding, and purchasing when using the Services. {process.env.REACT_APP_URL_SITE_NAME} is
                not responsible or liable for your bidding or spending on {process.env.REACT_APP_URL_SITE_NAME} or through any of the Services. Furthermore, you
                are solely responsible for, and {process.env.REACT_APP_URL_SITE_NAME} is not responsible for and shall not be liable for, any items that you bid
                on or purchase through the Services. It is your sole responsibility to only purchase items that you have fully read
                the product descriptions of and are familiar with. You are solely responsible for your purchases.</p>

            <ol>
                <li><strong>Changes to these Terms</strong></li>
            </ol>

            <p>{process.env.REACT_APP_URL_SITE_NAME} reserves the right to withdraw, change, or modify these Terms, the Services, or any policy or guideline
                related to the Services, at any time and from time-to-time in its sole discretion, without notice. Any changes will
                be effective upon posting of the revisions on the Site and it is your responsibility to check these Terms each time
                before using the Services so you are aware of any changes, as they are binding on you. Your continued use of this
                Site and the Services following our posting of them will constitute your acceptance of such changes. IF YOU DO NOT
                AGREE TO THESE TERMS OR ANY MODIFICATION, DO NOT REGISTER OR CONTINUE TO USE THE SERVICES.</p>


            <ol start="2">
                <li><strong>Eligibility</strong></li>
            </ol>

            <p>The target market for the Services is the United States of America and Canada. Only residents of the aforementioned
                countries are allowed to use the Service. {process.env.REACT_APP_URL_SITE_NAME} reserves the right to restrict availability of auctions and delivery
                of items based on the country of the user. The country of the user can be identified based on their IP address, billing
                address or mailing address. The user is responsible for notifying {process.env.REACT_APP_URL_SITE_NAME} at support@{process.env.REACT_APP_URL_SITE_NAME}.com in case of their
                country of residence is identified incorrectly.
            </p>

            <p>Items purchased through the Services may only be delivered to the contiguous United States and the following Canadian
                provinces and territories: Alberta, British Columbia, Manitoba, New Brunswick, Newfoundland &amp; Labrador, Northwest
                Territories, Nova Scotia, Nunavut Territory, Ontario, Prince Edward Island, Saskatchewan, Yukon Territory. {process.env.REACT_APP_URL_SITE_NAME}
                does not deliver to PO Boxes or to freight forwarding companies. {process.env.REACT_APP_URL_SITE_NAME} may decline shipping to RR boxes. Please
                contact support before placing an order if you wish to use an RR box.</p>


            <ol start="3">
                <li><strong>General Policies</strong></li>
            </ol>

            <p>The {process.env.REACT_APP_URL_SITE_NAME} policies accessible throughout the Site are expressly incorporated by reference into these terms, and
                you should review each carefully. The procedures and guidelines contained below explain the auction process. The {process.env.REACT_APP_URL_SITE_NAME} <Link to='/privacy'>Privacy Policy</Link> governs the collection and
                use of user information on the Site. The FAQ provides additional detail on how the Services work, and the House
                Rules set out important details governing every user's rights and obligations. </p>


            <ol start="4">
                <li><strong>Contractual Obligations</strong></li>
            </ol>

            <p>The auction process is a method in which the Site solicits offers from buyers for the sale of lots. By submitting
                bids, you are making a legal offer to buy the lot. Once a bid is accepted, a contract is created between the buyer
                and the Site for the sale and purchase of the lot in accordance with the terms disclosed at the time of the auction.
                By using the Services and bidding on a lot, or by using the 'Buy It Now' function, you agree to complete the
                transaction as described and fulfill any and all contractual obligations.</p>


            <ol start="5">
                <li><strong>Access to Systems</strong></li>
            </ol>

            <p>You are prohibited from violating or attempting to violate the security of the Services, including, without
                limitation: (i) accessing data not intended for such user or logging onto a server or an account which the user is
                not authorized to access; (ii) attempting to probe, scan or test the vulnerability of a system or network or to
                breach security or authentication measures without proper authorization; or (iii) accessing or using the Services or
                any portion thereof without authorization, in violation of these Terms or in violation of applicable law. </p>

            <p>You may not use any scraper, crawler, spider, robot, or other automated means of any kind to access or copy data on
                the Site, deep-link to any feature or content on the Site, bypass our robot exclusion headers, or other measures we
                may use to prevent or restrict access to the Site.</p>

            <p>You agree not to interfere with the Site, Services, or any transaction and agree not to use any device, software, or
                routine to interfere or attempt to interfere with the proper working of the Services or any activity being conducted
                on or through the Services. You agree not to impose any unreasonably or disproportionately large load on {process.env.REACT_APP_URL_SITE_NAME}'s
                systems or facilities. You agree not to put any material on the Site that contains any viruses, Trojan horses,
                worms, time bombs, cancelbots or any other computer programming routines that may damage, interfere with,
                surreptitiously intercept or expropriate any system, data or personal information. Violations of system or network
                security may result in civil or criminal liability. {process.env.REACT_APP_URL_SITE_NAME} will investigate occurrences that may involve such
                violations and may involve, and cooperate with, law enforcement authorities in prosecuting users who are involved in
                such violations. </p>


            <ol start="6">
                <li><strong>Bidding</strong></li>
            </ol>

            <p>Each {process.env.REACT_APP_URL_SITE_NAME} bid (a "Bid") is a limited, non-transferable, one-year license to place one bid on a
                {process.env.REACT_APP_URL_SITE_NAME} auction. Bids have no monetary value; are not credited against the purchase price of products purchased on
                the Site; and may not be exchanged for money or any items of monetary value.</p>

            <p>Due to the billing of charges, {process.env.REACT_APP_URL_SITE_NAME} would like to advise all users to monitor their bidding practices. Repeated
                online bidding can incur high costs. Users should therefore pay attention to their bidding practices.</p>


            <ol start="7">
                <li><strong>Fraud and Other Restricted Behavior</strong></li>
            </ol>

            <p>You acknowledge that in order to maintain fair and honest auctions, {process.env.REACT_APP_URL_SITE_NAME} retains the right to monitor and enforce
                any fraudulent or malicious activity on the Site. You acknowledge that you have read what these activities include,
                but are not limited to, in these Terms, the "House Rules" policy, and you agree not to engage in any of these,
                or any other fraudulent or malicious activities. As stated herein, {process.env.REACT_APP_URL_SITE_NAME} reserves the right to temporarily or
                permanently suspend any user account engaged or suspected to engage in any such activities.</p>

            <p>You may not register and make bids under a false name or with an invalid credit card. Such fraudulent conduct is a
                violation of federal and state laws. Fraudulent bidders may be reported to relevant state and federal law
                enforcement agencies and {process.env.REACT_APP_URL_SITE_NAME} will cooperate to ensure that violators are prosecuted to the fullest extent of
                the law.</p>

            <p>A bidder may not resell any product or service obtained through the Site and Services without prior written consent
                from {process.env.REACT_APP_URL_SITE_NAME}. Moreover, a user may not use the products to create any investment scheme, commodities contract or
                pool or otherwise to use {process.env.REACT_APP_URL_SITE_NAME} as a product delivery mechanism for a brokered or 'middleman' agreement or
                obligation. If it is discovered that a user is, in fact, reselling products obtained from {process.env.REACT_APP_URL_SITE_NAME}, we reserve the
                right to suspend the account without further notice, voiding all auctions in which the user has participated.
                Additionally, the user may be required to compensate {process.env.REACT_APP_URL_SITE_NAME} for any damages.</p>

            <p>{process.env.REACT_APP_URL_SITE_NAME} reserves the right to refuse any order you place with us. {process.env.REACT_APP_URL_SITE_NAME} may, in its sole discretion, limit or
                cancel quantities purchased per person, per household or per order. These restrictions may include orders placed by
                or under the same customer account, the same credit card, geographic region, and/or orders that use the same phone
                number, billing and/or shipping address. In the event {process.env.REACT_APP_URL_SITE_NAME} changes or cancels an order, {process.env.REACT_APP_URL_SITE_NAME} will attempt to
                notify you by contacting the e-mail, billing address and/or phone number provided at the time the order was made.
                {process.env.REACT_APP_URL_SITE_NAME} reserves the right to limit or prohibit orders that, in its sole judgment, appear to be placed by dealers,
                resellers, distributors and others who violate these Terms.</p>

            <p>No user may permit or assist others to use the Services for any purpose other than that for which they are intended.
                A user shall not permit or assist others to abuse or fraudulently use the Services, including but not limited to the
                following:</p>

            <ul>
                <li>Obtaining or attempting to obtain service by any fraudulent means or device with intent to avoid payment;</li>
                <li>Accessing, altering, or destroying any information of another {process.env.REACT_APP_URL_SITE_NAME} user by any fraudulent means or device,
                    or attempting to do so; or
                </li>
                <li>Using Services so as to interfere with the use of {process.env.REACT_APP_URL_SITE_NAME} network by other users or authorized users,
                    intentionally or not; or in violation of the law or in aid of any unlawful act.
                </li>
            </ul>

            <p>Each user acknowledges that {process.env.REACT_APP_URL_SITE_NAME}'s network may only be used for lawful purposes. {process.env.REACT_APP_URL_SITE_NAME} reserves the right to
                monitor all network activity. While using {process.env.REACT_APP_URL_SITE_NAME}, each user agrees to refrain from the following prohibited
                acts:</p>

            <ul>
                <li>Maintaining more than one account or maintaining control or influence over more than one account;</li>
                <li>Infringing upon the intellectual property of {process.env.REACT_APP_URL_SITE_NAME}, establishing a competing site, or otherwise copying or
                    using the data on the Site for any reason other than bidding on products for the bidder's own use;
                </li>
                <li>Manipulate the bidding process by placing fictitious bids, colluding with other parties to create the illusion
                    of volume ('marking the close' or 'painting the tape'), or engaging in any other disingenuous or fraudulent
                    activity that undermines the integrity of the online market;
                </li>
                <li>Breaching {process.env.REACT_APP_URL_SITE_NAME}'s policies;</li>
                <li>Attempting to harvest or otherwise collect information about users, including email addresses;</li>
                <li>Attempting to upload or download any computer virus;</li>
                <li>Attempting to transmit via email any unsolicited advertising, promotional materials, "junk mail", "spam", "chain
                    letters", "pyramid schemes", or any other form of email solicitation to other users;
                </li>
                <li>Attempting to communicate with other users to promote or provide instructional information about illegal
                    activities, promote physical harm, or injury against any users.
                </li>
                <li>Viewing and/or downloading or uploading pornographic pictures or stories.</li>
                <li>Attempting to attack other people's internet connections, Web Sites or computers, or damage or deface anyone
                    else's Web Site.
                </li>
            </ul>


            <ol start="8">
                <li><strong>Collusion/Team Play</strong></li>
            </ol>

            <p>Any kind of collusion or team play is strictly forbidden on {process.env.REACT_APP_URL_SITE_NAME}. By definition, this includes, but is not
                limited to, two or more members working together as a team, or creating the impression that there are more people
                involved and competing to win an auction than there actually are, or placing bids until Buy it Now offers are earned
                and then switching over to another account to continue to earn further Buy it Now offers on the same deal.</p>

            <p>{process.env.REACT_APP_URL_SITE_NAME} does not allow two members that know, or are associated with, each other to participate in the same auction.
                Two members participating in the auction are by definition engaging in team play, and automatically lose their right
                to purchase the Deal or receive any of their spent bids back. {process.env.REACT_APP_URL_SITE_NAME} reserves the right to implement measures,
                determined by the sole discretion of {process.env.REACT_APP_URL_SITE_NAME}, that automatically restrict associated accounts from participating in
                the same auctions.</p>

            <p>We reserve the right to disqualify, in our absolute discretion, any users we suspect to be working in a team by
                voiding all their wins and freezing their accounts. No funds or Bids will be returned or transferred into other
                accounts. If a user is found to have violated these Terms, any free Bids provided to them and used, including those
                provided in the past, must immediately be paid for by the user at their current selling price.</p>


            <ol start="9">
                <li><strong>Passwords/Equipment</strong></li>
            </ol>

            <p>You may not disclose your username and password to any third party and you agree that you are solely responsible for
                any use of or action taken under your account. Your password may be used only to access and use the Services. If
                your user credentials or password is compromised or otherwise disclosed, you must notify {process.env.REACT_APP_URL_SITE_NAME} and change your
                password immediately in order to minimize your risk of loss. You are responsible for providing and maintaining all
                equipment necessary to use the Services, including, without limitation, computer hardware and software, modems,
                network or Wi-Fi connections and telephone service. Further, you are solely responsible for (a) maintaining the
                security of your personal information, including, without limitation, account and login information, passwords, and
                financial information; (b) all telephone and toll charges which may apply to your account depending on your
                location; and (c) any other charges resulting from the use of your account.</p>


            <ol start="10">
                <li><strong>Investigation of Violations</strong></li>
            </ol>

            <p>We may investigate any violation of these Terms, our policies or guidelines, or any other complaints and take any
                action that we deem appropriate and reasonable under the circumstance to protect our systems, facilities, users
                and/or third parties. Our actions may include, but are not limited to, issuing warnings, suspension or termination
                of the Services or any user accounts, and/or removal of or disabling access to content hosted on {process.env.REACT_APP_URL_SITE_NAME}'s systems.
                We reserve the right and have absolute discretion to remove or disable access, screen, or edit any content that
                violates these provisions or is otherwise objectionable. {process.env.REACT_APP_URL_SITE_NAME} also reserves the right to report any activity
                that it suspects violates any law or regulation to appropriate law enforcement officials.</p>


            <ol start="11">
                <li><strong>License</strong></li>
            </ol>

            <p>By using the Services and submitting any information or materials to {process.env.REACT_APP_URL_SITE_NAME} ("Licensed Materials"), you hereby
                grant us a non-exclusive, worldwide, royalty-free, irrevocable, and sublicensable right to use, reproduce, modify,
                display, and distribute the Licensed Materials, in any existing or future media, known or unknown. Any Licensed
                Materials you provide to {process.env.REACT_APP_URL_SITE_NAME} will be considered non-confidential and non-proprietary. You agree not to use the
                Services to send, knowingly receive, upload, download, use or re-use any material which does not comply with these
                Terms, including the Content Standards set forth below. You acknowledge and warrant that you have the right and
                authority to provide such license to {process.env.REACT_APP_URL_SITE_NAME}. You understand and acknowledge that you are responsible for any
                Licensed Materials you submit or contribute, and you, not {process.env.REACT_APP_URL_SITE_NAME}, have full responsibility for such content,
                including its legality, reliability, accuracy and appropriateness. {process.env.REACT_APP_URL_SITE_NAME} is not responsible, or liable to any
                third party, for the content or accuracy of any Licensed Materials posted by you or any other user of the Services.
                {process.env.REACT_APP_URL_SITE_NAME} has the right to disclose your identity or other information about you to any third party who claims that
                material posted by you violates their rights, including their intellectual property rights or their right to
                privacy.</p>

            <p>You understand and agree that {process.env.REACT_APP_URL_SITE_NAME} or its licensors hold all right, title, and interest to the Services and all
                intellectual property, material, content, and features provided through the Services, and the design, selection, and
                arrangement thereof. The foregoing applies regardless of (i) the form of intellectual property, (ii) how it was
                provided to you, or (iii) your geolocation. You understand and agree that you may only use the content available
                provided to you through the Services, unless otherwise expressly provided by {process.env.REACT_APP_URL_SITE_NAME}. &nbsp;You may not conduct the
                following activities through the Services: (a) any resale or commercial use of the Site or Services, (b) the
                collection and use of any e-mail or user information, ratings, or listings; (c) making derivative uses of the Site
                and its contents; or (d) use of any data mining, robots, or similar data gathering and extraction methods. </p>

            <p>You agree not to make use of any other users' information other than as necessary to participate in the Site
                and to complete any transaction in which you are involved. Specifically, you agree that with respect to any
                information disclosed to you by {process.env.REACT_APP_URL_SITE_NAME} regarding another user of the Services, you only will use such information,
                unless otherwise authorized, (a) to complete transactions originating from the Site, including the use of
                third-party services, (b) for communications with us, or (c) as permitted by the corresponding user identified in
                such information. You agree not to disclose any user information disclosed to you by {process.env.REACT_APP_URL_SITE_NAME} except as provided by
                these Terms or with our written consent. You further agree not to use any information accessible from the Site for
                purposes of solicitation, advertisement, or spamming, or which is harassing, threatening, harmful, invasive of
                privacy or publicity rights, abusive, inflammatory or otherwise objectionable.</p>


            <ol start="12">
                <li><strong>Content Standards</strong></li>
            </ol>

            <p>Your Licensed Materials shall not:</p>

            <ul>
                <li>Contain any material which is defamatory, obscene, indecent, abusive, offensive, harassing, violent, hateful,
                    inflammatory, or otherwise objectionable.
                </li>
                <li>Promote sexually explicit or pornographic material, violence, or discrimination based on race, sex, religion,
                    nationality, disability, sexual orientation, or age.
                </li>
                <li>Infringe any patent, trademark, trade secret, copyright, or other intellectual property or other rights of any
                    other person.
                </li>
                <li>Violate the legal rights (including the rights of publicity and privacy) of others or contain any material that
                    could give rise to any civil or criminal liability under applicable laws or regulations or that otherwise may be
                    in conflict with these Terms or {process.env.REACT_APP_URL_SITE_NAME}'s Privacy Policy.
                </li>
                <li>Be likely to deceive any person.</li>
                <li>Promote any illegal activity, or advocate, promote, or assist any unlawful act.</li>
                <li>Cause annoyance, inconvenience, or needless anxiety or be likely to upset, embarrass, alarm, or annoy any other
                    person.
                </li>
                <li>Impersonate any person, or misrepresent your identity or affiliation with any person or organization.</li>
                <li>Involve commercial activities or sales, such as contests, sweepstakes, and other sales promotions, barter, or
                    advertising.
                </li>
                <li>Give the impression that they emanate from or are endorsed by us or any other person or entity, if this is not
                    the case.
                </li>
            </ul>

            <p>{process.env.REACT_APP_URL_SITE_NAME} has the right to remove or refuse to post on the Service any Licensed Materials for any or no reason in its
                sole discretion. However, {process.env.REACT_APP_URL_SITE_NAME} cannot review all Licensed Materials before it is posted on its Services, and
                cannot ensure prompt removal of objectionable material after it has been posted. Accordingly, {process.env.REACT_APP_URL_SITE_NAME} assumes no
                liability for any action or inaction regarding transmissions, communications, or content provided by any user or
                third party. {process.env.REACT_APP_URL_SITE_NAME} has no liability or responsibility to anyone for performance or non-performance of the
                activities described in this Section.</p>


            <ol start="13">
                <li><strong>Registration with {process.env.REACT_APP_URL_SITE_NAME} for Use</strong></li>
            </ol>

            <p>To use the Services, you must first register for an online user account, consisting of a username, password, credit
                card information, billing address, and other information as may be required by {process.env.REACT_APP_URL_SITE_NAME}. You may register only once
                and may only have one account at {process.env.REACT_APP_URL_SITE_NAME} for your personal use. Additionally, each household or "group of users
                associated with each other", determined by the sole discretion of {process.env.REACT_APP_URL_SITE_NAME}, may not register or have control over
                more than two {process.env.REACT_APP_URL_SITE_NAME} member accounts. If a single person is found to have or maintain control over several
                accounts on {process.env.REACT_APP_URL_SITE_NAME}, or if a household or a "group of users associated with each other" is found to have or
                maintain control over more than two accounts on {process.env.REACT_APP_URL_SITE_NAME}, all accounts will be immediately suspended, all auctions
                the accounts have participated in will be voided, and any Bids purchased will be removed without prior warning and
                without any form of compensation.</p>

            <p>During the registration process, the user must choose a username. The username may not offend common decency or
                infringe upon the rights of third parties. The username may also not be misleading in any way or, in {process.env.REACT_APP_URL_SITE_NAME}'s
                judgment, closely resemble the identity of an existing user. It is a condition of your use of the Services that all
                the information you provide through the Services is correct, current, and complete. You agree to maintain and
                promptly update your user account information to keep it true, accurate, current, and complete. If you provide any
                information that is untrue, inaccurate, not current, or incomplete, or {process.env.REACT_APP_URL_SITE_NAME} has reasonable grounds to suspect
                that such information is untrue, inaccurate, not current, or incomplete, {process.env.REACT_APP_URL_SITE_NAME} may suspend or terminate your
                account or access to the Services. If you abuse your user account, whether it harms a third party or {process.env.REACT_APP_URL_SITE_NAME}, you
                may be disqualified and, if applicable, {process.env.REACT_APP_URL_SITE_NAME} may notify applicable authorities of the possibility of a
                crime.</p>

            <p>ACCOUNTS AUTHORIZED TO USE THE SERVICES CANNOT BE TRANSFERRED OR USED BY ANYONE OTHER THAN THE AUTHORIZED ACCOUNT
                HOLDER. USE OF THIS ACCOUNT BY A PERSON OR PERSONS NOT AUTHORIZED BY {process.env.REACT_APP_URL_SITE_NAME} IS STRICTLY PROHIBITED. NO MORE THAN
                ONE LOG-IN SESSION OR ONE AUTHORIZED ACCOUNT USER CAN BE ACTIVE AT A TIME FOR ANY ACCOUNT. ACCOUNTS WHICH HAVE BEEN TRANSFERRED IN WHOLE, PART, OR PROXY BIDDING TO OTHER
                PARTIES, OR SHOW OTHER ACTIVITY IN VIOLATION OF THESE TERMS, ARE SUBJECT TO IMMEDIATE TERMINATION. {process.env.REACT_APP_URL_SITE_NAME} RESERVES
                THE RIGHT TO MAINTAIN LOGS OF ACCOUNT ACTIVITY AND TO USE SAID LOGS FOR THE PURPOSE OF DETECTING USAGE IN VIOLATION
                OF THESE TERMS.</p>

            <p>{process.env.REACT_APP_URL_SITE_NAME} reserves the right to at any time temporarily or permanently disqualify a user for violating these Terms.
                Users will be disqualified when, in {process.env.REACT_APP_URL_SITE_NAME}'s reasonable opinion, a user has violated any law, regulation or
                equitable interest in favor of a third party, such as protecting users and the Service from manipulation. Examples
                of the foregoing include, but are not limited to, (i) honoring bids of users who have not paid for the Bids, (ii)
                the use of unauthorized bidding software, or (iii) establishing several user accounts for the same person. Moreover,
                {process.env.REACT_APP_URL_SITE_NAME} reserves the right to cancel unauthorized {process.env.REACT_APP_URL_SITE_NAME} accounts or accounts that have been inactive. Each user
                agrees not to hold {process.env.REACT_APP_URL_SITE_NAME} responsible for any loss incurred as a result of such action.</p>


            <ol start="14">
                <li><strong>Bid Purchasing for Online Bidding / Payment</strong></li>
            </ol>

            <p>
                All prices are quoted and payments processed in United States dollars. The user is responsible for conversion rates
                and fees which may occur in case of a currency conversion done by their bank or payment processor.
            </p>

            <p>{process.env.REACT_APP_URL_SITE_NAME} Bids must be purchased by users prior to online bidding. Only registered users can submit the online bids,
                which can be purchased in packages (so-called 'Bid Packs') at the currently applicable prices, which can be viewed
                in 'My Dashboard' under 'Buy bids'. The Bids must be paid for in advance. When the Bids are purchased and deposited
                into the user's {process.env.REACT_APP_URL_SITE_NAME} account, a contract for the purchase of the Bids materializes. The associated invoice is
                due immediately. If a user is suspended for whatever reason, all of their Bids will be forfeited and will not be
                refunded.</p>

            <p>Users who have won an auction and paid for a product are not eligible for a refund on either Bid purchases, account
                activation fees, or product fees if their product has been shipped by the time of request.</p>

            <p>Should a payment not be honored for a user, a returned payment fee will be charged. The user shall be solely
                responsible for these fees. {process.env.REACT_APP_URL_SITE_NAME} reserves the right to block access to the user account until full payment is
                received by {process.env.REACT_APP_URL_SITE_NAME} for the outstanding amount, including any fees due to returned payment, etc. {process.env.REACT_APP_URL_SITE_NAME} may
                withhold any outstanding deliveries until it receives full payment. The user's bank may or may not charge the user
                an international payment fee in connection with a credit card payment on the {process.env.REACT_APP_URL_SITE_NAME} site. It is the sole
                responsibility of the user to be familiar with the policies and practices of his or her bank for such fees. {process.env.REACT_APP_URL_SITE_NAME}
                holds no responsibility for additional payment fees of any kind issued to the user by the user's bank.</p>

            <p>Unused Bids are eligible for a full refund only if the Bids have been purchased at their full price of 60c per
                Bid.</p>
            <p>Example (normal priced Bids): John purchases 100 Bids for $60 and Bids 10 times. All the remaining 90 Bids are
                eligible for a total refund of $54 ( 90 * $0.60 ).</p>

            <p>If the Bids have been purchased at a promotional rate of less than 60c per Bid, only the portion of the purchased
                Bids that the customer would have received at 60c each is eligible for a refund. The refundable portion of the Bids
                is always used first.</p>

            <p>Example (discount priced Bids): John purchases 100 Bids for $30 and Bids 10 times. Only the first 50 Bids of those
                100 Bids were refundable ( $30 / $0.60 = 50 ). John has 40 refundable Bids and 50 free Bids left on his account. He
                is eligible for a refund of 40 * $0.60 = $24.</p>

            <p>{process.env.REACT_APP_URL_SITE_NAME} reserves the right to sell Bids at any discounted price to specific user groups for promotional purposes.
                All {process.env.REACT_APP_URL_SITE_NAME} users understand that they may from time to time receive unique offers to buy discounted Bids and that
                other users may do so as well. Similarly, users may see different product pricing, based partly on the activity
                characteristics of that user. {process.env.REACT_APP_URL_SITE_NAME} may issue any number of free bids to users or user groups as part of special
                promotions or for any other reasons at its discretion.</p>

            <p>{process.env.REACT_APP_URL_SITE_NAME} reserves the right to, at its discretion, remove any sign up or other promotional Bids that have been added
                to a user's account for free at any given time without further notice.</p>


            <ol start="15">
                <li><strong>No Questions Asked 100% Money Back Guarantee</strong></li>
            </ol>

            <p>Bidders on {process.env.REACT_APP_URL_SITE_NAME} are entitled to a No Questions Asked 100% Money Back Guarantee on their first Bid Pack purchase.
                Should a bidder be unsatisfied with the Services for any reason, they are entitled to get their entire first Bid
                Pack purchase fully refunded in accordance with these Terms. To qualify for a refund, users must email
                support@{process.env.REACT_APP_URL_SITE_NAME}.com within 90 days of purchase and request for the refund. The refund will be credited back to you
                in 7 business days. The refund will be granted regardless of if you have won or lost auctions, or whether you have
                Bids left on your account or not. Only 1 refund per person.</p>


            <ol start="16">
                <li><strong>Bidding</strong></li>
            </ol>

            <p>For online bidding, an individual bid can be submitted by clicking the "BID" button. After the first Bid Pack purchase used Bids are no longer valid for refunds
                and it is deducted from the user's Bid balance accordingly.</p>

            <p>{process.env.REACT_APP_URL_SITE_NAME} may not be held responsible for any software errors of commission or omission. The user is prohibited from using any bidding software not
                authorized by {process.env.REACT_APP_URL_SITE_NAME}; if the user does, in fact, use unauthorized software, it may result in immediate
                disqualification from {process.env.REACT_APP_URL_SITE_NAME}'s systems and Service. {process.env.REACT_APP_URL_SITE_NAME} will from time to time award all users who start
                using the Site with free Bids. The number of free Bids awarded or not awarded is determined solely by {process.env.REACT_APP_URL_SITE_NAME} on an
                account by account basis.</p>

            <p>A bid is registered and counted only when {process.env.REACT_APP_URL_SITE_NAME}'s system registers the bid. A bid is not counted and valid when it
                is sent from the user's Web browser, but when {process.env.REACT_APP_URL_SITE_NAME}'s servers receive and register the bid request.</p>

            <p>It usually takes less than a second for a bid to be registered, however it may take up to several seconds or longer
                for {process.env.REACT_APP_URL_SITE_NAME} to receive the bid if there is a delay in the network or if the system is under high stress. {process.env.REACT_APP_URL_SITE_NAME}
                is not responsible for any delays that occur in bidding or in the auctions. {process.env.REACT_APP_URL_SITE_NAME} strives to make the {process.env.REACT_APP_URL_SITE_NAME}
                timer clocks as accurate as possible. While {process.env.REACT_APP_URL_SITE_NAME} believes that auction timers are very accurate, {process.env.REACT_APP_URL_SITE_NAME} cannot
                and does not guarantee that the system, including the bidding clock, will always function reliably.</p>

            <p>All bids are final. Winning bids and their associated fees are non-refundable.</p>

            <p>To comply with fair and honest auction practices, {process.env.REACT_APP_URL_SITE_NAME} will reschedule auctions that end prematurely due to
                severe third party or technical problems to a later time. In such events, where all bidders aren't able to
                participate normally in the auctions, {process.env.REACT_APP_URL_SITE_NAME} will return all used Bids from such rescheduled auctions to all
                involved bidders.</p>


            <ol start="17">
                <li><strong>Winning Limits</strong></li>
            </ol>

            <p>As described in the House Rules, which are incorporated by reference, each user may win the same item, in the same
                auction type, only once in a limited time period, as specified in the details for each auction. The winning limit resets
                automatically once the period has passed from the end onf the auction. The system will prevent users from placing
                bids on an auction if they have already won the same product during the period. All the booked BidBuddies
                will automatically be canceled for the other auctions of the same product, when the user wins an auction.</p>

            <p>Furthermore, each user may win up to 50 auctions per week. Once a user has reached the 50 wins limit, the system will
                prevent them from placing bids on any auctions, and all booked BidBuddies will automatically be canceled. The weekly
                winning limit resets at 00:00 PST on the day of the week you created your account.
            </p>

            <p>{process.env.REACT_APP_URL_SITE_NAME} reserves the right to, from time to time review the guidelines for any winning limits and may at any time
                under its sole discretion limit accounts from winning an excessive amount of auctions. {process.env.REACT_APP_URL_SITE_NAME} attempts to limit
                some auctions to users of comparable skill, as determined by {process.env.REACT_APP_URL_SITE_NAME}, to enhance user experience and equal chances
                of winning auctions. In so doing, {process.env.REACT_APP_URL_SITE_NAME} may limit which auctions are available to particular users based on any
                factors deemed appropriate by {process.env.REACT_APP_URL_SITE_NAME} in its sole discretion, including experience of the user, historical success
                of the user, demographic factors, prior bidding and spending activity, and other factors. In particular, {process.env.REACT_APP_URL_SITE_NAME}
                may limit certain auctions to less experienced or successful users in any manner {process.env.REACT_APP_URL_SITE_NAME} deems appropriate to
                optimize the overall user experience of all {process.env.REACT_APP_URL_SITE_NAME} users. You acknowledge that You may be, and you consent to
                being, excluded from auctions at {process.env.REACT_APP_URL_SITE_NAME}'s discretion.</p>

            <p>This limit is put in place to ensure that all users have a high quality of user experience.</p>


            <ol start="18">
                <li><strong>Bid Expiration</strong></li>
            </ol>

            <p>Bids held in user accounts must be used within 365 days from the day that the Bids have entered the account through
                purchase, item exchange or auction win. If the Bids have not been used within that time, they will expire and be
                permanently removed from the account.</p>


            <ol start="19">
                <li><strong>How the Auctions Work</strong></li>
            </ol>

            <p>With each bid submitted, the price of the item on which the user is bidding increases by $0.01. A bid submitted by
                the user constitutes an offer from that user to {process.env.REACT_APP_URL_SITE_NAME} to conclude a contract to purchase the item. A contract to
                purchase the item does not materialize until the auction is finished and the last bidder has followed all the steps
                provided for in this Section. The purchase contract will exist between {process.env.REACT_APP_URL_SITE_NAME} and the user who has submitted the
                highest bid up until the point when the last bidder has submitted the last qualifying bid of the respective
                auction.</p>

            <p>Each new bid resets the 'countdown time' for the auction. The countdown time for each of the auctions is shown on the
                auction details. The auction ends when no more bids are submitted within the remaining time stated. </p>

            <p>The user who submits the highest bid and is the last to bid wins the auction. All other Bids on the item expire and
                will not be refunded. The last bidder is determined based on the records in {process.env.REACT_APP_URL_SITE_NAME}'s database and {process.env.REACT_APP_URL_SITE_NAME}'s
                decision regarding the identity of the last bidder shall be final. If a bid is placed too late, does not reset the
                timer of an auction or is not correctly registered by the system, it is then invalid. Even though this bid may be
                the last bid on an auction, it is the last and highest valid bid on an auction that wins the auction.</p>

            <p>An invalid bid is one where the auction timer is not reset after the bid has been placed. {process.env.REACT_APP_URL_SITE_NAME} recommends its
                users to always bid at least 5 seconds prior to the auction timer reaches zero, as otherwise the bid might be
                reflected as accepted by the system, but in reality be received too late.</p>

            <p>Auctions may offer the 'Buy It Now &amp; Get your Bids Back' option ("Buy it Now"). 'Buy It Now' will
                allow you to receive your spent Bids back, if you purchase the same model of an identical item at its 'Buy it
                Now' price. The option to 'Buy It Now' will be offered for a limited period of time (usually 7 days after the
                auction ends) after which the option to 'Buy It Now' is permanently revoked.</p>

            <p>The 'Buy it Now &amp; Get your Bids Back' option is offered at the 'Buy it Now &amp; Get your Bids Back' price listed
                for each auction. The price may vary between auctions for the same product, but will not change not after the first
                bid has been placed in an auction. The price is provided solely for the purpose of exercising the 'Buy it Now &amp;
                Get your Bids Back' option. For the avoidance of doubt, {process.env.REACT_APP_URL_SITE_NAME} can not guarantee that the 'Buy it Now &amp; Get
                your Bids Back' prices are comparable to prices for which the item is sold through retailers or other merchants
                elsewhere, and thus the 'Buy it Now &amp; Get your Bids Back' pricing should not be seen as a reflection of product
                value, or used for price comparisons or other similar purposes.</p>


            <p>{process.env.REACT_APP_URL_SITE_NAME} is not responsible for third party abuse and manipulation of the {process.env.REACT_APP_URL_SITE_NAME} service, for example, if other
                users create chaos on the Site that then results in lost bids. In that case, and other cases of third party abuse,
                {process.env.REACT_APP_URL_SITE_NAME} is not responsible.</p>


            <p>{process.env.REACT_APP_URL_SITE_NAME} is constantly attempting to maintain fairness and balance in its auctions therefore some new users or users
                who have lost auctions may receive special promotions, access to special auctions, and/or free Bids through site
                features. This is designed to maintain fair practice and helps balance and create a great auction experience for all
                users.</p>


            <ol start="20">
                <li><strong>Notification of the Win / Acceptance of the Win / Payment</strong></li>
            </ol>

            <p>After the auction has ended, the highest bidder ('winner') who is logged on with {process.env.REACT_APP_URL_SITE_NAME} will see a 'congratulation
                page' displayed, along with all of the important information such as the total price, the payment details, etc. The
                user must actively confirm the win by following the steps indicated on that page or elsewhere on the Services. As
                soon as the winner has paid the total amount reflected on the 'payment page', the win is accepted. The winner will
                also receive a notification of the win by e-mail with a request for payment.</p>

            <p>The user must pay the total price (auction price and applicable taxes) no later than 14 days after the notification
                of the win is sent. After this deadline has passed, {process.env.REACT_APP_URL_SITE_NAME} can declare a rescission of the purchase contract to
                the user, which results in revocation of the user's right to delivery of the item. There is likewise no entitlement
                to replacement of any of the Bids used in the auction. If a user chooses to "Buy it Now", the item price including
                applicable tax fees must be paid within 7 days of the auction ending in order to be eligible. Users are responsible
                for all applicable tax charges associated with purchases.</p>

            <p>Orders may be subject to sales tax (when applicable and required by law). Buy it Now orders the tax is based on the
                Buy it Now price and for auction wins the tax is based on the final auction price. </p>

            <p>The user may obtain and be legally entitled to the product only after the user fully pays for the product and all of
                the applicable Bids used to obtain the product. Should the user not pay for Bids that have already been purchased,
                payments received shall be viewed as payment toward the Bids not yet paid for, and a right of retention shall be
                exercised with regard to the item purchased at auction until all Bids and the total price have been paid in full. If
                the user chooses to pay for the item by credit card and the sales price is lower than $1, then the price may be
                rounded up to $1.</p>

            <p>{process.env.REACT_APP_URL_SITE_NAME} may offer the option to exchange the auction win to bids. If available, the offer is presented before making a
                payment for the auction win. If the user accepts the exchange to bids offer the bids are added to the user's account,
                and the user loses the option to pay the auction price (plus applicable taxes) to obtain the item. The offer may change
                over time and vary between users and auctions.</p>

            <p>If the user does not receive the won product or product obtained through "Buy it Now", the user must file in a claim
                of non-receipt via e-mail to support@{process.env.REACT_APP_URL_SITE_NAME}.com within sixty (60) calendar days of the purchase, or
                {process.env.REACT_APP_URL_SITE_NAME}'s obligation to deliver the product will end. No refunds of the purchase amount or cost of spent Bids to
                win the item will be returned to the user.</p>

            <p>{process.env.REACT_APP_URL_SITE_NAME} is not responsible for stolen or lost items. In the event that a product is stolen or lost, {process.env.REACT_APP_URL_SITE_NAME} will do
                its best to help customers upon request, by providing tracking information received from the supplier, as well as
                screenshots or copies of the sales receipt and possible shipping confirmation, if available.</p>


            <ol start="21">
                <li><strong>Cancellation / Rescission</strong></li>
            </ol>

            <p>The user may return the item in an un-depreciated condition within 30 days of receipt if not satisfied for any
                reason. Upon verification of the product's condition, {process.env.REACT_APP_URL_SITE_NAME} will refund the purchase price of the product.
                {process.env.REACT_APP_URL_SITE_NAME} will not refund the value of the Bids spent to obtain the product. In the event that a user wishes to
                return a Buy it Now order the user also forfeits all the Free Bids given to them alongside the purchase. If the user
                has used the Free Bids given to them, the user will need to purchase the equivalent number of Bids in order for
                {process.env.REACT_APP_URL_SITE_NAME} to process the refund. Once both the item has been returned and the Free Bids removed from the user's
                account, a refund will be given. Only Bids that have been acquired by purchasing Bid Packs for the then-current full
                price shall have a right for refund within 30 calendar days of purchase (90 calendar days for first Bid pack
                purchase). Such paid Bids are always used first, before promotional Bids, free Bids, Bids obtained through the Buy
                it Now or other bids that have been obtained in any other way than paying full price for each Bid. Bids obtained
                during the Buy it Now process do not have any rights to refund. {process.env.REACT_APP_URL_SITE_NAME} also grants to the user ordering products
                for personal purposes the right to cancel in accordance with statutory provisions on mail order sales and
                e-commerce. The right to cancel does not exist in the case of mail order sales and e-commerce contracts for the
                delivery of audio and video recordings or of software, if and to the extent that the seal on the delivered media has
                been broken by the user.</p>


            <ol start="22">
                <li><strong>Right to cancel</strong></li>
            </ol>

            <p>You may cancel your contract within 30 days in written notice (e.g. letter, e-mail) to {process.env.REACT_APP_URL_SITE_NAME} or by returning the
                items, without stating your reasons.</p>

            <p>The 30 day period begins when the item is received. Receipt can be e-mail receipt to the e-mail address provided to
                {process.env.REACT_APP_URL_SITE_NAME} below. Cancellation is to be sent to the following address:</p>

            <p>E-Mail: support@{process.env.REACT_APP_URL_SITE_NAME}.com</p>


            <ol start="23">
                <li><strong>Consequences of Cancellation</strong></li>
            </ol>

            <p>In the case of a valid cancellation, both parties must return what has been received in performance of the contract
                and the benefits derived therefrom (e.g. interest) as applicable. If user is unable to return to {process.env.REACT_APP_URL_SITE_NAME} the
                service or product that the user received or is only able to return it in part or only in a depreciated condition,
                the user must compensate {process.env.REACT_APP_URL_SITE_NAME} for the difference in value, unless the deterioration resulted from proper use. In
                the case of use of items, the foregoing compensation provision will not apply if the deterioration of the item is
                attributable solely to it being tested comparable to those tests that may reasonably be performed in a retail store.
                In other respects, the user may avoid this duty to pay compensation by not using the item(s) as the user would use
                his/her own property, and by refraining from any action that would detract from its value. Items that can be shipped
                in packages are to be returned. In these cases, return is free of charge for the user. Items that cannot be shipped
                in packages will be picked up upon coordination with the user. A user must satisfy obligations to refund payments
                within 30 days after sending the user's notice of cancellation.</p>

            <p>When a user cancels by the deadline, the user is not obligated to accept delivery. Under those circumstances where
                {process.env.REACT_APP_URL_SITE_NAME} cancels by the deadline, {process.env.REACT_APP_URL_SITE_NAME} will refund any purchase price already paid. The foregoing
                notwithstanding, the service charge (e.g. money spent on purchase of Bids) will not be refunded.</p>

            <p>Users may return items received from {process.env.REACT_APP_URL_SITE_NAME} within 30 days of receipt for any reason however are subject to
                applicable return shipping or pick up fees.</p>

            <p>Should {process.env.REACT_APP_URL_SITE_NAME} incur additional costs for insufficient postage on the return, for a COD shipment, or for courier
                shipments and other irregular shipping methods that are over and above postal shipping costs, these costs will be
                deducted from any payment remitted by the user. Items in sets and boxes can only be returned in their entirety.</p>


            <ol start="24">
                <li><strong>Delivery</strong></li>
            </ol>

            <p>Unless otherwise agreed, delivery will be made from {process.env.REACT_APP_URL_SITE_NAME}'s supplier's warehouse or from {process.env.REACT_APP_URL_SITE_NAME}'s warehouse to
                the address provided by the user. Deliveries are made solely within the continental US.
                {process.env.REACT_APP_URL_SITE_NAME} may engage third parties to satisfy its contractual obligations without being
                required to notify the buyer. Obvious damage to the item from transport or packaging damaged during transport is to
                be reported to the supplier upon taking delivery.</p>

            <p>Should {process.env.REACT_APP_URL_SITE_NAME} not be able to deliver the item ordered for whichever reason, {process.env.REACT_APP_URL_SITE_NAME} is entitled to declare
                rescission to the user or to substitute the item with a comparable replacement product with similar features.
                {process.env.REACT_APP_URL_SITE_NAME} may also choose to replace the item ordered with a gift card of the value of the item that the user may
                choose to use to obtain the same product. This also applies for items ordered through Buy it Now from the {process.env.REACT_APP_URL_SITE_NAME}
                store.</p>

            <p>If the product delivered is damaged, defective, or otherwise does not match the product description, {process.env.REACT_APP_URL_SITE_NAME} will
                replace the product at {process.env.REACT_APP_URL_SITE_NAME}'s expense. Please contact support@{process.env.REACT_APP_URL_SITE_NAME}.com to arrange return and replacement of
                any such product. Replacement is not covered for any items shipped to a freight forwarding company and shipment to a
                freight forwarding company is not permitted under these Terms.</p>


            <ol start="25">
                <li><strong>Right of Substitution</strong></li>
            </ol>

            <p>Subject to the cancellation policy specified herein, {process.env.REACT_APP_URL_SITE_NAME} may substitute another similar item of the same general
                character or function, provided it has the same or higher retail value, for purposes of delivery to the user.
                {process.env.REACT_APP_URL_SITE_NAME} may substitute another similar item of a lesser value if the user requests one, or furnish the user with a
                gift card. A gift card may be the most suitable substitute solution for challenges related to product availability
                or supplier challenges. Such right of substitution may be necessary from time to time if, for example, unforeseen or
                undetected challenges arise with the manufacturing or inventory system of the supplier, impacting the ability to
                deliver the exact item sought by the user.</p>


            <ol start="26">
                <li><strong>Product Correction </strong></li>
            </ol>

            <p>If {process.env.REACT_APP_URL_SITE_NAME} is unable to provide the user with the item auctioned due to unforeseen circumstances, {process.env.REACT_APP_URL_SITE_NAME} reserves
                the right to offer the winner in its absolute discretion an alternative item in the same price range or the
                equivalent of the item's Manufacturer's Recommended Retail Price ('MSRP') in Bids so that if a Bid's
                then-current price is $0.60, {process.env.REACT_APP_URL_SITE_NAME} issuing one (1) Bid to the user is equal to $0.60.</p>

            <p>Should subsequent correction be undertaken by way of delivery of the item originally auctioned, the user shall be
                obligated to return to {process.env.REACT_APP_URL_SITE_NAME} the substituted item delivered within 30 days at {process.env.REACT_APP_URL_SITE_NAME}'s expense. </p>

            <p>Items delivered shall remain {process.env.REACT_APP_URL_SITE_NAME}'s property until payment in full of the total price, including for the Bids
                used in the auction. Until such time as full payment has been made, the user undertakes to store the item separately
                from their other possessions and to insure it. {process.env.REACT_APP_URL_SITE_NAME} shall have full rights of access to the place where the item
                is located during normal business hours to take possession of it.</p>


            <ol start="27">
                <li><strong>Merchandise Sources</strong></li>
            </ol>

            <p>You acknowledge that items sold on {process.env.REACT_APP_URL_SITE_NAME} may come from suppliers owned by {process.env.REACT_APP_URL_SITE_NAME} or where one or more ultimate
                {process.env.REACT_APP_URL_SITE_NAME} shareholders owns an interest, or there are directors or advisors shared between {process.env.REACT_APP_URL_SITE_NAME} and the supplier.
                The selection of products and services available on {process.env.REACT_APP_URL_SITE_NAME} is always based on price, customer satisfaction, and
                product reviews.</p>


            <ol start="28">
                <li><strong>Manufacturer's Warranty and Disclaimer</strong></li>
            </ol>

            <p>{process.env.REACT_APP_URL_SITE_NAME} does not manufacture or control any of the products or services offered on the Service. The availability of
                products or services through the Service does not indicate an affiliation with or endorsement of any product,
                service or manufacturer. Accordingly, {process.env.REACT_APP_URL_SITE_NAME} does not provide any warranties with respect to the products or
                services offered through the Service. However, the products and services offered through the Service may be covered
                by a manufacturer's warranty included with the product. To obtain warranty service for defective products, please
                follow the instructions included in the manufacturer's warranty.</p>

            <p>WARRANTIES RELATING TO PRODUCTS OR SERVICES OFFERED, SOLD, OR DISTRIBUTED BY {process.env.REACT_APP_URL_SITE_NAME} OR THROUGH THE SERVICES ARE
                SUBJECT TO SEPARATE WARRANTY TERMS AND CONDITIONS, IF ANY, PROVIDED WITH OR IN CONNECTION WITH THE APPLICABLE
                PRODUCTS OR SERVICES. YOU AFFIRM THAT {process.env.REACT_APP_URL_SITE_NAME} SHALL NOT BE LIABLE, UNDER ANY CIRCUMSTANCES, FOR ANY BREACH OF
                WARRANTY CLAIMS OR FOR ANY DAMAGES ARISING OUT OF THE MANUFACTURER'S FAILURE TO HONOR ITS WARRANTY OBLIGATIONS TO
                YOU.</p>


            <ol start="29">
                <li><strong>Limitation of Liability</strong></li>
            </ol>

            <p>IN NO EVENT WILL {process.env.REACT_APP_URL_SITE_NAME}, ITS AFFILIATES, OR THEIR LICENSORS, SERVICE PROVIDERS, PERSONNEL, AGENTS, OFFICERS, OR
                DIRECTORS BE LIABLE TO YOU FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL, SPECIAL, OR INCIDENTAL DAMAGES INCURRED BY YOU,
                UNDER ANY THEORY OF LIABILITY AND HOWEVER IT MAY BE CAUSED, EVEN IF FORESEEABLE, INCLUDING BUT NOT LIMITED TO LOST
                PROFITS, LOSS OF GOODWILL OR REPUTATIONAL HARM, DATA LOSS, OR OTHER INTANGIBLE LOSSES, ARISING OUT OF OR IN
                CONNECTION WITH THESE TERMS OR BREACH OF THESE TERMS, YOUR USE, OR INABILITY TO USE, THE SERVICE, ANY WEBSITES
                LINKED TO IT, ANY CONTENT ON THE SERVICE, OR SUCH OTHER WEBSITES, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE
                SERVICE OR SUCH OTHER WEBSITES. THIS LIMITATION INCLUDES, BUT IS NOT LIMITED TO, ANY DAMAGE OR HARM CAUSED BY
                INACCURACY OF THE DATA OR SERVICES PROVIDED (OR YOUR RELIANCE ON SUCH DATA OR SERVICES), CHANGES TO THE SERVICES,
                PERMANENT REMOVAL OF CONTENT FROM THE SERVICES (INCLUDING LICENSED MATERIAL), OR BREACHES OF
                THESE TERMS BY YOU. THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE EXCLUDED OR LIMITED UNDER
                APPLICABLE LAW. EXCEPT AS PROVIDED ABOVE, {process.env.REACT_APP_URL_SITE_NAME}'S SOLE AND MAXIMUM LIABILITY FOR ANY REASON, INCLUDING
                WITHOUT LIMITATION ANY LIABILITY FOR DAMAGES CAUSED OR ALLEGEDLY CAUSED BY ANY FAILURE OF PERFORMANCE, ERROR,
                OMISSION, INTERRUPTION, DELETION, DEFECT, DELAY IN OPERATION OR TRANSMISSION, COMMUNICATIONS LINE FAILURE, THEFT OR
                DESTRUCTION OF OR UNAUTHORIZED ACCESS TO, ALTERATION OF, OR USE OF RECORDS, WHETHER FOR BREACH OF CONTRACT,
                NEGLIGENCE OR TORTIOUS BEHAVIOR, OR UNDER ANY OTHER CAUSE OF ACTION, WHETHER IN LAW OR EQUITY, AND FOR ANY DAMAGES
                ARISING HEREUNDER, AND YOUR SOLE AND EXCLUSIV REMEDY FOR ANY CAUSE OF ACTION WHATSOEVER, SHALL BE STRICTLY LIMITED
                TO THE ACTUAL AMOUNT PAID BY OR ON BEHALF OF THE USER TO {process.env.REACT_APP_URL_SITE_NAME} FOR THE PRODUCT OR SERVICE GIVING RISE TO THE
                DAMAGE THAT YOU HAVE ORDERED THROUGH USE OF THE SERVICES.</p>


            <ol start="30">
                <li><strong>WARNING - Seizures</strong></li>
            </ol>

            <p>Due to the flashing elements on the website, some users may experience seizures or loss of awareness or other
                symptoms linked to an epileptic condition, even if they have never experienced anything like this before.</p>

            <p>Anyone who has experienced such symptoms should consult a doctor before using the website.</p>


            <ol start="31">
                <li><strong>Disclaimer of Warranties</strong></li>
            </ol>

            <p>THE SERVICES AND ALL CONTENT AND INFORMATION CONTAINED WITH THE SERVICES ARE OFFERED BY {process.env.REACT_APP_URL_SITE_NAME}, ITS AGENTS, OR
                SERVICE PROVIDERS "AS IS" WITHOUT ANY WARRANTY OR CONDITION OF ANY KIND (EXPRESS OR IMPLIED). USE OF THIS SERVICE IS
                AT YOUR SOLE RISK. {process.env.REACT_APP_URL_SITE_NAME} DOES NOT WARRANT THE SERVICED AGAINST FAILURE OF PERFORMANCE INCLUDING, WITHOUT
                LIMITATION, ANY FAILURE DUE TO COMPUTER HARDWARE OR COMMUNICATION SYSTEMS, OR THAT THE SITE OR THE SERVICES WILL
                ALWAYS BE AVAILABLE, ACCESSIBLE, UNINTERRUPTED, TIMELY, SECURE, ACCURATE, COMPLETE, OR ERROR-FREE, OR THAT DEFECTS
                IN THE SERVICES WILL BE CORRECTED. EXCEPT AS EXPRESSLY PROVIDED IN THESE TERMS, {process.env.REACT_APP_URL_SITE_NAME} DOES NOT MAKE AND HEREBY
                DISCLAIMS, AND YOU HEREBY WAIVE, ANY REPRESENTATIONS OR WARRANTIES, ARISING BY LAW OR OTHERWISE, REGARDING THE
                SERVICES, OR ANY PORTION THEREOF, INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR
                A PARTICULAR PURPOSE, TITLE, NON-INFRINGEMENT, OR ARISING FROM COURSE OF DEALING, COURSE OF PERFORMANCE OR USAGE IN
                TRADE. FURTHER, {process.env.REACT_APP_URL_SITE_NAME} DOES NOT WARRANT AND MAKES NO REPRESENTATIONS THAT THE SERVICE (OR
                ANY PORTION THEREOF) WILL MEET YOUR NEEDS OR EXPECTATIONS OR WILL CONTAIN INFORMATION THAT IS CURRENT, ACCURATE, OR
                SHOULD BE RELIED UPON. YOU ASSUME TOTAL RESPONSIBILITY AND RISK FOR YOUR USE OF THIS SITE, THE SERVICES, AND LINKED
                WEBSITES. {process.env.REACT_APP_URL_SITE_NAME} DOES NOT WARRANT THAT FILES AVAILABLE FOR DOWNLOAD WILL BE FREE OF VIRUSES,
                WORMS, TROJAN HORSES OR OTHER DESTRUCTIVE PROGRAMMING. YOU ARE RESPONSIBLE FOR IMPLEMENTING
                PROCEDURES SUFFICIENT TO SATISFY YOUR NEEDS FOR DATA BACK UP AND SECURITY. THE FOREGOING DOES NOT AFFECT ANY
                WARRANTIES WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</p>


            <ol start="32">
                <li><strong>Your Indemnity</strong></li>
            </ol>

            <p>You hereby release, and agree to defend, hold harmless, and indemnify {process.env.REACT_APP_URL_SITE_NAME} its affiliates, licensors, and service
                providers, and its and their respective officers, directors, employees, contractors, agents, licensors, suppliers,
                successors, and assigns from and against any and all claims, actions, proceedings, suits, liabilities, damages,
                settlements, penalties, fines, costs, or expenses (including, without limitation, reasonable attorneys' fees, court
                costs, and other litigation expenses) arising out of or relating to (a) your violation or breach of any term,
                condition, representation or warranty of these Terms; (b) your improper or illegal use of the Services; (c) your
                violation, alleged violation, or misappropriation of any intellectual property right (including, without limitation,
                trademark, copyright, patent, trade secrets) or non-proprietary right of a third party; or (d) your use or access of
                the Services, or access by anyone accessing the Services using your username and password.</p>


            <ol start="33">
                <li><strong>Termination</strong></li>
            </ol>

            <p>These Terms will continue until either party chooses to terminate them. This may be done by you at any time upon (1)
                receipt by {process.env.REACT_APP_URL_SITE_NAME} of written notice of your termination, and (2) your ceasing to use and access the Service
                immediately. If you access or use the Service after providing notice to {process.env.REACT_APP_URL_SITE_NAME}, you are assenting to these Terms
                anew. Termination of these Terms by either shall terminate any rights you may have to use any content or Services
                provided. Any licenses provided to {process.env.REACT_APP_URL_SITE_NAME} to use Licensed Material shall remain in effect unless otherwise agreed
                to in writing by the parties.</p>

            <p>In its sole discretion, {process.env.REACT_APP_URL_SITE_NAME} may terminate these Terms or prohibit or suspend access to the Services by you at
                any time, . Termination or suspension may occur for reasons set forth in these Terms including, without limitation,
                if: (a) your use of the Service violates these terms or any applicable policy or guideline, is potentially illegal
                or harmful to {process.env.REACT_APP_URL_SITE_NAME} or any other party, or could subject {process.env.REACT_APP_URL_SITE_NAME} to liability or additional expense; (b)
                providing the Service becomes technically or commercially unfeasible; or (c) you fail to pay any charges due to
                {process.env.REACT_APP_URL_SITE_NAME}.</p>


            <ol start="34">
                <li><strong>Dispute Resolution – Agreement to Arbitration and Waiver of Class Rights</strong></li>
            </ol>

            <p>You and {process.env.REACT_APP_URL_SITE_NAME} agree that any legal disputes or claims arising out of or relating to these Terms (including but not
                limited to the Services, use of the Services, or the interpretation, enforceability, revocability of the Terms, or
                the arbitrability of any disputes or claims) that cannot be resolved informally shall be submitted to binding
                arbitration in the state in which the Terms were performed. The arbitration shall be conducted by the American
                Arbitration Association under its Consumer Arbitration Rules, a copy of which can be obtained at
                https://www.adr.org/sites/default/files/Consumer%20Rules.pdf, or as otherwise mutually agreed by you and {process.env.REACT_APP_URL_SITE_NAME}.
                Any judgment on the award rendered by the arbitrator may be entered in any court having jurisdiction thereof. Claims
                shall be brought within the time required by applicable law. You and {process.env.REACT_APP_URL_SITE_NAME} agree that any claim, action, or
                proceeding arising out of or relating to the Terms must be brought in your individual capacities, and not as
                plaintiffs or class members in any class, collective, or representative proceeding. The arbitrator may not
                consolidate more than one person's claims, and may not otherwise preside over any form of a class, collective,
                or representative proceeding. YOU ACKNOWLEDGE AND AGREE THAT YOU AND {process.env.REACT_APP_URL_SITE_NAME} ARE EACH WAIVING THE RIGHT TO A TRIAL
                BY JURY OR TO PARTICIPATE AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS ACTION OR REPRESENTATIVE
                PROCEEDING.</p>


            <ol start="35">
                <li><strong>Applicable Law</strong></li>
            </ol>

            <p>THIS AGREEMENT WILL BE GOVERNED BY THE LAWS OF THE UNITED STATES AND THE STATE OF MINNESOTA, WITHOUT REFERENCE TO
                RULES GOVERNING CHOICE OF LAWS. </p>


            <ol start="36">
                <li><strong>Compliance with Laws; Taxes</strong></li>
            </ol>

            <p>You shall at all times comply with all applicable laws and regulations regarding the use of the Services and your
                bidding, and purchase, of items through the Services. You shall indemnify and hold {process.env.REACT_APP_URL_SITE_NAME} harmless from your
                failure to so comply. You agree that {process.env.REACT_APP_URL_SITE_NAME} shall not have to perform any obligations set forth in this Agreement
                if such performance would violate any present or future law, regulation, or policy of any applicable government.</p>

            <p><strong>Purchases by U.S. users</strong></p>
            <p>The purchases made on the site are subject to state use tax unless specifically exempt. The purchases are not exempt
                merely because the purchase is made over the Internet, by catalog, or by other remote means. If applicable, you may
                find the use tax forms and corresponding instructions on the Department of Taxes website of your state.</p>

            <ol start="37">
                <li><strong>No Agency</strong></li>
            </ol>

            <p>{process.env.REACT_APP_URL_SITE_NAME} is not the agent, fiduciary, trustee or other representative of you. Nothing expressed or mentioned in or
                implied from this Agreement is intended or shall be construed to give to any person other than the parties hereto
                any legal or equitable right, remedy or claim under or in respect to this Agreement. This Agreement and all of the
                representations, warranties, covenants, conditions and provisions hereof are intended to be and are for the sole and
                exclusive benefit of the parties hereto.</p>


            <ol start="38">
                <li><strong>Severability</strong></li>
            </ol>

            <p>Should any clause or provision hereof be deemed invalid, void or unenforceable either in its entirety or in a
                particular application, the remainder of these Terms shall nonetheless remain in full force and effect and, if the
                subject term or provision is deemed to be invalid, void or unenforceable only with respect to a particular
                application, such term or provision shall remain in full force and effect with respect to all other applications.
                Further, if any provision of these terms is found by a court of competent jurisdiction to be invalid, the parties
                nevertheless agree that the court should endeavor to give effect to the parties' intentions as reflected in the
                provision and the other provisions of these Terms shall remain in full force and effect.</p>


            <ol start="39">
                <li><strong>Claim Limitation.</strong></li>
            </ol>

            <p>Subject to Section 34 (Dispute Resolution – Agreement to Arbitration and Waiver of Class Rights), you agree
                regardless of any statute or law to the contrary that any claim or cause of action arising out of or relating to the
                Site, the Services, or these Terms must be filed within one (1) year after such claim of action arose or be forever
                barred.</p>


            <ol start="40">
                <li><strong>Force Majeure</strong></li>
            </ol>

            <p>{process.env.REACT_APP_URL_SITE_NAME} will not be liable or responsible to you, nor be deemed to have defaulted or breached these Terms, for any
                failure or delay in its performance under these Terms when and to the extent such failure or delay is caused by or
                results from acts or circumstances beyond our reasonable control, including, without limitation, acts of God, flood,
                fire, earthquake, explosion, governmental actions, war, invasion or hostilities (whether war is declared or not),
                terrorist threats or acts, riot or other civil unrest, national emergency, revolution, insurrection, epidemic,
                lockouts, strikes or other labor disputes (whether or not relating to our workforce), or restraints or delays
                affecting carriers or inability or delay in obtaining supplies of adequate or suitable materials, materials or
                telecommunication breakdown or power outage.</p>


            <ol start="41">
                <li><strong>No Waiver</strong></li>
            </ol>

            <p>You understand and agree no waiver by {process.env.REACT_APP_URL_SITE_NAME} of any term or condition set forth in these Terms shall be deemed a
                further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure
                of {process.env.REACT_APP_URL_SITE_NAME} or any {process.env.REACT_APP_URL_SITE_NAME} affiliate to enforce their rights under these Terms or at law will not be considered a
                waiver of such rights.</p>


            <ol start="42">
                <li><strong>Notices</strong></li>
            </ol>

            <p><u>To you</u>. {process.env.REACT_APP_URL_SITE_NAME} may provide any notice to you under these Terms by: (i) sending a message to the email
                address you provide, or (ii) by posting to the Service. Notices sent by email will be effective when {process.env.REACT_APP_URL_SITE_NAME} sends
                the email and notices {process.env.REACT_APP_URL_SITE_NAME} provides by posting will be effective upon posting. It is your responsibility to keep
                your email address current.</p>

            <p><u>To {process.env.REACT_APP_URL_SITE_NAME}</u>. You must contact {process.env.REACT_APP_URL_SITE_NAME} as follows: (i) by email at support@{process.env.REACT_APP_URL_SITE_NAME}.com. {process.env.REACT_APP_URL_SITE_NAME} may update its addresses for notices
                by posting a notice on the Service. Notices provided by personal delivery will be effective immediately. Notices
                provided by overnight courier or email will be effective one business day after they are sent. Notices provided by
                registered or certified mail will be effective three business days after they are sent.</p>


            <ol start="43">
                <li><strong>Entire Agreement</strong></li>
            </ol>

            <p>These terms constitute the entire agreement of the parties with respect to the subject matter hereof and supersedes
                and cancels all prior and contemporaneous agreements, claims, representations, and understandings of the parties in
                connection with the subject matter hereof.</p>
        </>
    );
})

export default TermsNormal;