import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_TEXT } from '../../../content';
import { actionShoppingFromUpdatedFalse } from '../../../reducers/layout';

const ShoppingFromSaved = React.memo(() => {

    const dispatch = useDispatch();

    const timeout = useRef(null)

    const shoppingFromUpdated = useSelector((state) => state.layout.shoppingFrom.updated)

    useEffect(() => {

        clearTimeout(timeout.current)

        if (shoppingFromUpdated) {

            timeout.current = setTimeout(() => {

                dispatch(actionShoppingFromUpdatedFalse());

            }, 2500)
        }

        () => clearTimeout(timeout.current)

    }, [shoppingFromUpdated]);

    return (
        <div className={`saveBox column ${shoppingFromUpdated ? 'active' : ''}`} onClick={e => e.stopPropagation()}>
            <svg className="upArrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 21.3"><path d="M24,21.3H0l12-20L24,21.3z"></path></svg>
            <div className="saveBoxText row">{DEFAULT_TEXT['shoppingFrom.success']}</div>
        </div>
    );
})

export default ShoppingFromSaved;