import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useCartMode } from '../../providers/CartMode';
import { LegalBox } from '../../style/main';
import TermsCartMode from './TermsCartMode';
import TermsNormal from './TermsNormal';

const Terms = React.memo(() => {

    const { cartMode } = useCartMode();

    useEffect(() => {

        window.scrollTo({
            top: 0,
            left: 0
        });

    }, []);

    return (
        <LegalBox className='column'>
            <div className="wrap column">

                <h1>Terms of Use</h1>


                <p><strong>Last Updated: April 04, 2022</strong></p>

                {!cartMode ?
                    <TermsNormal />
                    :
                    <TermsCartMode />
                }



            </div>
        </LegalBox>
    );
})

export default Terms;