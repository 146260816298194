import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { MenuCoverBox } from '../../style/main';
import { scrollTop } from '../helpers';

const MenuCover = React.memo(() => {

    const menuOpen = useSelector((state) => state.layout.menuOpen)
    const dealDropdownOpen = useSelector((state) => state.layout.dealDropdownOpen)
    const myAccountMenu = useSelector((state) => state.layout.myAccountMenu)
    const shoppingFromMenu = useSelector((state) => state.layout.shoppingFrom.open)

    useEffect(() => {

        const root = document.getElementById('root')
        const body = document.querySelector('body');
        const cornerPieceBox = document.getElementById('cornerPieceBox')
        const rightSideHeader = document.getElementById('rightSideHeader')

        if (!rightSideHeader) {
            return
        }

        if (menuOpen || myAccountMenu || shoppingFromMenu) {

            // check for existing scrollbar size when switching between open menus, or use the scrollbar width
            const scrollBarWidth = window.innerWidth - document.documentElement.clientWidth;
            let paddingRight = parseInt(window.getComputedStyle(root)?.paddingRight) || scrollBarWidth;

            const paddingRightTotal = `${paddingRight}px`

            body.style.overflow = 'hidden';
            root.style.paddingRight = paddingRightTotal;
            rightSideHeader.style.marginRight = paddingRightTotal;

            cornerPieceBox.style.display = 'block';

        } else {

            body.style.overflow = 'auto';
            root.style.paddingRight = 0;
            rightSideHeader.style.marginRight = 'initial';
            cornerPieceBox.style.display = 'none';

        }

    }, [menuOpen, myAccountMenu, shoppingFromMenu]);

    if (!menuOpen && !dealDropdownOpen && !myAccountMenu && !shoppingFromMenu) {
        return null
    }

    return <MenuCoverBox className={`${(myAccountMenu || shoppingFromMenu) ? 'forceShow' : ''}`} />
})

export default MenuCover;