import { store } from '../..';
import { DEFAULT_TEXT } from '../../content';

export const createReturnUrl = ({ BID_PACK_AUCTION_DATA, CREDIT_PACKAGE, PRODUCT_TYPE_CART, newCreditCard }) => {

    //cart can still pass CREDIT_PACKAGE, keep cart as priority

    //CREDIT_PACKAGE IS ALREADY THE CREDIT PACK PRODUCT, IF A CART ORDER, CHECK TO SEE IF IT A BID_PACK PRODUCT_TYPE
    let BID_PURCHASE_PARAM = '';

    if (PRODUCT_TYPE_CART) {
        const wonBidPackage = BID_PACK_AUCTION_DATA.find(bidPack => bidPack.PRODUCT_TYPE === PRODUCT_TYPE_CART);
        if (wonBidPackage) {
            BID_PURCHASE_PARAM = wonBidPackage.PRODUCT_TYPE;
        }
    } else {
        BID_PURCHASE_PARAM = CREDIT_PACKAGE;
    }

    const url = new URL(window.location.href);


    if (BID_PURCHASE_PARAM) {
        url.searchParams.append("BID_PURCHASE_PARAM", BID_PURCHASE_PARAM);
    }

    if (!newCreditCard) {
        url.searchParams.append("redirect_status", "succeeded");
    }

    return newCreditCard ? url.href : `${url.pathname}${url.search}`
}

export const getParams = () => {

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const redirect_status = urlParams.get('redirect_status')
    const BID_PURCHASE_PARAM = urlParams.get('BID_PURCHASE_PARAM')

    return { redirect_status, BID_PURCHASE_PARAM }

}

export const displayNameRules = value => {

    let error = false;

    if (!value?.length) {
        error = DEFAULT_TEXT['form.displayName.error.required']
    } else if (value.length < 3) {
        error = DEFAULT_TEXT['form.displayName.error.minimum']
    }

    return error

}

export const createPrice = price => {

    price = parseInt(price)

    price = (price / 100).toFixed(2);

    return price
}

export const scrollTop = () => {

    window.scrollTo(0, 0);

}

export const checkWinner = ({ uid, history }) => {
    const winner = history?.[0]?.uid
    return uid === winner
}

export const amountCost = amount => parseInt(amount) / 100

export const currentPage = searchParams => parseInt(searchParams.get("page")) || 1;

export const avatarImageUrl = count => `${process.env.REACT_APP_URL_IMAGE_GATEWAY_PRODUCTION}avatars/${count}.png`

export const assetPrefix = ({ type = 'image', path }) => {

    if (!path) {
        'path missing on image'
        return
    }

    let prefix = '/img/'

    if (type === 'video') {
        prefix = '/video/'
    }

    return `${process.env.REACT_APP_GATEWAY_URL}${prefix}${path}`

}

//AUTH0 UID
export const auth0UserInfo = user => ({ email: user?.email, uid: user?.sub });

export const getUserCredentials = () => {

    const { uid, email } = store.getState()?.user || {};

    return { uid, email }

}
