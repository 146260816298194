import React, { useState, useCallback, useRef } from 'react';
import { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_TEXT, DELETE_ACCOUNT_TAG, DISPLAY_NAME_MAX_CHARACTERS } from '../../content';
import { actionUpdateDisplayName } from '../../reducers/user';
import { displayNameRules } from '../helpers';
import { MEMBER_POP_STEPS, MemberPopContext } from '../MemberPop';
import { useAuth0 } from '@auth0/auth0-react';

const DeleteAccount = React.memo(() => {

    const {
        logout
    } = useAuth0();

    const accessToken = useSelector((state) => state.user.accessToken)

    const [error, setError] = useState(false);

    const inputRef = useRef(null);

    const { submitDeleteAccountRef, setLoading } = useContext(MemberPopContext)

    const handleSubmit = useCallback(async (e) => {

        e.preventDefault();
        let value = inputRef.current.value;
        const phrase = DELETE_ACCOUNT_TAG.toLowerCase();

        if (value) {
            value = value.toLowerCase();
        }

        if (value !== phrase) {
            setError(DEFAULT_TEXT['member.pop.DELETE_ACCOUNT.error']);
            return
        }

        setLoading(true);
        setError(false);

        let response = await fetch(process.env.REACT_APP_URL_DELETE_ACCOUNT, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ accessToken }),
        })

        const { success, error } = await response.json();

        console.log(error)

        if (success) {
            logout({
                logoutParams: {
                    returnTo: window.location.origin
                }
            });
        } else {
            setLoading(false);
            setError(DEFAULT_TEXT['member.pop.DELETE_ACCOUNT.error.fatal']);
        }

    }, []);

    return (
        <form className="deleteAccountBox column" onSubmit={handleSubmit}>

            <div className="deleteAccountIntro" dangerouslySetInnerHTML={{ __html: DEFAULT_TEXT['member.pop.paragraph.DELETE_ACCOUNT'] }} />

            <div className="field column">
                <div className="inputBox row">
                    <input type="text" placeholder={DEFAULT_TEXT['member.pop.DELETE_ACCOUNT.placeholder']} name="deleteAccount" ref={inputRef} />
                </div>

                {error && <div className="error">{error}</div>}

            </div>

            <button ref={submitDeleteAccountRef}>Hidden</button>

        </form>
    );
})

export default DeleteAccount;