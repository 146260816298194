import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { actionMemberPop } from '../../reducers/user';
import { SlideMenuRightBox } from '../../style/main';
import { actionCloseMenus } from '../../reducers/layout';
import { MY_ACCOUNT_TAB_KEYS } from '../MyAccount';
import { DEFAULT_TEXT } from '../../content';
import { MEMBER_POP_STEPS } from '../MemberPop';
import { useAuth0 } from '@auth0/auth0-react';

export const myAccountMenuText = [
    {
        name: DEFAULT_TEXT['page.myaccount.menu.addBids'],
        click: 'addBids'
    },
    {
        name: DEFAULT_TEXT['page.myaccount.menu.winningAuctions'],
        url: `${DEFAULT_TEXT['url.winningAuctions']}`,
    },
    {
        name: DEFAULT_TEXT['page.myaccount.menu.orderHistory'],
        url: `${DEFAULT_TEXT['url.orderHistory']}`,
    },
    {
        name: DEFAULT_TEXT['page.myaccount.menu.bidHistory'],
        url: `${DEFAULT_TEXT['url.bidHistory']}`,
    },
    {
        name: DEFAULT_TEXT['page.myaccount.menu.myAccount'],
        url: DEFAULT_TEXT['url.myAccount'],
    },
    {
        name: DEFAULT_TEXT['page.myaccount.menu.shippingInfo'],
        url: `${DEFAULT_TEXT['url.myAccount']}?${MY_ACCOUNT_TAB_KEYS.SHIPPING_INFO}=true`,
    },
    {
        name: DEFAULT_TEXT['page.myaccount.menu.signOut'],
        click: 'logOut'
    }
]


const MenuMyAccountTab = React.memo(({ name, arrow, click, url }) => {


    const {
        logout
    } = useAuth0();

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const onClick = useCallback(() => {

        if (click === 'addBids') {

            dispatch(actionMemberPop(MEMBER_POP_STEPS.SELECT_CREDIT_PACK));

        } else if (click === 'logOut') {

            logout({
                logoutParams: {
                    returnTo: window.location.origin
                }
            });

        } else if (url) {

            navigate(url);

        }

    }, [click, url]);

    return (
        <li className='row' onClick={onClick}>
            <div>{name}</div>
            {arrow &&
                <svg className='arrow' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M 7.4296875 9.5 L 5.9296875 11 L 12 17.070312 L 18.070312 11 L 16.570312 9.5 L 12 14.070312 L 7.4296875 9.5 z" />
                </svg>
            }
        </li>
    );
})

const MenuMyAccount = React.memo(() => {

    const dispatch = useDispatch();

    const myAccountMenu = useSelector((state) => state.layout.myAccountMenu)
    const dealDropdownOpen = useSelector((state) => state.layout.dealDropdownOpen)
    const menuOpen = useSelector((state) => state.layout.menuOpen)

    const closeMenu = useCallback(() => {

        dispatch(actionCloseMenus({ myAccountMenu: true }))

    }, []);

    useEffect(() => {

        const removeEventListener = () => {
            window.removeEventListener("click", closeMenu)
        }

        removeEventListener()

        if (!myAccountMenu) {

            dispatch(actionCloseMenus({ myAccountMenu: true }))

        } else {

            window.addEventListener("click", closeMenu)
        }

        return removeEventListener

    }, [myAccountMenu]);

    useEffect(() => {

        if (menuOpen || dealDropdownOpen) {

            dispatch(actionCloseMenus({ myAccountMenu: true }))

        }
    }, [menuOpen, dealDropdownOpen]);

    return (
        <SlideMenuRightBox className={`column ${myAccountMenu ? 'active' : ''}`}>

            {myAccountMenuText.map(x => <MenuMyAccountTab key={x.name} {...x} />)}

        </SlideMenuRightBox>
    );
})

export default MenuMyAccount;