import React, { useState, useCallback, useRef } from 'react';
import { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_TEXT, DISPLAY_NAME_MAX_CHARACTERS } from '../../content';
import { actionUpdateDisplayName } from '../../reducers/user';
import { displayNameRules, getUserCredentials } from '../helpers';
import { MEMBER_POP_STEPS, MemberPopContext } from '../MemberPop';

const EditDisplayName = React.memo(() => {

    const dispatch = useDispatch();

    const [focusInput, setFocusInput] = useState(false)

    const uid = useSelector((state) => state.user.uid)
    const displayName = useSelector((state) => state.user.displayName)
    const accessToken = useSelector((state) => state.user.accessToken)
    const memberPop = useSelector((state) => state.user.memberPop)

    const displayNameRef = useRef(null)

    const { submitEditDisplayRef, setLoading } = useContext(MemberPopContext)

    const [errors, setErrors] = useState({
        displayName: false,
        base: false
    });

    const onFocus = useCallback((e) => {
        setFocusInput(true)
    }, [setFocusInput]);

    const onBlur = useCallback((e) => {
        setFocusInput(false)
    }, [setFocusInput]);

    const handleSubmit = useCallback(async (e) => {

        e.preventDefault();

        const valueDisplayName = displayNameRef.current.value;

        const displayNameError = displayNameRules(valueDisplayName)

        setErrors({
            displayName: displayNameError
        })

        if (!displayNameError) {

            setLoading(true)

            const obj = {
                accessToken,
                displayName: valueDisplayName,
                ...getUserCredentials()
            }

            let response = await fetch(process.env.REACT_APP_URL_UPDATE_DISPLAY_NAME, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(obj),
            })

            response = await response.json();

            setLoading(false)

            if (response?.error) {

                setErrors({
                    base: true
                })

            } else {

                // UPDATE THE DISPLAY NAME AND CLOSE THE MEMBER POP
                dispatch(actionUpdateDisplayName(valueDisplayName));

            }

        }


    }, [uid, accessToken, displayName]);

    return (
        <form className="editDisplayNameBox column" onSubmit={handleSubmit}>

            {memberPop === MEMBER_POP_STEPS.NEW_DISPLAY_NAME &&
                <div className="newDisplayNameIntro">
                    {DEFAULT_TEXT['member.pop.intro.NEW_DISPLAY_NAME']}
                </div>
            }

            <div className="field column">
                <div className={`inputBox row ${focusInput ? 'active' : ''}`}>

                    <label htmlFor="displayName">{DEFAULT_TEXT[`member.pop.${memberPop}.placeholder`]}</label>
                    <input type="text" name="displayName" placeholder={DEFAULT_TEXT[`member.pop.${memberPop}.placeholder`]} ref={displayNameRef} onFocus={onFocus} onBlur={onBlur} maxLength={DISPLAY_NAME_MAX_CHARACTERS} onChange={(e) => e.target.value = e.target.value.replace(/\s+/g, '')} />

                </div>

                {errors?.displayName && <div className="error">{errors.displayName}</div>}

            </div>

            {errors?.base && <div className="error base" dangerouslySetInnerHTML={{ __html: DEFAULT_TEXT['form.displayName.error.base'] }}></div>}

            <button ref={submitEditDisplayRef}>Hidden</button>

        </form>
    );
})

export default EditDisplayName;