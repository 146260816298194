import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useSearchParams } from 'react-router-dom';
import { DEFAULT_TEXT } from '../../content';
import { BRAND_LIST } from '../../content/brands';
import { BreadcrumbBox } from '../../style/main';
import { Arrow } from './BreadcrumbsAuctionPage';

const BreadcrumbsFilter = React.memo(({ breadcrumbTotal, breadcrumbRef }) => {

    const [searchParams, setSearchParams] = useSearchParams();

    const auctions = useSelector((state) => state.auctions.auctions)

    let ALL_TEXT = DEFAULT_TEXT['breadcrumb.allDeals'];

    const filters = searchParams?.get('filter')?.split(' ') || []
    let filterString = '';


    if (filters.length) {

        let approvedFilters = []

        filters.sort((a, b) => a.localeCompare(b))

        filters.forEach(filter => {

            const brand = BRAND_LIST[filter];

            if (brand) {
                approvedFilters.push(brand)
            }
        })

        if (approvedFilters?.length > 1) {
            //ADD "and" to the ending of the breadcrumbs
            const lastIndexText = approvedFilters[approvedFilters.length - 1]
            approvedFilters[approvedFilters.length - 1] = `${DEFAULT_TEXT['breadcrumb.and']} ${lastIndexText}`
        }

        filterString = approvedFilters.join(', ')
    }

    return (
        <BreadcrumbBox className={`row ${!auctions.length ? 'loading' : ''}`} ref={breadcrumbRef}>

            <div className="resultText">

                {filterString ?

                    <>
                        <Link to="/">
                            <span className='allDeals'>{ALL_TEXT}</span>
                        </Link>
                        <Arrow />
                        {filterString}
                    </>

                    :
                    <span>{ALL_TEXT}</span>

                }

            </div>
            {breadcrumbTotal && <span className='totalAuctions'>({breadcrumbTotal} {DEFAULT_TEXT['breadcrumb.results']})</span>}

        </BreadcrumbBox >
    );
})

export default BreadcrumbsFilter;