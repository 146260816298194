import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { DEFAULT_TEXT } from '../../content';
import { CURRENCY } from '../../content/currency';
import { StyledInCart } from '../../style/main';
import { amountCost, createPrice } from '../helpers';
import { MemberPopContext } from '../MemberPop';
import ProductImage from '../snippet/ProductImage';

const InCart = React.memo(() => {

    const CREDIT_PACKS = useSelector((state) => state.auctions.CREDIT_PACKS)

    const { CREDIT_PACKAGE, cart } = useContext(MemberPopContext)

    const { SKU, amount, TITLE, BID_AMOUNT } = cart;

    const CHOSEN_CREDIT_PACK = CREDIT_PACKS.find(({ PRODUCT_TYPE }) => PRODUCT_TYPE === CREDIT_PACKAGE)

    return (
        <>
            {(CHOSEN_CREDIT_PACK) ?
                <StyledInCart className='row'>
                    <div className="checkoutLeft row">
                        <div className="radio row">
                            <div className="circle"></div>
                        </div>

                        {SKU ?
                            <>
                                <ProductImage SKU={SKU} TITLE={TITLE} large={true} />

                                <div className="productLeft column">
                                    <div className="title">{TITLE}</div>
                                    <div className="cost">
                                        {CURRENCY[process.env.REACT_APP_ISO_CODE].dollar}{createPrice(amount)}
                                    </div>
                                    {BID_AMOUNT &&
                                        <div className="subLine">
                                            ({BID_AMOUNT} {DEFAULT_TEXT['member.pop.CART.bidAmountSubText']})
                                        </div>
                                    }
                                </div>
                            </>
                            :
                            <div className="amount row">
                                <span>{CHOSEN_CREDIT_PACK.bid_amount}</span>
                                <div className="sub">{DEFAULT_TEXT['member.pop.bidSelect.bids']}</div>
                            </div>
                        }
                    </div>

                    {!amount &&
                        <div className="cost">
                            {CURRENCY[process.env.REACT_APP_ISO_CODE].dollar}{amountCost(CHOSEN_CREDIT_PACK.amount)}
                        </div>
                    }
                </StyledInCart>
                :
                ''
            }
        </>
    );
})

export default InCart;