import React from 'react';
import { HELP_BRANDS } from '../../content/brands';
import { assetPrefix } from '../helpers';

const HelpBrands = React.memo(() => {

    return (
        <div className="brandsBox row">
            {HELP_BRANDS.map(brand => <div className="brandBox row" key={brand}>
                <img src={assetPrefix({ path: `brands/${brand.toLowerCase()}.svg` })} />
            </div>)}
        </div>
    );
})

export default HelpBrands;