import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_TEXT, myAccountProfile } from '../../content';
import { actionMemberPop } from '../../reducers/user';
import { assetPrefix } from '../helpers';
import { MEMBER_POP_STEPS } from '../MemberPop';
import Avatar from '../snippet/Avatar';

const myAccountTabs = [
    DEFAULT_TEXT['button.myaccount.tab.profile.avatar'],
    DEFAULT_TEXT['button.myaccount.tab.profile.displayName'],
    DEFAULT_TEXT['button.myaccount.tab.profile.email'],
    DEFAULT_TEXT['button.myaccount.tab.profile.bidsAvailable']
]

const Profile = React.memo(() => {

    const dispatch = useDispatch();
    const displayName = useSelector((state) => state.user.displayName)
    const email = useSelector((state) => state.user.email)
    const credits = useSelector((state) => state.user.credits)

    const openAddCredits = useCallback(() => {

        dispatch(actionMemberPop(MEMBER_POP_STEPS.SELECT_CREDIT_PACK));

    }, []);

    const openEditDisplayName = useCallback(() => {

        dispatch(actionMemberPop(MEMBER_POP_STEPS.EDIT_DISPLAY_NAME));

    }, []);

    return (
        <>
            {myAccountTabs.map((text, i) =>

                <div className="infoRow row" key={i}>

                    <div className="infoRowLeft">{text}</div>

                    <div className="infoRowRight row">

                        {i === 0 ?
                            <Avatar type='myAccount2' />

                            :
                            i === 1 ?
                                <>
                                    <div className="smallBtn" onClick={openEditDisplayName}>{DEFAULT_TEXT['button.myaccount.tab.profile.edit']}</div>
                                    {displayName}
                                </>
                                :
                                i === 2 ?
                                    email
                                    :
                                    <>
                                        <div className="smallBtn" onClick={openAddCredits}>{DEFAULT_TEXT['button.myaccount.tab.profile.addBids']}</div>
                                        <div className="creditsBox row" onClick={openAddCredits} >
                                            <img src={assetPrefix({ path: 'credit.svg' })} />
                                            <span>{credits}</span>
                                        </div>
                                    </>
                        }

                    </div>

                </div>
            )}
        </>
    );
})

export default Profile;