import React, { useCallback, useContext } from 'react';
import { useSelector } from 'react-redux';
import { assetPrefix } from '../helpers';
import { MemberPopContext } from '../MemberPop';

const Avatar = React.memo(({ image, count }) => {

    const avatar = useSelector((state) => state.user.avatar)

    const { avatarCount, setAvatarCount } = useContext(MemberPopContext)

    const avatarClick = useCallback(() => {

        setAvatarCount(count)

    }, [count]);

    return (
        <div className={`avatarBox row  ${avatarCount === count ? 'selected' : ''} ${avatar === count ? 'chosen' : ''}`} onClick={avatarClick}>
            <img src={assetPrefix({ path: `avatars/${image}` })} />
            <div className='radio row'>
                <div className="circle"></div>
            </div>
        </div >
    );
})

export default Avatar;