import React, { createContext, useContext } from 'react';

const CartModeContext = createContext(null)

export const CartModeProvider = ({ children }) => {

    return (
        <CartModeContext.Provider value={{ cartMode: false }}>
            {children}
        </CartModeContext.Provider>
    )
}

export const useCartMode = () => useContext(CartModeContext)