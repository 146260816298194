import React from 'react';

const LdsRing = React.memo(() => {
    return (
        <div className="lds-ring">
            <div></div><div></div><div></div><div></div>
        </div>
    );
})

export default LdsRing;