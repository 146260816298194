import React from 'react';
import { avatarImages } from '../../content/avatars';
import Avatar from './Avatar';

const Avatars = React.memo(() => {

    return (
        <div className="avatarsBox row">
            {avatarImages.map((x) => <Avatar key={x.count} {...x} count={x.count} />)}
        </div>
    );
})

export default Avatars;