import React, { useRef, useState, useCallback } from 'react';
import ReactPlayer from 'react-player'
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { DEFAULT_TEXT } from '../../content';
import { usePages } from '../../providers/PagesProvider';
import { IntroVideoBox } from '../../style/main';
import { assetPrefix } from '../helpers';
import { useMemberPopProvider } from '../../providers/MemberPopProvider';

const IntroVideo = React.memo(() => {

    const { handleLogin } = useMemberPopProvider();

    const { currentPage } = usePages();

    let [searchParams, setSearchParams] = useSearchParams();

    const filter = searchParams.get("filter");

    const playerRef = useRef(null)

    const uid = useSelector((state) => state.user.uid)
    const userLoaded = useSelector((state) => state.user.userLoaded)

    const [duration, setDuration] = useState(0);

    const [video, setVideo] = useState({
        playing: false,
        played: false,
        ended: false,
        endControlsHide: false
    });

    const playVideo = useCallback((e) => {
        e.stopPropagation();
        setVideo(video => ({
            ...video,
            playing: true,
            played: true
        }))
    }, []);

    const onEnded = useCallback(() => {
        setTimeout(() => {
            setVideo(video => ({
                ...video,
                ended: true
            }))
        }, 700)
    }, []);

    const onProgress = useCallback((progress) => {
        const currentDuration = progress?.playedSeconds;
        if (duration && (currentDuration > duration - 1)) {
            setVideo(video => ({
                ...video,
                endControlsHide: true
            }))
        }
    }, [duration]);

    const replay = useCallback(() => {
        setVideo(video => ({
            ...video,
            endControlsHide: false,
            ended: false
        }))
        playerRef.current.seekTo(0, 'seconds')
    }, []);

    if (uid || filter || currentPage !== 1) {
        return null
    }

    return (
        <IntroVideoBox className={`row`}>
            <div className="wrap row">
                <div className="leftSide column">
                    <div className="tag row">{DEFAULT_TEXT['hero.tag']}</div>
                    <h1 dangerouslySetInnerHTML={{ __html: DEFAULT_TEXT['hero.h1'] }} />
                    <div className="h1Sub" dangerouslySetInnerHTML={{ __html: DEFAULT_TEXT['hero.sub'] }} />
                </div>
                <div className="videoBox row">
                    {userLoaded &&
                        <>
                            <ReactPlayer
                                ref={playerRef}
                                url={assetPrefix({ path: 'intro.mp4', type: 'video' })}
                                width="100%"
                                height="100%"
                                playsinline
                                controls={video.endControlsHide ? false : video.ended ? false : video.played}
                                playing={video.playing}
                                className="videoInner row"
                                onEnded={onEnded}
                                onDuration={(duration) => setDuration(duration)}
                                onProgress={onProgress}
                            />
                            {!video.played &&
                                <>
                                    <img src={assetPrefix({ path: 'poster.jpg' })} alt="Save up to 95% off!" className='poster' />
                                    <div className="playCover row" onClick={playVideo}>
                                        <div className="playCircle row">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M3 22v-20l18 10-18 10z" /></svg>
                                        </div>
                                    </div>
                                </>
                            }
                        </>
                    }
                    <svg viewBox="0 0 750 422" xmlns="http://www.w3.org/2000/svg" className='placeholder'>
                        <rect width="750" height="422" />
                    </svg>

                    {/* STOP VIDEO CLICK IN LAST 2 SECONDS */}
                    {video.endControlsHide && <div className="endedPreCover row"></div>}


                    <div className={`endedCover column ${video.ended ? 'active' : ''}`}>
                        <div className="button row" onClick={() => handleLogin({ signup: true })}>
                            {DEFAULT_TEXT['hero.cta']}
                        </div>
                        <div className="reloadCorner row" onClick={replay}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                <path d="M3 22v-20l18 10-18 10z" />
                            </svg>
                            <div>{DEFAULT_TEXT['hero.replay']}</div>
                        </div>
                    </div>
                </div>
            </div>
        </IntroVideoBox>
    );
})

export default IntroVideo;