import React, { useEffect, useState } from 'react';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { DEFAULT_TEXT } from '../content';
import { BidHistoryBox, PagesBox } from '../style/main';
import { assetPrefix, createPrice, getUserCredentials, scrollTop } from './helpers';
import LdsRing from './snippet/LdsRing';
import { Link, useSearchParams } from 'react-router-dom';
import { CURRENCY } from '../content/currency';

const DEFAULT_STATE = {
    loading: true,
    history: [],
    totalPages: 0
}

const Page = React.memo(({ count, currentPage, setBidHistory }) => {

    const [searchParams, setSearchParams] = useSearchParams();

    count = count + 1;

    const onClick = useCallback(() => {

        scrollTop();

        if (currentPage === count) {
            return
        }

        setBidHistory(DEFAULT_STATE)
        setSearchParams({ page: count })

    }, [count]);

    return (
        <div className={`page row ${currentPage === count ? 'selected' : ''}`} onClick={onClick}>
            {count}
        </div>
    );
})

const BidHistoryRow = React.memo(({ id, SKU, TITLE, priceCurrent, bidTime }) => {

    const ISO_CODE = process.env.REACT_APP_ISO_CODE;
    const LANGUAGE = process.env.REACT_APP_LANGUAGE.toUpperCase();

    const filename = `${LANGUAGE}_${SKU}.png`
    let filepath = assetPrefix({ path: `products/${ISO_CODE}/small/${filename}` })

    const newDate = new Date(bidTime);
    const date = newDate.toDateString()
    const time = newDate.toLocaleTimeString()

    const fullDate = `${date}, ${time}`

    return (
        <>
            <Link to={`/auctions/${id}`} className="bidHistoryRow row">

                <div className="leftSide row">
                    <img src={filepath} alt={TITLE} />
                    <div className="info column">
                        <div className="title">{TITLE}</div>
                        <div className="price">
                            {CURRENCY[process.env.REACT_APP_ISO_CODE].dollar}{createPrice(priceCurrent)} {DEFAULT_TEXT['page.bidHistory.bidPrice']}
                        </div>
                        <div className="time">{fullDate}</div>
                    </div>
                </div>

                <div className="btn row">{DEFAULT_TEXT['page.bidHistory.view']}</div>

            </Link>
        </>
    );
})

//USES SHARED CLASSES WITH ORDER HISTORY PAGE
const BidHistory = React.memo(() => {

    const [searchParams, setSearchParams] = useSearchParams();

    let page = parseInt(searchParams?.get('page'));

    if (!page || page <= 0) {
        page = 1;
    }

    const navigate = useNavigate();

    const uid = useSelector((state) => state.user.uid)
    const userLoaded = useSelector((state) => state.user.userLoaded)
    const accessToken = useSelector((state) => state.user.accessToken)

    const [bidHistory, setBidHistory] = useState(DEFAULT_STATE);

    useEffect(() => {
        scrollTop()
    }, []);

    useEffect(async () => {

        const stopLoading = () => {
            setBidHistory(x => ({
                ...x,
                loading: false
            }))
        }

        if (!userLoaded) {
            return
        }

        if (!uid && userLoaded) {
            stopLoading();
            return
        }

        let response = await fetch(process.env.REACT_APP_URL_BID_HISTORY, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ accessToken, page, ...getUserCredentials() }),
        })

        const { bidHistory, totalPages } = await response.json();

        if (bidHistory?.length) {
            setBidHistory(x => ({
                ...x,
                history: bidHistory,
                totalPages,
                loading: false
            }))
        } else {
            stopLoading();
        }

    }, [userLoaded, uid, accessToken, page]);

    const noBidHistoryClick = useCallback(() => {
        navigate('/');
    }, []);

    const totalPages = bidHistory.totalPages;

    const nextPage = useCallback(() => {
        scrollTop();
        setBidHistory(DEFAULT_STATE)
        setSearchParams({ page: page + 1 })
    }, [page]);

    const prevPage = useCallback(() => {
        scrollTop();
        setBidHistory(DEFAULT_STATE)
        setSearchParams({ page: page - 1 })
    }, [page]);

    return (
        <BidHistoryBox>
            <div className='wrap column'>
                {bidHistory?.loading ? <LdsRing /> :
                    <>
                        {bidHistory?.history?.length ?
                            <>
                                <div className="titleBox row">
                                    <div className='leftSide'>{DEFAULT_TEXT['page.bidHistory.title']}</div>
                                    <div className='rightSide'>{DEFAULT_TEXT['page.bidHistory.page']} {page} {DEFAULT_TEXT['page.bidHistory.of']} {totalPages}</div>
                                </div>
                                <div className="bidHistory column">

                                    {bidHistory?.history.map((auction, i) => <BidHistoryRow key={i} {...auction} />)}

                                </div>

                                <PagesBox className='row bidHistoryPage'>

                                    <div className={`sideArrow row ${page === 1 ? 'default' : ''}`} onClick={page === 1 ? null : prevPage}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" /></svg>
                                    </div>

                                    <div className="pages row">
                                        {[...Array(totalPages).keys()].map(count => <Page key={count} count={count} currentPage={page} setBidHistory={setBidHistory} />)}
                                    </div>

                                    <div className={`sideArrow row ${page === totalPages ? 'default' : ''}`} onClick={page === totalPages ? null : nextPage}>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M7.33 24l-2.83-2.829 9.339-9.175-9.339-9.167 2.83-2.829 12.17 11.996z" /></svg>
                                    </div>
                                </PagesBox>
                            </>
                            :
                            <div className="noBidHistory column">
                                {DEFAULT_TEXT['page.bidHistory.noHistory']}
                                <button onClick={noBidHistoryClick}>{DEFAULT_TEXT['auction.notFound.button']}</button>
                            </div>
                        }
                    </>
                }
            </div>
        </BidHistoryBox>
    );
})

export default BidHistory;