import React from 'react';
import { useSelector } from 'react-redux';
import ShoppingFromCity from './ShoppingFromCity';

const ShoppingFromCities = React.memo(() => {

    const shoppingFromCities = useSelector((state) => state.layout.shoppingFrom.cities)

    if (!shoppingFromCities?.length) {
        return null
    }

    return (
        <ul className="dropdown column">

            {shoppingFromCities.map((city, i) => <ShoppingFromCity key={i} city={city} />)}

        </ul>
    );
})

export default ShoppingFromCities;