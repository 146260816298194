import React, { useState, useEffect, useCallback, useRef } from 'react';
import { DEFAULT_TEXT } from '../../content';
import { avatarImages } from '../../content/avatars';
import { assetPrefix } from '../helpers';

const totalAvatars = 25;

const createImageArray = () => avatarImages.sort(() => .5 - Math.random()).slice(0, totalAvatars)


const randomNumberInclusive = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

const defaultState = {
    loaded: [],
    active: []
}

const HelpAvatars = React.memo(() => {

    const intervalRef = useRef(null);
    const timeoutRef = useRef(null);

    const [images, setImages] = useState(defaultState);

    const preload = [...Array(totalAvatars).keys()]

    const loopAvatars = useCallback(() => {

        let keys = [...Array(totalAvatars).keys()]



        intervalRef.current = setInterval(() => {

            if (keys?.length) {

                const index = randomNumberInclusive(0, keys.length - 1);
                const number = keys[index]

                keys.splice(index, 1)

                setImages(x => ({
                    ...x,
                    active: [...x.active, number]
                }))


            } else {

                clearInterval(intervalRef.current)

                timeoutRef.current = setTimeout(() => {
                    setImages(images => ({
                        ...images,
                        active: []
                    }))
                }, 2500)

            }

        }, 50)

    }, []);

    useEffect(() => {

        if (!images.active.length) {
            setTimeout(() => {
                setImages(images => ({
                    ...images,
                    loaded: createImageArray()
                }))
                loopAvatars()
            }, 201)
        }



    }, [images.active]);

    useEffect(() => {
        return () => {
            clearInterval(intervalRef.current)
            clearTimeout(timeoutRef.current)
            setImages(defaultState)
        }
    }, []);

    const loop = images?.loaded?.length ? images?.loaded : preload;

    return (
        <div className="avatarsBox column">
            <div className="avatarsTitle row">
                {DEFAULT_TEXT['page.help.avatarsBoxTitle']}
            </div>
            <div className="avatarsInner row">

                {loop?.map((x, i) =>
                    <div className={`avatar row ${images?.active.includes(i) ? 'active' : ''}`} key={i}>
                        {images?.loaded?.length ? <img src={assetPrefix({ path: `avatars/${x.image}` })} /> : ''}
                    </div>
                )}
            </div>
        </div>
    );
})

export default HelpAvatars;