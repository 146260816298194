import React from 'react';
import { Link } from 'react-router-dom';
import { DEFAULT_TEXT } from '../../content';
import { helpSections, topics } from '../../content/help';
import { HelpIndexBox } from '../../style/main';
import { HELP_GROUPS, helpPaths } from '../../content/helpPaths';

const HelpIndexSection = React.memo(({ groupKey, title, path, indexIntro }) => {

    const groupTopics = topics.filter(({ group }) => group === groupKey)

    //DONT SHOW CONTACT US GROUP, OR GROUPS WITH NO TOPICS
    if (!groupTopics?.length) {
        return null
    }

    return (
        <div className="section column">

            <Link to={`${DEFAULT_TEXT['url.help']}/${path}`} className="title row">
                <span>{title}</span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" /></svg>
            </Link>

            <div className="topics column">
                {groupTopics.map(({ title, key, path }) =>
                    <Link
                        to={`${DEFAULT_TEXT['url.help']}/${path}/${key}`}
                        className="topic row"
                        key={key}
                    >

                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 20">
                            <path d="M2,0C0.9,0,0,0.9,0,2v16c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V4.6L11.4,0H2z M2,2h8v4h4v12H2V2z M4,8v2h8V8H4z M4,11v2h8v-2H4z M4,14v2h5v-2H4z" />
                        </svg>
                        <span>{title}</span>

                    </Link>
                )}
            </div>


        </div>
    );
})


const HelpIndex = React.memo(() => {

    return (

        <HelpIndexBox>

            <div className='wrap column'>

                <div className="helpIntro row">
                    <div className="helpIntroLeft column">
                        <h1>{DEFAULT_TEXT['page.help.breadcrumbFirst']}</h1>
                        <p>{DEFAULT_TEXT['page.help.intro']}</p>
                    </div>
                    <div className="helpIntroRight column">
                        <div className="questionsTop">{DEFAULT_TEXT['page.help.questions']}</div>
                        <Link to={`${DEFAULT_TEXT['url.help']}/${helpPaths[HELP_GROUPS.CONTACT_US]}`} className="btn row">
                            {DEFAULT_TEXT['button.help.contactUs']}
                        </Link>
                    </div>
                </div>

                <div className="helpIndexBlocks row">
                    {helpSections.map(section => <HelpIndexSection groupKey={section.key}{...section} />)}
                </div>

            </div>
        </HelpIndexBox>
    );
})

export default HelpIndex;