import React from 'react';
import { DEFAULT_TEXT } from '../content';

const convertTime = value => {
    const sec = parseInt(value, 10); // convert value to number if it's string
    let hours = Math.floor(sec / 3600); // get hours
    let minutes = Math.floor((sec - (hours * 3600)) / 60); // get minutes
    let seconds = sec - (hours * 3600) - (minutes * 60); //  get seconds
    // add 0 if value < 10; Example: 2 => 02
    if (hours < 10) { hours = "0" + hours; }
    if (minutes < 10) { minutes = "0" + minutes; }
    if (seconds < 10) { seconds = "0" + seconds; }
    return hours + ':' + minutes + ':' + seconds; // Return is HH : MM : SS   
}

const Countdown = React.memo(({ countdown, sold, CREDIT_PACKAGE }) => {

    const seconds = countdown;

    let time = false;

    if (countdown === 0) {
        time = '00:00:00'
    } else if (seconds) {
        time = convertTime(seconds)
    }

    const heated = countdown && countdown <= 5;
    const flashing = countdown && countdown <= 3;

    return (
        <div className={`countdown ${CREDIT_PACKAGE ? '' : isNaN(countdown) ? 'loading' : ''} ${flashing ? 'flashing' : ''} ${heated ? 'heated' : ''}`}>

            {CREDIT_PACKAGE ?
                DEFAULT_TEXT['orderHistory.auction.creditPurchase']
                :
                sold ?
                    DEFAULT_TEXT['auction.countdown.ended']
                    :
                    (countdown >= 0) ?
                        time :
                        <div>Loading</div>}

        </div>
    );
})

export default Countdown;