import { createSlice, current } from '@reduxjs/toolkit'
import { MEMBER_POP_STEPS } from '../components/MemberPop';


const initialState = {
    uid: false,
    credits: 0,
    displayName: false,
    email: false,
    avatar: 0,
    memberPop: false,
    bidsPurchased: false,
    userLoaded: false,
    addressInfo: false,
    cartPaymentIntent: false,
    cartAuctionId: '',
    accessToken: ''
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        actionUserLoaded: (state, action) => {

            const { uid, credits, displayName, email, avatar, accessToken } = action.payload;

            state.uid = uid;
            state.credits = parseInt(credits) || 0;
            state.displayName = displayName;
            state.email = email;
            state.avatar = parseInt(avatar) || 0;
            state.userLoaded = true;
            state.accessToken = accessToken;

            let addressInfo = action?.payload?.addressInfo;

            if (addressInfo) {
                addressInfo = JSON.parse(addressInfo)
                state.addressInfo = addressInfo;
            }

        },
        actionUserDoesNotExist: (state, action) => {

            const { userDeleted } = action.payload;


            const obj = {
                ...initialState,
                userLoaded: true,
            }

            if (userDeleted) {
                obj.memberPop = MEMBER_POP_STEPS.USER_DELETED;
            }

            return obj

        },
        actionCredits: (state, action) => {

            state.credits = action.payload;

        },
        actionUpdateAvatar: (state, action) => {

            const avatar = current(state)?.avatar;

            state.avatar = action.payload;

            if (avatar) {
                // AVATAR EXISTS, CLOSE THE BOX, ONLY AN UPDATE
                state.memberPop = false;
            } else {
                // AVATAR DOES NOT EXIST AFTER SIGNUP, GO TO ADD DISPLAY NAME
                state.memberPop = MEMBER_POP_STEPS.NEW_DISPLAY_NAME;
            }

        },
        actionUpdateDisplayName: (state, action) => {

            const currentDisplayName = current(state)?.displayName;

            // DISPLAY NAME EXISTS, UPDATE THE NAME AND CLOSE THE BOX
            //DISPLAY NAME DOES NOT EXIST, GO TO CREDIT PACK (NEW USER)
            state.displayName = action.payload;
            state.memberPop = currentDisplayName ? false : MEMBER_POP_STEPS.SELECT_CREDIT_PACK;

        },
        actionMemberPop: (state, action) => {

            state.memberPop = action.payload;

            //DELETE ANY POSSIBLE STORED WINNING CART ID ON CLOSE
            if (!action.payload) {
                state.cartAuctionId = '';
            }

        },
        actionBidsPurchased: (state, action) => {

            state.memberPop = MEMBER_POP_STEPS.BIDS_ADDED;
            state.bidsPurchased = action.payload;

        },
        actionCart: (state, action) => {

            state.memberPop = MEMBER_POP_STEPS.CART;
            state.cartAuctionId = action.payload;
        },
    },
})

export const { actionUserLoaded, actionCredits, actionUserDoesNotExist, actionUpdateAvatar, actionMemberPop, actionBidsPurchased, actionUpdateDisplayName, actionCart } = userSlice.actions

export default userSlice.reducer