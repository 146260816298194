import React from 'react';
import {
    BrowserRouter,
    Routes,
    Route
} from "react-router-dom";
import AllWrap from '../components/AllWrap';
import Homepage from '../components/Homepage';
import Privacy from '../components/legal/Privacy';
import Terms from '../components/legal/Terms';
import Sold from '../components/Sold';
import TextProvider from '../providers/Text';
import MemberPopProvider from '../providers/MemberPopProvider';
import { PagesProvider } from '../providers/PagesProvider';
import MyAccount from '../components/MyAccount';
import AuctionPage from '../components/AuctionPage';
import Help from '../components/help/Help';
import { HelpProvider } from '../providers/HelpProvider';
import WinningAuctions from '../components/WinningAuctions';
import OrderHistory from '../components/OrderHistory';
import BidHistory from '../components/BidHistory';
import { DEFAULT_TEXT } from '../content';
import HowItWorks from '../components/HowItWorks';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AuthProvider from '../providers/AuthProvider';
import FirstConnect from '../providers/FirstConnect';
import { AuthenticationGuard } from '../components/AuthGuard';
import Signin from '../components/Signin';
import ForgotPassword from '../components/ForgotPassword';

const renderPaths = (paths, Element) => paths.map((path) => <Route key={path} path={path} element={Element} />);

const AppRouter = React.memo(() => {
    return (
        <TextProvider>
            <BrowserRouter>
                <AuthProvider>
                    <FirstConnect>
                        <MemberPopProvider>
                            <Routes>
                                <Route path="/" element={
                                    <AllWrap>
                                        <PagesProvider>
                                            <Homepage />
                                        </PagesProvider>
                                    </AllWrap>
                                } />
                                <Route path="/auctions/:id" element={
                                    <AllWrap auctionPage={true}>
                                        <AuctionPage />
                                    </AllWrap>
                                } />
                                <Route path="/sold" element={
                                    <AllWrap soldPage={true}>
                                        <Sold />
                                    </AllWrap>
                                } />
                                <Route
                                    path="/winning-auctions"
                                    element={<AuthenticationGuard component={WinningAuctions} />}
                                />
                                <Route
                                    path="/order-history"
                                    element={<AuthenticationGuard component={OrderHistory} />}
                                />
                                <Route
                                    path="/bid-history"
                                    element={<AuthenticationGuard component={BidHistory} />}
                                />
                                <Route path={DEFAULT_TEXT['url.howItWorks']} element={
                                    <AllWrap helpPage={true}>
                                        <HelpProvider>
                                            <HowItWorks />
                                        </HelpProvider>
                                    </AllWrap>
                                } />
                                <Route
                                    path="/myaccount"
                                    element={<AuthenticationGuard component={MyAccount} />}
                                />
                                {renderPaths(["/help", "/help/:group", "/help/:group/:path"], <AllWrap helpPage={true}>
                                    <HelpProvider>
                                        <Help />
                                    </HelpProvider>
                                </AllWrap>)}
                                <Route path="/terms" element={
                                    <AllWrap>
                                        <Terms />
                                    </AllWrap>
                                } />
                                <Route path="/privacy" element={
                                    <AllWrap>
                                        <Privacy />
                                    </AllWrap>
                                } />
                                <Route path="/signin" element={
                                    <Signin />
                                } />
                                <Route path="/signup" element={
                                    <Signin signup={true} />
                                } />
                                <Route path="/forgot-password" element={
                                    <ForgotPassword />
                                } />
                            </Routes>
                        </MemberPopProvider>
                    </FirstConnect>
                </AuthProvider>
            </BrowserRouter>

        </TextProvider>
    );
})

export default AppRouter;