import React, { useCallback } from 'react';
import { BRAND_ALL_TEXT, BRAND_LIST } from '../../../content/brands';
import { useSearchParams, useNavigate } from 'react-router-dom';

const DealsDropdownRow = React.memo(({ brand, count, viewDeals }) => {

    const [searchParams, setSearchParams] = useSearchParams();

    const navigate = useNavigate();

    const params = searchParams?.get('filter');

    const filters = params?.length ? params?.split(' ') : []

    const onClick = useCallback(() => {

        let newFilters = [...filters]

        if (newFilters.includes(brand)) {
            newFilters = newFilters.filter(filter => filter !== brand)
        } else {
            newFilters.push(brand)
        }

        let search = ''

        if (brand === 'ALL') {
            viewDeals()
        } else {
            search = `?filter=${newFilters.join('+')}`
        }

        navigate({
            pathname: '/',
            search,
        });

    }, [brand, filters, viewDeals]);

    return (
        <div className='tabType row' onClick={onClick}>

            <div className="leftSide row">

                {brand === 'ALL' ? BRAND_ALL_TEXT[process.env.REACT_APP_ISO_CODE] : BRAND_LIST[brand]}<span>({count})</span>

            </div>

            {brand !== 'ALL' &&
                <div className={`radio row ${!filters?.length || filters.includes(brand) ? 'selected' : ''}`}>

                    <div className="circle"></div>

                </div>
            }

        </div>
    );
})

export default DealsDropdownRow;