import React from 'react';
import { Link } from 'react-router-dom';
import { DEFAULT_TEXT } from '../../../content';
import { topics } from '../../../content/help';
import { useHelpProvider } from '../../../providers/HelpProvider';

export const HelpTabTopic = React.memo(({ title, groupKey, path }) => {

    const { pathInfo } = useHelpProvider();

    return (
        <>
            <Link
                to={`${DEFAULT_TEXT['url.help']}/${path}/${groupKey}`}
                className={`helpTabTopic row ${groupKey === pathInfo.key ? 'active' : ''}`}>
                <div className="activeBg"></div>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 20">
                    <path d="M2,0C0.9,0,0,0.9,0,2v16c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V4.6L11.4,0H2z M2,2h8v4h4v12H2V2z M4,8v2h8V8H4z M4,11v2h8v-2H4z M4,14v2h5v-2H4z" />
                </svg>
                <span>{title}</span>
            </Link>
        </>
    );
})

const HelpSubMenu = React.memo(({ groupKey }) => {

    const groupTopics = topics.filter(({ group }) => group === groupKey)

    return (
        <>
            {groupTopics.map(({ title, key, path }) => <HelpTabTopic key={key} groupKey={key} title={title} path={path} />)}
        </>
    );
})
export default HelpSubMenu;