import React from 'react';
import { useSelector } from 'react-redux';
import { DEFAULT_TEXT } from '../../../content';

const ShoppingFromCitiesError = React.memo(() => {

    const shoppingFromError = useSelector((state) => state.layout.shoppingFrom.error)

    if (!shoppingFromError) {
        return null
    }

    return (

        <ul className="error">
            <li className='row none'>{DEFAULT_TEXT['shoppingFrom.error']}</li>
        </ul>

    )
})

export default ShoppingFromCitiesError;