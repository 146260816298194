import React from 'react';
import { useSelector } from 'react-redux';
import { DEFAULT_TEXT } from '../../content';
import { CURRENCY } from '../../content/currency';
import { useHelpProvider } from '../../providers/HelpProvider';
import { amountCost } from '../helpers';
import ProductImage from '../snippet/ProductImage';
import HelpAvatars from './HelpAvatars';
import HelpBrands from './HelpBrands';
import HelpContact from './HelpContact';
import HelpRelated from './HelpRelated';
import NextTopic from './NextTopic';
import { HELP_GROUPS } from '../../content/helpPaths';

const HelpContent = React.memo(() => {

    const CREDIT_PACKS = useSelector((state) => state.auctions.CREDIT_PACKS)
    const BID_PACK_AUCTION_DATA = useSelector((state) => state.auctions.BID_PACK_AUCTION_DATA)

    const { groupInfo, pathInfo, groupPage } = useHelpProvider();

    const contactUsTab = groupInfo?.key === HELP_GROUPS.CONTACT_US;

    let { title, html, group, avatars, showBidsPacks, brands } = pathInfo;

    //CREDIT PACKAGE TEXT
    let creditPackageText = '';

    if (CREDIT_PACKS?.length) {
        CREDIT_PACKS?.forEach(({ bid_amount, amount }, i) => creditPackageText += `
        <li>
            <div class="helpCircle">${i + 1}</div>
            <div>
                <strong>${bid_amount} Bids</strong>: ${CURRENCY[process.env.REACT_APP_ISO_CODE].dollar}${amountCost(amount)} (${CURRENCY[process.env.REACT_APP_ISO_CODE].dollar}${(amountCost(amount) / bid_amount).toFixed(2)} ${DEFAULT_TEXT['page.help.perBid']})
            </div>
        </li>
        `)
    }

    const replacementTags = {
        'TAG_REPLACE_CREDIT_PACKS_COUNT': CREDIT_PACKS?.length || '',
        'TAG_REPLACE_CREDIT_PACKS_LOOP': creditPackageText,
        'TAG_REPLACE_BID_PACKS_COUNT': BID_PACK_AUCTION_DATA?.length || ''
    }

    for (const key in replacementTags) {
        const value = replacementTags[key]
        html = html?.replace(key, value)
    }

    //MAIN GROUP PAGE
    if (groupPage) {
        title = groupInfo?.title;
        html = groupInfo?.intro;
    }

    return (
        <div className="helpLeft column">
            <h1>{title}</h1>
            <div className="helpContent column">
                <div className="html column" dangerouslySetInnerHTML={{ __html: html }} />

                {(showBidsPacks && BID_PACK_AUCTION_DATA?.length) ?
                    BID_PACK_AUCTION_DATA.map(({ SKU, bid_amount }) =>
                        <div className='bidsPack row' key={SKU}>
                            <ProductImage SKU={SKU} TITLE={SKU} large={true} />
                            <div className="bidsAmount">
                                <span>{bid_amount}</span> {DEFAULT_TEXT['page.help.bidsPack']}
                            </div>
                        </div>)
                    :
                    ''
                }

                {contactUsTab && <HelpContact />}

                {avatars && <HelpAvatars />}

                {brands && <HelpBrands />}

                <NextTopic />

            </div>
            <HelpRelated />
        </div>
    );
})

export default HelpContent;