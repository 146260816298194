import React, { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { FooterBox } from '../style/main';
import Logo from './snippet/Logo';
import { useSelector } from 'react-redux';
import { DEFAULT_TEXT } from '../content';
import { Link } from 'react-router-dom';
import { BRAND_ALL_TEXT, BRAND_LIST } from '../content/brands';
import { assetPrefix } from './helpers';
import { helpSections } from '../content/help';

const FooterDealTab = React.memo(({ BRAND }) => {

    const navigate = useNavigate();

    const allText = BRAND_ALL_TEXT[process.env.REACT_APP_ISO_CODE];
    let allTab = BRAND === allText.toUpperCase();

    let name = allTab ? allText : BRAND_LIST[BRAND]

    if (!name) {
        return null
    }

    const viewDeals = useCallback(() => {

        window.scrollTo(0, 0)

        navigate({
            pathname: '/',
            search: allTab ? '' : `?filter=${BRAND}`
        });



    }, [BRAND, allTab]);


    return (
        <li onClick={viewDeals}>
            {name}
        </li>
    );
})


const Footer = React.memo(({ auctionPage, soldPage, helpPage }) => {

    const uid = useSelector((state) => state.user.uid)
    const userLoaded = useSelector((state) => state.user.userLoaded)
    const brands = useSelector((state) => state.auctions.brands)

    return (
        <FooterBox className={`${auctionPage || soldPage ? 'mobilePadding' : ''} row`}>

            <div className='wrap column'>

                <div className="menu row">

                    <div className="menuBlock column">
                        <h3>{DEFAULT_TEXT['footer.title.deals']}</h3>

                        <ul className="column">

                            {brands.map(({ brand }, i) => <FooterDealTab key={i} BRAND={brand} helpPage={helpPage} />)}


                        </ul>

                    </div>

                    <div className="menuBlock column">
                        <h3>{DEFAULT_TEXT['footer.title.help']}</h3>

                        <ul className="column">

                            {helpSections.map(({ title, path }) =>
                                <Link key={path} to={`${DEFAULT_TEXT['url.help']}/${path}`}>
                                    <li>{title}</li>
                                </Link>
                            )}

                        </ul>

                    </div>

                    <div className="menuBlock column">

                        <div className="flagBox row">
                            <img src={assetPrefix({ path: `flags/${DEFAULT_TEXT['footer.flag.usa']}.svg` })} className='flag' />
                            <span>{DEFAULT_TEXT['footer.language.english']}</span>
                        </div>

                        {(!uid && userLoaded) &&
                            <div className="smallBtns row">

                                <Link to={DEFAULT_TEXT['url.signin']} className='smallBtn'>
                                    {DEFAULT_TEXT['button.signin']}
                                </Link>

                                <Link to={DEFAULT_TEXT['url.signup']} className='smallBtn'>
                                    {DEFAULT_TEXT['button.join']}
                                </Link>

                            </div>
                        }

                        <div className={`copyright ${uid ? 'signedIn' : ''}`}>

                            &copy; {new Date().getFullYear()} {process.env.REACT_APP_URL_SITE_NAME}.com

                        </div>

                    </div>

                </div>

                <div className="footerBottom row">

                    <Logo />

                    <div className="footerBottomRight row">

                        <Link to={DEFAULT_TEXT['url.terms']}>
                            {DEFAULT_TEXT['terms.text']}
                        </Link>

                        <Link to={DEFAULT_TEXT['url.privacy']}>
                            {DEFAULT_TEXT['privacy.text']}
                        </Link>

                    </div>

                </div>

            </div>

        </FooterBox>
    );
})

export default Footer;