import { DEFAULT_TEXT, DISPLAY_NAME_MAX_CHARACTERS } from ".";
import { assetPrefix } from "../components/helpers";
import { HELP_GROUPS, helpPaths } from "./helpPaths";

const groupIntros = {
    [HELP_GROUPS.GETTING_STARTED]: `This helpful guide covers all the basics you need to learn to create your account, add bids and start winning auctions in just a few minutes.`,
    [HELP_GROUPS.AUCTIONS]: `In this guide you will learn all the information regarding ${process.env.REACT_APP_URL_SITE_NAME} auctions. It is important to understand our auction process in order to develop your proper winning strategy. ${process.env.REACT_APP_URL_SITE_NAME} auctions are slightly different than other auction websites and offer a greater potential to earn even larger savings on items.`,
    [HELP_GROUPS.PRODUCTS]: `All ${process.env.REACT_APP_URL_SITE_NAME} are brand new and factory sealed. We stock only the highest quality name brand items. Learn more about all the types of products we keep in stock on a continual basis.`,
    [HELP_GROUPS.SHIPPING]: `Learn about shipping costs and shipping methods. ${process.env.REACT_APP_URL_SITE_NAME} offers 100% free shipping on all items. We use only trusted carriers such as FedEx or UPS to ship your packages.`,
    [HELP_GROUPS.PAYMENT_METHODS]: `This guide covers the information regarding the types of payment methods we accept and how to use them. We use only the highest industry security standards when it comes to online shopping. You can shop with confidence when purchasing items from ${process.env.REACT_APP_URL_SITE_NAME}.`,
    [HELP_GROUPS.YOUR_ACCOUNT]: `This guide covers all the information you need to manage your account. Select a topic to learn more.`,
    [HELP_GROUPS.RESTRICTIONS]: `In order to create a fair auction environment, ${process.env.REACT_APP_URL_SITE_NAME} has certain restrictions for bidding and winning. You can learn more about these restrictions within this guide.`
}

const helpSections = [{
    title: 'Getting Started',
    key: HELP_GROUPS.GETTING_STARTED,
    path: helpPaths[HELP_GROUPS.GETTING_STARTED],
    intro: groupIntros[HELP_GROUPS.GETTING_STARTED]
}, {
    title: 'Auctions',
    key: HELP_GROUPS.AUCTIONS,
    path: helpPaths[HELP_GROUPS.AUCTIONS],
    intro: groupIntros[HELP_GROUPS.AUCTIONS]
}, {
    title: 'Products',
    key: HELP_GROUPS.PRODUCTS,
    path: helpPaths[HELP_GROUPS.PRODUCTS],
    intro: groupIntros[HELP_GROUPS.PRODUCTS]
}, {
    title: 'Shipping',
    key: HELP_GROUPS.SHIPPING,
    path: helpPaths[HELP_GROUPS.SHIPPING],
    intro: groupIntros[HELP_GROUPS.SHIPPING]
}, {
    title: 'Payment Methods',
    key: HELP_GROUPS.PAYMENT_METHODS,
    path: helpPaths[HELP_GROUPS.PAYMENT_METHODS],
    intro: groupIntros[HELP_GROUPS.PAYMENT_METHODS]
}, {
    title: 'Your Account',
    key: HELP_GROUPS.YOUR_ACCOUNT,
    path: helpPaths[HELP_GROUPS.YOUR_ACCOUNT],
    intro: groupIntros[HELP_GROUPS.YOUR_ACCOUNT]
}, {
    title: 'Restrictions',
    key: HELP_GROUPS.RESTRICTIONS,
    path: helpPaths[HELP_GROUPS.RESTRICTIONS],
    intro: groupIntros[HELP_GROUPS.RESTRICTIONS]
}, {
    title: 'Contact Us',
    key: HELP_GROUPS.CONTACT_US,
    path: helpPaths[HELP_GROUPS.CONTACT_US],
    intro: groupIntros[HELP_GROUPS.CONTACT_US],
}]

const urlSlug = (s, opt) => {
    s = String(s);
    opt = Object(opt);

    var defaults = {
        'delimiter': '-',
        'limit': undefined,
        'lowercase': true,
        'replacements': {},
        'transliterate': (typeof (XRegExp) === 'undefined') ? true : false
    };

    // Merge options
    for (var k in defaults) {
        if (!opt.hasOwnProperty(k)) {
            opt[k] = defaults[k];
        }
    }

    var char_map = {
        // Latin
        'À': 'A', 'Á': 'A', 'Â': 'A', 'Ã': 'A', 'Ä': 'A', 'Å': 'A', 'Æ': 'AE', 'Ç': 'C',
        'È': 'E', 'É': 'E', 'Ê': 'E', 'Ë': 'E', 'Ì': 'I', 'Í': 'I', 'Î': 'I', 'Ï': 'I',
        'Ð': 'D', 'Ñ': 'N', 'Ò': 'O', 'Ó': 'O', 'Ô': 'O', 'Õ': 'O', 'Ö': 'O', 'Ő': 'O',
        'Ø': 'O', 'Ù': 'U', 'Ú': 'U', 'Û': 'U', 'Ü': 'U', 'Ű': 'U', 'Ý': 'Y', 'Þ': 'TH',
        'ß': 'ss',
        'à': 'a', 'á': 'a', 'â': 'a', 'ã': 'a', 'ä': 'a', 'å': 'a', 'æ': 'ae', 'ç': 'c',
        'è': 'e', 'é': 'e', 'ê': 'e', 'ë': 'e', 'ì': 'i', 'í': 'i', 'î': 'i', 'ï': 'i',
        'ð': 'd', 'ñ': 'n', 'ò': 'o', 'ó': 'o', 'ô': 'o', 'õ': 'o', 'ö': 'o', 'ő': 'o',
        'ø': 'o', 'ù': 'u', 'ú': 'u', 'û': 'u', 'ü': 'u', 'ű': 'u', 'ý': 'y', 'þ': 'th',
        'ÿ': 'y',

        // Latin symbols
        '©': '(c)',

        // Greek
        'Α': 'A', 'Β': 'B', 'Γ': 'G', 'Δ': 'D', 'Ε': 'E', 'Ζ': 'Z', 'Η': 'H', 'Θ': '8',
        'Ι': 'I', 'Κ': 'K', 'Λ': 'L', 'Μ': 'M', 'Ν': 'N', 'Ξ': '3', 'Ο': 'O', 'Π': 'P',
        'Ρ': 'R', 'Σ': 'S', 'Τ': 'T', 'Υ': 'Y', 'Φ': 'F', 'Χ': 'X', 'Ψ': 'PS', 'Ω': 'W',
        'Ά': 'A', 'Έ': 'E', 'Ί': 'I', 'Ό': 'O', 'Ύ': 'Y', 'Ή': 'H', 'Ώ': 'W', 'Ϊ': 'I',
        'Ϋ': 'Y',
        'α': 'a', 'β': 'b', 'γ': 'g', 'δ': 'd', 'ε': 'e', 'ζ': 'z', 'η': 'h', 'θ': '8',
        'ι': 'i', 'κ': 'k', 'λ': 'l', 'μ': 'm', 'ν': 'n', 'ξ': '3', 'ο': 'o', 'π': 'p',
        'ρ': 'r', 'σ': 's', 'τ': 't', 'υ': 'y', 'φ': 'f', 'χ': 'x', 'ψ': 'ps', 'ω': 'w',
        'ά': 'a', 'έ': 'e', 'ί': 'i', 'ό': 'o', 'ύ': 'y', 'ή': 'h', 'ώ': 'w', 'ς': 's',
        'ϊ': 'i', 'ΰ': 'y', 'ϋ': 'y', 'ΐ': 'i',

        // Turkish
        'Ş': 'S', 'İ': 'I', 'Ç': 'C', 'Ü': 'U', 'Ö': 'O', 'Ğ': 'G',
        'ş': 's', 'ı': 'i', 'ç': 'c', 'ü': 'u', 'ö': 'o', 'ğ': 'g',

        // Russian
        'А': 'A', 'Б': 'B', 'В': 'V', 'Г': 'G', 'Д': 'D', 'Е': 'E', 'Ё': 'Yo', 'Ж': 'Zh',
        'З': 'Z', 'И': 'I', 'Й': 'J', 'К': 'K', 'Л': 'L', 'М': 'M', 'Н': 'N', 'О': 'O',
        'П': 'P', 'Р': 'R', 'С': 'S', 'Т': 'T', 'У': 'U', 'Ф': 'F', 'Х': 'H', 'Ц': 'C',
        'Ч': 'Ch', 'Ш': 'Sh', 'Щ': 'Sh', 'Ъ': '', 'Ы': 'Y', 'Ь': '', 'Э': 'E', 'Ю': 'Yu',
        'Я': 'Ya',
        'а': 'a', 'б': 'b', 'в': 'v', 'г': 'g', 'д': 'd', 'е': 'e', 'ё': 'yo', 'ж': 'zh',
        'з': 'z', 'и': 'i', 'й': 'j', 'к': 'k', 'л': 'l', 'м': 'm', 'н': 'n', 'о': 'o',
        'п': 'p', 'р': 'r', 'с': 's', 'т': 't', 'у': 'u', 'ф': 'f', 'х': 'h', 'ц': 'c',
        'ч': 'ch', 'ш': 'sh', 'щ': 'sh', 'ъ': '', 'ы': 'y', 'ь': '', 'э': 'e', 'ю': 'yu',
        'я': 'ya',

        // Ukrainian
        'Є': 'Ye', 'І': 'I', 'Ї': 'Yi', 'Ґ': 'G',
        'є': 'ye', 'і': 'i', 'ї': 'yi', 'ґ': 'g',

        // Czech
        'Č': 'C', 'Ď': 'D', 'Ě': 'E', 'Ň': 'N', 'Ř': 'R', 'Š': 'S', 'Ť': 'T', 'Ů': 'U',
        'Ž': 'Z',
        'č': 'c', 'ď': 'd', 'ě': 'e', 'ň': 'n', 'ř': 'r', 'š': 's', 'ť': 't', 'ů': 'u',
        'ž': 'z',

        // Polish
        'Ą': 'A', 'Ć': 'C', 'Ę': 'e', 'Ł': 'L', 'Ń': 'N', 'Ó': 'o', 'Ś': 'S', 'Ź': 'Z',
        'Ż': 'Z',
        'ą': 'a', 'ć': 'c', 'ę': 'e', 'ł': 'l', 'ń': 'n', 'ó': 'o', 'ś': 's', 'ź': 'z',
        'ż': 'z',

        // Latvian
        'Ā': 'A', 'Č': 'C', 'Ē': 'E', 'Ģ': 'G', 'Ī': 'i', 'Ķ': 'k', 'Ļ': 'L', 'Ņ': 'N',
        'Š': 'S', 'Ū': 'u', 'Ž': 'Z',
        'ā': 'a', 'č': 'c', 'ē': 'e', 'ģ': 'g', 'ī': 'i', 'ķ': 'k', 'ļ': 'l', 'ņ': 'n',
        'š': 's', 'ū': 'u', 'ž': 'z'
    };

    // Make custom replacements
    for (var k in opt.replacements) {
        s = s.replace(RegExp(k, 'g'), opt.replacements[k]);
    }

    // Transliterate characters to ASCII
    if (opt.transliterate) {
        for (var k in char_map) {
            s = s.replace(RegExp(k, 'g'), char_map[k]);
        }
    }

    // Replace non-alphanumeric characters with our delimiter
    var alnum = (typeof (XRegExp) === 'undefined') ? RegExp('[^a-z0-9]+', 'ig') : XRegExp('[^\\p{L}\\p{N}]+', 'ig');
    s = s.replace(alnum, opt.delimiter);

    // Remove duplicate delimiters
    s = s.replace(RegExp('[' + opt.delimiter + ']{2,}', 'g'), opt.delimiter);

    // Truncate slug to max. characters
    s = s.substring(0, opt.limit);

    // Remove delimiter from ends
    s = s.replace(RegExp('(^' + opt.delimiter + '|' + opt.delimiter + '$)', 'g'), '');

    return opt.lowercase ? s.toLowerCase() : s;
}


const paymentMethodBrands = 'Visa, Mastercard, American Express, Discover, PayPal and Google Pay.'

const helpTopics = [{
    title: `Creating your account`,
    html: `
<p>
Creating your DirectBrandDeals account is fast, free and easy. After creating your account you can instantly add bids to your account to start bidding and winning items.
</p>

<img src=${assetPrefix({ path: 'help/signup-1.jpg' })} class="signup" />

<p>
<strong><u>Follow these simple steps to create your account:</u></strong>
</p>

<ol>
    <li>
        <div class="helpCircle">1</div>
        <div><strong>Have a Gmail account?</strong> Connect your Gmail account and use it to sign in.</div>
    </li>
    <li>
        <div class="helpCircle">2</div>
        <div><strong>Have a Facebook account?</strong> Connect your Facebook account and use it to sign in.</div>
    </li>
    <li>
        <div class="helpCircle">3</div>
        <div><strong>Enter your email address:</strong> You will use your email address to sign into your account later.</div>
    </li>
    <li>
        <div class="helpCircle">4</div>
        <div><strong>Create a strong password:</strong> Your password must at least 8 characters. Contain lowercase letters (a-z), at least one uppercase letter (A-Z) and at least one special character (e.g. !@#$%^&*).</div>
    </li>
    <li>
        <div class="helpCircle">4</div>
        <div><strong>Select Continue</strong></div>
    </li>
</ol>

<p>
After creating your account you will instantly be redirected to the members area. Use your newly created account credentials to <a href="${DEFAULT_TEXT['url.signin']}">sign into your account</a> using your email and password at any time.
</p>

<p>
Ready to get started? <a href="${DEFAULT_TEXT['url.signup']}">signup free now!</a>
</p>

`,
    group: HELP_GROUPS.GETTING_STARTED
}, {
    title: `Select your avatar`,
    html: `
<p>
    Avatars are a fun way for you to be recognized when you're bidding on auctions. During your account creation you will be prompted to select from one of our <strong>100+ available avatar icons</strong>. Each time you place a bid your avatar will appear in the auctions bid history. You can also switch and save to a new avatar at any time from within your my account area.
</p>
    `,
    group: HELP_GROUPS.GETTING_STARTED,
    avatars: true
}, {
    title: `Create a display name`,
    html: `
<p>
    Your display name will appear on auctions when you place a bid. You can use your real name, nickname or use any display name you would like. You can easily change your display name within your my account area in the future.
</p>

<img src=${assetPrefix({ path: 'help/signup-display-name.jpg' })} class="signup" />

<ol>
    <li>
        <div class="helpCircle">1</div>
        <div><strong>Enter a display name:</strong> Display names must be a minimum of 3 characters in length. A maximum of ${DISPLAY_NAME_MAX_CHARACTERS} characters in length. Also note that display names cannot contain any offensive or abusive language. </div>
    </li>
    <li>
        <div class="helpCircle">2</div>
        <div><strong>Select continue</strong> to save your newly created display name.</div>
    </li>
</ol>

    `,
    group: HELP_GROUPS.GETTING_STARTED,
}, {
    title: `Adding Bids`,
    html: `
<p>
    It takes only a few seconds to add bids to your account. Bids can be purchased with major credit cards brands and forms of payments including:
</p>
<p>
    ${paymentMethodBrands}  
</p>
<p>
    Once purchased your bids will be instantly added to your account. After adding bids you will be able to start bidding and winning on auctions. You can also add additional bids to your account at any time.
</p>

<p><strong>Step 1:</strong> Select a Bid Package</p>

<img src=${assetPrefix({ path: 'help/addbids.jpg' })} class="addbids" />

<p>
    Bids come in <strong>TAG_REPLACE_CREDIT_PACKS_COUNT main packages</strong>:
</p>

<ol>
    TAG_REPLACE_CREDIT_PACKS_LOOP
</ol>

<p><strong>Step 2:</strong> Enter your billing information</p>

<p>
    ${process.env.REACT_APP_URL_SITE_NAME} accepts all forms of major credit and debit cards as valid forms of payments.
</p>

<p><strong>Accepted payment methods:</strong></p>

<img src=${assetPrefix({ path: 'help/acceptedcards.jpg' })} class="acceptedcards noBorder" />

<img src=${assetPrefix({ path: 'help/creditcard.jpg' })} class="creditcard" />

<p>
    Complete your bids purchase by entering your billing details on our <strong>secure checkout form:</strong>
</p>

<ol>
    <li>
        <div class="helpCircle">1</div>
        <div>Your chosen bids package from the previous step</div>
    </li>
    <li>
        <div class="helpCircle">2</div>
        <div>Enter your valid credit or debit card number</div>
    </li>
    <li>
        <div class="helpCircle">3</div>
        <div>Enter the cards 2 digit expiry month and 2 digit expiry year. (Example: 01/26)</div>
    </li>
    <li>
        <div class="helpCircle">4</div>
        <div>Enter the 3 digit "CVV" security code. (Usually found on the back of your credit card)</div>
    </li>
    <li>
        <div class="helpCircle">5</div>
        <div>Select the billing country</div>
    </li>
    <li>
        <div class="helpCircle">6</div>
        <div>Enter the billing ZIP code related to the card</div>
    </li>
</ol>

<p>
    That's it! Your bids will be instantly added to your account. Congratulations now ready to start <strong>bidding and winning.</strong>
</p>

`,
    group: HELP_GROUPS.GETTING_STARTED
}, {
    title: `Bidding on a product`,
    html: `
    <p>
        Once you have added bids to your account. You're now ready to start bidding and winning products. Your current total remaining bid amount will be viewable in the upper right corner of your screen while you're signed in.
    </p>

    <img src=${assetPrefix({ path: 'help/bidzoom.jpg' })} class="bidzoom noBorder noRadius" />

    <p>
        <strong>Step 1:</strong> Start by selecting an that you would like to bid on. There are 150+ live <a href="/">${process.env.REACT_APP_URL_SITE_NAME} auctions</a> active at all times throughout the day to choose from.
    </p>

    <img src=${assetPrefix({ path: 'help/bidauction.jpg' })} class="bidauction noBorder noRadius" />

    <p>
        <strong>Step 2:</strong> <strong>Place your bid!</strong> Simply place your bids by selecting the "BID" button located on any auction.
    </p>

    <img src=${assetPrefix({ path: 'help/bidbutton.jpg' })} class="bidbutton noBorder noRadius" />

    <p>
        <strong>Step 3:</strong> Congratulations! With each bid placed you will become the <strong>new high bidder</strong>.
    </p>

    <img src=${assetPrefix({ path: 'help/bidauctionafterbid.jpg' })} class="bidbutton noBorder noRadius" />

    <ol>
        <li>
            <div class="helpCircle">1</div>
            <div>Each bid placed increases the auction price by $0.01</div>
        </li>
        <li>
            <div class="helpCircle">2</div>
            <div>The last person to place a bid is automatically the highest bidder</div>
        </li>
        <li>
            <div class="helpCircle">3</div>
            <div>The countdown will continue to tick down. Each bid placed under 10 seconds, will return the countdown to 10 seconds.</div>
        </li>
    </ol>

    <img src=${assetPrefix({ path: 'help/bidzoomdeduct.jpg' })} class="bidzoomdeduct noBorder noRadius" />

    <ol>
        <li>
            <div class="helpCircle">4</div>
            <div> With each bid placed, <strong><u>one single bid</u></strong> will be deducted from your account.</div>
        </li>
    </ol>

    <p>
        Great! You're now ready to start bidding.
    </p>

    `,
    group: HELP_GROUPS.GETTING_STARTED
}, {
    title: `Winning an auction`,
    html: `

    <p>
       <strong>Get ready to win!</strong> You can win auctions in just a few simple steps.
    </p>

    <img src=${assetPrefix({ path: 'help/winauction1.jpg' })} class="winauction1 noBorder noRadius" />

    <ol>
        <li>
            <div class="helpCircle">1</div>
            <div>All auctions start at $0.00 and have <strong><u>no minimum reserve price.</u></strong></div>
        </li>
        <li>
            <div class="helpCircle">2</div>
            <div>Each auction has a general time limit and counts down <strong>1 second at a time.</strong></div>
        </li>
    </ol>

    <img src=${assetPrefix({ path: 'help/winauction-increase1.jpg' })} class="winauction-increase1 noBorder noRadius" />

    <ol>
        <li>
            <div class="helpCircle">3</div>
            <div>Each bid placed raises the auction price <strong>$0.01 per bid.</strong></div>
        </li>
    </ol>

    <img src=${assetPrefix({ path: 'help/winauction-biddinghistory.jpg' })} class="winauction-biddinghistory noBorder noRadius" />

    <ol>
        <li>
            <div class="helpCircle">4</div>
            <div>As the auction timer counts down, new bids can continue to be placed. With each bid <strong>continuing to increase the auction price only $0.01 each time.</strong></div>
        </li>
        <li>
            <div class="helpCircle">5</div>
            <div>The last person to place a bid on an auction is the <strong>current high bidder.</strong></div>
        </li>
    </ol>

    <img src=${assetPrefix({ path: 'help/winauction-backto10.jpg' })} class="winauction-backto10 noBorder noRadius" />

    <ol>
        <li>
            <div class="helpCircle">6</div>
            <div>When the countdown timer has 10 seconds or less remaining, and a new bid is placed. <strong>The auction countdown timer resets to 10 seconds</strong>, then continues to count down again. This gives a chance for all bidders to place their final bid(s) on the auction.</div>
        </li>
    </ol>

    <img src=${assetPrefix({ path: 'help/winauction-won.jpg' })} class="winauction-won noBorder noRadius" />

    <ol>
        <li>
            <div class="helpCircle">7</div>
            <div>If no new bids are placed in the last 10 seconds and you're the high bidder. <strong><u>You win!</u></strong></div>
        </li>
    </ol>

    <img src=${assetPrefix({ path: 'help/winauction-payauction.jpg' })} class="winauction-payauction noBorder noRadius" />

    <ol>
        <li>
            <div class="helpCircle">8</div>
            <div>All that is left to do is <strong>pay the final auction price.</strong> Then we express ship you your winning product.</div>
        </li>
    </ol>

    <p><strong>It's just that easy!</strong> You can start winning auctions today.</p>


    `,
    group: HELP_GROUPS.GETTING_STARTED
}, {
    title: `Countdown timer`,
    html: `
    
    <p>
        Each auction has a general time limit and counts down <strong>1 second at a time.</strong> The total exact auction time will be dependent on when all final bids have been placed.
    </p>

    <img src=${assetPrefix({ path: 'help/countdowntimer-1.jpg' })} class="countdowntimer-1 noBorder noRadius" />

    <ol>
        <li>
            <div class="helpCircle">1</div>
            <div>The main difference between regular auction countdown timers and ${process.env.REACT_APP_URL_SITE_NAME} auction countdown timers. Is that when the countdown timer has 10 seconds or less remaining, and a new bid is placed. <strong>The auction countdown timer will reset to 10 seconds</strong>, then continue to count down again. This gives a chance for all bidders to place their final bid(s) on the auction.</div>
        </li>
    </ol>

    <img src=${assetPrefix({ path: 'help/countdowntimer-2.jpg' })} class="countdowntimer-2 noBorder noRadius" />

    <ol>
        <li>
            <div class="helpCircle">2</div>
            <div>Once all final bids have been placed. The auction countdown timer <strong>will reach 00:00:00 and the auction will become sold.</strong> At this time bidding is officially closed and no additional bids can be placed.</div>
        </li>
    </ol>
    
    `,
    group: HELP_GROUPS.AUCTIONS
}, {
    title: `Bidding history`,
    html: `

    <p>Each auction contains a "bidding history". An auctions bidding history displays up to <strong>10 of the last bids.</strong> With details on which user placed a bid and what price they placed their bid at.</p>

    <img src=${assetPrefix({ path: 'help/auctions-biddinghistory.jpg' })} class="auctions-biddinghistory noBorder noRadius" />

    <ol>
        <li>
            <div class="helpCircle">1</div>
            <div>
                <p>Current user bidding history of the auction, listing up to 10 of the last bidders. The user in the <strong>1st spot is the current highest bidder</strong>.</p>
                <p><strong><u>Note:</u></strong> A user cannot bid multiple times in a row and outbid themselves. They must wait for another user to bid, before placing additional bids each time.</p>
            </div>
        </li>
        <li>
            <div class="helpCircle">2</div>
            <div>View up to the last 10 bid price amounts, with <strong>each bid increasing the price of the auction by $0.01.</strong></div>
        </li>
    </ol>
    
    `,
    group: HELP_GROUPS.AUCTIONS
}, {
    title: `Auction price`,
    html: `

    <p>${process.env.REACT_APP_URL_SITE_NAME} auctions work slightly different then most auctions. What is important to remember is that each bid increases the price of the auction by <strong>only $0.01 at a time.</strong> This helps to keep auction prices low, and the ability for the auction winner to receive the best possible savings.</p>

    <img src=${assetPrefix({ path: 'help/auctions-auctionprice.jpg' })} class="auctions-auctionprice noBorder noRadius" />

    <ol>
        <li>
            <div class="helpCircle">1</div>
            <div>All auctions <strong>start at $0.00</strong> and have <strong><u>no minimum reserve price.</u></strong></div>
        </li>
    </ol>

    <img src=${assetPrefix({ path: 'help/auctions-auctionprice-2.jpg' })} class="auctions-auctionprice-2 noBorder noRadius" />
    
    <ol>
        <li>
            <div class="helpCircle">2</div>
            <div>Each bid placed increases the price of the auction by <strong>only $0.01 at a time</strong></div>
        </li>
        <li>
            <div class="helpCircle">3</div>
            <div><strong>Each bidder can only place 1 bid at a time.</strong> The highest bidder cannot outbid themselves and increase the auction price. They must wait to be outbid before continuing to place addition bids on the auction.</div>
        </li>
        </ol>

    <img src=${assetPrefix({ path: 'help/auctions-auctionprice-3.jpg' })} class="auctions-auctionprice-3 noBorder noRadius" />
    
    <ol>
        <li>
            <div class="helpCircle">4</div>
            <div>When all bids have been placed and the auction countdown timer has ended. The <strong>final auction sold price is displayed</strong> and no new bids can be placed.</div>
        </li>
    </ol>

    <img src=${assetPrefix({ path: 'help/auctions-auctionprice-4.jpg' })} class="auctions-auctionprice-4 noBorder noRadius" />
    
    <ol>
        <li>
            <div class="helpCircle">5</div>
            <div>If you're the last bidder on the auction, you've won! <strong>Displayed is the total final auction price that you will pay.</strong> There is no additional fees, taxes or shipping costs.</strong></div>
        </li>
        <li>
            <div class="helpCircle">6</div>
            <div><strong>An "Add to cart" option will appear on your winning item.</strong> Simply add the item to your cart, pay the final auction price, enter your shipping details on the next steps, and then we will express ship your winning item to directly to you.</strong></div>
        </li>
    </ol>

    `,
    group: HELP_GROUPS.AUCTIONS
}, {
    title: `Signing in`,
    html: `

    <p><a href="${DEFAULT_TEXT['url.signin']}">Sign into your account</a> using a social signin method or your email and password associated with your account.</p>
    
    <img src=${assetPrefix({ path: 'help/youraccount-signin.jpg' })} class="youraccount-signin noBorder noRadius" />

    <ol>
        <li>
            <div class="helpCircle">1</div>
            <div><strong>Google Signin:</strong> For users who joined using the Google signin method.</div>
        </li>
        <li>
            <div class="helpCircle">2</div>
            <div><strong>Facebook Signin:</strong> For users who joined using the Facebook signin method.</div>
        </li>
        <li>
            <div class="helpCircle">2</div>
             <div><strong>Email and password:</strong> For users who joined using their email address and creating a password.</div>
        </li>
        <li>
            <div class="helpCircle">3</div>
            <div><strong>Select ${DEFAULT_TEXT['page.auth0.button.signin']}</strong> if you're using your email and created password to complete your account signin.</div>
        </li>
    </ol>

    <p><a href="${DEFAULT_TEXT['url.signin']}"><strong>Sign into your account now</strong></a></p>

    `,
    group: HELP_GROUPS.YOUR_ACCOUNT
}, {
    title: `Forgot your password`,
    html: `
    
        <p><a href="${DEFAULT_TEXT['url.forgotPassword']}">Forgot your password?</a> You can reset your password in a <strong>few simple steps.</strong></p>

        <img src=${assetPrefix({ path: 'help/youraccount-forgotpassword.jpg' })} class="youraccount-forgotpassword noBorder noRadius" />

        <ol>
            <li>
                <div class="helpCircle">1</div>
                <div>Enter your email</div>
            </li>
            <li>
                <div class="helpCircle">2</div>
                <div>Select ${DEFAULT_TEXT['button.forgotPassword']}</div>
            </li>
        </ol>

        <img src=${assetPrefix({ path: 'help/youraccount-forgotpassword-2.jpg' })} class="youraccount-forgotpassword-2 noBorder noRadius" />

        <ol>
            <li>
                <div class="helpCircle">3</div>
                <div><strong>Check your email for further insuctructions:</strong> If an account with the email you entered is registered, a reset password email will be sent to you. (Don't forget to check your promotions or spam folder if the email didn't arrive in your inbox). Then <strong>Follow the link and instuctions sent to your email</strong> to finish resetting your password.</div>
            </li>
        </ol>

        <p><a href="${DEFAULT_TEXT['url.forgotPassword']}"><strong>Need to reset your password?</strong></a></p>

        
    `,
    group: HELP_GROUPS.YOUR_ACCOUNT
}, {
    title: `Switching your avatar`,
    html: `
    
    <p>You can choose to switch to a <strong>new avatar</strong> at any time. Once you're signed into your account. <br>Visit your <a href="${DEFAULT_TEXT['url.myAccount']}">My account page.</a></p>

    <img src=${assetPrefix({ path: 'help/youraccount-changeavatar.jpg' })} class="youraccount-changeavatar noBorder noRadius" />

    <ol>
        <li>
            <div class="helpCircle">1</div>
            <div>Select your <strong>current avatar</strong></div>
        </li>
    </ol>

    <img src=${assetPrefix({ path: 'help/youraccount-changeavatar-2.jpg' })} class="youraccount-changeavatar-2 noBorder noRadius" />

    <ol>
        <li>
            <div class="helpCircle">2</div>
            <div><strong>Choose a new avatar</strong></div>
        </li>
        <li>
            <div class="helpCircle">3</div>
            <div>Select <strong>Continue</strong></div>
        </li>
    </ol>

    <img src=${assetPrefix({ path: 'help/youraccount-changeavatar-3.jpg' })} class="youraccount-changeavatar-3 noBorder noRadius" />

    <ol>
        <li>
            <div class="helpCircle">4</div>
            <div>Your <strong>new avatar will be saved</strong> and display on any <strong>new auctions</strong> you bid on.</div>
        </li>
    </ol>

    `,
    group: HELP_GROUPS.YOUR_ACCOUNT
}, {
    title: `Changing your display name`,
    html: `
    
    <p>You can choose to switch to a <strong>new display name</strong> at any time. Once you're signed into your account. <br>Visit your <a href="${DEFAULT_TEXT['url.myAccount']}">My account page.</a></p>

    <img src=${assetPrefix({ path: 'help/youraccount-changingyourdisplayname.jpg' })} class="youraccount-changingyourdisplayname noBorder noRadius" />

    <ol>
        <li>
            <div class="helpCircle">1</div>
            <div>Select the <strong>Profile</strong> section</div>
        </li>
    </ol>

    <img src=${assetPrefix({ path: 'help/youraccount-changingyourdisplayname-2.jpg' })} class="youraccount-changingyourdisplayname-2 noBorder noRadius" />

    <ol>
        <li>
            <div class="helpCircle">2</div>
            <div>Select <strong>Edit</strong> next to your current display name</div>
        </li>
    </ol>

    <img src=${assetPrefix({ path: 'help/youraccount-changingyourdisplayname-3.jpg' })} class="youraccount-changingyourdisplayname-3 noBorder noRadius" />

    <ol>
        <li>
            <div class="helpCircle">3</div>
            <div>Enter a <strong>new display name</strong></div>
        </li>
        <li>
            <div class="helpCircle">4</div>
            <div>Select <strong>Save</strong></div>
        </li>
    </ol>

    <img src=${assetPrefix({ path: 'help/youraccount-changingyourdisplayname-4.jpg' })} class="youraccount-changingyourdisplayname-4 noBorder noRadius" />

    <ol>
        <li>
            <div class="helpCircle">5</div>
            <div>Your <strong>display name will be saved</strong> and display on any <strong>new auctions</strong> you bid on.</div>
        </li>
    </ol>

    
    `,
    group: HELP_GROUPS.YOUR_ACCOUNT
}, {
    title: `Adding additional bids`,
    html: `
    
        <p>You can add additional bids to your account at any time. It is a good idea to keep additional bids in your account at all times, so you don't run out while placing bids on a live auction.</p>

        <p><strong>Step 1:</strong> Select the <strong>bids icon</strong> in the upper right hand side of your screen</p>

        <img src=${assetPrefix({ path: 'help/myaccount-addingadditionalbids.jpg' })} class="myaccount-addingadditionalbids noBorder noRadius" />
        
        <p><strong>Step 2:</strong> Select a Bid Package</p>
        
        <img src=${assetPrefix({ path: 'help/addbids.jpg' })} class="addbids" />
        
        <p>
            Choose from the <strong>TAG_REPLACE_CREDIT_PACKS_COUNT main packages</strong>:
        </p>
        
        <ol>
            TAG_REPLACE_CREDIT_PACKS_LOOP
        </ol>

        <p><strong>Step 4:</strong> Choose to use a credit card on file or add a new credit card (If you don't have a credit card on file, you can skip this step)</p>

        <img src=${assetPrefix({ path: 'help/myaccount-addingadditionalbids-choosecreditcard.jpg' })} class="myaccount-addingadditionalbids-choosecreditcard noBorder noRadius" />

        <ol>
            <li>
                <div class="helpCircle">1</div>
                <div>Choose this option if <strong>you already have a credit card</strong> on file. Then select the <strong>"Start Bidding"</strong> button. Your bids will be automatically added to your account and you can skip the next steps.</div>
            </li>
            <li>
                <div class="helpCircle">2</div>
                <div>Choose this option if you want to use a <strong>new credit card.</strong> Then select the <strong>"Add New Card"</strong> button.</div>
            </li>
        </ol>

        <p><strong>Step 5:</strong> Add a new credit card (Complete this step if you did not use a saved credit card on the previous step. Or you would like to use a new credit card.)</p>
        <img src=${assetPrefix({ path: 'help/creditcard.jpg' })} class="creditcard" />

        <p>
            Complete your bids purchase by entering your billing details on our <strong>secure checkout form:</strong>
        </p>
        
        <ol>
            <li>
                <div class="helpCircle">1</div>
                <div>Your chosen bids package from the previous step</div>
            </li>
            <li>
                <div class="helpCircle">2</div>
                <div>Enter your valid credit or debit card number</div>
            </li>
            <li>
                <div class="helpCircle">3</div>
                <div>Enter the cards 2 digit expiry month and 2 digit expiry year. (Example: 01/26)</div>
            </li>
            <li>
                <div class="helpCircle">4</div>
                <div>Enter the 3 digit "CVV" security code. (Usually found on the back of your credit card)</div>
            </li>
            <li>
                <div class="helpCircle">5</div>
                <div>Select the billing country</div>
            </li>
            <li>
                <div class="helpCircle">6</div>
                <div>Enter the billing ZIP code related to the card</div>
            </li>
        </ol>

        <p>Then select the <strong>"Start Bidding"</strong> button. Your bids will be automatically added to your account.</p>

    `,
    group: HELP_GROUPS.YOUR_ACCOUNT
}, {
    title: `Shipping info`,
    html: `
    
    <p>You can help speed up your item(s) checkout by saving your shipping info for future use. <br>Visit your <a href="${DEFAULT_TEXT['url.myAccount']}">My account page.</a></p>

    <img src=${assetPrefix({ path: 'help/youraccount-shippinginfo.jpg' })} class="youraccount-shippinginfo noBorder noRadius" />

    <ol>
        <li>
            <div class="helpCircle">1</div>
            <div>Select the <strong>Shipping info</strong> section</div>
        </li>
    </ol>

    <img src=${assetPrefix({ path: 'help/youraccount-shippinginfo-2.jpg' })} class="youraccount-shippinginfo-2 noBorder noRadius" />

    <ol>
        <li>
            <div class="helpCircle">2</div>
            <div>Enter your <strong>shipping info</strong> details</div>
        </li>
        <li>
            <div class="helpCircle">3</div>
            <div>Select <strong>"Save"</strong></div>
        </li>
    </ol>

    <p>That's all! Your <strong>shipping info</strong> will then be successfully saved.</p>

    `,
    group: HELP_GROUPS.YOUR_ACCOUNT
}, {
    title: `View winning auctions`,
    html: `
    
    <p>You can view your <strong>winning auctions</strong> easily. Once you're signed into your account. <br>Visit your <a href="${DEFAULT_TEXT['url.winningAuctions']}"> winning auctions.</a></p>

    <img src=${assetPrefix({ path: 'help/myaccount-winningauctions.jpg' })} class="myaccount-winningauctions noBorder noRadius" />

    <p><strong><u>Claiming your winning auction(s) is simple</u></strong></p>

    <ol>
        <li>
            <div class="helpCircle">1</div>
            <div>Select "Pay Now" on your winning auction</div>
        </li>
        <li>
            <div class="helpCircle">2</div>
            <div>Complete the checkout steps by entering your shipping information and entering your payment information.</div>
        </li>
        <li>
            <div class="helpCircle">3</div>
            <div>Complete payment on your auction. The final price you pay is listed on your auction. There is absolutely no hidden fees or any additional charges.</div>
        </li>
        <li>
            <div class="helpCircle">4</div>
            <div>We process and ship you your order (usually within 24-48 hours). Once shipped you will receive a tracking number for your item sent to your account email.</div>
        </li>
    </ol>

    `,
    group: HELP_GROUPS.YOUR_ACCOUNT
}, {
    title: `Winning Bid Packs`,
    html: `
    
    <p>You can view your winning <strong> Bid Packs</strong> easily. Once you're signed into your account. <br>Visit your <a href="${DEFAULT_TEXT['url.winningAuctions']}"> winning auctions.</a></p>

    <img src=${assetPrefix({ path: 'help/myaccount-winningbidpacks.jpg' })} class="myaccount-winningbidpacks noBorder noRadius" />

    <p><strong><u>Claiming your winning Bid Pack is simple</u></strong></p>

    <ol>
        <li>
            <div class="helpCircle">1</div>
            <div>Select "Pay Now" on your winning auction</div>
        </li>
        <li>
            <div class="helpCircle">2</div>
            <div>Complete your payment information. The final price you pay is listed on your auction. There is absolutely no hidden fees or any additional charges.</div>
        </li>
        <li>
            <div class="helpCircle">3</div>
            <div>Your winning Bid Pack will be automatically added to your account. The amount of bids in your account will increase by the total bids of the winning Bid Pack.</div>
        </li>
    </ol>

    `,
    group: HELP_GROUPS.YOUR_ACCOUNT
}, {
    title: `Reset your password`,
    html: `
    
    <p>To reset your password start by visiting your <a href="${DEFAULT_TEXT['url.myAccount']}">My account page.</a></p>

    <img src=${assetPrefix({ path: 'help/myaccount-resetpassword.jpg' })} class="myaccount-resetpassword noBorder noRadius" />

    <ol>
        <li>
            <div class="helpCircle">1</div>
            <div>Select the <strong>Account</strong> section further down the page</div>
        </li>
    </ol>

    <img src=${assetPrefix({ path: 'help/myaccount-resetpassword-2.jpg' })} class="myaccount-resetpassword-2 noBorder noRadius" />

    <ol>
        <li>
            <div class="helpCircle">2</div>
            <div>Select the <strong>Send password reset email</strong> button</div>
        </li>
        <li>
            <div class="helpCircle">3</div>
            <div>Check your email and follow the password reset instructions.</div>
        </li>
    </ol>



    `,
    group: HELP_GROUPS.YOUR_ACCOUNT
}, {
    title: `Are there any membership fees?`,
    html: `
        <p>Becoming a member is completely <strong>free</strong>. There are no hidden costs or membership fees. Complete payments only when you add bids to your account or are purchasing a winning item.</p>
    `,
    group: HELP_GROUPS.YOUR_ACCOUNT
}, {
    title: `How can I delete my account?`,
    html: `
    <p>You can choose to delete your account at any time. Start by visiting your <a href="${DEFAULT_TEXT['url.myAccount']}">My account page.</a> Warning deleting your account cannot be undone and you will lose any information associated with your account.</p>

    <img src=${assetPrefix({ path: 'help/myaccount-resetpassword.jpg' })} class="myaccount-resetpassword noBorder noRadius" />

    <ol>
        <li>
            <div class="helpCircle">1</div>
            <div>Select the <strong>Account</strong> section further down the page</div>
        </li>
    </ol>

    <img src=${assetPrefix({ path: 'help/youraccount-deleteaccount.jpg' })} class="myaccount-resetpassword-2 noBorder noRadius" />

    <ol>
        <li>
            <div class="helpCircle">2</div>
            <div>Select <strong>"DELETE"</strong> from within your my account tab.</div>
        </li>
    </ol>

    <img src=${assetPrefix({ path: 'help/youraccount-deleteaccount2.jpg' })} class="myaccount-resetpassword-2 noBorder noRadius" />

    <ol>
        <li>
            <div class="helpCircle">3</div>
            <div>Enter the text phrase exactly as written on your screen.</div>
        </li>
        <li>
            <div class="helpCircle">4</div>
            <div>Select <strong>"Confirm"</strong> to confirm your account deletion.</div>
        </li>
    </ol>
    `,
    group: HELP_GROUPS.YOUR_ACCOUNT
}, {
    title: `Are all products brand new?`,
    html: `<p>Yes! All products are <strong>brand new and factory sealed.</strong> We stock only the highest quality name brand items.</p>`,
    group: HELP_GROUPS.PRODUCTS,
    brands: true
}, {
    title: `Returns`,
    html: `

        <p>If your item is defective or damaged during shipping. Contact customer support within 7 days of receiving it to begin processing your return. We will replace it for you at no additional cost.</p>
        
        <p>Once your return has been approved we will email you a pre-paid tracking label.</p>
        
        <p>Include your item in its original packaging and place the tracking label on the outside of your package. Drop off your package at the shipping carrier location provided on the tracking label.</p> 
        
        <p>We will then express ship you a new replacement item within 1-2 business days of receiving your original.</p>

    `,
    group: HELP_GROUPS.PRODUCTS
}, {
    title: `Bids pack`,
    showBidsPacks: true,
    html: `
    
        <p>Winning a Bids Pack is a great way to earn extra bids. Bids Pack auctions work the exact same way as any other auction. Simply be the last bidder before the auction ends, and then pay your final winning price.</p>

        <p>After paying your winning auction price, your newly won bids will be automatically added to your account and can be instantly used to win other auctions. Each Bids Pack has no expiration or limitations.</p>

        <p>Bids Pack auctions are available in <strong>TAG_REPLACE_BID_PACKS_COUNT different sizes:</strong></p>
    `,
    group: HELP_GROUPS.PRODUCTS
}, {
    title: `Where do you ship items to?`,
    html: `

    <p>
        ${process.env.REACT_APP_URL_SITE_NAME} ships within the <strong>USA to all 50 states including Puerto Rico:</strong>
    </p>

    <p>
    ${['Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'District of Columbia', 'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania', 'Puerto Rico', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'].map(x => `${x} <br>`).join('')}
    </p>


    `,
    group: HELP_GROUPS.SHIPPING
}, {
    title: `How much does shipping cost?`,
    html: `
        <p>
            All ${process.env.REACT_APP_URL_SITE_NAME} shipping is absolutely <strong>free of charge<strong> on all items.
        </p>
    `,
    group: HELP_GROUPS.SHIPPING
}, {
    title: `Shipping speed`,
    html: `
    <p>
        Once you win an item your order will be packaged and processed on the next business day. The average time which you can expect to receive your item is within 2 business days of being processed. For any shipping delays please contact your shipping carrier associated with your tracking number.
    </p>
    `,
    group: HELP_GROUPS.SHIPPING
}, {
    title: `Tracking your package`,
    html: `
    <p>
        When your item is has been packaged and processed. We will email you a tracking number with details on your shipping carrier and how to track your package. All items are shipped with FedEx or UPS depending on your shipping address location.
    </p>

    <img src=${assetPrefix({ path: 'help/shipping-carriers.jpg' })} class="shipping-carriers" />

    `,
    group: HELP_GROUPS.SHIPPING
}, {
    title: `Which payment methods do you accept?`,
    html: `
    
    <p>We accept major credit cards brands and forms of payments including:<br> ${paymentMethodBrands}</p>

    <img src=${assetPrefix({ path: 'help/acceptedcards.jpg' })} class="acceptedcards noBorder" />
    
    `,
    group: HELP_GROUPS.PAYMENT_METHODS
}, {
    title: `Storing a payment method for future use`,
    html: `
        <p>You can easily store a payment method for future use within your <a href="${DEFAULT_TEXT['url.myAccount']}">My account page.</a> Storing a payment method makes adding bids or purchasing a winning item faster.</p>

        <img src=${assetPrefix({ path: 'help/paymentmethods-storingapaymentmethod.jpg' })} class="paymentmethods-storingapaymentmethod noBorder noRadius" />

        <ol>
            <li>
                <div class="helpCircle">1</div>
                <div>Select the <strong>Payment Methods</strong> section further down the page</div>
            </li>
        </ol>

        <img src=${assetPrefix({ path: 'help/paymentmethods-storingapaymentmethod-2.jpg' })} class="paymentmethods-storingapaymentmethod-2 noBorder noRadius" />

        <ol>
            <li>
                <div class="helpCircle">2</div>
                <div>Select the <strong>Add new payment method</strong> button</div>
            </li>
        </ol>

        <img src=${assetPrefix({ path: 'help/paymentmethods-storingapaymentmethod-3.jpg' })} class="paymentmethods-storingapaymentmethod-3 noBorder noRadius" />

        <ol>
            <li>
                <div class="helpCircle">3</div>
                <div>Fill in your credit card details <br><strong>Including:</strong> Card number, expiration, CVC (3 digit number on the back of your card), Country and Zip Code</div>
            </li>
            <li>
                <div class="helpCircle">4</div>
                <div>Select <strong>Add payment method</strong></div>
            </li>
        </ol>

        <img src=${assetPrefix({ path: 'help/paymentmethods-storingapaymentmethod-4.jpg' })} class="paymentmethods-storingapaymentmethod-4 noBorder noRadius" />

        <ol>
            <li>
                <div class="helpCircle">5</div>
                <div>Your payment method will now be saved for future use.</div>
            </li>
        </ol>


    `,
    group: HELP_GROUPS.PAYMENT_METHODS
}, {
    title: `Who can bid on items?`,
    html: `
        <p>There a few restrictions on who is allowed to bid on items:</p>

        <ol>
            <li>
                <div class="helpCircle">1</div>
                <div>You must be at least 18+ years of age</div>
            </li>
            <li>
                <div class="helpCircle">2</div>
                <div>Have a valid USA billing and shipping address</div>
            </li>
            <li>
                <div class="helpCircle">3</div>
                <div>Cannot be a friend, family member or be anyone associated with an employee working for the ${process.env.REACT_APP_URL_SITE_NAME} website.</div>
            </li>
            <li>
                <div class="helpCircle">4</div>
                <div>Be in possession of a valid unexpired USA drivers license as a form of identification to confirm your identity</div>
            </li>
            <li>
                <div class="helpCircle">5</div>
                <div>Be in possession of a valid USA credit or debit card that matches the name and information on your drivers license</div>
            </li>
        </ol>

        <p>All of the above criteria must be met in order to bid and win auctions. Failure to meet all the criteria will cause an immediate cancellation of the account. Including forfeiting all winning auctions</p>
    `,
    group: HELP_GROUPS.RESTRICTIONS
}, {
    title: `Account limits`,
    html: `

    <p><strong>Winning limits</strong> - There is no limit on the amount of items you are allowed to win.</p>
    <p><strong>Account limits</strong> - There is a maximum of 1 account allowed per household. Any additional accounts located belonging to the same person or household will be instantly closed.</p>
    `,
    group: HELP_GROUPS.RESTRICTIONS
}, {
    title: `Team Play`,
    html: `
     <p>To keep auctions fair all team play is strictly forbidden. No team bidding on auctions is allowed with friends, family members or acquaintances. We use advanced tracking software to track all patterns of team play. All accounts conducting in any form of team play will be instantly removed and restricted from future bidding.</p>
    `,
    group: HELP_GROUPS.RESTRICTIONS
}]

const topics = helpTopics.map(topic => {

    const { title, group } = topic;

    const key = urlSlug(title)
    const path = helpPaths[group]

    const obj = {
        ...topic,
        key,
        path
    }

    return obj

})

topics.sort((a, b) => a.group.localeCompare(b.group));

export { topics, helpSections }