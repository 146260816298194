import React, { useEffect, useCallback, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router';
import { useSearchParams } from "react-router-dom";
import TabBox from './myaccount/TabBox';
import { DEFAULT_TEXT } from '../content';
import { MyAccountBox } from '../style/main';
import { scrollTop } from './helpers';
import Avatar from './snippet/Avatar';

export const MY_ACCOUNT_TAB_KEYS = Object.freeze({
    PROFILE: 'PROFILE',
    SHIPPING_INFO: 'SHIPPING_INFO',
    ACCOUNT: 'ACCOUNT'
})

const myAccountTabs = Object.freeze({
    [MY_ACCOUNT_TAB_KEYS.PROFILE]: DEFAULT_TEXT['page.myaccount.tab.profile'],
    [MY_ACCOUNT_TAB_KEYS.SHIPPING_INFO]: DEFAULT_TEXT['page.myaccount.tab.shippingInfo'],
    [MY_ACCOUNT_TAB_KEYS.ACCOUNT]: DEFAULT_TEXT['page.myaccount.tab.account']
})

const scrollToTop = ref => {
    setTimeout(() => {
        const headerBox = document.getElementById('headerBox')

        const top = ref.offsetTop - 10 - headerBox.offsetHeight;

        window.scrollTo({
            top,
            left: 0
        });

    }, 10)
}


const MyAccount = React.memo(() => {

    const shippingInfoRef = useRef(null)

    let [searchParams, setSearchParams] = useSearchParams();

    const navigate = useNavigate();

    const displayName = useSelector((state) => state.user.displayName)
    const uid = useSelector((state) => state.user.uid)

    const [tab, setTab] = useState(false);

    useEffect(() => {

        if (uid) {

            const shippingInfoParam = searchParams.get(MY_ACCOUNT_TAB_KEYS.SHIPPING_INFO);

            if (shippingInfoParam) {

                navigate(window.location.pathname, { replace: true });

                setTab(MY_ACCOUNT_TAB_KEYS.SHIPPING_INFO)

                scrollToTop(shippingInfoRef.current)

            }
        }

    }, [uid, searchParams]);

    useEffect(() => {
        scrollTop()
    }, []);


    return (
        <div className='wrap column'>
            <MyAccountBox className='column'>


                <Avatar type='myAccount1' />

                <div className={`displayName ${!displayName ? 'loading' : ''}`}>
                    {displayName ? displayName : <span>loading</span>}
                </div>

                <div className="tabsBox column">

                    {Object.keys(myAccountTabs).map((key, i) =>
                        <TabBox key={key} keyVar={key} title={myAccountTabs[key]} setTab={setTab} tab={tab} shippingInfoRef={shippingInfoRef} scrollToTop={scrollToTop} />
                    )}

                </div>


            </MyAccountBox>
        </div>
    );
})

export default MyAccount;