import React, { useEffect, useState } from 'react';
import { useCallback } from 'react';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { DEFAULT_TEXT } from '../content';
import { Auctions, WinningAuctionBox } from '../style/main';
import AuctionSingle from './AuctionSingle';
import { getUserCredentials, scrollTop } from './helpers';
import LdsRing from './snippet/LdsRing';

//USES SHARED CLASSES WITH ORDER HISTORY PAGE
const WinningAuctions = React.memo(() => {

    const navigate = useNavigate();

    const uid = useSelector((state) => state.user.uid)
    const userLoaded = useSelector((state) => state.user.userLoaded)
    const accessToken = useSelector((state) => state.user.accessToken)

    const selectRef = useRef(null)

    const [winningAuctions, setWinningAuctions] = useState({
        loading: true,
        auctions: [],
        filteredAuctions: [],
        filter: 'ALL'
    });

    useEffect(() => {
        scrollTop()
    }, []);

    const selectChange = useCallback(() => {
        let filteredAuctions = [];
        let auctions = winningAuctions.auctions;
        const filter = selectRef.current.value;

        if (filter === 'ALL') {
            filteredAuctions = auctions;
        } else {
            filteredAuctions = auctions.filter(({ ORDER_STATUS }) => ORDER_STATUS === filter);
        }

        window.scrollTo({
            top: 0,
            left: 0
        });

        setWinningAuctions(x => ({
            ...x,
            filteredAuctions,
            filter
        }))

    }, [winningAuctions]);


    useEffect(async () => {

        const stopLoading = () => {
            setWinningAuctions(x => ({
                ...x,
                loading: false
            }))
        }

        if (!userLoaded) {
            return
        }

        if (!uid && userLoaded) {
            stopLoading();
            return
        }

        let auctions = await fetch(process.env.REACT_APP_URL_WINNING_AUCTIONS, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ accessToken, ...getUserCredentials() }),
        })

        auctions = await auctions.json();

        if (auctions?.length) {
            setWinningAuctions(x => ({
                ...x,
                loading: false,
                auctions: auctions,
                filteredAuctions: auctions
            }))
        } else {
            stopLoading();
        }

    }, [userLoaded, uid, accessToken]);

    const noWinningAuctionsClick = useCallback(() => {
        navigate('/');
    }, []);

    const auctions = winningAuctions?.auctions;
    const filterAuctions = winningAuctions?.filteredAuctions;
    const filter = winningAuctions.filter;

    return (
        <WinningAuctionBox>
            <div className='wrap column'>
                {winningAuctions?.loading ? <LdsRing /> :
                    !auctions?.length ?
                        <div className="noAuctionsFiltered column">
                            {DEFAULT_TEXT['winningAuctions.none']}
                            <button onClick={noWinningAuctionsClick}>{DEFAULT_TEXT['winningAuctions.button.none']}</button>
                        </div>
                        :
                        <>

                            <div className='winningAuctionTitleBox row'>
                                <div className="title">{DEFAULT_TEXT[`winningAuctions.title.${filter}`]} {`(${filterAuctions?.length || 0} Total)`}</div>
                                <select name="filter" id="filter" ref={selectRef} onChange={selectChange}>
                                    <option value='' disabled>{DEFAULT_TEXT['winningAuctions.select.filter']}</option>
                                    <option value="ALL" defaultValue>{DEFAULT_TEXT['winningAuctions.select.ALL']}</option>
                                    <option value="PAY_NOW">{DEFAULT_TEXT['winningAuctions.select.PAY_NOW']}</option>
                                    <option value="SHIPPED">{DEFAULT_TEXT['winningAuctions.select.SHIPPED']}</option>
                                    <option value="COMPLETE">{DEFAULT_TEXT['winningAuctions.select.COMPLETE']}</option>
                                </select>
                            </div>

                            {filterAuctions?.length ?
                                <Auctions className='row'>
                                    {filterAuctions.map(x => <AuctionSingle key={x.id} {...x} />)}
                                </Auctions>
                                :
                                <div className="noCriteria column">
                                    {DEFAULT_TEXT['winningAuctions.filteredZero']}
                                </div>
                            }

                        </>
                }
            </div>
        </WinningAuctionBox>
    );
})

export default WinningAuctions;