import React, { useEffect } from 'react';
import { useCartMode } from '../../providers/CartMode';
import { LegalBox } from '../../style/main';

const Privacy = React.memo(() => {

    const { cartMode } = useCartMode();

    useEffect(() => {

        window.scrollTo({
            top: 0,
            left: 0
        });

    }, []);

    return (
        <LegalBox className='column'>
            <div className="wrap coliumn">
                <h1>Privacy policy</h1>

                <p>Updated: April 04, 2022</p>

                <p><b>1. General</b>
                </p>

                <p>You may visit {process.env.REACT_APP_URL_SITE_NAME}.com at any time without registering. When you visit our
                    website, data is saved on our servers, as is the website that you visit us from,
                    the advertising you may have been referred by, the websites that you proceed to
                    from our site, the type of browser you use, and your IP address.</p>

                <p>As this is being done, no personal data is directly associated with you. However,
                    we do need some personal information from you if you want to actively participate
                    in {process.env.REACT_APP_URL_SITE_NAME}.com or use our mobile applications.</p>

                <p>Once you have registered with {process.env.REACT_APP_URL_SITE_NAME}.com, you are asked to choose a {process.env.REACT_APP_URL_SITE_NAME}.com
                    user name together with a password, which then allows you to take advantage of
                    all of the {process.env.REACT_APP_URL_SITE_NAME}.com services.</p>

                <p>If you have questions about our privacy policy, please email us at support@{process.env.REACT_APP_URL_SITE_NAME}.com.</p>

                <p>You can revoke your consent any time by closing your account with us. For requests and inquiries related to
                    California Consumer Privacy Act, please refer to section 7.</p>

                <p><b>2. What Data Do We Collect?</b>
                </p>

                <p>"Personal data" is the information that readily identifies you, for example your
                    name, address, e-mail address and mobile phone number or your payment details.
                    When you register, personal data is recorded and processed by {process.env.REACT_APP_URL_SITE_NAME}.com, as
                    well as when you use the service, for example:</p>

                {!cartMode &&

                    <ul>
                        <li>when you purchase bids;</li>
                        <li>when you place bids, win an auction, or purchase through Buy It Now;</li>
                        <li>when you securely login.</li>
                    </ul>

                }

                <p>"Usage data" is personal data that identify the user, as well as information on
                    the {process.env.REACT_APP_URL_SITE_NAME}.com services the user utilizes, {!cartMode && `for example your {process.env.REACT_APP_URL_SITE_NAME}.com user
                    name together with the associated password and your IP address when you purchase
                    bids, login to the site or win an auction. It may also include things like the
                    frequency and analysis of your auction participation and auctions you view online
                    but do not participate in`}.</p>

                <p>{process.env.REACT_APP_URL_SITE_NAME}.com may also collect information from you when you search, participate in
                    a contest or questionnaire or communicate with our customer support.</p>

                <p>We collect this data to make it possible for you to use {process.env.REACT_APP_URL_SITE_NAME}.com and to help
                    us personalize and continually improve your {process.env.REACT_APP_URL_SITE_NAME}.com experience. We maintain
                    all data in the US and Canada ourselves and with the assistance of service providers.</p>

                <p>{process.env.REACT_APP_URL_SITE_NAME}.com is directed toward a general audience over the age of 18 and is specifically
                    not directed toward children under the age of 13. If we learn that we have information
                    about anyone under 13, we will promptly delete it.</p>

                <p><b>3. Use of the Data</b>
                </p>

                <p>Our primary purpose in collecting personal information is to provide you with a
                    safe, smooth, efficient, and customized {process.env.REACT_APP_URL_SITE_NAME}.com experience. You agree that
                    we may use your personal information to:</p>
                <ul>
                    <li>provide the services and customer support you request;</li>
                    <li>resolve disputes and troubleshoot problems;</li>
                    <li>prevent potentially prohibited or illegal activities, and enforce our Terms of
                        Use;
                    </li>
                    <li>customize, measure and improve our services, content and advertising;</li>
                    <li>tell you about our services, targeted marketing, site updates, and promotional
                        offers based on your communication preferences.
                    </li>
                </ul>

                <p><b>4. Newsletter</b>
                </p>

                <p>When you register with {process.env.REACT_APP_URL_SITE_NAME}.com, you are added to receive the email newsletter.
                    You may unsubscribe at anytime from the newsletter and other marketing communications
                    by clicking the unsubscribe link at the bottom of the newsletter emails.</p>

                <p><b>5. Sharing Personal Data to Third Parties</b>
                </p>

                <p>We will not share your personal information with third parties for that recipient’s
                    own purposes unless you have clearly consented to such sharing. We will share
                    your data with service providers who assist us with the site, marketing and our
                    operations, such as payment processing or post-purchase fulfillment.</p>

                <p>{process.env.REACT_APP_URL_SITE_NAME}.com may also share your data for law enforcement purposes, to protect
                    our business from fraud or abuse, or in the event another party purchases our
                    company or assets.</p>

                <p><b>6. Viewing/Changing Personal Data</b>
                </p>

                <p>You can view the information in your {process.env.REACT_APP_URL_SITE_NAME}.com account at any time and update
                    it as necessary using your {process.env.REACT_APP_URL_SITE_NAME}.com user name and your {process.env.REACT_APP_URL_SITE_NAME}.com password.
                    You can also at any time contact our Customer Service to update your information.
                </p>

                <p>Once we are informed, we will adjust incorrect data accordingly.
                </p>

                <p>We will notify you upon request at any time as to whether and what personal data
                    in respect to your identity or your pseudonym has been stored.
                </p>

                <p><b>7. Privacy Rights of California Residents</b></p>

                <p>California law requires that we provide you a summary of your privacy rights under the California Consumer Privacy
                    Act (the "Act") and the California Civil Code.</p>

                <p>Subject to certain limits under California Civil Code if you are a California resident, you may ask us to provide you
                    with a list of certain categories of personal information (as defined in the Civil Code) that has been collected, or
                    that we have disclosed to third parties for their direct marketing purposes and the identity of the third parties
                    that received personal information from us for their direct marketing purposes. The Act further requires us to allow
                    you to control who you do not want us to share that information with. For inquiries or requests, please contact us
                    by phone, email, or standard mail to the addresses below.</p>

                <p>
                    Email: support@{process.env.REACT_APP_URL_SITE_NAME}.com<br />
                </p>

                <p>When contacting us, please indicate your name, address, email address, and what personally identifiable information
                    your request is related to. The request should be sent to the attention of our legal department, and labeled
                    "California Customer Choice Notice."
                </p>

                <p><b>8. Revoking Consent/Deleting Your Account</b>
                </p>

                <p>To the extent required by applicable statutes, you can revoke your consent to our storage, collection and processing
                    of your personal data at any time.</p>

                <p>You can delete or deactivate your {process.env.REACT_APP_URL_SITE_NAME}.com account at any time. {process.env.REACT_APP_URL_SITE_NAME} may retain data as required for
                    regulatory or customer service purposes or purposes defined in the Privacy Policy and Terms of Use.</p>

                {!cartMode &&
                    <p>If you choose to close your account, all remaining bids will be destroyed.</p>
                }

                <p><b>9. The Use of Cookies and other identifiers</b>
                </p>

                <p>{process.env.REACT_APP_URL_SITE_NAME}.com uses so-called "cookies", which are text files that are stored on
                    your computer that we retrieve when you visit the site to assist in customizing
                    your experience with the online service.</p>

                {!cartMode &&
                    <p>The information saved supports the functionality of the site, for example by keeping
                        track of your bids placed, auctions, etc.</p>
                }

                <p>It is necessary to accept cookies in order to use our service properly, although
                    you may always adjust the way your browser handles cookies.</p>

                <p>We would like to point out that turning off the ability of your browser to accept
                    cookies may result in limited functionality.</p>

                <p>{process.env.REACT_APP_URL_SITE_NAME} uses first-party cookies (such as the Google Analytics cookies) and third-party
                    cookies (such as the DoubleClick cookie) together to report how your ad impressions, other
                    uses of ad services, and interactions with these ad impressions and ad services are related
                    to visits to your site and {process.env.REACT_APP_URL_SITE_NAME}’s advertising efforts. Visitors can opt-out of Google
                    Analytics for Display Advertising and customize Google Display Network ads using the Ads
                    Settings.</p>

                <p>By adjusting the privacy and security settings on your device or browser, you can manage how your mobile device and
                    mobile browser share information, as well as how your mobile browser handles Cookies and other tracking identifiers.
                    Please refer to instructions provided by your mobile service provider or the manufacturer of your device to learn
                    how to adjust your settings.
                </p>

                <p><b>10. Security Measures</b></p>

                <p>The information in your {process.env.REACT_APP_URL_SITE_NAME}.com account and in your {process.env.REACT_APP_URL_SITE_NAME}.com profile is
                    password protected.</p>

                <p>Please do not provide your log in credentials to anyone else, as you will be liable
                    for the activity in your account.</p>

                <p>{process.env.REACT_APP_URL_SITE_NAME}.com will never ask for your password in e-mails that you receive unexpectedly
                    or that you did not request.</p>

                <p>Please remember to log out of your account and to close your Internet browser window
                    when you leave the {process.env.REACT_APP_URL_SITE_NAME}.com site; this is especially important if you use
                    the site through a public or shared device.</p>

                <p>You are responsible for protecting the secrecy of your log in details, and we assume
                    no liability for the abuse of login data and passwords used. Let us know immediately
                    if the secrecy of your log in credentials has been compromised. While we take
                    reasonable steps to protect your personal data in our control, no system is foolproof.</p>

                <p><b>11. Privacy Policy Changes</b>
                </p>

                <p>We reserve the right to change this policy at any time, without prior notification.
                    If we make a material change to this policy, we will highlight this to you.</p>
            </div>
        </LegalBox>
    );
})

export default Privacy;