import React, { useEffect, useCallback } from 'react';
import { ShoppingFromBox } from '../../../style/main';
import { useDispatch, useSelector } from 'react-redux';
import ShoppingFromMainBar from './ShoppingFromMainBar';
import ShoppingFromTitle from './ShoppingFromTitle';
import ShoppingFromInput from './ShoppingFromInput';
import ShoppingFromCities from './ShoppingFromCities';
import ShoppingFromCitiesError from './ShoppingFromCitiesError';
import { actionCloseMenus } from '../../../reducers/layout';


const ShoppingFrom = React.memo(() => {

    const dispatch = useDispatch();

    const dealDropdownOpen = useSelector((state) => state.layout.dealDropdownOpen)
    const shoppingFromMenu = useSelector((state) => state.layout.shoppingFrom.open)

    const closeMenu = useCallback(() => {

        dispatch(actionCloseMenus({ shoppingFrom: true }))

    }, []);

    useEffect(() => {

        const removeEventListener = () => {
            window.removeEventListener("click", closeMenu)
        }

        removeEventListener()

        if (shoppingFromMenu) {

            window.addEventListener("click", closeMenu)

        }

        return removeEventListener

    }, [shoppingFromMenu]);

    useEffect(() => {

        if (dealDropdownOpen) {
            closeMenu()
        }

    }, [dealDropdownOpen]);

    return (
        <ShoppingFromBox className="row" onClick={e => {
            e.stopPropagation()
            dispatch(actionCloseMenus({ dealDropdown: true }))
        }}>

            <ShoppingFromMainBar />

            <div className={`shoppingfromDropdownBox column ${shoppingFromMenu ? 'active' : ''}`}>

                <ShoppingFromTitle />
                <ShoppingFromInput />
                <ShoppingFromCities />
                <ShoppingFromCitiesError />


            </div>

        </ShoppingFromBox>
    );
})

export default ShoppingFrom;