import React from 'react';
import { Link } from 'react-router-dom';
import { DEFAULT_TEXT } from '../../content';
import { BreadcrumbBox } from '../../style/main';
import { BRAND_LIST } from '../../content/brands';

export const Arrow = React.memo(() => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7.7 12.2" className='arrowBreadcrumb'>
            <path d="M0.1,10.7l1.5,1.5l6.1-6.1L1.6,0L0.1,1.5l4.6,4.6L0.1,10.7z" />
        </svg>
    );
})

const BreadcrumbsAuction = React.memo(({ loading, TITLE, BRAND }) => {

    const url = `/?filter=${BRAND}`

    const BRAND_TEXT = BRAND_LIST[BRAND]

    return (

        <BreadcrumbBox className={`row ${loading ? 'loading' : ''}`}>

            <div className="breadcrumbLeft row">
                <li>
                    <Link to="/">
                        {DEFAULT_TEXT['breadcrumb.allDeals']}
                    </Link>
                </li>

                <Arrow />

                {BRAND &&
                    <li>
                        <Link to={url}>
                            {BRAND_TEXT}
                        </Link>
                    </li>
                }

                <Arrow />

                {TITLE &&
                    <li>
                        {TITLE}
                    </li>
                }
            </div>

        </BreadcrumbBox >

    );
})

export default BreadcrumbsAuction;