import React, { useEffect, useLayoutEffect, useCallback, useState, useRef } from 'react';
import { DEFAULT_TEXT } from '../content';
import { Link } from 'react-router-dom';
import Countdown from './Countdown';
import BidButton from './snippet/BidButton';
import ProductImage from './snippet/ProductImage';
import { useCartMode } from '../providers/CartMode';
import { assetPrefix, avatarImageUrl, createPrice } from './helpers';
import { CURRENCY } from '../content/currency';
import { useSelector } from 'react-redux';

const AuctionSingle = React.memo((props) => {

    const avatar = useSelector((state) => state.user.avatar)
    const userDisplayName = useSelector((state) => state.user.displayName)

    let { id, loading, SKU, TITLE, RETAIL_PRICE, sold, countdown, soldPage, priceCurrent, history, won, ORDER_STATUS, CREDIT_PACKAGE, amount } = props;

    const countdownZero = countdown === 0;

    const { cartMode } = useCartMode();

    const firstLoad = useRef(true)
    const flashTimeout = useRef(null)

    const [flash, setFlash] = useState(false);

    useEffect(() => {

        if (sold || soldPage) {
            return
        }

        // BID FLASH
        const price = parseInt(priceCurrent)

        if (price >= 0) {

            if (firstLoad.current) {
                firstLoad.current = false;
                return
            }

            setFlash(true)

            flashTimeout.current = setTimeout(() => {
                setFlash(false)
            }, 200)

        }

        return () => {
            clearTimeout(flashTimeout.current)
        }

    }, [priceCurrent, sold, soldPage]);

    const goToAuction = useCallback((e) => {
        if (loading) {
            e.preventDefault();
        }
    }, [loading]);

    useLayoutEffect(() => {

        // for same page auction click, reset the firstLoad so the bid does not flash on load
        firstLoad.current = true;

    }, [id]);

    return (
        <div className={`auction column ${loading ? 'loading' : ''} ${won ? `wonAuction ${ORDER_STATUS}` : CREDIT_PACKAGE ? 'CREDIT_PACKAGE' : sold ? 'justSold' : countdownZero ? 'countdownZero' : ''}`}>

            <Link to={loading ? '' : CREDIT_PACKAGE ? '' : `/auctions/${id}`} onClick={goToAuction} className='link column'>
                <div className="top column">

                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 251 363">
                        <rect width="251" height="363" />
                    </svg>

                    {!loading && <ProductImage SKU={SKU} TITLE={TITLE} />}

                    <img src={assetPrefix({ path: 'productbg.png' })} className="productbg" />

                    {(sold || won || CREDIT_PACKAGE) ?
                        <div className={`sold row ${CREDIT_PACKAGE ? 'CREDIT_PACKAGE' : won ? 'won' : ''}`}>
                            {CREDIT_PACKAGE ? DEFAULT_TEXT['auction.creditPackageCorner'] : won ? DEFAULT_TEXT['auction.won'] : DEFAULT_TEXT['auction.sold']}
                        </div>
                        :
                        ''
                    }

                </div>

                <div className="title row">
                    <h2>{loading ? <div>Loading</div> : TITLE}</h2>
                </div>

            </Link>

            <div className={`bottom column ${flash ? 'active' : ''} ${cartMode ? 'cartMode' : ''}`}>


                <div className='price'>{!loading && CURRENCY[process.env.REACT_APP_ISO_CODE].dollar}{loading ? <div>Loading</div> : CREDIT_PACKAGE ? createPrice(amount) : cartMode ? RETAIL_PRICE : priceCurrent ? createPrice(priceCurrent) : createPrice(0)}</div>

                {!cartMode &&
                    <>
                        <div className="avatarBox row">

                            <div className="avatar row">
                                {CREDIT_PACKAGE ? <img src={avatarImageUrl(avatar)} /> : history?.length ? <img src={assetPrefix({ path: `avatars/${history[0].avatar}.png` })} /> : ''}
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" className={`avatarNoBids ${(history?.length || CREDIT_PACKAGE) ? 'hide' : ''}`}>
                                    <path d="M 34.800781 33.800781 C 33.101563 33.101563 31 32.199219 31 31.5 L 31 27 C 33.5 25.101563 35 22.101563 35 19 L 35 13 C 35 7.5 30.5 3 25 3 C 19.5 3 15 7.5 15 13 L 15 19 C 15 22.101563 16.5 25.199219 19 27 L 19 31.5 C 19 32.101563 16.898438 33 15.199219 33.800781 C 11.101563 35.5 5 38.101563 5 45 L 5 46 L 45 46 L 45 45 C 45 38.101563 38.898438 35.5 34.800781 33.800781 Z" />
                                </svg>
                                <svg viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg" className='avatarLoader'>
                                    <rect width="128" height="128" />
                                </svg>
                            </div>

                        </div>

                        <div className='highBidder'>
                            {loading ?
                                <div>Loading</div>
                                : CREDIT_PACKAGE ?
                                    userDisplayName
                                    :
                                    history?.length ? `High Bidder: ${history[0].displayName}`
                                        :
                                        DEFAULT_TEXT['auction.noBidders']
                            }
                        </div>

                        <Countdown countdown={countdown} sold={sold} CREDIT_PACKAGE={CREDIT_PACKAGE} />
                    </>
                }
                <BidButton {...props} />

            </div>

        </div>
    );
})

export default AuctionSingle;