import React, { useEffect, useRef, useState } from 'react';
import { GoToTopBox, HelpPage, headerHeight } from '../../style/main';
import { useLocation } from 'react-router';
import HelpBreadcrumb from './HelpBreadcrumb';
import HelpContent from './HelpContent';
import { DEFAULT_TEXT } from '../../content';
import HelpMenu from './menu/HelpMenu';
import HelpMenuTitleMobile from './menu/HelpMenuTitleMobile';
import { useHelpProvider } from '../../providers/HelpProvider';
import HelpIndex from './HelpIndex';
import GoToTop from '../snippet/GoToTop';


const Help = React.memo(() => {

    const { helpIndexPage, helpBreadCrumbRef, howItWorksPage } = useHelpProvider();

    const location = useLocation();

    useEffect(() => {

        window.scrollTo(0, 0)

    }, [location]);

    return (
        <>


            <HelpPage className={`column ${(!howItWorksPage && !helpIndexPage) ? 'helpSubPage' : ''}`}>


                {helpIndexPage ?

                    <HelpIndex />

                    :

                    <>

                        {!howItWorksPage && <HelpMenuTitleMobile />}

                        <div className="wrap column">

                            <HelpBreadcrumb helpBreadCrumbRef={helpBreadCrumbRef} />


                            <div className="helpBox column">

                                <div className="helpContent row">
                                    <HelpContent />
                                    <HelpMenu />
                                </div>
                            </div>

                            <GoToTop />

                        </div>
                    </>
                }

            </HelpPage>

        </>

    );
})

export default Help;