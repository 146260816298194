import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { assetPrefix } from '../helpers';

const Logo = React.memo(() => {

    const onClick = useCallback(() => {

        window.scroll(0, 0)

    }, []);

    return (
        <Link to="/" className="logo row" onClick={onClick}>
            <img src={assetPrefix({ path: 'logo.png' })} alt={`Welcome to ${process.env.REACT_APP_URL_SITE_NAME}!`} />
        </Link>
    );
})

export default Logo;