import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { DEFAULT_TEXT } from '../content';
import { Auctions, WinningAuctionBox } from '../style/main';
import AuctionSingle from './AuctionSingle';
import { getUserCredentials, scrollTop } from './helpers';
import LdsRing from './snippet/LdsRing';

//USES SHARED CLASSES WITH WINNING AUCTION PAGE
const OrderHistory = React.memo(() => {

    const navigate = useNavigate();

    const uid = useSelector((state) => state.user.uid)
    const userLoaded = useSelector((state) => state.user.userLoaded)
    const accessToken = useSelector((state) => state.user.accessToken)

    const [orders, setOrders] = useState({
        loading: true,
        orderList: []
    });

    useEffect(() => {
        scrollTop()
    }, []);

    useEffect(async () => {

        if (!userLoaded) {
            return
        }

        if (!uid && userLoaded) {
            return
        }

        let orderList = await fetch(process.env.REACT_APP_URL_ORDER_HISTORY, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ accessToken, ...getUserCredentials() }),
        })

        orderList = await orderList.json()

        setOrders(orders => ({
            ...orders,
            loading: false,
            orderList
        }))

    }, [uid, userLoaded, accessToken]);

    const orderList = orders.orderList;

    const noOrdersClick = useCallback(() => {
        navigate('/');
    }, []);

    return (
        <WinningAuctionBox>

            <div className='wrap column'>

                {orders.loading ?
                    <LdsRing />
                    :
                    <>
                        {orderList?.length ?
                            <>
                                <div className='winningAuctionTitleBox row'>
                                    <div className="title">{DEFAULT_TEXT['orderHistory.title']}</div>
                                </div>
                                <Auctions className='row'>

                                    {orderList.map((x, i) => <AuctionSingle key={i} {...x} />)}

                                </Auctions>

                            </>
                            :
                            <div className="noCriteriaOrders column">
                                {DEFAULT_TEXT['orderHistory.noOrders']}
                                <button onClick={noOrdersClick}>{DEFAULT_TEXT['auction.notFound.button']}</button>
                            </div>
                        }
                    </>
                }

            </div>

        </WinningAuctionBox>
    );
})

export default OrderHistory;