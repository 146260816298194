import React from 'react';
import { DEFAULT_TEXT } from '../../content';
import { useHelpProvider } from '../../providers/HelpProvider';
import { HelpTabTopic } from './menu/HelpSubMenu';

const HelpRelated = React.memo(() => {

    const { relatedTopics } = useHelpProvider();

    return (
        <>
            {relatedTopics?.length ?
                <>
                    <h2>{DEFAULT_TEXT['page.help.relatedArticles']}</h2>
                    <div className="helpContent column">
                        {relatedTopics.map(({ title, key, path }) => <HelpTabTopic key={key} groupKey={key} title={title} path={path} />)}
                    </div>
                </>
                :
                ''
            }
        </>
    );
})

export default HelpRelated;