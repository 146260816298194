export const FIREBASE_PERM = {
    development: {
        apiKey: "AIzaSyBO7y9meggWqAx10KkUdUJab9D70LR3JS4",
        authDomain: "stockedlivestaging.firebaseapp.com",
        projectId: "stockedlivestaging",
        storageBucket: "stockedlivestaging.appspot.com",
        messagingSenderId: "145388079284",
        appId: "1:145388079284:web:63241f6c794e69463bafe1"
    },
    production: {
        apiKey: "AIzaSyABmO_F_ukWhHQRobqvsixJPYI8Bt84ulY",
        authDomain: "stockedlive.firebaseapp.com",
        projectId: "stockedlive",
        storageBucket: "stockedlive.appspot.com",
        messagingSenderId: "232176711317",
        appId: "1:232176711317:web:a65701dd7ef66b96ec0d9c"
    }
}