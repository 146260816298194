import React, { useCallback, useEffect, useRef } from 'react';
import { helpSections } from '../../../content/help';
import HelpSubMenu from '../menu/HelpSubMenu';
import { Link } from 'react-router-dom';
import { useHelpProvider } from '../../../providers/HelpProvider';
import { DEFAULT_TEXT } from '../../../content';
import { HelpMenuBox } from '../../../style/main';
import { HELP_GROUPS } from '../../../content/helpPaths';

const HelpTabFirst = React.memo(({ title, groupKey, path }) => {

    let { groupSelectedKey, windowSize, selectedGroup, setSelectedGroup } = useHelpProvider();

    const activeGroup = groupSelectedKey === groupKey;
    const thisGroupSelected = selectedGroup === groupKey;

    const contactUsTab = groupKey === HELP_GROUPS.CONTACT_US;

    const onClick = useCallback((e) => {

        //SELECTED GROUP IS USED TO OPEN OR CLOSE ON MOBILE
        if (selectedGroup === groupKey) {
            setSelectedGroup('')
        } else {
            setSelectedGroup(groupKey)
        }

        if (windowSize === 'mobile' && !contactUsTab) {
            e.preventDefault();
        }

    }, [windowSize, groupKey, selectedGroup, contactUsTab]);

    return (
        <div className={`helpTab column ${windowSize === 'desktop' && activeGroup ? 'active' : ''} ${windowSize === 'mobile' && thisGroupSelected ? 'selectedGroup' : ''}`}>
            <Link to={`${DEFAULT_TEXT['url.help']}/${path}`} className="helpTabTitle row" onClick={onClick}>
                <span>{title}</span>
                {(groupKey && !contactUsTab) ?
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7.7 12.2"><path d="M0.1,10.7l1.5,1.5l6.1-6.1L1.6,0L0.1,1.5l4.6,4.6L0.1,10.7z"></path></svg>
                    :
                    ''
                }
            </Link>
            {groupKey &&
                <HelpSubMenu groupKey={groupKey} />
            }
        </div>
    );
})


const HelpMenu = React.memo(() => {

    const helpMenuRef = useRef(null)
    const { helpMenuOpen } = useHelpProvider();

    useEffect(() => {

        if (!helpMenuOpen) {
            helpMenuRef.current.scrollTo(0, 0)
        }

    }, [helpMenuOpen]);

    return (

        <HelpMenuBox className={`${helpMenuOpen ? 'helpMenuOpen' : ''}`} ref={helpMenuRef}>
            {helpSections.map((section) => <HelpTabFirst {...section} key={section.key} groupKey={section.key} />)}
        </HelpMenuBox>
    );
})

export default HelpMenu;