import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux'
import { configureStore } from '@reduxjs/toolkit'
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import userReducer from './reducers/user';
import auctionReducer from './reducers/auctions';
import layoutReducer from './reducers/layout';
import AppRouter from './routers/AppRouter';
import Sockets from './components/Sockets';
import { CartModeProvider } from './providers/CartMode';
import { FIREBASE_PERM } from './config/firebase';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { breakpoint, headerHeight } from './style/main';


const firebaseApp = initializeApp(FIREBASE_PERM[process.env.REACT_APP_URL_FIREBASE_PERM]);

export const db = getFirestore(firebaseApp);

export const store = configureStore({
	reducer: {
		user: userReducer,
		auctions: auctionReducer,
		layout: layoutReducer,
	},
},
	window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

const theme = {
	color1: '#34c7d6',
	header: '#000000',
	blackText: '#000000',
	white: '#ffffff',
	base: '#444444',
	error: '#cd3d64',
	price: '#cc0000',
	wonGradientMiddle: '#fd231d',
	wonGradientSide: '#fe7022',
	creditPackageCornerGradientMiddle: '#fdb81d',
	creditPackageCornerGradientSide: '#fe6922',
	button: '#63dce6',
	wonButton: '#49a826',
	wonButtonComplete: '#383838',
	highBidder: '#34c7d6',
	loading: '#f1f1f1',
	auctionPageSideBox: '#f6f6f6',
	bidFlash: '#fff1c0',
	title: '#000000',
	flash: '#ffeac5',
	userBid: '#d9d9d9',
	flashTime: '0.2s',
	bidsAddedPurchaseBox: '#fb9618',
	closeBtn: '#626262',
	auctionLight: '#63dce6',
	helpSectionTop: '#48cdd8',
	shoppingFrom: {
		bg: '#f6f6f6;',
		border: '#d3d3d3',
	},
	addBidsSelected: {
		background: '#e4ffd9',
		borderColor: '#7e9f71'
	},
	tag: {
		font: '#ffffff',
		bg: '#cc0000'
	},
	helpBidsPackText: '#e79f1b',
	signupBackground: '#393939'
}

const GlobalStyle = createGlobalStyle`
	#root {
		min-height: 100%;
		display: flex;
	}
	body {
		padding: 0;
		margin: 0;
		font-size: 18px;
	}

	body, html {
		min-height: 100%;
		height: 1px;
	}

	* {
		box-sizing: border-box;
		-webkit-tap-highlight-color: transparent;
	}

	body, input, textarea, button {
		font-family: 'Roboto', sans-serif;
	}

	button {
		outline: none;
		user-select: none;
	}

	input, select, textarea {
		outline: none;
	}

	textarea {
		resize: none;
	}

	a {
		text-decoration: none;
	}

	ul, li {
		list-style-type: none;
	}


	ul,
	li,
	ol {
		padding: 0;
		margin: 0;
	}

	.column  { 
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.row {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}

	.wrap {
		width: calc(100% - 50px);
		max-width: 1400px;

		@media (max-width: 1500px){
			width: calc(100% - 40px);
		}

		&.settings {
			width: 100%;
			flex-grow: 1;
			max-width: none;
		}

		&.auctionPageBox {
			@media (max-width: 1500px){
				width: 100%;
				max-width: none;
				padding: 0 20px;
			}
		}
	}

	.allBox {
		width: 100%;
		min-height: 100%;
		overflow: hidden;
		justify-content: flex-start;
		padding: ${headerHeight[1]} 0 0;

		@media (max-width: ${breakpoint[1500]}){
			padding: ${headerHeight[2]} 0 0;
		}

	}

	h1, h2, h3, h4, h5, h6 {
		padding: 0;
		margin: 0;
		font-weight: 700;
	}

	button, .button {
		width: 100%;
		max-width: 410px;
		height: 55px;
		min-height: 55px;
		border: 0;
		cursor: pointer;
		font-weight: 700;
		font-size: 21px;
		background: ${theme.button};
		color: ${theme.white};
		border-radius: 55px;
		box-shadow: 0 2px 5px 0 rgba(213, 217, 217, 0.5);
	}

	input, select, textarea {
		background: ${theme.white};
	}

	@media (max-width: 767px){

		button, .button {
			height: 48px;
			min-height: 48px;
			font-size: 18px;
		}

	}

`


ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<CartModeProvider>
				<Sockets />
				<GlobalStyle />
				<ThemeProvider theme={theme}>
					<AppRouter />
				</ThemeProvider>
			</CartModeProvider>
		</Provider>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
