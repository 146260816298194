import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { DEFAULT_TEXT } from '../../content';
import { assetPrefix } from '../helpers';
import { bc, broadcast_bids_purchased } from '../Sockets';

const PurchasedBids = React.memo(() => {

    const bidsPurchased = useSelector((state) => state.user.bidsPurchased)
    const CREDIT_PACKS = useSelector((state) => state.auctions.CREDIT_PACKS)
    const BID_PACK_AUCTION_DATA = useSelector((state) => state.auctions.BID_PACK_AUCTION_DATA)

    const BID_PACK = CREDIT_PACKS.find(({ PRODUCT_TYPE }) => PRODUCT_TYPE === bidsPurchased) || BID_PACK_AUCTION_DATA.find(({ PRODUCT_TYPE }) => PRODUCT_TYPE === bidsPurchased)

    const bid_amount = BID_PACK.bid_amount;
    const SKU = BID_PACK.SKU;

    const ISO_CODE = process.env.REACT_APP_ISO_CODE;
    const LANGUAGE = process.env.REACT_APP_LANGUAGE.toUpperCase();

    const filename = `${LANGUAGE}_${SKU}.jpg`
    const filepath = assetPrefix({ path: `products/${ISO_CODE}/large/${filename}` })

    useEffect(() => {
        bc.postMessage(broadcast_bids_purchased);
    }, []);

    return (
        <div className='purchasedBox column'>
            <img src={filepath} alt={`${bid_amount} ${DEFAULT_TEXT['member.pop.afterPurchase.bids']}`} />
            <p><span>{bid_amount}</span> {DEFAULT_TEXT['member.pop.afterPurchase.bids']}</p>
        </div>
    );
})

export default PurchasedBids;