import React, { useEffect } from 'react';
import { SoldPageTitleBox, Auctions } from '../style/main';
import { DEFAULT_TEXT } from '../content';
import AuctionSingle from './AuctionSingle';
import { useDispatch, useSelector } from 'react-redux';
import { actionSoldAuctions } from '../reducers/auctions';
import AuctionPageBottomButton from './snippet/AuctionPageBottomButton';
import { scrollTop } from './helpers';

const Sold = React.memo(() => {

    const dispatch = useDispatch();

    const uid = useSelector((state) => state.user.uid)
    const userLoaded = useSelector((state) => state.user.userLoaded)
    const sold = useSelector((state) => state.auctions.sold)

    useEffect(() => {
        scrollTop()
    }, []);

    useEffect(async () => {

        if (!userLoaded) {
            return
        }

        let sold = await fetch(process.env.REACT_APP_URL_SOLD)

        sold = await sold.json()

        if (uid) {
            sold = sold.map(auction => {
                const { history } = auction;
                const won = history?.[0]?.uid === uid;

                if (won) {
                    auction.won = true;
                }

                return auction
            })
        }

        dispatch(actionSoldAuctions(sold))

    }, [userLoaded, uid]);

    return (
        <>

            <div className='wrap column'>
                <SoldPageTitleBox className='column'>
                    <h1>{DEFAULT_TEXT['page.sold.title']}</h1>
                    <div className="blurb">{DEFAULT_TEXT['page.sold.blurb']}</div>
                </SoldPageTitleBox>


                <Auctions className='row'>
                    {!sold?.length ?
                        [...Array(9).keys()].map(x => <AuctionSingle key={x} loading={true} />)
                        :
                        sold.map((x, i) => <AuctionSingle key={x.id} {...x} soldPage={true} />)
                    }
                </Auctions>
            </div>

            <AuctionPageBottomButton />

        </>
    );
})

export default Sold;