import React, { createContext, useContext, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

const PagesContext = createContext(undefined)

export const PagesProvider = React.memo(({ children }) => {

    let [searchParams, setSearchParams] = useSearchParams();

    const currentPage = parseInt(searchParams.get("page")) || 1;

    return (
        <>

            <PagesContext.Provider value={{ currentPage }}>
                {children}
            </PagesContext.Provider>

        </>
    );
})

export const usePages = () => useContext(PagesContext)

