import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { DEFAULT_TEXT } from '../../../content';
import { actionCloseMenus } from '../../../reducers/layout';

const ShoppingFromTitle = React.memo(() => {

    const dispatch = useDispatch();

    const switchShoppingFromMenu = useCallback(() => {

        dispatch(actionCloseMenus({ shoppingFrom: true }))

    }, []);

    return (
        <div className="title row">

            <div className="arrowBox row" onClick={switchShoppingFromMenu}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M 7.4296875 9.5 L 5.9296875 11 L 12 17.070312 L 18.070312 11 L 16.570312 9.5 L 12 14.070312 L 7.4296875 9.5 z"></path></svg>
            </div>

            <span>{DEFAULT_TEXT['shoppingFrom.preText']}</span>

        </div>
    );
})

export default ShoppingFromTitle;