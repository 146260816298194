import React, { useEffect, useState, useCallback, createContext, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Credits from './memberPop/Credits';
import { DEFAULT_TEXT } from '../content';
import { MemberPopBox } from '../style/main';
import { actionMemberPop, actionUpdateAvatar } from '../reducers/user';
import Avatars from './memberPop/Avatars';
import PurchasedBids from './memberPop/PurchasedBids';
import LdsRing from './snippet/LdsRing';
import EditDisplayName from './memberPop/EditDisplayName';
import { useNavigate } from 'react-router';
import SelectPaymentMethod from './memberPop/SelectPaymentMethod';
import UserDeleted from './memberPop/UserDeleted';
import { useAuth0 } from '@auth0/auth0-react';
import DeleteAccount from './memberPop/DeleteAccount';
import { getUserCredentials } from './helpers';

export const MEMBER_POP_STEPS = Object.freeze({
    SELECT_CREDIT_PACK: 'SELECT_CREDIT_PACK',
    ADD_CREDIT_CARD_LAST_STEP: 'ADD_CREDIT_CARD_LAST_STEP',
    SELECT_PAYMENT_METHOD: 'SELECT_PAYMENT_METHOD',
    AVATARS: 'AVATARS',
    BIDS_ADDED: 'BIDS_ADDED',
    NEW_DISPLAY_NAME: 'NEW_DISPLAY_NAME',
    EDIT_DISPLAY_NAME: 'EDIT_DISPLAY_NAME',
    CART_MODE_SHIPPING: 'CART_MODE_SHIPPING',
    IDLE_BOX: 'IDLE_BOX',
    BIDS_ADDED: 'BIDS_ADDED',
    ADD_NEW_CARD: 'ADD_NEW_CARD',
    CART: 'CART',
    USER_DELETED: 'USER_DELETED',
    DELETE_ACCOUNT: 'DELETE_ACCOUNT'
})

const CART_DEFAULT = {
    loading: true
}

export const MemberPopContext = createContext(null)

const MemberPop = React.memo(() => {

    const dispatch = useDispatch();

    const cartModeShippingAddressButtonRef = useRef(null);

    const navigate = useNavigate();

    const submitButtonRef = useRef(null)
    const submitEditDisplayRef = useRef(null)
    const submitDeleteAccountRef = useRef(null)

    const uid = useSelector((state) => state.user.uid)
    const displayName = useSelector((state) => state.user.displayName)
    const accessToken = useSelector((state) => state.user.accessToken)
    const memberPop = useSelector((state) => state.user.memberPop)
    const avatar = useSelector((state) => state.user.avatar)
    const CREDIT_PACKS = useSelector((state) => state.auctions.CREDIT_PACKS)
    const cartAuctionId = useSelector((state) => state.user.cartAuctionId)

    const FIRST_CHOSEN_CREDIT_PACK = CREDIT_PACKS?.[1]?.PRODUCT_TYPE;

    const [CREDIT_PACKAGE, setCreditPackage] = useState(FIRST_CHOSEN_CREDIT_PACK);

    const [cardSelected, setCardSelected] = useState(0);

    const ADD_NEW_CARD_SELECTED = cardSelected === MEMBER_POP_STEPS.ADD_NEW_CARD;

    const [refillError, setRefillError] = useState(false);

    const [cartLoadError, setCartLoadError] = useState(false);

    const [loading, setLoading] = useState(false);

    const [avatarCount, setAvatarCount] = useState(avatar);

    const [cart, setCart] = useState(CART_DEFAULT);

    const title = DEFAULT_TEXT[`member.pop.title.${memberPop}`]
    let buttonText = DEFAULT_TEXT[`member.pop.button.${cartLoadError ? 'CART.cartLoadError' : (memberPop === MEMBER_POP_STEPS.ADD_CREDIT_CARD_LAST_STEP && cart.WINNING_AUCTION_ID) ? 'ADD_CREDIT_CARD_LAST_STEP.CART' : memberPop}`];

    const {
        logout
    } = useAuth0();


    const nextStep = useCallback(async () => {

        if (loading) {
            return
        }

        if (cartLoadError) {
            navigate(0);
            return
        }

        switch (memberPop) {
            case MEMBER_POP_STEPS.SELECT_CREDIT_PACK:
                dispatch(actionMemberPop(MEMBER_POP_STEPS.SELECT_PAYMENT_METHOD));
                break;
            case MEMBER_POP_STEPS.ADD_CREDIT_CARD_LAST_STEP:
                //SUBMIT CREDIT CARD
                submitButtonRef.current.click()
                break;
            case MEMBER_POP_STEPS.AVATARS:
                if (avatarCount) {
                    setLoading(true)

                    let response = await fetch(process.env.REACT_APP_URL_UPDATE_AVATAR, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({ accessToken, avatar: avatarCount, ...getUserCredentials() })
                    })

                    response = await response.json();

                    if (response?.updatedAvatar) {
                        // AVATAR EXISTS, CLOSE THE BOX, ONLY AN UPDATE

                        dispatch(actionUpdateAvatar(avatarCount));

                        if (avatar) {
                            dispatch(actionMemberPop(false));
                        } else {
                            //NEW USER GO TO SAVE NEW DISPLAY NAME
                            setLoading(false)
                            dispatch(actionMemberPop(MEMBER_POP_STEPS.NEW_DISPLAY_NAME));
                        }
                    }
                }
                break;
            case MEMBER_POP_STEPS.NEW_DISPLAY_NAME:
            case MEMBER_POP_STEPS.EDIT_DISPLAY_NAME:
                submitEditDisplayRef.current.click()
                break
            case MEMBER_POP_STEPS.CART_MODE_SHIPPING:
                cartModeShippingAddressButtonRef.current.click()
                break
            case MEMBER_POP_STEPS.USER_DELETED:
                logout({
                    logoutParams: {
                        returnTo: window.location.origin
                    }
                });
                break
            case MEMBER_POP_STEPS.DELETE_ACCOUNT:
                submitDeleteAccountRef.current.click();
                break
            case MEMBER_POP_STEPS.IDLE_BOX:
                navigate(0);
                break
            default:
                dispatch(actionMemberPop(false));
        }

    }, [uid, memberPop, avatar, avatarCount, setLoading, ADD_NEW_CARD_SELECTED, loading, cartLoadError, accessToken]);

    const goBack = useCallback(() => {

        if (loading) {
            return
        }

        if (cartLoadError) {
            navigate(0);
            return
        }

        setRefillError(false)

        switch (memberPop) {
            case MEMBER_POP_STEPS.ADD_CREDIT_CARD_LAST_STEP:
                dispatch(actionMemberPop(MEMBER_POP_STEPS.SELECT_PAYMENT_METHOD));
                break
            case MEMBER_POP_STEPS.SELECT_PAYMENT_METHOD:
                if (cart.WINNING_AUCTION_ID) {
                    dispatch(actionMemberPop(false));
                } else {
                    dispatch(actionMemberPop(MEMBER_POP_STEPS.SELECT_CREDIT_PACK));
                }
                break
            case MEMBER_POP_STEPS.IDLE_BOX:
                navigate(0);
                break
            default:
                dispatch(actionMemberPop(false));
                break
        }

    }, [memberPop, loading, cart, cartLoadError]);


    //PAY NOW ON ADD TO CART
    useEffect(async () => {

        const { loading, SKU, amount, TITLE, BID_AMOUNT } = cart;

        if (loading && cartAuctionId) {

            setLoading(true)

            let order = await fetch(process.env.REACT_APP_URL_ORDER_INFO, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ ...getUserCredentials(), WINNING_AUCTION_ID: cartAuctionId }),
            })

            const { savedOrder, error = '' } = await order.json() || {};

            setLoading(false)

            if (!savedOrder) {
                setCartLoadError(error);
                return
            }

            setCart(savedOrder)

            dispatch(actionMemberPop(MEMBER_POP_STEPS.SELECT_PAYMENT_METHOD));

        }

    }, [cart, cartAuctionId, accessToken]);

    return (

        <MemberPopContext.Provider value={{
            CREDIT_PACKAGE,
            setCreditPackage,
            submitButtonRef,
            submitEditDisplayRef,
            submitDeleteAccountRef,
            avatarCount,
            setAvatarCount,
            loading,
            setLoading,
            cardSelected,
            setCardSelected,
            refillError,
            cart,
            setCart,
            setRefillError,
        }}>

            <MemberPopBox className={memberPop === MEMBER_POP_STEPS.IDLE_BOX ? 'blur' : ''}>

                <div className={`inner column ${memberPop === MEMBER_POP_STEPS.AVATARS ? 'avatarsFormInner' : ''}`}>

                    <h4 className='row'>

                        {memberPop === MEMBER_POP_STEPS.IDLE_BOX || (avatar && displayName) || memberPop === MEMBER_POP_STEPS.CART_MODE_SHIPPING ?
                            <div className="svgBox column" onClick={goBack}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                    <path d="M 13 5.9296875 L 6.9296875 12 L 13 18.070312 L 14.5 16.570312 L 9.9296875 12 L 14.5 7.4296875 L 13 5.9296875 z" />
                                </svg>
                            </div>
                            :
                            ''
                        }

                        <span>{title}</span>

                    </h4>


                    <div className={`formOuter column ${memberPop === MEMBER_POP_STEPS.IDLE_BOX ? 'hide' : memberPop === MEMBER_POP_STEPS.AVATARS ? 'avatarsForm' : memberPop === MEMBER_POP_STEPS.BIDS_ADDED ? 'bidsAddedForm' : ''}`}>

                        {memberPop === MEMBER_POP_STEPS.SELECT_CREDIT_PACK || memberPop === MEMBER_POP_STEPS.ADD_CREDIT_CARD_LAST_STEP || memberPop === MEMBER_POP_STEPS.SELECT_PAYMENT_METHOD || memberPop === MEMBER_POP_STEPS.CART ?
                            <>
                                {
                                    cartLoadError ?
                                        <div className="cartLoadError row">
                                            <div className="error">{DEFAULT_TEXT[`member.pop.CART.cartLoadError.${cartLoadError}`]}</div>
                                        </div>
                                        :
                                        <>

                                            {memberPop !== MEMBER_POP_STEPS.CART && <Credits />}

                                            {(memberPop === MEMBER_POP_STEPS.CART) && cart.loading ? <div className="cartLoading row"><LdsRing /></div> : ''}

                                            {memberPop === MEMBER_POP_STEPS.SELECT_PAYMENT_METHOD && <SelectPaymentMethod />}

                                        </>
                                }
                            </>
                            :
                            memberPop === MEMBER_POP_STEPS.AVATARS ?
                                <Avatars />
                                :
                                memberPop === MEMBER_POP_STEPS.BIDS_ADDED ?
                                    <PurchasedBids />
                                    :
                                    (memberPop === MEMBER_POP_STEPS.EDIT_DISPLAY_NAME || memberPop === MEMBER_POP_STEPS.NEW_DISPLAY_NAME) ?
                                        <EditDisplayName />
                                        :
                                        memberPop === MEMBER_POP_STEPS.USER_DELETED ?
                                            <UserDeleted />
                                            :
                                            memberPop === MEMBER_POP_STEPS.DELETE_ACCOUNT ?
                                                <DeleteAccount />
                                                :
                                                ''
                        }
                    </div>

                    {memberPop !== MEMBER_POP_STEPS.SELECT_PAYMENT_METHOD &&
                        <div className={`buttonBox row ${memberPop === MEMBER_POP_STEPS.IDLE_BOX ? 'idleBox' : ''}`}>
                            <button onClick={nextStep}>
                                {(loading) ?

                                    <LdsRing />

                                    :

                                    buttonText

                                }
                            </button>
                        </div>

                    }

                </div>

            </MemberPopBox>

        </MemberPopContext.Provider>
    );
})

export default MemberPop;