const localStorageKeys = {
    shoppingFrom: 'shoppingFrom'
}

export const localStorageSetCity = city => {

    localStorage.setItem(localStorageKeys.shoppingFrom, city);

}

export const localStorageGetCity = () => localStorage.getItem(localStorageKeys.shoppingFrom) || false;