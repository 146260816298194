import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_TEXT } from '../../../content';
import { actionShoppingFromCity, actionToggleShoppingFromMenu } from '../../../reducers/layout';
import { localStorageGetCity } from '../../helpers/localstorage';
import ShoppingFromSaved from './ShoppingFromSaved';

const ShoppingFromMainBar = React.memo(() => {

    const userLoaded = useSelector((state) => state.user.userLoaded)
    const dispatch = useDispatch();
    const shoppingFrom = useSelector((state) => state.layout.shoppingFrom.city)

    const switchShoppingFromMenu = useCallback(() => {

        dispatch(actionToggleShoppingFromMenu());

    }, []);

    useEffect(() => {

        if (userLoaded) {

            const city = localStorageGetCity();

            if (city) {

                dispatch(actionShoppingFromCity({ city }));

            }
        }

    }, [userLoaded]);


    return (
        <div className="left row" onClick={switchShoppingFromMenu}>

            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10.3 14.6">
                <path d="M5.2,0.4c-2.8,0-5,2.2-5,5c0,0.8,0.3,1.6,0.8,2.5c0.4,0.9,1,1.8,1.6,2.7c1.1,1.7,2.3,3.1,2.3,3.1l0.4,0.5l0.4-0.5
    c0,0,1.1-1.4,2.3-3.1C8.4,9.7,9,8.8,9.4,7.9c0.4-0.9,0.8-1.7,0.8-2.5C10.2,2.6,7.9,0.4,5.2,0.4z M5.2,1.4c2.2,0,4,1.8,4,4
    c0,0.5-0.2,1.2-0.7,2.1c-0.4,0.8-1,1.7-1.5,2.6c-0.9,1.4-1.5,2.1-1.8,2.5c-0.3-0.4-0.9-1.1-1.8-2.5C2.8,9.2,2.2,8.3,1.8,7.5
    C1.4,6.6,1.2,5.9,1.2,5.4C1.2,3.2,3,1.4,5.2,1.4z M5.2,4.4c-0.6,0-1,0.4-1,1s0.4,1,1,1s1-0.4,1-1S5.7,4.4,5.2,4.4z"/>
            </svg>

            <div className="locationText">

                <div className="blurb">
                    {DEFAULT_TEXT['shoppingFrom.preText']}
                </div>

                {userLoaded &&
                    <div className="location">
                        <u>{shoppingFrom || DEFAULT_TEXT['shoppingFrom.underline']}</u>
                    </div>
                }

                <ShoppingFromSaved />

            </div>

        </div>
    );
})

export default ShoppingFromMainBar;