import React from 'react';
import { Link } from 'react-router-dom';
import { DEFAULT_TEXT } from '../../content';
import { useHelpProvider } from '../../providers/HelpProvider';
import { BreadcrumbBox } from '../../style/main';
import { Arrow } from '../snippet/BreadcrumbsAuctionPage';

const HelpBreadcrumb = React.memo(({ helpBreadCrumbRef }) => {

    const { groupInfo, pathInfo } = useHelpProvider();

    return (
        <BreadcrumbBox className="row help" ref={helpBreadCrumbRef}>

            <div className="breadcrumbLeft row">
                <li>
                    {(groupInfo?.title || pathInfo?.title) ?
                        <Link to={DEFAULT_TEXT['url.help']}>
                            {process.env.REACT_APP_URL_SITE_NAME} {DEFAULT_TEXT['page.help.breadcrumbFirst']}
                        </Link>
                        :

                        <>
                            {process.env.REACT_APP_URL_SITE_NAME} {DEFAULT_TEXT['page.help.breadcrumbFirst']}
                        </>
                    }
                </li>
                {groupInfo?.title &&
                    <>
                        <Arrow />
                        <li>
                            {pathInfo?.title ?
                                <Link to={`${DEFAULT_TEXT['url.help']}/${groupInfo.path}`}>
                                    {groupInfo?.title}
                                </Link>
                                :
                                groupInfo?.title
                            }
                        </li>
                    </>
                }
                {pathInfo?.title &&
                    <>
                        <Arrow />
                        <li>
                            {pathInfo?.title}
                        </li>
                    </>
                }
            </div>
        </BreadcrumbBox>
    );
})

export default HelpBreadcrumb;