import React from 'react';

const TermsCartMode = React.memo(() => {
    return (
        <>

            <p>PLEASE READ THIS AGREEMENT CARFULLY AS IT GOVERNS THE TERMS OF USE OF THE {process.env.REACT_APP_URL_SITE_NAME}.COM WEBSITE (HEREIN KNOWN AS “THE WEBSITE” OR “THIS WEBSITE”).
            </p>

            <h3> 1. Agreement Acceptance</h3>
            <p>THIS AGREEMENT IS BETWEEN YOU AND ALL PERSONS YOU REPRESENT (INCLUDING NATURAL PERSONS AND ANY TYPE OF INCORPORATED OR UNINCORPORATED ENTITY, HEREIN KNOWN AS “THE USER”) AND “{process.env.REACT_APP_URL_SITE_NAME} (HERIN KNOWN AS “{process.env.REACT_APP_URL_SITE_NAME}”), REGARDING THE ACCESS AND USE OF THIS WEBSITE, INCLUDING ALL CONTENT, INFORMATION, PRODUCTS AND SERVICES AVAILABLE ON OR THROUGH THE WEBSITE.
            </p>
            <p>WITH EVERY VISIT TO THIS WEBSITE YOU, THE USER SIGNIFY YOUR ACCEPTANCE AND AGREEMENT TO BE BOUND BY THIS AGREEMENT AS IT READS AT THE TIME OF THE VISIT AND BY VISITING THIS WEBSITE REPRESENT AND WARRANT THAT YOU, THE USER HAVE THE LEGAL AUTHORITY TO AGREE TO AND ACCEPT THIS AGREEMENT. IF YOU DO NOT OR CANNOT AGREE WITH EACH PROVISION OF THIS AGREEMENT YOU MAY NOT ACCESS OR USE THIS WEBSITE.
            </p>
            <p>THIS WEBSITE IS INTENDED FOR INFORMATIONAL PURPOSES ONLY AND IS NOT INTENDED TO CONVEY ADVICE OR RECOMMENDATIONS, OR AN OFFER TO SELL ANY PRODUCTS OR SERVICES.
            </p>
            <p>THIS AGREEMENT, YOUR ACCESS TO AND USE OF THE WEBSITE, AND ALL RELATED MATTERS ARE GOVERNED SOLELY BY THE LAWS OF FLORIDA, USA. ANY DISPUTE BETWEEN YOU AND {process.env.REACT_APP_URL_SITE_NAME} OR ANY OTHER PERSON ARISING FROM, CONNECTED WITH OR RELATING TO THE WEBSITE, THIS AGREEMENT, OR ANY RELATED MATTERS (COLLECTIVELY "DISPUTES") WILL BE RESOLVED BEFORE THE COURTS OF FLORIDA, USA, AND YOU HEREBY IRREVOCABLY SUBMIT AND ATTORN TO THE ORIGINAL AND EXCLUSIVE JURISDICTION OF THOSE COURTS IN RESPECT OF ALL DISPUTES.
            </p>
            <p>IF ANY PROVISION OF THIS AGREEMENT IS HELD TO BE INVALID OR UNENFORCEABLE FOR ANY REASON, THEN THE PROVISION WILL BE DEEMED TO BE SEVERED FROM THIS AGREEMENT AND THE REMAINING PROVISIONS WILL CONTINUE IN FULL FORCE AND EFFECT.
            </p>
            <p>THIS AGREEMENT IS IN ADDITION TO ANY OTHER AGREEMENT YOU MAY HAVE WITH {process.env.REACT_APP_URL_SITE_NAME}.
            </p>
            <p>THE SUBMISSIONS FOR ANY PURPOSE WHATSOEVER WITHOUT PROVIDING ANY COMPENSATION TO YOU THE USER, OR ANY OTHER PERSONS.
            </p>

            <h3>5. Agreement Changes</h3>
            <p>AT THE SOLE DISCRETION OF {process.env.REACT_APP_URL_SITE_NAME}, THIS AGREEMENT MAY BE CHANGED AND OR UPDATED AT ANY TIME WITHOUT ANY NOTICE OR LIABILITY TO YOU OR ANY OTHER PERSONS. BY USING THE WEBSITE AFTER THIS REVISED AGREEMENT HAS BEEN POSTED, YOU SIGNIFY YOUR ACCEPTANCE AND AGREEMENT TO BE BOUND BY THE REVISED AGREEMENT. YOU THE USER MAY NOT CHANGE OR AMEND THIS AGREEMENT IN ANY WAY WHATSOEVER.
            </p>


            <h3>6. Errors and Omissions</h3>

            <p>{process.env.REACT_APP_URL_SITE_NAME} STRIVES TO ENSURE THE ACCURACY OF ALL INFORMATION ON THE WEBSITE. HOWEVER, ERRORS, MISPRINTS, INACCURACIES, OMISSIONS AND OTHER ERRORS MAY OCCUR.
            </p>
            <p>{process.env.REACT_APP_URL_SITE_NAME} DOES NOT TAKE RESPONSIBILITY FOR ANY ERRORS, MISPRINTS, INACCURACIES, OMISSIONS OR ANY OTHER INACCURACIES IN ANY PART OF THE WEBSITE, INCLUDING BUT NOT LIMITED TO PRODUCT PRICING, PRODUCT INFORMATION / SPECIFICATIONS, OR ITEM AVAILABILITY.
            </p>
            <p>{process.env.REACT_APP_URL_SITE_NAME} RESERVES THE RIGHT TO CORRECT ANY ERROR, INACCURACY OR OMISSION AT ANY TIME WITHOUT PRIOR NOTICE OR LIABILITY TO YOU OR ANY OTHER PERSONS.
            </p>
            <p>{process.env.REACT_APP_URL_SITE_NAME} RESERVES THE RIGHT TO CHANGE OR UPDATE ANY PRODUCTS OR SERVICES AND ANY INFORMATION RELATED TO THOSE PRODUCTS OR SERVICES AT ANY TIME WITHOUT PRIOR NOTICE OR LIABILITY TO YOU OR ANY OTHER PERSONS.
            </p>
            <p>UNLESS OTHERWISE STATED, DIMENSIONS AND WEIGHT LISTED ON OUR PRODUCT PAGES ARE FOR THE PRODUCT ONLY. SHIPPING DIMENSIONS AND WEIGHT FOR PRODUCTS MAY BE DIFFERENT THAN THE LISTED PRODUCT DIMENSIONS AND WEIGHT DUE TO THE ADDITIONAL SIZE AND WEIGHT OF ACCESSORIES AND PACKAGING MATERIAL(S).
            </p>
            <p>ANY USE OF THE MARKS, EXCEPT AS EXPRESSLY PROVIDED IN THIS AGREEMENT, IS STRICTLY PROHIBITED. NOTHING APPEARING ON THE WEBSITE OR ELSEWHERE SHALL BE CONSTRUED AS GRANTING, BY IMPLICATION, ESTOPPEL, OR OTHERWISE, ANY LICENSE OR RIGHT TO USE ANY OF THE MARKS.
            </p>

            <h3>8. Disclaimer</h3>
            <p>ACCESS TO AND USE OF THE WEBSITE IS AT YOUR OWN RISK. THE WEBSITE IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY REPRESENTATIONS, WARRANTIES OR CONDITIONS OF ANY KIND, WHETHER EXPRESSED OR IMPLIED, AND INCLUDING WITHOUT LIMITATION IMPLIED REPRESENTATIONS, WARRANTIES OR CONDITIONS OF OR RELATING TO ACCURACY, ACCESSIBILITY, FITNESS FOR A PARTICULAR PURPOSE, MERCHANTABILITY, PERFORMANCE OR DURABILITY, ALL OF WHICH ARE DISCLAIMED BY {process.env.REACT_APP_URL_SITE_NAME} TO THE FULLEST EXTENT PERMITTED BY LAW.
            </p>
            <p>{process.env.REACT_APP_URL_SITE_NAME} AND ITS PROVIDERS WILL NEVER BE LIABLE TO YOU OR ANY OTHER PERSON FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, PUNITIVE OR EXEMPLARY LOSS OR DAMAGE ARISING FROM, CONNECTED WITH, OR RELATING TO THE WEBSITE OR THIS AGREEMENT INCLUDING BUT NOT LIMITED TO LOSS OF DATA, BUSINESS, MARKETS, SAVINGS, INCOME, PROFITS, USE, PRODUCTION, REPUTATION OR GOODWILL, ANTICIPATED OR OTHERWISE, OR ECONOMIC LOSS, UNDER ANY THEORY OF LIABILITY (WHETHER IN CONTRACT, TORT, STRICT LIABILITY OR ANY OTHER THEORY OR LAW OR EQUITY), REGARDLESS OF ANY NEGLIGENCE OR OTHER FAULT OR WRONGDOING (INCLUDING WITHOUT LIMITATION GROSS NEGLIGENCE AND FUNDAMENTAL BREACH) BY {process.env.REACT_APP_URL_SITE_NAME} OR ANY PERSON FOR WHOM {process.env.REACT_APP_URL_SITE_NAME} IS RESPONSIBLE, AND EVEN IF {process.env.REACT_APP_URL_SITE_NAME} HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE BEING INCURRED.
            </p>
            <p>THE EXCLUSION OF CERTAIN WARRANTIES AND THE LIMITATION OF CERTAIN LIABILITIES IS PROHIBITED IN SOME JURISDICTIONS. THESE STATUTORY PROHIBITIONS MAY APPLY TO YOU.
            </p>

        </>
    );
})

export default TermsCartMode;