import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { DEFAULT_TEXT } from '../../../content';
import { actionShoppingFromCity } from '../../../reducers/layout';
import { localStorageSetCity } from '../../helpers/localstorage';

const ShoppingFromCity = React.memo(({ city }) => {

    const dispatch = useDispatch();

    const onClick = useCallback(() => {

        localStorageSetCity(city)
        dispatch(actionShoppingFromCity({ city, updated: true }));

    }, [city]);

    return (
        <li className='row' onClick={onClick}>

            <span>{city}</span>

            <div className="smallBtn">{DEFAULT_TEXT['shoppingFrom.button']}</div>

        </li>
    );

})

export default ShoppingFromCity;