import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';
import { DEFAULT_TEXT } from '../content';
import { CURRENCY } from '../content/currency';
import { useCartMode } from '../providers/CartMode';
import { AuctionPageBox, AuctionPageDescriptionBox } from '../style/main';
import AuctionPageBottom from './AuctionPageBottom';
import Countdown from './Countdown';
import { createPrice, checkWinner, assetPrefix, scrollTop } from './helpers';
import BidButton from './snippet/BidButton';
import BreadcrumbsAuction from './snippet/BreadcrumbsAuctionPage';
import HighBidder from './snippet/HighBidder';
import ProductImage from './snippet/ProductImage';
import AuctionPageBottomButton from './snippet/AuctionPageBottomButton';

const goToTop = () => {
    window.scrollTo(0, 0);
}


const Details = React.memo(() => {

    return (
        <div className="details column">

            <div className="detail row">
                <div className='row'>{DEFAULT_TEXT['auction.extraDetail.1']}</div>
                <div className='row'>{DEFAULT_TEXT['auction.extraDetail.1.text']}</div>
            </div>

            <div className="detail row">
                <div className='row'>{DEFAULT_TEXT['auction.extraDetail.2']}</div>
                <div className='row'>{DEFAULT_TEXT['auction.extraDetail.2.text']}</div>
            </div>

            <div className="detail row">
                <div className='row'>{DEFAULT_TEXT['auction.extraDetail.3']}</div>
                <div className='row'>{DEFAULT_TEXT['auction.extraDetail.3.text']}</div>
            </div>

            <div className="detail row">
                <div className='row'>{DEFAULT_TEXT['auction.extraDetail.4']}</div>
                <div className='row'>
                    <img src={assetPrefix({ path: 'payments.jpg' })} className='payments' alt="" />
                </div>
            </div>

        </div>
    );
})

const Description = React.memo(({ loading, TITLE, PRODUCT_DESCRIPTION }) => {

    const navigate = useNavigate();
    const { pathname } = useLocation();

    const uid = useSelector((state) => state.user.uid)

    const { cartMode } = useCartMode();

    const goToJoin = useCallback(() => {
        navigate(DEFAULT_TEXT['url.signup'], {
            state: pathname
        });
    }, [pathname]);


    return (


        <AuctionPageDescriptionBox className='row'>

            <div className={`descriptionSide column ${cartMode ? 'cartMode' : ''}`}>
                <h2 data-product="TITLE">{loading ? <div>Loading</div> : `${DEFAULT_TEXT['auction.details.title']} ${TITLE}:`}</h2>

                <div className='description column' dangerouslySetInnerHTML={{ __html: PRODUCT_DESCRIPTION }} data-product="PRODUCT_DESCRIPTION" />
            </div>

            {!cartMode &&

                <div className="checks column">

                    <h2>{loading ? <div>Loading</div> : `${DEFAULT_TEXT['auction.details.title.2']}`}</h2>

                    <div className="checksBox column">

                        {DEFAULT_TEXT['page.auction.checks'].map((text, i) =>
                            <div className="checkRow row" key={i}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" >
                                    <path d="M 12 2 C 6.486 2 2 6.486 2 12 C 2 17.514 6.486 22 12 22 C 17.514 22 22 17.514 22 12 C 22 10.874 21.803984 9.7942031 21.458984 8.7832031 L 19.839844 10.402344 C 19.944844 10.918344 20 11.453 20 12 C 20 16.411 16.411 20 12 20 C 7.589 20 4 16.411 4 12 C 4 7.589 7.589 4 12 4 C 13.633 4 15.151922 4.4938906 16.419922 5.3378906 L 17.851562 3.90625 C 16.203562 2.71225 14.185 2 12 2 z M 21.292969 3.2929688 L 11 13.585938 L 7.7070312 10.292969 L 6.2929688 11.707031 L 11 16.414062 L 22.707031 4.7070312 L 21.292969 3.2929688 z" />
                                </svg>
                                <span>{text}</span>
                            </div>)
                        }

                        <div className="checkBlurb" onClick={uid ? goToTop : goToJoin}><u>{DEFAULT_TEXT['auction.details.title.3.bottom']}</u></div>

                    </div>

                </div>

            }

        </AuctionPageDescriptionBox>
    );
})


const HighBidderNickname = React.memo(({ loading, history }) => {

    return (

        <div className="highBidder">
            {loading ?
                <div>Loading</div>
                :
                history?.length ? `High Bidder: ${history[0]?.displayName}`
                    :
                    DEFAULT_TEXT['auction.noBidders']
            }
        </div>

    );
})

const AuctionPage = React.memo(() => {

    const navigate = useNavigate();

    let [info, setInfo] = useState({
        loading: true
    });

    const [flash, setFlash] = useState(false);

    const { cartMode } = useCartMode();

    const { id } = useParams();

    const fetched = useRef(false)

    const firstLoad = useRef(true)
    const priceRef = useRef(true)
    const flashTimeout = useRef(null)

    const userLoaded = useSelector((state) => state.user.userLoaded)
    const uid = useSelector((state) => state.user.uid)

    const auctions = useSelector((state) => state.auctions.auctions)
    const auction = auctions?.find(x => x.id === id);

    if (auction) {
        info = auction;
    }

    useEffect(async () => {

        if (!userLoaded) {
            return
        }

        if (auctions.length && !auction && !fetched.current) {

            fetched.current = true;

            let auction = await fetch(`${process.env.REACT_APP_URL_ENDED_AUCTION}?auctionId=${id}`)
            auction = await auction.json()

            if (auction?.id) {

                setInfo(auction)

            } else {

                setInfo({
                    notFound: true
                })

            }

        }

    }, [userLoaded, auctions, auction]);

    useEffect(() => {

        if (info?.sold) {
            return
        }

        // BID FLASH
        const price = parseInt(info.priceCurrent)

        if (price >= 0) {

            if (firstLoad.current) {
                firstLoad.current = false;
                return
            }

            setFlash(true)

            flashTimeout.current = setTimeout(() => {
                setFlash(false)
            }, 200)

        }

        return () => {
            clearTimeout(flashTimeout.current)
        }

    }, [info.priceCurrent, info.sold]);

    useEffect(() => {
        clearTimeout(flashTimeout.current)
        setFlash(false)
        goToTop()
    }, [id]);

    const notFoundClick = useCallback(() => {
        navigate('/', { replace: true });
    }, []);

    const countdownZero = info?.countdown === 0;

    //WON AUCTION
    let won = false;

    if (uid && info.sold) {
        won = checkWinner({ uid, history: info?.history })
    }

    return (

        <>

            {!info.notFound &&
                <div className='wrap column'>

                    <BreadcrumbsAuction {...info} />

                </div>
            }

            <div className='wrap auctionPageBox column'>


                <AuctionPageBox className={`column ${info.loading ? 'loading' : ''} ${won ? `wonAuction ${info.ORDER_STATUS}` : info.sold ? 'justSold' : countdownZero ? 'countdownZero' : ''}`}>

                    {(!info.loading && info.notFound) ?
                        <div className="notFoundBox column">

                            <div className="notFoundTitle">{DEFAULT_TEXT['auction.notFound.title']}</div>
                            <div className="notFoundSub" dangerouslySetInnerHTML={{ __html: DEFAULT_TEXT['auction.notFound.sub'] }} />
                            <button onClick={notFoundClick}>{DEFAULT_TEXT['auction.notFound.button']}</button>

                        </div>
                        :
                        <>

                            <h1 className='row' data-product="TITLE">{info.loading ? <div>Loading</div> : info.TITLE}</h1>

                            <div className='mainBox row'>

                                <div className="left column">

                                    <div className="imgBox row">
                                        {!info.loading ?
                                            <ProductImage SKU={info.SKU} TITLE={info.TITLE} large={true} />
                                            :
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 518 637" className='skeleton'>
                                                <rect width="518" height="637" />
                                            </svg>
                                        }
                                    </div>

                                    {(info.sold || won) ?
                                        <div className={`soldCorner column ${won ? 'won' : ''}`}>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 250">
                                                <defs>
                                                    <linearGradient id="won-gradient" x2="0.35" y2="1">
                                                        <stop offset="0%" stopColor="var(--color-stop)" />
                                                        <stop offset="30%" stopColor="var(--color-stop)" />
                                                        <stop offset="100%" stopColor="var(--color-bot)" />
                                                    </linearGradient>
                                                </defs>
                                                <rect x="-203.7" y="27.1" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -15.3623 66.258)" width="552" height="49.1" />
                                            </svg>
                                            <div className="soldText row">{won ? DEFAULT_TEXT['auction.won'] : DEFAULT_TEXT['auction.sold']}</div>
                                        </div>
                                        :
                                        ''
                                    }

                                </div>

                                <div className="right column">

                                    <div className="bottom row">

                                        <div className="bidArea column">

                                            <div className={`flashArea column ${flash ? 'active' : ''} ${cartMode ? 'cartMode' : ''}`}>

                                                <div className='price' ref={priceRef}>{!info.loading && CURRENCY[process.env.REACT_APP_ISO_CODE].dollar}{info.loading ? <div>Loading</div> : cartMode ? info.RETAIL_PRICE : info.priceCurrent ? createPrice(info.priceCurrent) : createPrice(0)}</div>

                                                {!cartMode &&
                                                    <>
                                                        <div className="avatar row">
                                                            {info?.history?.length ? <img src={assetPrefix({ path: `avatars/${info?.history[0].avatar}.png` })} /> : ''}
                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" className={`avatarNoBids ${info.history?.length ? 'hide' : ''}`}>
                                                                <path d="M 34.800781 33.800781 C 33.101563 33.101563 31 32.199219 31 31.5 L 31 27 C 33.5 25.101563 35 22.101563 35 19 L 35 13 C 35 7.5 30.5 3 25 3 C 19.5 3 15 7.5 15 13 L 15 19 C 15 22.101563 16.5 25.199219 19 27 L 19 31.5 C 19 32.101563 16.898438 33 15.199219 33.800781 C 11.101563 35.5 5 38.101563 5 45 L 5 46 L 45 46 L 45 45 C 45 38.101563 38.898438 35.5 34.800781 33.800781 Z" />
                                                            </svg>
                                                            <svg viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg" className='avatarLoader'>
                                                                <rect width="128" height="128" />
                                                            </svg>
                                                        </div>

                                                        <HighBidderNickname {...info} />


                                                        <Countdown sold={info?.sold} countdown={info?.countdown} />
                                                    </>
                                                }

                                                <BidButton {...info} priceRef={priceRef} won={won} />

                                            </div>

                                            <Details />


                                        </div>

                                        {!cartMode &&
                                            <div className='highBidders column'>

                                                <div className="title">
                                                    <div>{DEFAULT_TEXT['auction.biddingHistory']}</div>
                                                </div>

                                                <div className="highBiddersList column">
                                                    {info?.history?.length ? info?.history.map((x, i) =>
                                                        <HighBidder {...x} key={i} userTopBid={(i === 0) && uid && (x.uid === uid)} userBid={uid && (x.uid === uid)} bidderPrice={info.priceCurrent - i} count={i} highBidderWon={(info?.sold && i === 0)} firstLoad={firstLoad} />
                                                    )
                                                        :
                                                        <div className='noBidders row'>{DEFAULT_TEXT['auction.noBidders']}</div>
                                                    }
                                                </div>

                                            </div>
                                        }

                                    </div>

                                </div>

                            </div>

                        </>
                    }




                </AuctionPageBox>

            </div>

            {(!info.loading && !info.notFound) ?
                <div className="wrap column">

                    <Description {...info} />

                </div>
                :
                ''
            }

            <div className='wrap column'>

                <AuctionPageBottom auctionId={info?.id} />

            </div>

            <AuctionPageBottomButton />
        </>
    );
})

export default AuctionPage;