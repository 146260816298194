import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';
import { actionUserDoesNotExist, actionUserLoaded } from '../reducers/user';
import { actionSetAuctions } from '../reducers/auctions';
import { useDispatch } from 'react-redux';
import { auth0UserInfo } from '../components/helpers';

const FirstConnect = React.memo(({ children }) => {

    const dispatch = useDispatch();

    const [loaded, setLoaded] = useState(false);

    const {
        user,
        isAuthenticated,
        isLoading,
        getAccessTokenSilently
    } = useAuth0();

    useEffect(async () => {
        if (!isLoading) {

            const obj = {}
            let accessToken = '';

            const { uid } = auth0UserInfo(user);

            if (uid) {
                accessToken = await getAccessTokenSilently();
                obj.accessToken = accessToken;
                obj.uid = uid;
            }

            const config = {
                method: "POST",
                body: JSON.stringify(obj),
                headers: { "Content-Type": "application/json" }
            }

            try {

                let response = await fetch(process.env.REACT_APP_URL_FIRST_CONNECT, config)
                response = await response.json()

                const { auctions, userInfo } = response;

                //USERS WITH A DELETED ACCOUNT
                const userDeleted = userInfo?.userDeleted === 'true';

                if (userInfo && !userDeleted) {
                    dispatch(actionUserLoaded({ ...userInfo, accessToken }))
                } else {
                    dispatch(actionUserDoesNotExist({ userDeleted }))
                }

                dispatch(actionSetAuctions(auctions))

                setLoaded(true)

            } catch (e) {
                console.error('Connection error, please try again.')
            }

        }
    }, [isLoading, isAuthenticated, user]);

    if (!loaded) {
        return null
    }

    return children;
})

export default FirstConnect;