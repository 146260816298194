import React, { useEffect, useState } from 'react';
import { CURRENCY } from '../../content/currency';
import { assetPrefix, createPrice } from '../helpers';
import { DEFAULT_TEXT } from '../../content';
import { useSelector } from 'react-redux';

const HighBidder = React.memo(({ userTopBid, userBid, displayName, avatar, bidderPrice, count, highBidderWon, firstLoad }) => {

    const [flash, setFlash] = useState(false);

    useEffect(() => {


        if ((count === 0) && !firstLoad.current) {
            setFlash(true)
            setTimeout(() => {
                setFlash(false)
            }, 300)
        }

    }, [bidderPrice, count]);

    return (
        <div className={`bidder column ${highBidderWon ? 'highBidderWon' : userTopBid ? 'userTopBid' : userBid ? 'userBid' : flash ? 'flash' : ''}`}>

            {highBidderWon &&
                <div className="highBidderWonTitle row">
                    {DEFAULT_TEXT['auction.biddingHistory.winner']}
                </div>
            }

            <div className="innerBidder row">
                <div className="bidderLeft row">
                    <img src={assetPrefix({ path: `avatars/${avatar}.png` })} />
                    <span>{displayName}</span>
                </div>

                <div className="priceRight">
                    {CURRENCY[process.env.REACT_APP_ISO_CODE].dollar}{createPrice(bidderPrice)}
                </div>
            </div>

        </div>
    );
})

export default HighBidder;