import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { DEFAULT_TEXT } from '../content';
import { helpSections, topics } from '../content/help';
import { useSelector } from 'react-redux';
import { HELP_GROUPS, helpPaths } from '../content/helpPaths';

const HelpContext = createContext(undefined)

export const HelpProvider = React.memo(({ children }) => {

    const location = useLocation();

    const helpMenuTitleMobileRef = useRef(null)
    const helpBreadCrumbRef = useRef(null)

    const [windowSize, setWindowSize] = useState('');
    const [helpMenuOpen, setHelpMenuOpen] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState('false');

    const { menuOpen, dealDropdownOpen, myAccountMenu, shoppingFromMenu } = useSelector((state) => state.layout)

    let { group, path } = useParams();

    //HOW IT WORKS PAGE
    const howItWorksPage = location.pathname === DEFAULT_TEXT['url.howItWorks']

    if (howItWorksPage) {
        group = helpPaths[HELP_GROUPS.GETTING_STARTED];
    }

    //GROUP INFO
    const groupInfo = helpSections.find(({ path }) => path === group) || {};
    const groupSelectedKey = groupInfo?.key;

    //HELP INDEX PAGE
    const helpIndexPage = location.pathname === DEFAULT_TEXT['url.help']

    useEffect(() => {

        //RESET MOBILE HELP MENU WHEN ON INDEX
        if (location.pathname === DEFAULT_TEXT['url.help']) {
            setSelectedGroup('')
        } else {
            setSelectedGroup(groupSelectedKey)
        }

    }, [location, groupSelectedKey]);

    useEffect(() => {

        setHelpMenuOpen(false)

    }, [location]);

    useEffect(() => {

        const resize = () => {
            const windowWidth = window.innerWidth;
            if (windowWidth > 1023) {
                setWindowSize('desktop')
            } else {
                setWindowSize('mobile')
            }
        }

        window.addEventListener("resize", resize);

        resize()

        return () => window.removeEventListener("resize", resize);

    }, []);

    useEffect(() => {

        const body = document.querySelector('body');

        //close help menu when other menus are open, but leave overflow hidden active
        if (menuOpen || dealDropdownOpen || myAccountMenu || shoppingFromMenu) {
            setHelpMenuOpen(false)
            return
        }

        if (helpMenuOpen) {
            body.style.overflow = 'hidden';
        } else {
            body.style.overflow = 'auto';
        }

    }, [helpMenuOpen, menuOpen, dealDropdownOpen, myAccountMenu, shoppingFromMenu]);

    useEffect(() => {

        if (windowSize === 'desktop' && helpMenuOpen) {
            setHelpMenuOpen(false)
        }

    }, [windowSize, helpMenuOpen]);

    //PATH INFO
    const pathInfo = topics.find(({ key }) => key === path) || {}

    //RELATED - FILTER OUT CURRENT TOPIC ALSO
    const groupTopics = topics.filter(({ group }) => group === groupInfo.key)
    const relatedTopics = groupTopics?.filter(({ key }) => key !== path)

    //MAIN GROUP PAGE
    const groupPage = groupInfo?.title && !pathInfo?.title;

    //NEXT TOPIC
    const groupIndex = helpSections.findIndex(({ path }) => path === group);
    const pathIndex = groupTopics.findIndex(({ key }) => key === path);
    //IF PATH INDEX IS NOT FOUND ABOVE, IT RETURNS -1 IN THE GROUP, THE +1 BELOW WILL CHOOSE THE 0 INDEX
    let nextTopic = groupTopics[pathIndex + 1]
    //LAST OF GROUP PATH, GO TO NEXT GROUP INDEX
    let endOfGroupNextGroupPath = ''
    const groupNext = helpSections[groupIndex + 1]
    const lastPathInGroup = pathIndex === (groupTopics.length - 1)
    if (lastPathInGroup) {
        endOfGroupNextGroupPath = groupNext?.path;
    }

    let nextTopicUrl = `${DEFAULT_TEXT['url.help']}/${endOfGroupNextGroupPath || groupInfo.path}/${nextTopic?.key || ''}`

    //LAST OF ALL GROUPS, DONT SHOW NEXT TOPIC BUTTON
    if (!groupNext && lastPathInGroup) {
        nextTopicUrl = '';
    }

    return (
        <>

            <HelpContext.Provider value={{ groupInfo, groupSelectedKey, pathInfo, relatedTopics, groupPage, nextTopicUrl, helpMenuOpen, setHelpMenuOpen, windowSize, selectedGroup, setSelectedGroup, helpMenuTitleMobileRef, helpIndexPage, helpBreadCrumbRef, howItWorksPage }}>
                {children}
            </HelpContext.Provider>

        </>
    );
})

export const useHelpProvider = () => useContext(HelpContext)

