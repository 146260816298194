import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actionCloseMenus, actionToggleDealdropdown } from '../../../reducers/layout';
import DealsDropdown from './DealsDropdown';

const DealTab = React.memo(({ name }) => {

    const dispatch = useDispatch();

    const dealDropdownOpen = useSelector((state) => state.layout.dealDropdownOpen)
    const shoppingFromMenu = useSelector((state) => state.layout.shoppingFrom.open)

    const closeDealMenu = useCallback(() => {

        dispatch(actionCloseMenus({ dealDropdown: true }))

    }, []);

    const resize = useCallback(() => {

        // close menus on collapse down to prevent menu background from showing
        if (window.innerWidth < 1152) {
            dispatch(actionCloseMenus({ menu: true, dealDropdown: true }))
        }

    }, []);

    useEffect(() => {

        const removeEventListener = () => {
            window.removeEventListener("click", closeDealMenu)
        }

        removeEventListener()

        if (dealDropdownOpen) {

            window.addEventListener("click", closeDealMenu)

        }

        return removeEventListener

    }, [dealDropdownOpen]);

    useEffect(() => {

        const removeEventListener = () => {
            window.removeEventListener("click", resize)
        }

        removeEventListener()

        window.addEventListener('resize', resize);

        return removeEventListener

    }, []);


    useEffect(() => {
        if (shoppingFromMenu) {
            dispatch(actionCloseMenus({ dealDropdown: true }))
        }
    }, [shoppingFromMenu]);

    const onClick = useCallback((e) => {

        e.stopPropagation();
        dispatch(actionToggleDealdropdown())

    }, []);

    return (
        <div className="liInner row" onClick={onClick}>

            <div>{name}</div>
            <svg className='arrow' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M 7.4296875 9.5 L 5.9296875 11 L 12 17.070312 L 18.070312 11 L 16.570312 9.5 L 12 14.070312 L 7.4296875 9.5 z" />
            </svg>

            <DealsDropdown />
        </div>
    );
})

export default DealTab;