import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { actionCreditAndBidPackInfo } from '../reducers/auctions';

const TextProvider = React.memo(({ children }) => {

    const dispatch = useDispatch();

    //RELOAD PAGE WHEN CONNECTION INITIATES
    const checkConnection = () => {
        const interval = setInterval(async () => {
            try {
                await fetch(process.env.REACT_APP_URL_TEXT);
                clearInterval(interval)
                window.location.reload();
            } catch (e) {
                console.error('Not connected')
            }
        }, 1000)
    }

    useEffect(async () => {

        try {
            let response = await fetch(process.env.REACT_APP_URL_TEXT);
            response = await response.json();

            const { CREDIT_PACKS, BID_PACK_AUCTION_DATA } = response;

            if (CREDIT_PACKS && BID_PACK_AUCTION_DATA) {
                dispatch(actionCreditAndBidPackInfo({
                    CREDIT_PACKS,
                    BID_PACK_AUCTION_DATA
                }));
            }
        } catch (e) {
            console.error('No text loaded')
            checkConnection();
        }

    }, []);

    return (
        <>
            {children}
        </>
    );
})

export default TextProvider;