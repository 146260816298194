import React, { useState, useEffect, useRef, useLayoutEffect, useCallback } from 'react';
import io from 'socket.io-client';
import { useDispatch } from 'react-redux';
import { actionSetAuctions, actionAuctionsBids, actionCountdowns, actionJustSold } from '../reducers/auctions';
import { actionCredits } from '../reducers/user';

const socketPort = process.env.REACT_APP_URL_SOCKET
export const socket = io(socketPort);
export const bc = new BroadcastChannel("socket");

export const SOCKET_CHANNELS = Object.freeze({
    newConnection: 'newConnection',
    countdowns: 'countdowns',
    auctionBids: 'auctionBids',
    emitBid: 'emitBid',
    bidPlaced: 'bidPlaced'
})

export const socket_bid_channel = 'bid'

export const broadcast_bids_purchased = 'broadcast_bids_purchased'

const Sockets = React.memo(() => {

    const dispatch = useDispatch();

    const timeoutRef = useRef(null);

    const useActions = () => ({
        [SOCKET_CHANNELS.countdowns]: ({ countdowns, soldAuctionIds }) => {
            //COUNTDOWNS
            dispatch(actionCountdowns({ countdowns, soldAuctionIds }))

            if (soldAuctionIds.length) {
                // ADD 1 SECOND DELAY AFTER SELLING FOR TIMER TO HIT 0 THEN ENDED/SOLD SIGN
                setTimeout(() => {
                    dispatch(actionJustSold(soldAuctionIds))
                }, 1000)
            }

            clearTimeout(timeoutRef.current)

            timeoutRef.current = setTimeout(() => {
                //IF COUNTDOWN DOES NOT HAPPEN FOR 2 SECONDS RE-CONNECT THE SOCKET, HAPPENS WITH BROADCASTING, AND CLOSING PARENT TAB, THE RE-BROADCAST WILL DISCONNECT ANY EXTRA SOCKET AFTER
                socket.connect()
            }, 2000)

        },
        [SOCKET_CHANNELS.auctionBids]: bidAuctions => {
            //BID AUCTIONS
            dispatch(actionAuctionsBids(bidAuctions))
        },
        [SOCKET_CHANNELS.bidPlaced]: credits => {
            //UPDATE CREDITS AFTER BID
            dispatch(actionCredits(credits))
        }
    })

    useEffect(() => {

        socket.on('connect', function () {

            bc.postMessage(SOCKET_CHANNELS['newConnection']);

            bc.onmessage = (event) => {

                let { data: eventData } = event;

                if (eventData === SOCKET_CHANNELS['newConnection']) {
                    socket.disconnect()
                    return
                }

                if (eventData === broadcast_bids_purchased) {
                    //reload page after bids purchased so credits are updated
                    window.location.reload();
                    return
                }

                //data and channel are passed into the event object
                const data = eventData?.data;
                const channel = eventData?.channel;

                //BID COMING FROM ALTERNATE TAB
                if (channel === SOCKET_CHANNELS['emitBid']) {
                    if (socket?.connected) {
                        socket.emit(socket_bid_channel, data);
                    }
                    return
                }

                if (!data || !channel) {
                    console.error('channel or data does not exist')
                    return
                }

                useActions()[channel](data)

            };
        });

        socket.on(SOCKET_CHANNELS['countdowns'], ({ countdowns, soldAuctionIds }) => {

            const channel = SOCKET_CHANNELS['countdowns'];
            const data = { countdowns, soldAuctionIds }

            useActions()[channel](data)

            bc.postMessage({
                channel,
                data
            });

        });

        socket.on(SOCKET_CHANNELS['auctionBids'], (bidAuctions) => {

            const channel = SOCKET_CHANNELS['auctionBids'];
            const data = bidAuctions

            useActions()[channel](data)

            bc.postMessage({
                channel,
                data
            });

        });

        socket.on(SOCKET_CHANNELS['bidPlaced'], (credits) => {

            const channel = SOCKET_CHANNELS['bidPlaced'];
            const data = credits

            useActions()[channel](data)

            bc.postMessage({
                channel,
                data
            });

        });

        socket.on('connect_error', err => {
            console.log(err)
            console.error('socket connect_error')
        })
        socket.on('connect_failed', err => {
            console.log('socket connect_failed')
        })

    }, []);

    return null
})

export default Sockets;