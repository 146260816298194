import { createSlice, current } from '@reduxjs/toolkit'

const soldTotal = 48;

export const auctionSlice = createSlice({
    name: 'auctions',
    initialState: {
        auctions: [],
        sold: [],
        CREDIT_PACKS: false,
        BID_PACK_AUCTION_DATA: [],
        brands: []
    },
    reducers: {
        actionSetAuctions: (state, action) => {

            const auctions = action.payload;

            //GET BRANDS DROPDOWN COUNT
            const BRANDS_COUNTS = {
                ALL: 0
            }

            auctions.forEach(({ BRAND }) => {
                if (BRANDS_COUNTS[BRAND] >= 0) {
                    BRANDS_COUNTS[BRAND]++
                } else {
                    BRANDS_COUNTS[BRAND] = 1;
                }

                BRANDS_COUNTS.ALL++
            })

            let brands = [];

            for (const key in BRANDS_COUNTS) {
                const count = BRANDS_COUNTS[key]
                brands.push({
                    brand: key,
                    count
                })
            }

            brands.sort((a, b) => a.brand.localeCompare(b.brand))

            state.auctions = auctions;
            state.brands = brands;


        },
        actionCreditAndBidPackInfo: (state, action) => {

            const { CREDIT_PACKS, BID_PACK_AUCTION_DATA } = action.payload;

            state.CREDIT_PACKS = CREDIT_PACKS;
            state.BID_PACK_AUCTION_DATA = BID_PACK_AUCTION_DATA;

        },
        actionCountdowns: (state, action) => {

            let { countdowns, soldAuctionIds } = action.payload;

            state.auctions = state.auctions.map(auction => {

                const { id } = auction;

                const countdown = countdowns.find(countdown => countdown.id === id)?.countdown;

                const obj = {
                    ...auction
                }

                if (soldAuctionIds?.includes(id)) {

                    obj.countdown = 0;

                } else if (countdown >= 0) {

                    obj.countdown = countdown;

                }

                return obj

            })

        },
        actionJustSold: (state, action) => {

            // MARK ACTIVE AUCTIONS AS SOLD
            const soldAuctionIds = action.payload;

            state.auctions = state.auctions.map(auction => {

                const { id } = auction;

                const obj = {
                    ...auction
                }

                if (soldAuctionIds?.includes(id)) {

                    obj.sold = true;

                    //mark object with pay now
                    obj.ORDER_STATUS = 'PAY_NOW'

                    //PUSH ONTO EXISTING SOLD ARRAY IF IT EXISTS
                    if (state.sold?.length) {
                        state.sold.unshift(obj)
                        state.sold.splice(soldTotal, state.sold.length)
                    }

                }

                return obj

            })


        },
        actionAuctionsBids: (state, action) => {

            const bidAuctions = action.payload;

            state.auctions = state.auctions.map(auction => {

                let { id, priceCurrent } = auction;

                const bidAuction = bidAuctions.find(x => x.id === id);

                const obj = {
                    ...auction
                }

                if (bidAuction) {

                    obj.priceCurrent = parseInt(priceCurrent) + 1;
                    obj.history = bidAuction.history;

                    if (!bidAuction.noBoost) {
                        obj.countdown = 10;
                    }

                }


                return obj

            })

        },
        actionSoldAuctions: (state, action) => {

            const sold = action.payload;

            sold.splice(soldTotal, sold.length)

            state.sold = sold

        }
    },
})

export const { actionSetAuctions, actionCreditAndBidPackInfo, actionAuctionsBids, actionCountdowns, actionJustSold, actionSoldAuctions } = auctionSlice.actions

export default auctionSlice.reducer