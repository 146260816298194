import React, { useEffect, useCallback, useState } from 'react';
import { useForm } from "react-hook-form";
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import { SigninBox, SuccessCheckBlurb } from './../style/main';
import { useDispatch } from 'react-redux';
import Logo from './snippet/Logo';
import { DEFAULT_TEXT } from './../content';
import LdsRing from './snippet/LdsRing';
import { scrollTop } from './helpers';
import { actionCloseMenus } from './../reducers/layout';
import { resetPasswordFetch } from './snippet/ResetPasswordFetch';

const FIELDS = Object.freeze({
    EMAIL: 'email',
    PASSWORD: 'password',
    DISPLAY_NAME: 'displayName'
})

const ForgotPassword = React.memo(() => {

    const page = 'forgotPassword';

    const { register, handleSubmit, watch, setValue, reset, getValues, setError, clearErrors, formState: { errors } } = useForm();

    const location = useLocation();

    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false)

    const [forgotPassOk, setForgotOk] = useState(false)

    const resetForm = useCallback(() => {

        setTimeout(() => {
            reset({
                [FIELDS.EMAIL]: '',
                [FIELDS.PASSWORD]: '',
                [FIELDS.DISPLAY_NAME]: ''
            })
        }, 5)

    }, []);

    useEffect(() => {

        const subscription = watch((value, { name, type }) => {

            clearErrors('base')

            let test = name === FIELDS.EMAIL || name === FIELDS.PASSWORD;

            let inputValue = value[name];

            if (test && /\s+/g.test(inputValue)) {

                inputValue = inputValue.replace(/\s+/g, '')
                setValue(name, inputValue);

            }


        });

        return () => subscription.unsubscribe();

    }, [watch]);


    const onSubmit = useCallback(async () => {

        setLoading(true)

        if (loading) {
            return
        }

        const email = getValues("email");

        await resetPasswordFetch({ email })

        setForgotOk(true)
        setLoading(false)

    }, [loading]);

    const onFocus = useCallback((e) => {

        e.target.parentElement.classList.add('active');

    }, []);

    const onBlur = useCallback((e) => {
        e.target.parentElement.classList.remove('active');
    }, []);

    useEffect(() => {

        // CLOSE ALL OPEN MENUS
        dispatch(actionCloseMenus({
            menu: true,
            dealDropdown: true,
            myAccountMenu: true,
            shoppingFrom: true
        }))

        resetForm()
        setForgotOk(false)
        scrollTop();

    }, [location]);


    useEffect(() => {

        return () => resetForm()

    }, []);

    useEffect(() => {
        const body = document.querySelector('body');
        body.style.overflow = 'auto';
    }, []);

    return (
        <SigninBox className="column">

            <form onSubmit={handleSubmit(onSubmit)} className={`column ${page}`}>

                <div className="logoBox row">
                    <Logo />
                </div>

                <div className="innerForm row">

                    <div className={`formRight column ${page}`}>

                        <div className="blurb column">
                            <h2>{DEFAULT_TEXT[`page.${page}.title`]}</h2>
                            <span className="blurbSmall">{DEFAULT_TEXT[`page.${page}.subtitle`]}</span>
                        </div>

                        {!forgotPassOk &&
                            <>
                                <div className="fields column">

                                    <div className="field column">
                                        <div className='inputBox row'>
                                            <label htmlFor={FIELDS.EMAIL}>{DEFAULT_TEXT['form.email.label']}</label>

                                            <input
                                                type="email"
                                                name={FIELDS.EMAIL}
                                                placeholder={DEFAULT_TEXT['form.email.placeholder']}
                                                {...register(FIELDS.EMAIL, {
                                                    required: DEFAULT_TEXT['form.email.error.required'],
                                                    pattern: {
                                                        value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                        message: DEFAULT_TEXT['form.email.error.valid']
                                                    }
                                                })}
                                                onFocus={onFocus}
                                                onBlur={onBlur}
                                            />

                                        </div>

                                        {errors?.email?.message && <div className="error">{errors?.email?.message}</div>}

                                    </div>

                                </div>

                                {errors?.base?.message && <div className="error base" dangerouslySetInnerHTML={{ __html: errors?.base?.message }}></div>}

                            </>
                        }



                        {forgotPassOk &&
                            <SuccessCheckBlurb className='row forgotPasswordSuccess'>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M 12 2 C 6.486 2 2 6.486 2 12 C 2 17.514 6.486 22 12 22 C 17.514 22 22 17.514 22 12 C 22 10.874 21.803984 9.7942031 21.458984 8.7832031 L 19.839844 10.402344 C 19.944844 10.918344 20 11.453 20 12 C 20 16.411 16.411 20 12 20 C 7.589 20 4 16.411 4 12 C 4 7.589 7.589 4 12 4 C 13.633 4 15.151922 4.4938906 16.419922 5.3378906 L 17.851562 3.90625 C 16.203562 2.71225 14.185 2 12 2 z M 21.292969 3.2929688 L 11 13.585938 L 7.7070312 10.292969 L 6.2929688 11.707031 L 11 16.414062 L 22.707031 4.7070312 L 21.292969 3.2929688 z"></path></svg>

                                <div className="text">
                                    {DEFAULT_TEXT['form.success.forgotPassword']}
                                </div>
                            </SuccessCheckBlurb>
                        }

                        {!forgotPassOk &&
                            <button type="submit">

                                {!loading ?

                                    DEFAULT_TEXT[`button.${page}`]

                                    :

                                    <LdsRing />

                                }

                            </button>
                        }

                        <div className="alreadyMember row">
                            <span>{DEFAULT_TEXT[`page.${page}.under.button.text`]} </span>
                            <Link to={page === 'signup' ? DEFAULT_TEXT['url.signin'] : DEFAULT_TEXT['url.signup']}>
                                {page === 'signup' ? DEFAULT_TEXT['url.signin.text'] : DEFAULT_TEXT['button.signup']}
                            </Link>
                        </div>

                    </div>

                </div>

            </form>

            <div className="background"></div>

        </SigninBox>
    );
})

export default ForgotPassword;