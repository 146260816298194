import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actionMemberPop } from '../../reducers/user';
import { AvatarImage } from '../../style/main';
import { avatarImageUrl } from '../helpers';
import { MEMBER_POP_STEPS } from '../MemberPop';

const Avatar = ({ type }) => {

    const dispatch = useDispatch();

    const openAvatars = useCallback(() => {

        dispatch(actionMemberPop(MEMBER_POP_STEPS.AVATARS));

    }, []);

    const avatar = useSelector((state) => state.user.avatar)

    return (
        <AvatarImage className={`row ${type}`} onClick={openAvatars}>

            {avatar > 0 && <img src={avatarImageUrl(avatar)} />}

        </AvatarImage>
    );
}

export default Avatar;