import React, { useEffect, useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_TEXT } from '../../../content';
import { actionShoppingFromCities, actionShoppingFromError } from '../../../reducers/layout';

const ShoppingFromInput = React.memo(() => {

    const dispatch = useDispatch();

    const inputRef = useRef(null);

    const shoppingFromMenu = useSelector((state) => state.layout.shoppingFrom.open)

    const [focused, setFocused] = useState(false);

    const clearShoppingFrom = useCallback(() => {

        dispatch(actionShoppingFromCities([]))
        dispatch(actionShoppingFromError(false))

    }, []);

    const onChange = useCallback((e) => {

        const zipCode = e.target.value.replace(/[^0-9]/g, '')

        e.target.value = zipCode;

        if (zipCode.length === 5) {

            fetch(`${process.env.REACT_APP_URL_ZIP_CODE}?zipCode=${zipCode}`)
                .then(response => response.json())
                .then((cities) => {

                    if (cities?.length) {

                        dispatch(actionShoppingFromCities(cities))

                    } else {

                        throw 'no cities found'

                    }

                }).catch(e => {

                    dispatch(actionShoppingFromError(true))

                })

        } else {

            clearShoppingFrom()

        }

    }, []);


    const onFocus = useCallback(() => {

        setFocused(true)

    }, []);

    const onBlur = useCallback(() => {

        setFocused(false)

    }, []);

    const clearInput = useCallback(() => {

        inputRef.current.value = '';
        clearShoppingFrom()

    }, []);

    useEffect(() => {

        // clear input after shopping from city updated
        if (!shoppingFromMenu) {
            clearInput();
        }

    }, [shoppingFromMenu]);

    return (

        <div className="field column">

            <div className={`inputBox ${focused ? 'active' : ''}`}>

                <label htmlFor="zipCode">{DEFAULT_TEXT['shoppingFrom.placeholder']}</label>

                <input type="text" id="zipCode" placeholder={DEFAULT_TEXT['shoppingFrom.placeholder']} onFocus={onFocus} onBlur={onBlur} onChange={onChange} maxLength={5} ref={inputRef} />

                <div className="clear row" onClick={clearInput}>

                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z" /></svg>

                </div>

            </div>

        </div>
    );
})

export default ShoppingFromInput;