import React, { useCallback, useContext } from 'react';
import { SelectPaymentMethodBox } from '../../style/main';
import InCart from './InCart';
import { DEFAULT_TEXT } from '../../content';
import { MemberPopContext } from '../MemberPop';
import LdsRing from '../snippet/LdsRing';
import PaypalCheckout from '../PaypalCheckout';

const SelectPaymentMethod = React.memo(() => {

    const { loading, refillError, paypalReceiptLoading } = useContext(MemberPopContext)

    return (
        <SelectPaymentMethodBox className={`column`}>
            <InCart />
            <div className="selectPaymentMethodInner column">
                <div className="selectPaymentMethodTitle row">
                    {DEFAULT_TEXT['member.pop.title.SELECT_PAYMENT_METHOD.title']}
                </div>
                <div className="cards column">
                    {refillError && <div className='error'>{DEFAULT_TEXT['member.pop.paymentMethod.error']}</div>}
                    {/* <div className="cardSelectBox paypal row">
                        <Paypal />
                    </div> */}
                    <PaypalCheckout />
                </div>
            </div>
            {loading && <div className="cover"></div>}
            {paypalReceiptLoading &&
                <div className="completingPaypalOrder column">
                    <LdsRing />
                </div>
            }
        </SelectPaymentMethodBox>
    );
})

export default SelectPaymentMethod;