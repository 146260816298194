import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Link, useSearchParams } from 'react-router-dom';
import { usePages } from '../providers/PagesProvider';
import { PagesBox } from '../style/main';
import { auctionsPerPage } from './Homepage';

const Page = React.memo(({ page, params }) => {

    const { currentPage } = usePages();

    const onClick = useCallback(() => {

        window.scrollTo({
            top: 0,
            left: 0
        });

    }, []);

    return (
        <>
            <Link to={`?page=${page}${params}`} onClick={onClick}>
                <div className={`page row ${page === currentPage ? 'selected' : ''}`}>
                    {page}
                </div>
            </Link>
        </>
    );
})

const Pages = React.memo(({ totalPages }) => {

    const [searchParams, setSearchParams] = useSearchParams();

    let params = searchParams.get('filter')?.split(' ')?.join('+') || ''

    if (params) {
        params = `&filter=${params}`
    }

    const { currentPage } = usePages();

    const onClick = useCallback((e) => {

        window.scrollTo({
            top: 0,
            left: 0
        });

    }, []);

    return (
        <>

            <PagesBox className='row'>

                <Link to={`/?page=${currentPage === 1 ? 1 : currentPage - 1}${params}`} className={`sideArrow row ${currentPage === 1 ? 'default' : ''}`} onClick={onClick}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" /></svg>
                </Link>

                <div className="pages row">
                    {[...Array(totalPages).keys()].map(page => <Page key={page + 1} page={page + 1} params={params} />)}
                </div>

                <Link to={`/?page=${currentPage === totalPages ? totalPages : currentPage + 1}${params}`} className={`sideArrow row ${currentPage === totalPages ? 'default' : ''}`} onClick={onClick}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M7.33 24l-2.83-2.829 9.339-9.175-9.339-9.167 2.83-2.829 12.17 11.996z" /></svg>
                </Link>

            </PagesBox>

        </>
    );
})

export default Pages;