import React from 'react';
import { useSelector } from 'react-redux';
import { DEFAULT_TEXT } from '../content';
import { useCartMode } from '../providers/CartMode';
import { AuctionBottomTitle, Auctions } from '../style/main';
import AuctionSingle from './AuctionSingle';

const AuctionPageBottom = React.memo(({ auctionId }) => {

    const { cartMode } = useCartMode();

    let auctions = useSelector((state) => state.auctions.auctions)

    auctions = auctions?.filter(x => x.id !== auctionId)?.slice(0, 4)

    return (
        <>

            {!cartMode &&
                <AuctionBottomTitle>
                    {DEFAULT_TEXT['auction.endingSoon']}
                </AuctionBottomTitle>
            }

            <Auctions className='row auctionPageBottom'>
                {!auctions?.length ?
                    [...Array(9).keys()].map(x => <AuctionSingle key={x} loading={true} />)
                    :
                    auctions.map((x, i) => <AuctionSingle key={x.id} {...x} />)
                }
            </Auctions>
        </>
    );
})

export default AuctionPageBottom;