import { Auth0Provider } from '@auth0/auth0-react';
import React from 'react';
import { useNavigate } from 'react-router';

const AuthProvider = React.memo(({ children }) => {

    const navigate = useNavigate();

    const onRedirectCallback = (appState) => navigate(appState?.returnTo || window.location.pathname);

    const providerConfig = {
        domain: process.env.REACT_APP_0AUTH_DOMAIN,
        clientId: process.env.REACT_APP_0AUTH_CLIENT_ID,
        onRedirectCallback,
        authorizationParams: {
            redirect_uri: window.location.origin,
        },
    };

    return (
        <Auth0Provider
            {...providerConfig}
        >
            {children}
        </Auth0Provider>
    );
})

export default AuthProvider;