export const resetPasswordFetch = async ({ email }) => {

    const body = {
        email
    }

    await fetch(process.env.REACT_APP_URL_CREATE_FORGOT_PASSWORD, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    })


}