import React, { useEffect, useState, useRef, useCallback } from 'react';
import { DEFAULT_TEXT } from '../../../content';
import { useHelpProvider } from '../../../providers/HelpProvider';
import { HelpMenuTitleMobileBox, headerHeight } from '../../../style/main';

const HelpMenuTitleMobile = React.memo(() => {

    const { helpMenuOpen, setHelpMenuOpen, helpMenuTitleMobileRef } = useHelpProvider();

    const onClick = useCallback(() => {

        setHelpMenuOpen(open => !open)

    }, []);

    return (
        <HelpMenuTitleMobileBox className={`row ${helpMenuOpen ? 'active' : ''}`} ref={helpMenuTitleMobileRef} onClick={onClick}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 24" className="open"><path d="M0,5v2h20V5H0z M0,11v2h20v-2H0z M0,17v2h20v-2H0z"></path></svg>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="close"><path d="M4.4,2.8L2.8,4.4l7.6,7.6l-7.6,7.6l1.7,1.7l7.6-7.6l7.6,7.6l1.7-1.7L13.7,12l7.6-7.6l-1.7-1.7L12,10.3L4.4,2.8z"></path></svg>
            <span>{DEFAULT_TEXT['page.help.mobileMenu']}</span>
        </HelpMenuTitleMobileBox>
    );
})

export default HelpMenuTitleMobile;