import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actionToggleMyAccountMenu } from '../../reducers/layout';
import { avatarImageUrl } from '../helpers';

const AvatarRight = React.memo(() => {

    const avatar = useSelector((state) => state.user.avatar)

    const dispatch = useDispatch();

    const openMyAccountMenu = useCallback(() => {

        dispatch(actionToggleMyAccountMenu());

    }, []);

    if (!avatar) {
        return null
    }

    return (
        <div className="avatarBox row" onClick={openMyAccountMenu}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M 7.4296875 9.5 L 5.9296875 11 L 12 17.070312 L 18.070312 11 L 16.570312 9.5 L 12 14.070312 L 7.4296875 9.5 z"></path>
            </svg>

            <picture>
                <source media="(min-width: 768px)" srcSet={`${avatarImageUrl(avatar)}`} />
                <source media="(min-width: 375px)" srcSet={`${avatarImageUrl(avatar)}`} />
                <img src={`${avatarImageUrl(avatar)}`} />
            </picture>

        </div>
    );
})

export default AvatarRight;