import { withAuthenticationRequired } from "@auth0/auth0-react";
import React from "react";
import AllWrap from "./AllWrap";

export const AuthenticationGuard = ({ component }) => {
    const Component = withAuthenticationRequired(component, {
        onRedirecting: () => (
            ''
        ),
    });

    return <AllWrap><Component /></AllWrap>;
};