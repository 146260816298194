import React, { useEffect } from 'react';
import { useMemberPopProvider } from '../providers/MemberPopProvider';

const Signin = React.memo(({ signup }) => {

    const { handleLogin } = useMemberPopProvider();

    useEffect(async () => {

        if (signup) {
            handleLogin({ returnTo: '/', signup: true })
        } else {
            handleLogin({ returnTo: '/' })
        }

    }, [signup]);

    return null
})

export default Signin;