import React, { useCallback, useRef } from 'react';
import Slider from "react-slick";
import { HowItWorksBox } from '../style/main';
import { HELP_BRANDS } from '../content/brands';
import { assetPrefix } from './helpers';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { DEFAULT_TEXT } from '../content';
import Help from './help/Help';
import { useHelpProvider } from '../providers/HelpProvider';

const brands = [...HELP_BRANDS, ...HELP_BRANDS]

const settings = {
    dots: false,
    arrows: false,
    autoplay: true,
    infinite: true,
    speed: 500,
    autoplaySpeed: 1500,
    slidesToShow: 15,
    slidesToScroll: 1,
    centerMode: true,
    responsive: [
        {
            breakpoint: 2500,
            settings: {
                slidesToShow: 12,
            }
        },
        {
            breakpoint: 1680,
            settings: {
                slidesToShow: 10,
            }
        },
        {
            breakpoint: 1440,
            settings: {
                slidesToShow: 9,
            }
        },
        {
            breakpoint: 1152,
            settings: {
                slidesToShow: 7,
            }
        },
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 6,
            }
        },
        {
            breakpoint: 1023,
            settings: {
                slidesToShow: 5,
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 4,
            }
        },
        {
            breakpoint: 550,
            settings: {
                slidesToShow: 2,
            }
        }
    ]
};

const HowItWorks = React.memo(() => {

    const { helpBreadCrumbRef } = useHelpProvider();

    const scrollToAuction = useCallback(() => {

        const headerBox = document.getElementById('headerBox')

        window.scrollTo({
            'behavior': 'smooth',
            'left': 0,
            'top': helpBreadCrumbRef.current.offsetTop - headerBox.offsetHeight
        });
    }, []);

    return (
        <HowItWorksBox className='column'>
            <div className="introTop column">
                <h1>{DEFAULT_TEXT['howItWorks.h1']}</h1>
                <div className='subText'>{DEFAULT_TEXT['howItWorks.subText']}</div>
                <div className="arrowBox column" onClick={scrollToAuction}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M 16 4 C 9.382813 4 4 9.382813 4 16 C 4 22.617188 9.382813 28 16 28 C 22.617188 28 28 22.617188 28 16 C 28 9.382813 22.617188 4 16 4 Z M 16 6 C 21.535156 6 26 10.464844 26 16 C 26 21.535156 21.535156 26 16 26 C 10.464844 26 6 21.535156 6 16 C 6 10.464844 10.464844 6 16 6 Z M 15 10 L 15 18.125 L 12.71875 15.84375 L 11.28125 17.25 L 15 20.96875 L 15 21 L 15.03125 21 L 16 21.96875 L 20.71875 17.25 L 19.28125 15.84375 L 17 18.125 L 17 10 Z" /></svg>
                    <span>Start Guide</span>
                </div>
            </div>

            <Slider {...settings}>
                {brands.map((brand, i) => <div className="slideInner" key={i}>
                    <img src={assetPrefix({ path: `brands/${brand.toLowerCase()}.svg` })} />
                </div>)}
            </Slider>

            <Help />

        </HowItWorksBox>
    );
})

export default HowItWorks;