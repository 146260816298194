import React, { useEffect, useCallback } from 'react';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { DEFAULT_TEXT } from '../../content';
import { CURRENCY } from '../../content/currency';
import { amountCost } from '../helpers';
import { MemberPopContext, MEMBER_POP_STEPS } from '../MemberPop';

const Credits = React.memo(() => {

    const CREDIT_PACKS = useSelector((state) => state.auctions.CREDIT_PACKS)
    const memberPop = useSelector((state) => state.user.memberPop)

    return (
        <div className={`creditsBox column ${memberPop !== MEMBER_POP_STEPS.SELECT_CREDIT_PACK ? 'hide' : ''}`}>
            {CREDIT_PACKS?.map((x, i) => <CreditBox key={x.bid_amount} {...x} />)}
        </div>
    );
})

const CreditBox = React.memo(({ bid_amount, amount, SKU, PRODUCT_TYPE }) => {

    const uid = useSelector((state) => state.user.uid)

    const { loading, CREDIT_PACKAGE, setCreditPackage, paymentIntentId, paypalOrderId } = useContext(MemberPopContext)

    const updatePaymentIntent = useCallback(async () => {

        if (loading) {
            return
        }

        setCreditPackage(PRODUCT_TYPE)

    }, [loading, PRODUCT_TYPE]);

    return (
        <div className={`credit row ${PRODUCT_TYPE === CREDIT_PACKAGE ? 'selected' : ''}`} onClick={updatePaymentIntent}>

            <div className="left row">

                <div className='radio row'>
                    <div className="circle"></div>
                </div>

                <div className="amount row">
                    <span>{bid_amount}</span>
                    <div className="sub">{DEFAULT_TEXT['member.pop.bidSelect.bids']}</div>
                </div>

            </div>

            <div className="cost">
                {CURRENCY[process.env.REACT_APP_ISO_CODE].dollar}{amountCost(amount)}
            </div>

        </div>
    );
})

export default Credits;