import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useCartMode } from '../providers/CartMode';
import { usePages } from '../providers/PagesProvider';
import { Auctions } from '../style/main';
import FeatureBox from './snippet/FeatureBox';
import Pages from './Pages';
import Breadcrumbs from './snippet/BreadcrumbsFilter';
import AuctionSingle from './AuctionSingle';
import { useSearchParams } from 'react-router-dom';
import IntroVideo from './snippet/IntroVideo';
import { checkWinner, scrollTop } from './helpers';
export const auctionsPerPage = 48;

const Homepage = React.memo(() => {

    const { cartMode } = useCartMode();

    const breadcrumbRef = useRef(null)

    const userLoaded = useSelector((state) => state.user.userLoaded)
    const uid = useSelector((state) => state.user.uid)

    let auctions = useSelector((state) => state.auctions.auctions)

    let [searchParams, setSearchParams] = useSearchParams();

    const filters = searchParams?.get('filter')?.split(' ') || [];

    useEffect(() => {
        scrollTop()
    }, []);

    if (filters.length) {
        const filtered = auctions.filter(({ BRAND }) => filters.includes(BRAND))

        if (filtered.length) {
            auctions = filtered;
        }
    }

    const breadcrumbTotal = auctions?.length;

    const { currentPage } = usePages();
    const start = (currentPage - 1) * auctionsPerPage;
    const end = start + auctionsPerPage;
    let auctionsPage = auctions.slice(start, end)

    const totalPages = Math.ceil(auctions?.length / auctionsPerPage);

    if (uid) {
        auctionsPage = auctionsPage.map(auction => {

            const { history, sold } = auction;

            let won = false;

            if (sold) {
                won = checkWinner({ uid, history })
            }

            return {
                ...auction,
                won
            }

        })
    }

    return (
        <>

            {!cartMode &&
                <IntroVideo />
            }

            <div className="wrap">

                <Breadcrumbs breadcrumbTotal={breadcrumbTotal} breadcrumbRef={breadcrumbRef} />

                <Auctions className='row'>
                    {!userLoaded || !auctionsPage?.length ?
                        [...Array(9).keys()].map(x => <AuctionSingle key={x} loading={true} />)
                        :
                        auctionsPage.map(x => <AuctionSingle key={x.id} {...x} />)
                    }
                </Auctions>

                <Pages totalPages={totalPages} />
            </div>

        </>
    );
})

export default Homepage;