import React, { useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_TEXT } from '../../../content';
import { actionCloseMenus } from '../../../reducers/layout';
import DealsDropdownRow from './DealsDropdownRow';


const DealsDropdown = React.memo(({ liDropdownScrollRef }) => {

    const dispatch = useDispatch();

    const brands = useSelector((state) => state.auctions.brands)
    const dealDropdownOpen = useSelector((state) => state.layout.dealDropdownOpen)

    const viewDeals = useCallback(() => {

        dispatch(actionCloseMenus({ menu: true, dealDropdown: true }))

    }, []);

    const closeDealDropdown = useCallback((e) => {
        e.stopPropagation();
        dispatch(actionCloseMenus({ dealDropdown: true }))
    }, []);

    return (
        <>

            {dealDropdownOpen &&
                <svg className='upArrow' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 21.3">
                    <path d="M24,21.3H0l12-20L24,21.3z" />
                </svg>
            }

            <div className={`liDropdownBox column ${dealDropdownOpen ? 'active' : ''}`} onClick={e => e.stopPropagation()}>

                <div className='liDropdown column'>

                    <div className="liDropdownScroll column" ref={liDropdownScrollRef}>

                        <div className='tabType row close' onClick={closeDealDropdown}>
                            <div className="closeBox row">
                                <svg className='arrowClose' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                    <path d="M 7.4296875 9.5 L 5.9296875 11 L 12 17.070312 L 18.070312 11 L 16.570312 9.5 L 12 14.070312 L 7.4296875 9.5 z" />
                                </svg>
                            </div>
                        </div>

                        {brands?.map((brand, i) => <DealsDropdownRow key={i} {...brand} viewDeals={viewDeals} />)}

                    </div>

                    <div className="buttonBox row">
                        <button onClick={viewDeals}>{DEFAULT_TEXT['button.dealTabHeader']}</button>
                    </div>

                </div>

            </div>

        </>
    );
})

export default DealsDropdown;