import React from 'react';
import { HeaderBox } from '../../style/main';
import Logo from '../snippet/Logo';
import Menu from './Menu';
import MenuMobileIcon from './MenuMobileIcon';
import AvatarRight from './AvatarRight';
import MenuMyAccount from './MenuMyAccount';
import { useSelector } from 'react-redux';
import { DEFAULT_TEXT } from '../../content';
import HeaderCredits from './HeaderCredits';
import ShoppingFrom from './shoppingFrom/ShoppingFrom';
import CornerPiece from './CornerPiece';
import { useMemberPopProvider } from '../../providers/MemberPopProvider';

const Header = React.memo(({ helpPage }) => {

    const uid = useSelector((state) => state.user.uid)
    const userLoaded = useSelector((state) => state.user.userLoaded)

    const { handleLogin } = useMemberPopProvider();

    return (
        <>
            <HeaderBox className="row" id="headerBox">

                <div className="leftSideHeader row">
                    <MenuMobileIcon />
                    <Logo />
                    <Menu />
                </div>

                {userLoaded &&
                    <div className="rightSideHeader row" id="rightSideHeader">
                        {uid ?
                            <>
                                <HeaderCredits />
                                <AvatarRight />
                                <MenuMyAccount />

                            </>
                            :
                            <>
                                <div className='signinLink row' onClick={handleLogin}>
                                    <div>{DEFAULT_TEXT['button.signin']}</div>
                                </div>
                                <div className="btn row" onClick={() => handleLogin({ signup: true })}>
                                    <div>{DEFAULT_TEXT['button.join']}</div>
                                </div>
                            </>
                        }
                    </div>
                }

                <CornerPiece />

            </HeaderBox>

            {!helpPage && <ShoppingFrom />}
        </>
    );
})

export default Header;