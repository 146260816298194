import React, { useEffect, useCallback, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { DEFAULT_TEXT } from '../../content';
import { useCartMode } from '../../providers/CartMode';
import { actionMemberPop, actionCart } from '../../reducers/user';
import { BidButtonBox } from '../../style/main';
import { MEMBER_POP_STEPS } from '../MemberPop';
import { SOCKET_CHANNELS, bc, socket, socket_bid_channel } from '../Sockets';
import { useMemberPopProvider } from '../../providers/MemberPopProvider';
import { getUserCredentials } from '../helpers';

const BidButton = React.memo(({ loading, id, sold, priceRef, history, countdownZero, won, ORDER_STATUS, CREDIT_PACKAGE }) => {

    const accessToken = useSelector((state) => state.user.accessToken)

    const { handleLogin } = useMemberPopProvider();

    const { cartMode } = useCartMode();

    const { pathname } = useLocation();

    const dispatch = useDispatch();

    const timeout = useRef(null)
    const timeoutLockBid = useRef(null)
    const bidButtonRef = useRef(null)

    const [alreadyHighBidder, setAlreadyHighBidder] = useState();
    const [lockBid, setLockBid] = useState(false);

    const uid = useSelector((state) => state.user.uid)
    const credits = useSelector((state) => state.user.credits)

    const highBidder = history?.length ? history[0]?.uid : false

    useEffect(() => {

        if (uid && (uid !== highBidder)) {

            clearTimeout(timeout.current)
            setAlreadyHighBidder(false)

        }

    }, [id, highBidder]);

    const bid = useCallback(async () => {

        if (cartMode) {

            dispatch(actionMemberPop(MEMBER_POP_STEPS.CART_MODE_SHIPPING));

        } else if (!uid) {

            handleLogin({ signup: true })

        } else if (!credits || credits <= 0) {

            // no credits
            dispatch(actionMemberPop(MEMBER_POP_STEPS.SELECT_CREDIT_PACK));

        } else if (sold || countdownZero) {

            return

        } else {

            // already the high bidder
            if (uid === highBidder) {

                clearTimeout(timeout.current)

                setAlreadyHighBidder(true)

                timeout.current = setTimeout(() => {

                    setAlreadyHighBidder(false)

                }, 1000)

            } else if (lockBid) {

                return

            } else {

                setLockBid(true)

                const priceRefCurrent = priceRef?.current;
                const windowWidth = window.innerWidth;
                const windowHeight = window.innerHeight;

                if (windowWidth < 768 && priceRefCurrent) {

                    const headerBox = document.getElementById('headerBox')
                    const headerBoxHeight = headerBox.offsetHeight;
                    const priceDistanceFromTop = priceRefCurrent.getBoundingClientRect().top - headerBoxHeight;
                    const priceTop = priceRefCurrent.offsetTop - headerBoxHeight - 10;

                    // 1ST CHECK IS TO SEE IF PRICE IS OUT OF TOP OF WINDOW. 2ND CHECK TO SEE IF PRICE IS OUT OF BOTTOM OF THE WINDOW
                    if (priceDistanceFromTop < 0 || windowHeight - priceDistanceFromTop - priceRefCurrent.offsetHeight - bidButtonRef.current.offsetHeight < 0) {

                        window.scrollTo({
                            top: priceTop,
                            behavior: 'smooth',
                        });

                    }

                }

                const data = { id, accessToken, ...getUserCredentials() };

                //IF SOCKET IS CONNECTED ON THIS TAB PLACE THE BID, IF NOT BROADCAST TO THE TAB WITH THE SOCKET CONNECTED TO PLACE THE BID
                if (socket?.connected) {
                    socket.emit(socket_bid_channel, data);
                } else {
                    bc.postMessage({
                        channel: SOCKET_CHANNELS['emitBid'],
                        data
                    });
                }

            }


        }

    }, [uid, id, accessToken, highBidder, sold, countdownZero, priceRef, cartMode, lockBid, credits, pathname]);

    const ORDER_STATUS_BUTTON = useCallback(async () => {

        switch (ORDER_STATUS) {
            case 'PAY_NOW':
                dispatch(actionCart(id));
                break;
            case 'SHIPPED':
                // code block
                break;
            case 'COMPLETE':
                // code block
                break;
            default:
            // code block
        }


    }, [id, ORDER_STATUS]);



    useEffect(() => {

        if (lockBid) {

            timeoutLockBid.current = setTimeout(() => {
                setLockBid(false)
            }, 1000)

        }

        return () => {
            clearTimeout(timeoutLockBid.current)
        }

    }, [lockBid]);

    return (
        <BidButtonBox className='column' ref={bidButtonRef}>

            <button onClick={CREDIT_PACKAGE ? null : won ? ORDER_STATUS_BUTTON : loading ? null : bid} className={uid ? 'signedIn' : ''}>
                {won ? DEFAULT_TEXT[`button.won.${ORDER_STATUS}`]
                    : CREDIT_PACKAGE ? DEFAULT_TEXT['button.won.COMPLETE']
                        : !cartMode ? DEFAULT_TEXT['button.bid']
                            : DEFAULT_TEXT['button.bid.carMode']
                }
            </button>

            <div className={`alreadyHightBidder column ${alreadyHighBidder ? 'active' : ''}`}>
                <div className="text">{DEFAULT_TEXT['auction.highBidder']}</div>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 17.948">
                    <polygon id="arrow-37" points="12,17.948 0,-0.052 24,-0.052 " />
                </svg>
            </div>

        </BidButtonBox>
    );
})

export default BidButton;