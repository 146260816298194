import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actionMemberPop } from '../../reducers/user'
import { assetPrefix } from '../helpers';
import { MEMBER_POP_STEPS } from '../MemberPop';

const HeaderCredits = React.memo(() => {

    const dispatch = useDispatch();
    const credits = useSelector((state) => state.user.credits)

    const openAddCredits = useCallback(() => {

        dispatch(actionMemberPop(MEMBER_POP_STEPS.SELECT_CREDIT_PACK));

    }, []);

    return (

        <div className="credits row" onClick={openAddCredits}>
            <img src={assetPrefix({ path: 'credit.svg' })} />
            <span>{credits}</span>
        </div>
    );
})

export default HeaderCredits;