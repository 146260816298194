import React from 'react';
import { DEFAULT_TEXT, USER_DELETE_FUNCTION_TAG } from '../../content';
import { useAuth0 } from '@auth0/auth0-react';
import { helpPaths } from '../../content/helpPaths';

const UserDeleted = React.memo(() => {

    const text = DEFAULT_TEXT['member.pop.paragraph.USER_DELETED'].split(USER_DELETE_FUNCTION_TAG)

    const contactUsPage = `${window.location.origin}${DEFAULT_TEXT['url.help']}/${helpPaths.CONTACT_US}`;

    const {
        logout
    } = useAuth0();

    const handleLogout = () => {
        logout({
            logoutParams: {
                returnTo: contactUsPage
            }
        });
    }

    return (
        <div className='userDeleted'>
            {text.map((text, i) => {
                return <React.Fragment key={i}>
                    {i > 0 && <span className="logout" onClick={handleLogout}>{DEFAULT_TEXT['member.pop.paragraph.logout.tag.USER_DELETED']}</span>}
                    <span>{text}</span>
                </React.Fragment>
            })}
        </div>
    );
})

export default UserDeleted;