import React from 'react';
import { assetPrefix } from '../helpers';

const ProductImage = React.memo(({ SKU, TITLE, large }) => {

    const ISO_CODE = process.env.REACT_APP_ISO_CODE;
    const LANGUAGE = process.env.REACT_APP_LANGUAGE.toUpperCase();

    const filename = `${LANGUAGE}_${SKU}.${large ? 'jpg' : 'png'}`
    let filepath = assetPrefix({ path: `products/${ISO_CODE}/${large ? 'large' : 'small'}/${filename}${large ? '?height=750&width=500' : ''}` })

    return (
        <img src={filepath} className="product" alt={TITLE} data-product={large ? "THUMB_LARGE" : "THUMB_SMALL"} />
    );
})

export default ProductImage;