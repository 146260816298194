export const HELP_GROUPS = Object.freeze({
    GETTING_STARTED: 'GETTING_STARTED',
    AUCTIONS: 'AUCTIONS',
    YOUR_ACCOUNT: 'YOUR_ACCOUNT',
    PRODUCTS: 'PRODUCTS',
    SHIPPING: 'SHIPPING',
    PAYMENT_METHODS: 'PAYMENT_METHODS',
    RESTRICTIONS: 'RESTRICTIONS',
    CONTACT_US: 'CONTACT_US',
})

export const helpPaths = Object.freeze({
    [HELP_GROUPS.GETTING_STARTED]: 'getting-started',
    [HELP_GROUPS.AUCTIONS]: 'auctions',
    [HELP_GROUPS.YOUR_ACCOUNT]: 'your-account',
    [HELP_GROUPS.PRODUCTS]: 'products',
    [HELP_GROUPS.SHIPPING]: 'shipping',
    [HELP_GROUPS.PAYMENT_METHODS]: 'payment-methods',
    [HELP_GROUPS.RESTRICTIONS]: 'restrictions',
    [HELP_GROUPS.CONTACT_US]: 'contact-us',
})