import React, { useCallback, useRef } from 'react';
import Profile from './Profile';
import Account from './Account';
import { useSelector } from 'react-redux';
import { MY_ACCOUNT_TAB_KEYS } from '../MyAccount';
import ShippingInfo from './ShippingInfo';

const TabBox = React.memo(({ title, keyVar, tab, setTab, shippingInfoRef, scrollToTop }) => {

    const uid = useSelector((state) => state.user.uid)

    const bidHistoryRef = useRef(null)

    const changeTab = useCallback((e) => {

        if (!uid) {
            return
        }

        let value = keyVar;

        // close the box if the same var is used already
        if (tab == keyVar) {

            value = false;

        } else {

            const selectedTab = document.getElementById(value)
            scrollToTop(selectedTab)

        }

        setTab(value)

    }, [setTab, keyVar, tab, uid, scrollToTop]);

    return (
        <div className={`tabBox column ${keyVar} ${keyVar === tab ? 'active' : ''}`} onClick={changeTab}
            ref={
                keyVar === MY_ACCOUNT_TAB_KEYS.BID_HISTORY ? bidHistoryRef :
                    keyVar === MY_ACCOUNT_TAB_KEYS.SHIPPING_INFO ? shippingInfoRef : null
            }
            id={keyVar}>
            <div className="titleBar row">
                <span>{title}</span>
                <svg className="arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M 7.4296875 9.5 L 5.9296875 11 L 12 17.070312 L 18.070312 11 L 16.570312 9.5 L 12 14.070312 L 7.4296875 9.5 z"></path></svg>
            </div>
            <div className="infoBox column" onClick={e => e.stopPropagation()}>
                {keyVar === MY_ACCOUNT_TAB_KEYS.PROFILE ?
                    <Profile />
                    :
                    keyVar === MY_ACCOUNT_TAB_KEYS.SHIPPING_INFO ?
                        <ShippingInfo />
                        :
                        keyVar === MY_ACCOUNT_TAB_KEYS.ACCOUNT ?
                            <Account />
                            :
                            ''
                }
            </div>
        </div >

    );
})

export default TabBox;