import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { DEFAULT_TEXT } from '../../content';
import { SuccessCheckBlurb } from '../../style/main';
import LdsRing from '../snippet/LdsRing';
import { getUserCredentials } from '../helpers';

const FIELDS = Object.freeze({
    NAME: 'name',
    EMAIL: 'email',
    SUBJECT: 'subject',
    MESSAGE: 'message',
    PHONE_NUMBER: 'phoneNumber'
})

const HelpContact = React.memo(() => {

    const uid = useSelector((state) => state.user.uid)
    const accessToken = useSelector((state) => state.user.accessToken)

    const { register, handleSubmit, getValues, setError, reset, formState: { errors } } = useForm();

    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false);
    const [baseError, setBaseError] = useState(false);

    const onSubmit = useCallback(async () => {

        setBaseError(false)
        setSuccess(false)

        //FOR LOGGED IN MEMEBERS KEEP TRACK WHO SENT THE MESSAGE WITH ACCESS TOKEN, NOT LOGGED IN IS UNDEFINED
        const name = getValues("name");
        const email = getValues("email");
        const subject = getValues("subject");
        const message = getValues("message");
        const phoneNumber = getValues("phoneNumber");

        setLoading(true)

        let response = await fetch(process.env.REACT_APP_URL_CONTACT_US, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ name, email, subject, message, uid, accessToken, phoneNumber, userInfo: { ...getUserCredentials() } })
        })

        response = await response.json();

        setLoading(false)

        if (response.success) {

            reset({
                [FIELDS.NAME]: '',
                [FIELDS.EMAIL]: '',
                [FIELDS.SUBJECT]: '',
                [FIELDS.MESSAGE]: '',
                [FIELDS.PHONE_NUMBER]: ''
            })

            setSuccess(true)


        } else {

            setBaseError(true)

        }

    }, [accessToken])

    const onFocus = useCallback((e) => {

        e.target.parentElement.classList.add('active');

    }, []);

    const onBlur = useCallback((e) => {

        e.target.parentElement.classList.remove('active');

    }, []);

    return (
        <form onSubmit={handleSubmit(onSubmit)} className='contactUs column'>
            <div className="field column">
                <div className='inputBox row'>
                    <label htmlFor={FIELDS.NAME}>{DEFAULT_TEXT['contactUs.form.name.label']}</label>

                    <input
                        type="text"
                        name={FIELDS.NAME}
                        placeholder={DEFAULT_TEXT['contactUs.form.name.placeholder']}
                        {...register(FIELDS.NAME, {
                            required: DEFAULT_TEXT['contactUs.form.error.name.required'],
                        })}
                        onFocus={onFocus}
                        onBlur={onBlur}
                    />

                </div>

                {errors?.name?.message && <div className="error">{errors?.name?.message}</div>}

            </div>
            <div className="field column">
                <div className='inputBox row'>
                    <label htmlFor={FIELDS.EMAIL}>{DEFAULT_TEXT['form.email.label']}</label>

                    <input
                        type="email"
                        name={FIELDS.EMAIL}
                        placeholder={DEFAULT_TEXT['form.email.placeholder']}
                        {...register(FIELDS.EMAIL, {
                            required: DEFAULT_TEXT['form.email.error.required'],
                            pattern: {
                                value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                message: DEFAULT_TEXT['form.email.error.valid']
                            }
                        })}
                        onFocus={onFocus}
                        onBlur={onBlur}
                    />

                </div>

                {errors?.email?.message && <div className="error">{errors?.email?.message}</div>}

            </div>
            <div className="field column">
                <div className='inputBox row'>
                    <label htmlFor={FIELDS.PHONE_NUMBER}>{DEFAULT_TEXT['form.phoneNumber.label']}</label>

                    <input
                        type="text"
                        name={FIELDS.PHONE_NUMBER}
                        placeholder={DEFAULT_TEXT['form.phoneNumber.placeholder']}
                        {...register(FIELDS.PHONE_NUMBER)}
                        onFocus={onFocus}
                        onBlur={onBlur}
                    />

                </div>
            </div>
            <div className="field column">
                <div className='inputBox row'>
                    <label htmlFor={FIELDS.SUBJECT}>{DEFAULT_TEXT['contactUs.form.subject.label']}</label>

                    <input
                        type="text"
                        name={FIELDS.SUBJECT}
                        placeholder={DEFAULT_TEXT['contactUs.form.subject.placeholder']}
                        {...register(FIELDS.SUBJECT, {
                            required: DEFAULT_TEXT['contactUs.form.error.subject.required'],
                        })}
                        onFocus={onFocus}
                        onBlur={onBlur}
                    />

                </div>

                {errors?.subject?.message && <div className="error">{errors?.subject?.message}</div>}

            </div>
            <div className="field column">
                <div className='inputBox row'>
                    <label htmlFor={FIELDS.MESSAGE}>{DEFAULT_TEXT['contactUs.form.message.label']}</label>

                    <textarea
                        name={FIELDS.MESSAGE}
                        placeholder={DEFAULT_TEXT['contactUs.form.message.placeholder']}
                        {...register(FIELDS.MESSAGE, {
                            required: DEFAULT_TEXT['contactUs.form.error.message.required'],
                        })}
                        onFocus={onFocus}
                        onBlur={onBlur}
                    />

                </div>

                {errors?.message?.message && <div className="error">{errors?.message?.message}</div>}

            </div>



            {success && <SuccessCheckBlurb className='row'>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M 12 2 C 6.486 2 2 6.486 2 12 C 2 17.514 6.486 22 12 22 C 17.514 22 22 17.514 22 12 C 22 10.874 21.803984 9.7942031 21.458984 8.7832031 L 19.839844 10.402344 C 19.944844 10.918344 20 11.453 20 12 C 20 16.411 16.411 20 12 20 C 7.589 20 4 16.411 4 12 C 4 7.589 7.589 4 12 4 C 13.633 4 15.151922 4.4938906 16.419922 5.3378906 L 17.851562 3.90625 C 16.203562 2.71225 14.185 2 12 2 z M 21.292969 3.2929688 L 11 13.585938 L 7.7070312 10.292969 L 6.2929688 11.707031 L 11 16.414062 L 22.707031 4.7070312 L 21.292969 3.2929688 z"></path></svg>

                <div className="text">
                    {DEFAULT_TEXT['contactUs.form.success']}
                </div>
            </SuccessCheckBlurb>}

            {baseError && <div className="error base">{DEFAULT_TEXT['contactUs.form.error.base']}</div>}

            <button type="submit">

                {!loading ?

                    DEFAULT_TEXT['contactUs.form.button.submit']

                    :

                    <LdsRing />

                }

            </button>

        </form>
    );
})

export default HelpContact;