import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import DealTab from './dealsTab/DealTab';
import { Link, useLocation } from 'react-router-dom';
import { useMemberPopProvider } from '../../providers/MemberPopProvider';

const MenuTab = React.memo((props) => {

    const { pathname } = useLocation();

    const { handleLogin } = useMemberPopProvider();

    const { signedIn, mobileOnly, dealsTab, name, url, signinFunction } = props;

    const uid = useSelector((state) => state.user.uid)

    const startSignin = useCallback((e) => {
        e.stopPropagation();
        handleLogin();
    }, []);

    if (uid && signedIn) {
        return null
    }

    return (
        <li className={dealsTab ? 'dealsTab' : mobileOnly ? 'mobile' : ''}>

            {signinFunction ?
                <div className='liInner' onClick={startSignin}>{name}</div>
                : dealsTab ? <DealTab {...props} />
                    :
                    <Link className="liInner" to={url} state={pathname}>
                        <div>{name}</div>
                    </Link>
            }
        </li>
    );
})

export default MenuTab;