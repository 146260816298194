import React, { useEffect, useLayoutEffect, useRef, useCallback, createContext, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { actionBidsPurchased, actionMemberPop } from '../reducers/user';
import { getParams } from '../components/helpers';
import MemberPop, { MEMBER_POP_STEPS } from '../components/MemberPop';
import { socket } from '../components/Sockets';
import { useAuth0 } from '@auth0/auth0-react';

const events = ['mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart', 'resize'];

const MemberPopContext = createContext(undefined)

export const MemberPopProvider = React.memo(({ children }) => {

    const {
        loginWithRedirect
    } = useAuth0();

    const handleLogin = async (props) => {

        const { returnTo, signup } = props || {};

        const returnObject = {
            appState: {
                returnTo: returnTo || window.location.pathname,
            }
        }

        if (signup) {
            returnObject.authorizationParams = {
                screen_hint: "signup",
            }
        }

        await loginWithRedirect(returnObject);
    };

    const userTimeout = useRef(null)

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const { userLoaded, uid, memberPop, avatar, displayName, accessToken } = useSelector((state) => state.user)

    const CREDIT_PACKS = useSelector((state) => state.auctions.CREDIT_PACKS)

    useLayoutEffect(() => {

        // LOAD AVATAR POP FOR MEMBERS WHO HAVE NOT SELECTED AN AVATAR
        if (uid && userLoaded && !avatar) {
            dispatch(actionMemberPop(MEMBER_POP_STEPS.AVATARS));
        } else if (uid && userLoaded && !displayName) {
            //LOAD DISPLAY NAME FOR USERS WHO HAVE NOT ENTERED A DISPLAY NAME
            dispatch(actionMemberPop(MEMBER_POP_STEPS.NEW_DISPLAY_NAME));
        }

    }, [uid, userLoaded, avatar, displayName]);

    // AFTER PURCHASE
    useEffect(() => {

        if (!userLoaded || !CREDIT_PACKS) {
            return
        }

        const { redirect_status, BID_PURCHASE_PARAM } = getParams();

        if (BID_PURCHASE_PARAM && (redirect_status === 'succeeded')) {
            navigate(window.location.pathname, { replace: true });
            // // THIS WILL LAUNCH THE POPUP AND ALSO FILL IN THE BID PACKAGE IN REDUX
            dispatch(actionBidsPurchased(BID_PURCHASE_PARAM));
        }

    }, [userLoaded, CREDIT_PACKS]);

    const moved = useCallback(() => {

        const removeEventListener = () => {
            events.forEach((name) => {
                document.removeEventListener(name, resetTimer, true);
            });
        }

        removeEventListener()

        resetTimer();

        return removeEventListener

    }, []);

    const resetTimer = useCallback(() => {

        events.forEach((name) => {
            document.addEventListener(name, moved, true);
        });

        clearTimeout(userTimeout.current)

        userTimeout.current = setTimeout(() => {

            dispatch(actionMemberPop(MEMBER_POP_STEPS.IDLE_BOX));

            const body = document.querySelector('body');
            body.style.overflow = 'hidden';

            socket.disconnect()

        }, 1000 * 60 * 5)

    }, [socket]);

    useEffect(() => {
        resetTimer()
    }, []);

    return (
        <MemberPopContext.Provider value={{ handleLogin }}>
            {children}

            {(memberPop === MEMBER_POP_STEPS.IDLE_BOX) || (memberPop === MEMBER_POP_STEPS.USER_DELETED) || (uid && memberPop) ?
                <MemberPop />
                :
                ''
            }


        </MemberPopContext.Provider>
    );
})

export const useMemberPopProvider = () => useContext(MemberPopContext)

export default MemberPopProvider;
