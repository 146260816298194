import { PayPalButtons, PayPalScriptProvider, usePayPalScriptReducer } from '@paypal/react-paypal-js';
import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useContext, useState } from 'react';
import { MemberPopContext } from './MemberPop';
import { createReturnUrl, getUserCredentials } from './helpers';
import { useNavigate } from 'react-router';
import { DEFAULT_TEXT } from '../content';

export const PAYPAL_INIT = {
    "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
    "enable-funding": "paylater,venmo,card",
    "disable-funding": "",
    "data-sdk-integration-source": "integrationbuilder_sc",
};

const PaypalCheckout = React.memo(() => {

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const { paypalOrderId, setPaypalReceiptLoading, setLoading, CREDIT_PACKAGE, cart, setRefillError } = useContext(MemberPopContext)

    const accessToken = useSelector((state) => state.user.accessToken)
    const BID_PACK_AUCTION_DATA = useSelector((state) => state.auctions.BID_PACK_AUCTION_DATA);

    const [fatalError, setFatalError] = useState('');

    const order = { accessToken, ...getUserCredentials() }

    const WINNING_AUCTION_ID = cart?.WINNING_AUCTION_ID;

    if (WINNING_AUCTION_ID) {
        order.WINNING_AUCTION_ID = WINNING_AUCTION_ID;
    } else {
        order.CREDIT_PACKAGE = CREDIT_PACKAGE;
    }

    const thankYou = useCallback(async () => {

        const return_url = createReturnUrl({
            BID_PACK_AUCTION_DATA,
            PRODUCT_TYPE_CART: cart?.PRODUCT_TYPE,
            CREDIT_PACKAGE
        })

        window.location.href = return_url;

    }, [CREDIT_PACKAGE, cart, BID_PACK_AUCTION_DATA]);

    return (
        <>
            {fatalError && <div className="error errorInverse">{DEFAULT_TEXT[fatalError]}</div>}

            <PayPalScriptProvider options={PAYPAL_INIT}>
                <PayPalButtons
                    style={{
                        shape: "rect",
                        layout: "vertical",
                    }}
                    className="paypalButtonsBox"
                    createOrder={async () => {
                        setFatalError('')
                        try {
                            const response = await fetch(process.env.REACT_APP_URL_PAYPAL_CREATE_INTENT, {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json",
                                },
                                body: JSON.stringify(order),
                            });

                            const status = response.status;
                            const orderData = await response.json();

                            if (status === 500 || !orderData.id) {
                                throw new Error(orderData ? JSON.stringify(orderData) : '');
                            }

                            if (orderData.id) {
                                return orderData.id;
                            }

                        } catch (error) {
                            console.error(error);
                            setFatalError('member.pop.paypal.createIntent.fatal');
                        }
                    }}
                    onApprove={async (data, actions) => {
                        try {
                            let response = await fetch(
                                process.env.REACT_APP_URL_PAYPAL_CAPTURE,
                                {
                                    method: "POST",
                                    headers: {
                                        "Content-Type": "application/json",
                                    },
                                    body: JSON.stringify({ orderID: data.orderID, ...order }),
                                },
                            );

                            const status = response.status;
                            response = await response.json();

                            // Three cases to handle:
                            //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                            //   (2) Other non-recoverable errors -> Show a failure message
                            //   (3) Successful transaction -> Show confirmation or thank you message

                            const errorDetail = response?.details?.[0];

                            if (errorDetail?.issue === "INSTRUMENT_DECLINED") {
                                // (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                                // recoverable state, per https://developer.paypal.com/docs/checkout/standard/customize/handle-funding-failures/
                                return actions.restart();
                            } else if (status === 500) {
                                // (2) Other non-recoverable errors -> Show a failure message;
                                throw new Error(response ? JSON.stringify(response) : '');
                            } else {

                                const { paypalReceiptOrderId } = response;

                                if (paypalReceiptOrderId) {
                                    thankYou()
                                }

                            }
                        } catch (error) {
                            console.error(error);
                            setFatalError('member.pop.paymentMethod.error');
                        }
                    }}
                />
            </PayPalScriptProvider>
        </>
    );
})

export default PaypalCheckout;